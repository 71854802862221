import { Api } from "../api/Api";
import { useState } from 'react';

export const useEmailListHook = () => {
    const [isLoading, setIsLoading] = useState(false);

    const createEmaillist= async (data) => {
        setIsLoading(true);
        const response = await Api.post("api/v1/email-data-list", {
            data
        });
        setIsLoading(false);
        return response;
    };
    const deleteEmail = async (data) => {
        setIsLoading(true);
        try {
          const response = await Api.delete(`api/v1/email-data-list/${data.id}`,{data});
    
          setIsLoading(false);
          return response;
        } catch (error) {
          setIsLoading(false);
          throw error;
        }
      };
    const listEmail = async (data) => {
        setIsLoading(true);
        const response = await Api.get("/api/v1/email-data-list", {
            data
        });
        setIsLoading(false);
        return response;

    };

    const deleteEmaillist = async (rowId) => {
        setIsLoading(true);
        try {
            const response = await Api.delete(`api/v1/email-data-list/${rowId}`);

            setIsLoading(false);
            return response;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };

    const editEmail = async (rowId) => {
        setIsLoading(true);
        try {
            const response = await Api.get(`api/v1/email-data-list/${rowId}/edit`);
            setIsLoading(false);
            return response;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };

    const updateEmail = async (data, id) => {
        console.log("id");
        console.log(data);
        setIsLoading(true);
        try {
            const response = await Api.patch(`api/v1/email-data-list/${id}`, {
                data
            });
            setIsLoading(false);
            return response;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };

    const deleteMultipleItems = async (id, model) => {
        setIsLoading(true);
        try {
          const response = await Api.post(`api/v1/delete-multiple`, {
            id,
            model,
          });
          setIsLoading(false);
          return response;
        } catch (error) {
          setIsLoading(false);
          throw error;
        }
      };

    return {createEmaillist, listEmail, deleteEmaillist,deleteEmail,editEmail,updateEmail,deleteMultipleItems,  isLoading };
};
