export const ProjectCode = [
    { id : 1, label:"YLI-DEMO", value:"YLI-DEMO" },
    { id : 2, label:"SPLT075", value:"SPLT075" },
    { id : 3, label:"TMUC049", value:"TMUC049" },
    { id : 4, label:"All", value:"All" }
];

export const customStyles = {
    header: {
      style: {
        background: '#ddd',
        color: '#fff',
        borderBottom: '2px solid #ddd',
      },
    },
    table: {
      style: {
        border: '1px solid #ddd',
      },
    }
  };