import React, { useEffect, useState } from "react";
import SelectBox from "../UI/SelectBox";
import TextField from "../UI/TextField";
import "../../assets/css/addRule.scss";
import { useHistory, useLocation } from "react-router-dom";
import { useProjectCodeInfo } from "../../Hooks/useProjectCodeInfo";
import Loader from "../Utility/Loader";
import { useEntityAttribute } from "../../Hooks/useEntityAttribute";
import Button from "../UI/Button";


const GtmDataLayerpage = (props) => {

  const [extraFormData, setExtraFormData] = useState({
    id: "",
    project_code: "",
  });
  const [attributeNameList, setAttributes] = useState([]);
  const [errors, setErrors] = useState({});
  const [submitStart, setSubmitStart] = useState(false);
  const [submitEnd, setSubmitEnd] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { listEntityAttribute,CreateDataLayer,Deletedatalayer,isLoading } = useEntityAttribute();


  const history = useHistory();
  const [formData, setFormData] = useState([
    {
      attribute_name: "",
      click_event: "",
      question: "",
      answer:""
    },
  ]);
  const { listProjectCode } = useProjectCodeInfo();
  const [projectCodeList, setProjectCodeList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await listEntityAttribute();

        const formattedAttributes = response.data.map((item) => ({
          label: item.attribute_name,
          value: item.slug,
          id: item._id,
          project_code: item.project_code,
        }));
        setAttributes(formattedAttributes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const projectCodeResponse = await listProjectCode();
        const resProjectCodeList = projectCodeResponse.data.map((item) => ({
          label: item.project_code,
          value: item.project_code,
        }));
        setProjectCodeList(resProjectCodeList);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (event, sectionIndex) => {
    const { name, value } = event.target;
    switch (name) {
      case "project_code":
        setExtraFormData((prevData) => {
          return {
            ...prevData,
            [name]: value,
          };
        });
        break;

      default:
        setFormData((prevData) => {
          const newData = [...prevData];
          const originalFieldName = name.replace(`_${sectionIndex}`, ""); 
          newData[sectionIndex] = {
            ...newData[sectionIndex],
            [originalFieldName]: value,
          };
          return newData;
        });

        break;
    }
  };

  // const handleAddSection = () => {
  //   setFormData((prevData) => [
  //     ...prevData,
  //     {
  //       module: "",
  //       order_number: "",
  //     },
  //   ]);
  // };
  const handleAddSectionTwo = () => {
    setFormData((prevData) => [
      ...prevData,
      {
        attribute_name: "",
        click_event: "",
        question: "",
        answer:""
      }
    ]);
  };

  const removeSection = async (event, sectionIndex) => {
    const data = {
      sectionIndex: sectionIndex,
      attribute_name: formData[sectionIndex].attribute_name,
      project_code:extraFormData.project_code
    };
    await Deletedatalayer(data);
    const updatedFormData = formData.filter(
      (_, index) => index !== sectionIndex
    );
    setFormData(updatedFormData);
  };


  const convertToNewFormat = () => {
    const convertedData = {
      project_code: extraFormData.project_code,
      data: [],
    };
    formData.forEach((section) => {
        var { answer, attribute_name, click_event,question } = section;
        convertedData.data.push({
          attribute_name,
          click_event,
          question,
          answer
        });
    });
    return convertedData
  };

  const renderDynamicSections = () => {
    return formData.map((section, index) => (
      <div key={index} className="dynamic-section pt-3">
        <div className="row row-gap-1">
          {/* Add other fields with similar logic */}
          <div className="col-lg-3 col-md-4">
            <label className="form-label">Attribute Name</label>
            <SelectBox
              className="form-select"
              id={`attribute_name_${index}`}
              name={`attribute_name_${index}`}
              OptionValue={attributeNameList.filter(item => item.project_code === extraFormData.project_code)}
              value={section.attribute_name}
              onChange={(e) => handleInputChange(e, index)}
              error={errors[`attribute_name_${index}`]}
              emptyOption="Select Attribute Name"
            />
          </div>
          <div className="col-lg-2 col-md-5">
            <label className="form-label">ClickEvent</label>
            <TextField
              className="form-control"
              name={`click_event_${index}`}
              id={`click_event_${index}`}
              value={section.click_event}
              placeholder="Enter Click Event"
              onChange={(e) => handleInputChange(e, index)}
              error={errors[`click_event_${index}`]}
            />
          </div>
          <div className="col-lg-2 col-md-5">
            <label className="form-label">Question</label>
            <TextField
              className="form-control"
              name={`question_${index}`}
              id={`question_${index}`}
              value={section.question}
              placeholder="Enter Question"
              onChange={(e) => handleInputChange(e, index)}
              error={errors[`question_${index}`]}
            />
          </div>
          <div className="col-lg-2 col-md-5">
            <label className="form-label">Answers</label>
            <TextField
              className="form-control"
              name={`answer_${index}`}
              id={`answer_${index}`}
              value={section.answer}
              placeholder="Enter Answers"
              onChange={(e) => handleInputChange(e, index)}
              error={errors[`answer_${index}`]}
            />
          </div>
          <div className="col-sm-2  col-md-3"  style={{paddingTop: "6px"}}>
            <div className="row gap-2">
              <div className="col">
                <label className="form-label"></label>
                {index >= 0 && (
                  <div className="col d-flex ">
                    <Button
                      className="add-section fw-bolder fs-6"
                      onClick={(e) => removeSection(e, index)}
                      label="-"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  };


  const finalSubmit = async (action) => {
    try {
      if (action === "start") {
        if (extraFormData.project_code) {
          setSubmitStart(true);
          setSubmitEnd(false);
        }
      } else {
        const convertedData = convertToNewFormat();
        await CreateDataLayer(convertedData);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  return (
    <>
      <Loader active={isLoading} />
      <div className="AddRule table-section container-fluid mb-5">
        <h6 className="pg-ttl">GTM DataLayer</h6>
        <div className="row tablesec-bg py-3 px-3">
       
        <div className="col-sm-6 col-md-6">
            <div className="row justify-content-between my-2">
              <div className="col-md-12 ">
                <label className="col-form-label">Project Code</label>
              </div>
              <div className="col-md-12 col-lg-9">
                <SelectBox
                  className="form-select"
                  name="project_code"
                  id="project_code"
                  OptionValue={projectCodeList}
                  value={extraFormData.project_code}
                  onChange={handleInputChange}
                  error={errors.project_code}
                  emptyOption="Select project code"
                />
              </div>
            </div>
          </div>
          {submitEnd && <div className="col-lg-6 col-md-6 my-4 d-flex justify-content-left" style={{ paddingTop: "18px" }}>
            <button
              className="btn btn-danger fw-bolder fs-6"
              onClick={() => finalSubmit("start")}
            >
              Submit
            </button>
          </div>}
          {/* <br/> */}
          {submitStart && (<div>
            <div className="col d-flex justify-content-end">
              <button className='add-section fw-bolder fs-6' style={{padding: "8px 37px"}} onClick={handleAddSectionTwo}>+</button>
            </div>
            {renderDynamicSections()}
            <div className="col-sm-12 col-md-6"></div>
            <div className="col-lg-12 col-md-6 my-4 d-flex justify-content-end">
              <button
                className="btn btn-danger fw-bolder fs-6"
                onClick={() => finalSubmit("submit")}
              >
                Submit
              </button>
            </div>
          </div>)}
        </div>
      </div>
    </>
  );
};

export default GtmDataLayerpage;

