import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import SelectBox from "../UI/SelectBox";
import TextField from "../UI/TextField";
import "../../assets/css/addRule.scss";
import { Bodytype, MethodType } from "../../Constants/BodyTypeConstants";
import { useHttpClients } from "../../Hooks/useHttpClients";
import { useHistory } from "react-router-dom";
import { useProcessType } from "../../Hooks/useProcessType";
import Loader from "../Utility/Loader";

const HttpClientsWidgets = (props) => {
  const { createHttpClients } = useHttpClients();
  const {
    editHttpClient,
    updatetHttpClient,
    listingHttpClients,
    deleteHttpClients,
  } = useHttpClients();
  const [sendClientObject, setsendClientObject] = useState("");
  const { listProcess } = useProcessType();
  const history = useHistory();
  const handleMinus = (e, removeIndex) => {
    const array = headervalues.filter((item, index) => index !== removeIndex);
    setHeaderValues(array);
  };
  const handleChange = (e, index) => {
    let value = e.target.value;
    let name = e.target.name;
    setFormData({
      ...formData,
      data: {
        ...formData.data,
        [name]: value,
      },
    });
  };

  const [formData, setFormData] = useState({
    project_code: "",
    process_type_id: "",
    process_type: "",
    data: {
      body: "",
      method: "",
      headers: [],
      url: "",
    },
    response: [{ response_key: "", response_data: "", is_dynamic: "no" }],
  });
  const [headervalues, setHeaderValues] = useState([
    {
      header_name: "",
      header_value: "",
    },
  ]);
  const handleAddDiv = () => {
    setHeaderValues((headers) => [
      ...headers,
      {
        header_name: "",
        header_value: "",
      },
    ]);
  };

  const [extraFormData, setExtraExtraFormData] = useState({
    project_code: "",
    process_type: "",
    process_type_id: "",
    body_type: "",
    method_type: "",
    url_value: "",
  });
  const rowId = props.process_type_id;
  const [errors, setErrors] = useState({});
  const id = props.process_type_id;
  const handleInputChange = (event, sectionIndex) => {
    const { name, value, options } = event.target;
    switch (name) {
      case "process_type":
        const selectedOption = options[options.selectedIndex];
        const label = selectedOption.label;
        setExtraExtraFormData((prevData) => {
          return {
            ...prevData,
            [`${name}_id`]: value,
            [`${name}`]: label,
          };
        });
        break;
      case "project_code":
      case "body_type":
      case "method_type":
      case "url_value":
      case "header_name":
        setExtraExtraFormData((prevData) => {
          return {
            ...prevData,
            [name]: value,
          };
        });
        break;
      default:
        setFormData((prevData) => {
          const newData = { ...prevData };
          const originalFieldName = name.replace(`_${sectionIndex}`, "");
          const fieldName = name.split("_")[0];

          newData[fieldName][sectionIndex] = {
            ...newData[fieldName][sectionIndex],
            [originalFieldName]: value,
          };
          return newData;
        });
        break;
    }
  };
  const handleheaderChange = (e, index) => {
    const name = e.target.name;
    const value = e.target.value;
    setHeaderValues((header) => {
      const newData = [...header];
      const originalName = name.replace(`_${index}`, "");
      newData[index] = {
        ...newData[index],
        [originalName]: value,
      };
      return newData;
    });
  };

  const handleAddSection = (request) => {
    switch (request) {
      case "header":
        setFormData((prevData) => {
          const updatedPrevData = { ...prevData };
          updatedPrevData.header = [
            ...updatedPrevData.header,
            {
              header_name: "",
              header_value: "",
            },
          ];
          return updatedPrevData;
        });

        break;
      case "response":
        setFormData((prevData) => {
          const updatedPrevData = { ...prevData };
          updatedPrevData.response = [
            ...updatedPrevData.response,
            {
              response_key: "",
              response_data: "",
              is_dynamic: "no",
            },
          ];
          return updatedPrevData;
        });
        break;
    }
  };

  const removeSection = async (event, sectionIndex, operation) => {
    const data = {
      sectionIndex: sectionIndex,
      id: rowId,
    };
    try {
      await deleteHttpClients(rowId);
      setFormData((prevData) => {
        const updatedFormData = { ...prevData };
        updatedFormData[operation].splice(sectionIndex, 1);
        return updatedFormData;
      });
    } catch (error) {
      console.error("Error deleting section:", error);
    }
  };
  const handleClose = () => {
    props.setView(false);
    setsendClientObject("");
    setFormData({
      project_code: "",
      process_type_id: "",
      process_type: "",
      data: {
        body: "",
        method: "",
        headers: [],
        url: "",
      },
      response: [{ response_key: "", response_data: "", is_dynamic: "no" }],
    });
    setHeaderValues([
      {
        header_name: "",
        header_value: "",
      },
    ]);
    setExtraExtraFormData({
      project_code: "",
      process_type: "",
      process_type_id: "",
      body_type: "",
      method_type: "",
      url_value: "",
    });
    setErrors({});
  };

  const handleCheckboxChange = (sectionIndex) => {
    setFormData((prevData) => {
      const newData = { ...prevData };
      newData.response[sectionIndex] = {
        ...newData.response[sectionIndex],
        is_dynamic:
          newData.response[sectionIndex].is_dynamic === "no" ? "yes" : "no",
      };
      return newData;
    });
  };
  const renderDynamicSections = () => {
    return headervalues.map((item, index) => (
      <>
        <React.Fragment key={index}>
          <div key={index} className="dynamic-section pt-3">
            <div className="row row-gap-3">
              <div className="col-sm-6 col-md-5">
                <label className="form-label">Header name</label>
                <TextField
                  type="text"
                  className="form-control"
                  id={`header_name_${index}`}
                  name={`header_name_${index}`}
                  value={item.header_name}
                  placeholder="name....."
                  onChange={(e) => handleheaderChange(e, index)}
                />
              </div>
              <div className="col-sm-6 col-md-5">
                <label className="form-label">Value</label>
                <TextField
                  type="text"
                  className="form-control"
                  id={`header_value_${index}`}
                  name={`header_value_${index}`}
                  value={item.header_value}
                  placeholder="value....."
                  onChange={(e) => handleheaderChange(e, index)}
                />
              </div>
              <div className="col-sm-2 col-md-2 mb-5">
                <label className="form-label"></label>
                {index > 0 && (
                  <div className="col d-flex justify-content-end">
                    <button
                      className="add-section fw-bolder fs-6"
                      onClick={(e) => handleMinus(e, index)}
                    >
                      -
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
      </>
    ));
  };

  const renderDynamicSectionsTwo = () => {
    return (
      formData.response &&
      formData.response.map((section, index) => (
        <React.Fragment key={index}>
          <div key={index} className="dynamic-section pt-3 mb-3">
            <div className="col">
              <div className="row">
                <div className="col-auto">
                  <label className="form-label">Is dynamic</label>
                </div>
                <div className="col-auto">
                  <input
                    className="form-check-input p-2"
                    type="checkbox"
                    name={`advancedCheckbox_${index}`}
                    onChange={() => handleCheckboxChange(index)}
                    checked={section.is_dynamic === "yes" ? true : false}
                  />
                </div>
              </div>
            </div>
            <div className="row row-gap-3">
              <div className="col-sm-6 col-md-5">
                <label className="form-label">Response Key</label>
                <TextField
                  className="form-control"
                  name={`response_key_${index}`}
                  id={`response_key_${index}`}
                  value={section.response_key}
                  onChange={(e) => handleInputChange(e, index)}
                  error={errors[`response_key_${index}`]}
                />
              </div>
              <div className="col-sm-6 col-md-5">
                <label className="form-label">Response data</label>
                <TextField
                  className="form-control"
                  name={`response_data_${index}`}
                  id={`response_data_${index}`}
                  value={section.response_data}
                  onChange={(e) => handleInputChange(e, index)}
                  error={errors[`response_data_${index}`]}
                />
              </div>
              <div className="col-sm-2 col-md-2 mb-3">
                <label className="form-label"></label>
                {index > 0 && (
                  <div className="col d-flex justify-content-end">
                    <button
                      className="add-section fw-bolder fs-6"
                      onClick={(e) => removeSection(e, index, "response")}
                    >
                      -
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
      ))
    );
  };

  const convertToNewFormat = () => {
    const array = {
      project_code: props.project_code ? props.project_code : "",
      process_type_id: props.process_type_id ? props.process_type_id : "",
      process_type: props.process_type ? props.process_type : "",
      module_collection_id: props.moduleCollectionId
        ? props.moduleCollectionId
        : "",
      data: {
        body: formData.data.body,
        method: formData.data.method,
        url: formData.data.url,
        headers: [],
        response: [],
      },
      created_at: "",
      updated_at: "",
    };
    headervalues.forEach((elements) => {
      const { header_name, header_value } = elements;
      array.data.headers.push({
        header_name: header_name,
        header_value: header_value,
      });
    });

    formData.response.forEach((section) => {
      const { response_key, response_data, is_dynamic, ...rest } = section;
      if (response_key || response_data) {
        array.data.response.push({
          response_key: response_key,
          response_data: response_data,
          is_dynamic: is_dynamic,
        });
      }
    });

    return array;
  };

  useEffect(() => {
    if (props.process_type_id && props.view == true) {
      (async () => {
        try {
          props.setLoader(true);
          const listResponse = await listingHttpClients();
          const listprocess = await listProcess();
          const processResult = listprocess.data.find(
            (item) => item._id == props.process_type_id
          );
          const stepResult = processResult.steps.find(
            (item) =>
              item.module == props.index.module &&
              item.order_number == props.index.order_number
          );
          const listResult = listResponse.data.find(
            (item) => item.process_type_id == props.process_type_id
          );
          const response = await editHttpClient(
            stepResult.module_collection_id
          );
          if (response.data._id) {
            setsendClientObject(response.data._id);
          }
          if (response && response.status == 200) {
            formData.data.url = response.data.data.url;
            formData.data.body = response.data.data.body;
            formData.data.method = response.data.data.method;
            if (response.data.data.headers) {
              setHeaderValues(response.data.data.headers);
            }
            if (response.data.data.response) {
              formData.response = response.data.data.response;
            }
            props.setLoader(false);
          } else {
            setHeaderValues({
              header_name: "",
              header_value: "",
            });
          }
        } catch (error) {}
        props.setLoader(false);
      })();
    }
  }, [props.view, rowId]);
  useEffect(() => {
    props.setCollectionId && props.setCollectionId();
  }, [props.colectionid]);

  const finalSubmit = async () => {
    const array = convertToNewFormat();
    let response = null;
    let type = null;
    if (props.view == true && rowId && sendClientObject) {
      type = "update";
      response = await updatetHttpClient(array, sendClientObject);
    } else {
      type = "create";
      response = await createHttpClients(array);
    }
    const newFormData = [...props.formData];
    newFormData[props.formDataIndex].module_collection_id = response.data.id;
    props.setFormData(newFormData);
    closeAll(response.data.id, response.status, type);
  };

  const closeAll = (id, status, type) => {
    if (id && status == 200) {
      props.setView({ view: false });
      if (type == "create") {
        props.finalSubmit(id);
      }
      handleClose();
    }
  };

  return (
    <>
      {props.loader ? (
        <Loader active={props.loader} />
      ) : (
        <Modal show={props.view} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Http Client Widget</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="AddRule -table-section container-fluid -mb-5">
              <div className="row -tablesec-bg py-3 -px-3">
                <div className="col-sm-12 col-md-4">
                  <div className="row my-2">
                    <div className="col-md-12 col-lg-3">
                      <label className="col-form-label">URL</label>
                    </div>
                    <div className="col-md-12 col-lg-9">
                      <input
                        type="text"
                        defaultValue={formData.data.url}
                        className="form-control"
                        id="url"
                        name="url"
                        placeholder=".....URL........."
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="row my-2">
                    <div className="col-md-12 col-lg-3">
                      <label className="col-form-label">Body</label>
                    </div>
                    <div className="col-md-12 col-lg-9">
                      <SelectBox
                        className="form-select"
                        name="body"
                        id="project_code"
                        OptionValue={Bodytype}
                        value={formData.data.body}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="row my-2">
                    <div className="col-md-12 col-lg-3">
                      <label className="col-form-label">Method</label>
                    </div>
                    <div className="col-md-12 col-lg-9">
                      <SelectBox
                        className="form-select"
                        name="method"
                        id="project_code"
                        OptionValue={MethodType}
                        value={formData.data.method}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col mt-3">
                  <div className="row">
                    <div className="col d-flex align-items-center">
                      <h4 className="mb-0">Header</h4>
                    </div>
                    <div className="col d-flex justify-content-end">
                      <button
                        className="add-section fw-bolder fs-6"
                        onClick={handleAddDiv}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  {renderDynamicSections()}
                  <div className="row">
                    <div className="col d-flex align-items-center">
                      <h4 className="mb-0">Response</h4>
                    </div>
                    <div className="col d-flex justify-content-end">
                      <button
                        className="add-section fw-bolder fs-6"
                        onClick={() => handleAddSection("response")}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  {renderDynamicSectionsTwo()}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <div className="AddRule">
              <button
                className="btn btn-danger fw-bolder fs-6"
                onClick={finalSubmit}
              >
                Submit
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default HttpClientsWidgets;
