import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import "./App.scss";
import Layout from "./Components/Layouts/Layout";
import Login from "./Components/Pages/Login";
import Dashboard from "../src/Components/Pages/Dashboard";
import AuthProvider from "./Components/Utility/AuthContext";
import TemplateViewer from "./Components/Pages/TemplateViewer";

const App = () => {

  return (
    <>
      <Router>
        <AuthProvider>
          <Switch>
            <Route exact path="/dashboard">
              <Dashboard />
            </Route>
            <Route exact path={['/', '/login']}>
              <Login />
            </Route>
            <Route exact path="/template-editor" component={TemplateViewer} />
            <Route path="/" component={Layout} />
          </Switch>
        </AuthProvider>
      </Router>
    </>
  );
};

export default App;
