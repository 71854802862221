import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { ComparisonOperators } from "../../Constants/OperatorConstants";
import { JoinOperators } from "../../Constants/OperatorConstants";
import { useEntityAttribute } from "../../Hooks/useEntityAttribute";
import { useRule } from "../../Hooks/useRule";
import SelectBox from "../UI/SelectBox";
import TextField from "../UI/TextField";
import "../../assets/css/addRule.scss";
import { useHistory } from "react-router-dom";
import { useProcessType } from "../../Hooks/useProcessType";
import Loader from "../Utility/Loader";

const AudienceSelection = (props) => {
  const urlParams = new URLSearchParams(window.location.search);
  const rowId = urlParams.get("id") ? urlParams.get("id") : "";
  const history = useHistory();
  const { listEntityAttribute } = useEntityAttribute();
  const { createRule, editRule, updateRule, getRuleWithProcessId, listrules } =
    useRule();
  const { listProcess } = useProcessType();
  const [formData, setFormData] = useState([
    {
      join_operator: "",
      attribute: "",
      operator: "",
      value: "",
      is_advanced: "no",
      is_advanced_rules: [
        {
          rules_combined_by: "",
          properties: [
            {
              join_operator: "",
              attribute: "",
              operator: "",
              value: "",
            },
          ],
        },
      ],
    },
  ]);
  const [attributes, setAttributes] = useState([]);
  const [audianceSelection, setAudianceSelection] = useState("");
  const [
    valuesCorrespondingToProjectCode,
    setValuesCorrespondingToProjectCode,
  ] = useState({
    attributes: [],
  });
  const [errors, setErrors] = useState({});
  // to find the corresponding processType and attributes to project_code
  useEffect(() => {
    // Define a function to filter values based on project code
    const filterValues = () => {
      const matchingAttributes = attributes.filter(
        (item) => item.project_code === props.project_code
      );
      // Combine matching attributes and process types into label value format
      const formattedValues = {
        attributes: matchingAttributes.map((attribute) => ({
          label: attribute.label,
          value: attribute.value,
        })),
      };

      setValuesCorrespondingToProjectCode(formattedValues);
    };

    filterValues();
  }, [attributes, props.project_code]);
  /* eslint-disable no-restricted-globals */
  const searchParams = new URLSearchParams(location.search);
  /* eslint-enable no-restricted-globals */
  const id = searchParams.get("id");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await listEntityAttribute();
        const response3 = await listProcess();

        const formattedAttributes = response.data.map((item) => ({
          label: item.attribute_name,
          value: item.slug,
          id: item._id,
          project_code: item.project_code,
        }));
        // Update the state with the formatted data
        setAttributes(formattedAttributes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [props.process_type_id]);

  useEffect(() => {
    if (props.process_type_id && props.view === true) {
      (async () => {
        try {
          props.setLoader(true);
          //const listResponse = await listrules();
          const listprocess = await listProcess();
          const processResult = listprocess.data.find(
            (item) => item._id == props.process_type_id
          );
          const stepResult = processResult.steps.find(
            (item) =>
              item.module == props.index.module &&
              item.order_number == props.index.order_number
          );
          if (stepResult && stepResult.module_collection_id) {
            const response = await editRule(stepResult.module_collection_id);
            if (response.data._id) {
              setAudianceSelection(response.data._id);
            }
            if (response && response.status == 200) {
              const responseData = response.data.options;
              if (responseData && responseData.length > 0) {
                const updatedFormData = responseData.map((item) => {
                  if (item.is_advanced == "no") {
                    return {
                      join_operator: item.join_operator,
                      attribute: item.attribute,
                      operator: item.operator,
                      value: item.value,
                      is_advanced: "no",
                      is_advanced_rules: [
                        {
                          rules_combined_by: "",
                          properties: [
                            {
                              join_operator: "",
                              attribute: "",
                              operator: "",
                              value: "",
                            },
                          ],
                        },
                      ],
                    };
                  } else if (item.is_advanced == "yes") {
                    return {
                      join_operator: "",
                      attribute: "",
                      operator: "",
                      value: "",
                      is_advanced: "yes",
                      is_advanced_rules: item.rules,
                    };
                  }
                });
                setFormData(updatedFormData);
              } else {
                console.log(
                  "Response data is empty. Setting formData to default."
                );
              }
              props.setLoader(false);
            }
          }
        } catch (error) {
          console.error("Error Edit Http:", error);
        }
        props.setLoader(false);
      })();
    }
  }, [props.view, props.process_type_id, props.index]);

  const handleCheckboxChange = (sectionIndex) => {
    setFormData((prevData) => {
      const newData = [...prevData];
      newData[sectionIndex] = {
        ...newData[sectionIndex],
        is_advanced: newData[sectionIndex].is_advanced === "no" ? "yes" : "no",
      };
      // Reset relevant fields if is_advanced is set to "no"
      if (newData[sectionIndex].is_advanced === "no") {
        newData[sectionIndex] = {
          ...newData[sectionIndex],
          join_operator: "", // Reset operator or other relevant fields
        };
      }
      return newData;
    });
  };
  const handleInputChange = (
    event,
    sectionIndex,
    ruleIndex,
    propertiesIndex
  ) => {
    const { name, value, options } = event.target;
    setFormData((prevData) => {
      const newData = [...prevData];
      const originalFieldName = name.replace(`_${sectionIndex}`, ""); // Remove the index from the field name
      if (newData[sectionIndex].is_advanced == "yes") {
        if (originalFieldName == "rules_combined_by") {
          newData[sectionIndex].is_advanced_rules[ruleIndex] = {
            ...newData[sectionIndex].is_advanced_rules[ruleIndex],
            [originalFieldName]: value,
          };
        } else {
          newData[sectionIndex].is_advanced_rules[ruleIndex].properties[
            propertiesIndex
          ] = {
            ...newData[sectionIndex].is_advanced_rules[ruleIndex].properties[
              propertiesIndex
            ],
            [originalFieldName]: value,
          };
        }
      } else {
        newData[sectionIndex] = {
          ...newData[sectionIndex],
          [originalFieldName]: value,
        };
      }
      return newData;
    });
  };

  const handleAddSection = () => {
    setFormData((prevData) => [
      ...prevData,
      {
        join_operator: "",
        attribute: "",
        operator: "",
        value: "",
        is_advanced: "no",
        is_advanced_rules: [
          {
            rules_combined_by: "",
            properties: [
              {
                join_operator: "",
                attribute: "",
                operator: "",
                value: "",
              },
            ],
          },
        ],
      },
    ]);
  };

  const handleDeleteSection = (index) => {
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData.splice(index, 1);
      return updatedFormData;
    });
  };

  const handleCombineRule = (index, advancedRuleIndex, operation) => {
    switch (operation) {
      case "Add":
        setFormData((preFormData) => {
          const newCombineRuleFormData = [...preFormData];
          newCombineRuleFormData[index] = {
            ...newCombineRuleFormData[index],
            is_advanced_rules: [
              ...newCombineRuleFormData[index].is_advanced_rules,
              {
                rules_combined_by: "",
                properties: [
                  {
                    join_operator: "",
                    attribute: "",
                    operator: "",
                    value: "",
                  },
                ],
              },
            ],
          };
          return newCombineRuleFormData;
        });
        break;
      case "Delete":
        setFormData((preFormData) => {
          const updatedFormData = [...preFormData];
          updatedFormData[index].is_advanced_rules.splice(advancedRuleIndex, 1);
          return updatedFormData;
        });
        break;
      default:
        break;
    }
  };
  const handlePropertiesRule = (
    index,
    advancedRuleIndex,
    PropertiesRuleIndex,
    operation
  ) => {
    switch (operation) {
      case "Add":
        setFormData((preFormData) => {
          const newPropertiesRuleFormData = [...preFormData];
          newPropertiesRuleFormData[index].is_advanced_rules[
            advancedRuleIndex
          ] = {
            ...newPropertiesRuleFormData[index].is_advanced_rules[
              advancedRuleIndex
            ],
            properties: [
              ...newPropertiesRuleFormData[index].is_advanced_rules[
                advancedRuleIndex
              ].properties,
              {
                join_operator: "",
                attribute: "",
                operator: "",
                value: "",
              },
            ],
          };
          return newPropertiesRuleFormData;
        });
        break;
      case "delete":
        setFormData((preFormData) => {
          const updatedFormData = [...preFormData];
          updatedFormData[index].is_advanced_rules[
            advancedRuleIndex
          ].properties.splice(PropertiesRuleIndex, 1);
          return updatedFormData;
        });
        break;
      default:
        break;
    }
  };
  const renderDynamicSections = () => {
    return formData.map((section, index) => (
      <div key={index} className="dynamic-section pt-3">
        <div className="row mb-1">
          <div className="col">
            <div className="row">
              <div className="col-auto">
                <label className="form-label">Advanced</label>
              </div>
              <div className="col-auto">
                <input
                  className="form-check-input p-2"
                  type="checkbox"
                  name={`advancedCheckbox_${index}`}
                  onChange={() => handleCheckboxChange(index)}
                  checked={section.is_advanced === "yes" ? true : false}
                />
              </div>
            </div>
          </div>
          <div className="col d-flex justify-content-end">
            <button
              className="add-section fw-bolder fs-6 mr-1"
              onClick={() => handleDeleteSection(index)}
            >
              - remove rule
            </button>
          </div>
        </div>
        <div className="row row-gap-3">
          {section.is_advanced === "yes" && (
            <div className="row tablesec-bg py-3 px-3">
              <button
                className="rule-dy-btn"
                onClick={() => handleCombineRule(index, null, "Add")}
              >
                + Combine rule{" "}
              </button>
              {renderDynamicAdvancedRules(index)}
            </div>
          )}
          {/* Add other fields with similar logic */}
          {section.is_advanced === "no" && (
            <>
              <div
                className={`col-sm-6 ${
                  section.is_advanced === "yes" ? "col-md-3" : "col-md-4"
                }`}
              >
                <label className="form-label">Attributes</label>
                <SelectBox
                  className="form-select"
                  name={`attribute_${index}`}
                  id={`attribute_${index}`}
                  OptionValue={valuesCorrespondingToProjectCode.attributes}
                  emptyOption="Select attribute"
                  emptyOptionVal=""
                  value={section.attribute}
                  onChange={(e) => handleInputChange(e, index)}
                  error={errors[`attribute_${index}`]}
                />
              </div>
              <div
                className={`col-sm-6 ${
                  section.is_advanced === "yes" ? "col-md-3" : "col-md-4"
                }`}
              >
                <label className="form-label">Operator</label>
                <SelectBox
                  className="form-select"
                  name={`operator_${index}`}
                  id={`operator_${index}`}
                  OptionValue={ComparisonOperators}
                  value={section.operator}
                  onChange={(e) => handleInputChange(e, index)}
                  error={errors[`operator_${index}`]}
                />
              </div>
              <div
                className={`col-sm-6 ${
                  section.is_advanced === "yes" ? "col-md-3" : "col-md-4"
                }`}
              >
                <label className="form-label">Value</label>
                <TextField
                  className="form-control"
                  name={`value_${index}`}
                  id={`value_${index}`}
                  value={section.value}
                  onChange={(e) => handleInputChange(e, index)}
                  error={errors[`value_${index}`]}
                />
              </div>
            </>
          )}
        </div>
      </div>
    ));
  };

  const renderDynamicAdvancedRules = (mainRuleIndex) => {
    return formData.map(
      (section, index) =>
        index === mainRuleIndex && (
          <>
            {section.is_advanced_rules.map(
              (isAdvancedRules, advancedRuleIndex) => (
                <div
                  key={advancedRuleIndex}
                  className={`row tablesec-bg py-3 px-3 col-sm-12`}
                >
                  <div>
                    {section.is_advanced_rules.length > 1 && (
                      <button
                        className="rule-dy-btn"
                        onClick={() =>
                          handleCombineRule(index, advancedRuleIndex, "Delete")
                        }
                      >
                        - Combine rule
                      </button>
                    )}
                    <button
                      className="rule-dy-btn"
                      onClick={() =>
                        handlePropertiesRule(
                          index,
                          advancedRuleIndex,
                          null,
                          "Add"
                        )
                      }
                    >
                      + Properties rule
                    </button>
                  </div>
                  <label className="form-label">Rules Combined By</label>
                  <SelectBox
                    className="form-select"
                    name={`rules_combined_by_${mainRuleIndex}`}
                    id={`rules_combined_by_${mainRuleIndex}`}
                    OptionValue={JoinOperators}
                    value={isAdvancedRules.rules_combined_by}
                    onChange={(e) =>
                      handleInputChange(e, mainRuleIndex, advancedRuleIndex)
                    }
                    error={errors[`rules_combined_by_${mainRuleIndex}`]}
                    emptyOption="Select combined rule operator"
                  />
                  {isAdvancedRules.properties.map(
                    (rulesProperties, propertiesIndex) => (
                      <div className="row tablesec-bg py-3 px-3">
                        {isAdvancedRules.properties.length > 1 && (
                          <div>
                            <button
                              className="rule-dy-btn"
                              onClick={() =>
                                handlePropertiesRule(
                                  index,
                                  advancedRuleIndex,
                                  propertiesIndex,
                                  "delete"
                                )
                              }
                            >
                              - properties rule
                            </button>
                          </div>
                        )}
                        <div className={`col-sm-6 col-md-3`}>
                          <label className="form-label">Join Operator</label>
                          <SelectBox
                            className="form-select"
                            name={`join_operator_${mainRuleIndex}`}
                            id={`join_operator_${mainRuleIndex}`}
                            OptionValue={JoinOperators}
                            value={rulesProperties.join_operator}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                mainRuleIndex,
                                advancedRuleIndex,
                                propertiesIndex
                              )
                            }
                            error={errors[`join_operator_${mainRuleIndex}`]}
                            emptyOption="Select join operator"
                          />
                        </div>
                        <div className={`col-sm-6 col-md-3`}>
                          <label className="form-label">Attributes</label>
                          <SelectBox
                            className="form-select"
                            name={`attribute_${mainRuleIndex}`}
                            id={`attribute_${mainRuleIndex}`}
                            OptionValue={
                              valuesCorrespondingToProjectCode.attributes
                            }
                            emptyOption="Select attribute"
                            emptyOptionVal=""
                            value={rulesProperties.attribute}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                mainRuleIndex,
                                advancedRuleIndex,
                                propertiesIndex
                              )
                            }
                            error={errors[`attribute_${mainRuleIndex}`]}
                          />
                        </div>
                        <div className={`col-sm-6 col-md-3`}>
                          <label className="form-label">Operator</label>
                          <SelectBox
                            className="form-select"
                            name={`operator_${mainRuleIndex}`}
                            id={`operator_${mainRuleIndex}`}
                            OptionValue={ComparisonOperators}
                            value={rulesProperties.operator}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                mainRuleIndex,
                                advancedRuleIndex,
                                propertiesIndex
                              )
                            }
                            error={errors[`operator_${mainRuleIndex}`]}
                          />
                        </div>
                        <div className={`col-sm-6 col-md-3`}>
                          <label className="form-label">Value</label>
                          <TextField
                            className="form-control"
                            name={`value_${mainRuleIndex}`}
                            id={`value_${mainRuleIndex}`}
                            value={rulesProperties.value}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                mainRuleIndex,
                                advancedRuleIndex,
                                propertiesIndex
                              )
                            }
                            error={errors[`value_${mainRuleIndex}`]}
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>
              )
            )}
          </>
        )
    );
  };

  const convertToNewFormat = () => {
    const convertedData = {
      project_code: props.project_code,
      process_type: props.process_type,
      process_type_id: props.process_type_id,
      module_collection_id: props.moduleCollectionId
        ? props.moduleCollectionId
        : "",
      options: [],
    };

    formData.map((section) => {
      if (section.is_advanced == "yes") {
        var { is_advanced, join_operator, attribute, operator, value } =
          section;
        convertedData.options.push({
          is_advanced,
          rules: section.is_advanced_rules,
        });
      } else {
        var { is_advanced, join_operator, attribute, operator, value } =
          section;
        convertedData.options.push({
          is_advanced,
          join_operator,
          attribute,
          operator,
          value,
        });
      }
    });

    return convertedData;
  };

  const finalSubmit = () => {
    const convertedData = convertToNewFormat();
    let response = null;
    let type = null;
    (async () => {
      if (props.view == true && rowId && audianceSelection) {
        type = "update";
        response = await updateRule(convertedData, audianceSelection);
      } else {
        type = "create";
        response = await createRule(convertedData);
      }
      const moduleCollectionId = {
        module_collection_id: response.data.id,
      };
      const newFormData = [...props.formData];
      newFormData[props.formDataIndex].module_collection_id = response.data.id;
      props.setFormData(newFormData);
      closeAll(response.data.id, response.status, type);
    })();
  };

  const closeAll = (id, status, type) => {
    if (id && status == 200) {
      props.setView(false );
      if (type == "create") {
        props.finalSubmit(id);
      }
      handleClose();
    }
  };

  const handleClose = () => {
    props.setView(false);
    setAudianceSelection();
    setFormData([
      {
        join_operator: "",
        attribute: "",
        operator: "",
        value: "",
        is_advanced: "no",
        is_advanced_rules: [
          {
            rules_combined_by: "",
            properties: [
              {
                join_operator: "",
                attribute: "",
                operator: "",
                value: "",
              },
            ],
          },
        ],
      },
    ]);
  };
  return (
    <>
      {props.loader ? (
        <Loader active={props.loader} />
      ) : (
        <Modal show={props.view} onHide={handleClose} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Audience Selection</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="AddRule table-section container-fluid mb-5">
              <div className="row  py-3 px-3">
                <div className="col mt-3">
                  <div className="row">
                    <div className="col d-flex align-items-center">
                      <h4 className="mb-0">Add Rule</h4>
                    </div>
                    <div className="col d-flex justify-content-end">
                      <button
                        className="add-section fw-bolder fs-6"
                        onClick={handleAddSection}
                      >
                        + add rule
                      </button>
                    </div>
                  </div>
                  {renderDynamicSections()}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <div className="AddRule">
              <button
                className="btn btn-danger fw-bolder fs-6"
                onClick={() => finalSubmit()}
              >
                Submit
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default AudienceSelection;
