import React, { useState, useEffect } from "react";
import TogglePopup from "../Includes/Modules/TogglePopup";
import TableHead from "../Utility/TableHead";
import DataTable from "react-data-table-component";
import { ModuleColumns } from "../../Constants/TableColumns";
import { useModuleInfo } from "../../Hooks/useModuleInfo";
import TextField from "../UI/TextField";
import "../../assets/css/module.scss";
import DeletemodulePopup from "../Includes/Modules/DeletemodulePopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Loader from "../Utility/Loader";

const Module = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const { listModuleCode, isLoading } = useModuleInfo();
  const [showEdit, setShowEdit] = useState(false);
  const [moduleCode, setModulecode] = useState("");
  const [showCode, setshowCode] = useState("");
  const [showName, setShowName] = useState("");
  const [showSlug, setShowSlug] = useState("");
  const [showWidgets, setShowWidgets] = useState("");
  const [showAllowedIn, setshowAllowedIn] = useState("");
  const [deletes, setDelete] = useState(false);
  const [showID, setShowID] = useState("");
  const [toggleListing, setToggleListing] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [multipleValue, setMultipleValue] = useState("");
  const [id, setDeleteId] = useState("");
  const [selectAll, setSelectAll] = useState(false); // New state to track select all

  const ModuleColumnsWithActions = [
  
    ...ModuleColumns,
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <button onClick={() => moduleEdit(row.id, row.status)}>
            <FontAwesomeIcon icon={faEdit} />
          </button>
        </div>
      ),
    },
  ];
  const filterModuleData = (moduleData, filterText) => {
    return moduleData.filter(
      (item) =>
        item.id.toString().toLowerCase().includes(filterText) ||
        item.module_name.toLowerCase().includes(filterText) ||
        item.status.toString().toLowerCase().includes(filterText) ||
        item.slug.toString().toLowerCase().includes(filterText) ||
        item.allowed_in.toString().toLowerCase().includes(filterText)
    );
  };
  
  useEffect(() => {
    (async () => {
      const response = await listModuleCode();
      const moduleData = response.data.map((item) => ({
        id: item._id,
        module_name: item.module_name,
        status: item.status === 1 ? "ACTIVE" : "INACTIVE",
        slug: item.slug,
        widgets: item.widgets,
        allowed_in:
          Array.isArray(item.allowed_in) && item.allowed_in.length >= 2
            ? item.allowed_in.join(" , ")
            : item.allowed_in || [],
      }));
      setData(moduleData);
      setFilteredData(moduleData);
    })();
  }, [toggleListing]);

  useEffect(() => {
    (async () => {
      const response = await listModuleCode();
      const moduleData = response.data.map((item) => ({
        id: item._id,
        module_name: item.module_name,
        status: item.status === 1 ? "ACTIVE" : "INACTIVE",
        slug: item.slug,
        widgets: item.widgets,
        allowed_in:
          Array.isArray(item.allowed_in) && item.allowed_in.length >= 2
            ? item.allowed_in.join(" , ")
            : item.allowed_in || [],
      }));
      setData(moduleData);
      if (filterText) {
        const filtered = filterModuleData(moduleData, filterText);
        setFilteredData(filtered);
      } else {
        setFilteredData(moduleData);
      }
    })();
  }, [toggleListing, filterText]);
  

  const handleFilter = (e) => {
    const keyword = e.target.value.toLowerCase();
    const filtered = filterModuleData(data, keyword);
    setFilteredData(filtered);
    setFilterText(keyword);
  };
  const moduleEdit = (module_id, status) => {
    setShowEdit(true);
    setModulecode(status);
    const editFilter = data.find((item) => item.id === module_id);
    setShowName(editFilter.module_name);
    setshowCode(editFilter.status);
    setShowSlug(editFilter.slug);
    setShowWidgets(editFilter.widgets);
    setshowAllowedIn(editFilter.allowed_in);
    setShowID(editFilter.id);
  };
  const moduleDelete = async (module_id) => {
    setDelete(true);
    setShowID(module_id);
  };

  const handleCheckboxChange = (rowId) => {
    const updatedSelectedRows = selectedRows.includes(rowId)
      ? selectedRows.filter((id) => id !== rowId)
      : [...selectedRows, rowId];
    setSelectedRows(updatedSelectedRows);
  };

  const handleMultipleDelete = async () => {
    setDelete(true);
    setDeleteId(selectedRows);
    setMultipleValue("multiple");
  };

  return (
    <>
      <Loader active={isLoading} />
      <div className="col-lg-12 table-section module">
        <div className="row m-0">
          <div className="col-lg-12 col-md-12 p-1">
            <div className="tablesec-bg">
              <div className="col-lg-12 py-3 px-3">
                <div className="row">
                  <div className="col-lg-4" id="search_bar1">
                    <TableHead title="Module Management" />
                  </div>
                  <div className="col-lg-5"></div>

                  <div className="col-lg-1 text-right">
                    <a href="#">
                      <i
                        className="fa-solid fa-rotate px-2 mt-2"
                        aria-hidden="true"
                        data-toggle="tooltip"
                        title="Refresh Page"
                      ></i>
                    </a>
                  </div>
                  <div className="col-lg-2 text-right">
                    <TogglePopup
                      setData={setData}
                      setFilteredData={setFilteredData}
                      showEdit={showEdit}
                      moduleCode={moduleCode}
                      showCode={showCode}
                      setshowCode={setshowCode}
                      showName={showName}
                      showSlug={showSlug}
                      showWidgets={showWidgets}
                      setShowName={setShowName}
                      setShowSlug={setShowSlug}
                      setShowWidgets={setShowWidgets}
                      moduleID={showID}
                      setToggleListing={setToggleListing}
                      toggleListing={toggleListing}
                      showAllowedIn={showAllowedIn}
                      setshowAllowedIn={setshowAllowedIn}
                      setShowEdit={setShowEdit}
                      filterText={filterText}
                      setFilterText={setFilterText}
                    />
                  </div>
                </div>
              </div>
              <div className="table_hold">
                <div>
                  <TextField
                    type="text"
                    value={filterText}
                    onChange={handleFilter}
                    placeholder="Search..."
                    className="keyword-search"
                  />

                 

                  <DataTable
                    columns={ModuleColumnsWithActions}
                    data={filterText.length > 0 ? filteredData : data}
                    pagination
                    paginationPerPage={10}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Module;
