import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useSchedules } from "../../../Hooks/useSchedules";
import Loader from "../../Utility/Loader";
import { useMultipleDelete } from "../../../Hooks/useMultipleDelete";

const DeletePopup = (props) => {
  const { deleteSchedules, isLoading } = useSchedules();
  const {deleteMultipleItems} = useMultipleDelete();

  const handleClose = () => props.setDelete(false);
  const { listSchedules } = useSchedules();

  const listSchedule = async () => {
    const response = await listSchedules();
    const scheduleData = response.data.map((item) => ({
      _id: item._id,
      cron_name: item.name,
      slug: item.slug,
      project_code: item.project_code,
      process_type: item.process_type,
      cron_tab: item.cron_tab,
      status: item.status,
      process_type_id: item.process_type_id,
    }));

    const keyword = props.filterText.toLowerCase();
    const filteredData = scheduleData.filter(
      (item) =>
        item._id.toString().toLowerCase().includes(keyword) ||
        item.cron_name.toLowerCase().includes(keyword) ||
        item.project_code.toString().toLowerCase().includes(keyword)
    );

    props.setFilteredData(filteredData);
    props.setFilterText(keyword);
    props.setData(scheduleData);
  };

  const handleDelete = async () => {
    if (props.multipleValue == "multiple") {
      const response = await deleteMultipleItems(props.id, "Schedule");
      props.setMultipleValue("");
    } else {
      const response = await deleteSchedules(props.scheduleID);
    }
    handleClose();
    listSchedule();
    props.setToggleListing(!props.toggleListing);
  };
  return (
    <div>
      <Loader active={isLoading} />
      <Modal show={props.deletes} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default DeletePopup;
