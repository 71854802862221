import React, { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { usePages } from '../../../Hooks/usePages';

const TemplateElementEditor = ({ element, setElement, allowEdit, setAllowEdit, template_id, page_id, originalPlaceHolders }) => {
  const [editableContent, setEditableContent] = useState('');
  const [storedEdits, setStoredEdits] = useState({});
  const [base64Image, setBase64Image] = useState();
  const { createPlaceholders, isLoading } = usePages();

  // useEffect(() => {
  //   console.log(originalPlaceHolders);
  // }, [originalPlaceHolders])


  useEffect(() => {
    if (element) {
      // console.log(element || element.src);
      setEditableContent(element.innerHTML);
    }
  }, [element]);

  const handleInputChange = (e) => {
    setEditableContent(e.target.value);
  };

  const handleSaveChanges = () => {
    const placeholderValue = element.getAttribute('data-dynamic_placeholder');
    if (base64Image) {
      const uploadData = {
        "imageFile": base64Image.base64TextString
      };

      if (element.tagName.toLowerCase() === "img") {
        element.src = base64Image.base64TextString;

        if (placeholderValue) {
          setStoredEdits(prevState => ({
            ...prevState,
            [placeholderValue]: base64Image.base64TextString
          }));
        }
      }
    } else if (element) {
      element.innerHTML = editableContent;
      // store the edited values along with their keys
      if (placeholderValue) {
        setStoredEdits(prevState => ({
          ...prevState,
          [placeholderValue]: editableContent
        }));
      }
    }
    handleClose();
  };

  const handleClose = () => {
    setEditableContent(''); // Reset editable content
    setElement(null); // Clear element
    setBase64Image();
  };

  // confirm modal
  const [confirmModal, setConfirmModal] = useState(false)
  const confirmModalHide = () => {
    setConfirmModal(false);
    handleClose();
  };
  const confirmModalSave = () => {
    saveEdit();
    setConfirmModal(false);
  };
  // end

  const saveEdit = async () => {
    if (template_id && page_id) {
      let data = {
        page_id: page_id,
        template_id: template_id,
        place_holders: storedEdits
      };

      const response = await createPlaceholders(data);
      if (response.status) {

      }
    }
  };

  const userfile = {
    preview: undefined,
    title: undefined
  }

  const _handleReaderLoaded = (e) => {
    let binaryString = e.target.result;
    setBase64Image({
      base64TextString: binaryString
    });
  };

  const ImageSelector = async (e) => {
    let imageFile = e.target.files[0];
    if (imageFile) {
      // let extension = e.target.files[0].type;
      let imgsize = e.target.files[0].size;

      if (imgsize > 1024 * 1024 * 20) {
        // setIsInValidfFile(true);
        // setErrorMessage({ txt: 'Please choose a file less than 20mb' });
        return;
      }
      userfile.preview = URL.createObjectURL(imageFile);
      userfile.title = imageFile.name;

      if (imageFile) {
        let reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this);
        reader.readAsDataURL(imageFile);
      }
    }
  }

  const resetContent = () => {
    const placeholderValue = element.getAttribute('data-dynamic_placeholder');
    if (element.tagName.toLowerCase() === "img") {
      element.src = originalPlaceHolders[placeholderValue];
      // setEditableContent(originalPlaceHolders[placeholderValue]);
    } else if (element) {
      element.innerHTML = originalPlaceHolders[placeholderValue];
      // setEditableContent(originalPlaceHolders[placeholderValue]);
    }
    if (placeholderValue) {
      setStoredEdits(prevState => {
        return {
          ...prevState,
          [placeholderValue]: "{{delete}}"
        };
      });
    }
    handleClose();
  }

  return (
    <>
      <div className="edit" onClick={() => setAllowEdit(!allowEdit)}>
        {allowEdit ? (
          <svg className="pen-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M6 18 17.94 6M18 18 6.06 6" />
          </svg>
        ) : (
          <div className="row">
            <div className="col"><svg className="pen-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28" />
            </svg></div>
            <div className="col ps-0 pen-icon-text align-items-center">
              <p className='m-0 fw-bold'>Edit</p>
            </div>
          </div>
        )}
      </div>
      {Object.keys(storedEdits).length > 0 &&
        <div className="edit-save" onClick={() => (handleClose(), setConfirmModal(true))}>
          <div className="row">
            <div className="col"><svg className="pen-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M11 16h2m6.707-9.293-2.414-2.414A1 1 0 0 0 16.586 4H5a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V7.414a1 1 0 0 0-.293-.707ZM16 20v-6a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v6h8ZM9 4h6v3a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1V4Z" />
            </svg></div>
            <div className="col ps-0 pen-icon-text align-items-center">
              <p className='m-0 fw-bold'>Confirm</p>
            </div>
          </div>
        </div>
      }
      {/* modal section */}
      {allowEdit &&
        <Modal className="common-modal-class" size="lg" show={!!element} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Live html editor</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {element && (
              <Form className='pb-3' onSubmit={(e) => (e.preventDefault(), handleSaveChanges())}>
                <div className="d-flex justify-content-end">
                  <p className="reset-btn btn btn-danger" onClick={() => resetContent()}>Reset to default</p>
                </div>
                <Form.Group className="row gy-4">
                  {element.tagName.toLowerCase() === "img" ?
                    <div className='col-12'>
                      <Form.Label>select an image:</Form.Label>
                      <Form.Control type="file" accept='image/*' onChange={(e) => ImageSelector(e)} />
                    </div>
                    :
                    <div className='col-12'>
                      <Form.Label>Change the content:</Form.Label>
                      <Form.Control type="text" value={editableContent}
                        onChange={handleInputChange} />
                    </div>
                  }
                </Form.Group>
              </Form>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSaveChanges}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      }
      <Modal className="common-modal-class" size="lg" show={confirmModal} onHide={confirmModalHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm changes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your current changes will be saved
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={confirmModalHide}>
            Close
          </Button>
          <Button variant="primary" onClick={confirmModalSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TemplateElementEditor;
