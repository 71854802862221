import { Api } from "../api/Api";
import { useState } from 'react';

export const useProcessType = () => {
  const [isLoading, setIsLoading] = useState(false);

  const createProcessType = async (data) => {
    setIsLoading(true);
    const response = await Api.post("api/v1/process-type", {
      data
    });
    setIsLoading(false);
    return response;
  };

  const listProcess = async (data) => {
    setIsLoading(true);
    const response = await Api.get("/api/v1/process-type", {
      data
    });
    setIsLoading(false);
    return response;

  };

  const deleteProcess = async (rowId) => {
    setIsLoading(true);
    try {
      const response = await Api.delete(`api/v1/process-type/${rowId}`);

      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  const deleteProcessStep = async (data) => {
    setIsLoading(true);
    try {
      const response = await Api.delete(`api/v1/process-type/${data.id}`,{data});

      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  const editProcessType = async (rowId) => {
    setIsLoading(true);
    try {
      const response = await Api.get(`api/v1/process-type/${rowId}/edit`);
      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  // const editProcessTypeCollection = async (data) =>{
  //   setIsLoading(true);
  //   try {
  //     const response = await Api.get(`api/v1/process-type/${data}/show`);
  //     setIsLoading(false);
  //     return response;
  //   } catch (error) {
  //     setIsLoading(false);
  //     throw error;
  //   }
  // }

  const editProcessTypeCollection = async (rowId,projectId, moduleId, orderNumber, module) => {
    setIsLoading(true);
    try {
      const response = await Api.get(`api/v1/process-type/show`, {
        params: {
          id:rowId,
          projectId:projectId,
          moduleId: moduleId,
          orderNumber: orderNumber,
          module: module
        }
      });
      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  }
  
  const updateProcessType = async (data) => {
    setIsLoading(true);
    try {
      const response = await Api.patch(`api/v1/process-type/${data.id}`, {
        data
      });
      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  const newProcessUpdate = async (data) => {
    setIsLoading(true);
    try {
      const response = await Api.post("api/v1/update-process", {
        data
      });
      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };



  return { createProcessType, listProcess, deleteProcess, editProcessType, updateProcessType, deleteProcessStep, editProcessTypeCollection,newProcessUpdate, isLoading };
};

