import { Api } from "../api/Api";
import { useState } from 'react';

export const useHttpClients = () => {
    const [isLoading, setIsLoading] = useState(false);

    const createHttpClients = async (data) => {
        setIsLoading(true);
        const response = await Api.post("api/v1/http-clients", {
            data
        });
        setIsLoading(false);
        return response;
    };

    const listingHttpClients = async (data) => {
        setIsLoading(true);
        const response = await Api.get("/api/v1/http-clients", {
            data
        });
        setIsLoading(false);
        return response;

    };

    const deleteHttpClients = async (rowId) => {
        setIsLoading(true);
        try {
            const response = await Api.delete(`api/v1/http-clients/${rowId}`);

            setIsLoading(false);
            return response;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };

    const editHttpClient = async (rowId) => {
        setIsLoading(true);
        try {
            const response = await Api.get(`api/v1/http-clients/${rowId}/edit`);
            setIsLoading(false);
            return response;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };

    const updatetHttpClient = async (data, id) => {
        setIsLoading(true);
        try {
            const response = await Api.patch(`api/v1/http-clients/${id}`, {
                data
            });
            setIsLoading(false);
            return response;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };

    const FetchClientType = async (data) => {
        setIsLoading(true);
        try {
          const response = await Api.patch(`api/v1/http-clients/${data.id}`, {
            data
          });
          setIsLoading(false);
          return response;
        } catch (error) {
          setIsLoading(false);
          throw error;
        }
      };


    return {updatetHttpClient,editHttpClient,createHttpClients,listingHttpClients,deleteHttpClients,FetchClientType,  isLoading };
};
