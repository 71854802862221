import axios from "axios"
import { Api, ApiFormData } from "../../api/Api";

const instance = Api;
const instance1 = ApiFormData;
instance.interceptors.request.use(
  (request) => {
    const authToken = localStorage.getItem('token');

    if (authToken) {
      request.headers.Authorization = `Bearer ${authToken}`
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (response) => {
    // You can do something with the response data
    return response;
  },
  (error) => {
    // Handle unauthorized (401) status
    if (error.response && error.response.status === 401) {
      // Redirect to login page
      window.location.replace('/login');
    }

    // You can handle other error statuses as needed
    return Promise.reject(error);
  }
);

instance1.interceptors.request.use(
  (request) => {
    const authToken = localStorage.getItem('token');

    if (authToken) {
      request.headers.Authorization = `Bearer ${authToken}`
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance1.interceptors.response.use(
  (response) => {
    // You can do something with the response data
    return response;
  },
  (error) => {
    // Handle unauthorized (401) status
    if (error.response && error.response.status === 401) {
      // Redirect to login page
      window.location.replace('/login');
    }

    // You can handle other error statuses as needed
    return Promise.reject(error);
  }
);
export default instance;