import React, { useEffect, useState } from "react";
import Button from "../../UI/Button";
import TextField from "../../UI/TextField";
import Modal from 'react-bootstrap/Modal';
import "../../../assets/css/popup.scss";
import { useProjectCodeInfo } from "../../../Hooks/useProjectCodeInfo";
import Loader from "../../Utility/Loader";
import SelectBox from "../../UI/SelectBox";
import { useTemplates } from "../../../Hooks/useTemplates";
import { useHistory } from 'react-router-dom';

const TogglePopup = (props) => {

  const resetForm = () => {
    setFormData({
      project_code: "",
      page_type: "",
      name: "",
      folder_path: "",
      url: "",
      placeholder: ''
    });
    setDefaultSplitFlow(true);
    setErrors({});
  };
  const validationErrors = {};
  const searchParams = new URLSearchParams(window.location.search);
  const id = (searchParams.get('id')) ? searchParams.get('id') : '';
  const [show, setShow] = useState(false);
  const history = useHistory();
  const { listProjectCode } = useProjectCodeInfo();
  const { createTemplates, editTemplate, updateTemplate, isLoading } = useTemplates();
  const [projectCode, setProjectCode] = useState([]);
  const [templateId, setTemplateId] = useState(id);
  // initialising the values
  const [extraFormData, setExtraExtraFormData] = useState({
    project_code: "",
    page_type: "",
    name: "",
    folder_path: "",
    url: "",
    placeholder: ""

  });
  const [placeholders, setPlaceholder] = useState([{
    placeholder: "",
    placeholder_value: "",
  }])
  // add template popup close functinality
  const handleClose = () => {
    setShow(false);
    props.setShowEdit(false);
    props.setshowCode('');
    props.setShowName('');
    resetForm();
    setErrors({});
  };

  // if template id is presnt in the url, then call api for edit
  useEffect(() => {
    (id) ?? setTemplateId(id);
    if (id) {

      const fetchData = async () => {
        try {
          const response = await editTemplate(id);
          if (response.status === 200 && response.data) {
            const convertedData = dataForEditFormView(response.data);
            setExtraExtraFormData({
              id: convertedData.id,
              project_code: convertedData.project_code,
              page_type: convertedData.page_type,
              folder_path: convertedData.folder_path,
              name: convertedData.name,
              url: convertedData.url,
              placeholder: convertedData.placeholder
            });
            const arrayOfObjects = Object.entries(convertedData.placeholder).map(([key, value]) => ({
              placeholder: key,
              placeholder_value: value
            }));
            setPlaceholder(arrayOfObjects);
            setFormData(convertedData.formData);
          }
        } catch (error) {
          console.error('Error Edit Template:', error);
        }
      };

      fetchData();
    }
  }, [id,props.showEdit]);
  // api for listing project codes
  useEffect(() => {
    const fetchData = async () => {
      try {
        const projectCodeRes = await listProjectCode();
        const formattedProjectCode = projectCodeRes.data.map((item) => ({
          label: item.project_code,
          value: item.project_code,
        }));
        setProjectCode(formattedProjectCode);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();

  }, []);

  // for template details in edit popup form
  const dataForEditFormView = (apiResponse) => {
    const formData = {
      project_code: apiResponse.project_code || '',
      page_type: apiResponse.page_type || '',
      name: apiResponse.template_details.name || '',
      url: apiResponse.template_details.url || '',
      folder_path: apiResponse.template_details.folder_path || '',
      id: apiResponse._id || '',
      placeholder: apiResponse.template_details.placeholder || ''
    };

    return {
      project_code: apiResponse.project_code || '',
      page_type: apiResponse.page_type || '',
      name: apiResponse.template_details.name || '',
      url: apiResponse.template_details.url || '',
      folder_path: apiResponse.template_details.folder_path || '',
      id: apiResponse._id || '',
      placeholder: apiResponse.template_details.placeholder || ''
    };
  };
  // add template modal showing function
  const handleShow = () => {
    setShow(true);
    setExtraExtraFormData("")
    resetForm();
    setPlaceholder([{ placeholder: "", placeholder_value: "" }]);
  }
  const [formData, setFormData] = useState({ project_code: '' });

  const [errors, setErrors] = useState({});
  const [defaultSplitFlow, setDefaultSplitFlow] = useState(true);
  // handle input change functionality
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setExtraExtraFormData({
      ...extraFormData,
      [name]: value,
    });
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: ''
    }));

  }

  const placeholderChanges = (event, sectionIndex) => {
    const { name, value } = event.target;
    setPlaceholder((prevData) => {
      const newData = [...prevData];
      const originalFieldName = name.replace(`_${sectionIndex}`, "");
      newData[sectionIndex] = {
        ...newData[sectionIndex],
        [originalFieldName]: value,
      };
      return newData;
    });
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: ''
    }));
  }
  // final handle submit click functionality and validations are set
  const handleSubmit = () => {
    const { project_code, page_type, name, url, folder_path } = extraFormData;
    console.log(placeholders, 5999);
    //const [{placeholder,placeholder_value}] = placeholder;
    if (!project_code) {
      validationErrors.project_code = "Project code is required";
    }
    if (!page_type) {
      validationErrors.page_type = "Project type is required";
    }
    if (!name) {
      validationErrors.name = "Template name is required";
    }
    if (!folder_path) {
      validationErrors.folder_path = "Folder path is required";
    }
    if (!url) {
      validationErrors.url = "Template url is required";
    }
    // if (!placeholder) {
    //   validationErrors.url = "Template placeholder is required";
    // }
    // if (!placeholder_value) {
    //   validationErrors.url = "Template placeholder default value is required";
    // }
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return;
    }
    let placeholderObject = {};
    if (placeholders) {
      placeholders.forEach((object) => {
        placeholderObject[object.placeholder] = object.placeholder_value;
      });
    }

    console.log(placeholderObject);
    // the format for passing data to template creation api
    const transformedData = {
      project_code: project_code,
      page_type: page_type,
      template_details: {
        name: name,
        folder_path: folder_path,
        url: url,
        placeholder: placeholderObject
      }
    };
    if (transformedData) {
      (async () => {
        const response = await createTemplates(transformedData);
        if (response && response.status == 200) {
          setExtraExtraFormData("")
          setShow(false);
          props.setToggleListing(!props.toggleListing);
          history.push('/templates');
        }
      })();
    }

  };
  const handleAddSection = (e) => {
    e.preventDefault();
    setPlaceholder((prevData) => [
      ...prevData,
      {
        placeholder: "",
        placeholder_value: ""
      }
    ])

  }

  const renderDynamicSections = () => {
    return placeholders.map((section, index) => (
      <div key={index} className="dynamic-section pt-3">
        <div className="row row-gap-3">
          <div className="flex-row" style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
            <div className="col-lg-4">
              <label className="form-label">PlaceHolder</label>
              <TextField
                className="form-control"
                name={`placeholder_${index}`}
                id={`placeholder_${index}`}
                value={section.placeholder}
                placeholder="PlaceHolder"
                onChange={(e) => placeholderChanges(e, index)}
                error={errors[`placehoder_${index}`]}
              />
            </div>
            <div className="col-lg-4">
              <label className="form-label">PlaceHolder Default Value</label>
              <TextField
                className="form-control"
                name={`placeholder_value_${index}`}
                id={`placeholder_value_${index}`}
                value={section.placeholder_value}
                placeholder="PlaceHolder Default value"
                onChange={(e) => placeholderChanges(e, index)}
                error={errors[`placeholder_value_${index}`]}
              />
            </div>


            <div className="col-lg col-md-8">
              <div className="d-flex justify-content-end">
                {index >= 0 && (
                  <Button
                    className="add-section fw-bolder fs-6"
                    onClick={(e) => removeSection(e, index)}
                    label="-"
                  />
                )}
              </div>
            </div>
          </div>
        </div>

      </div>
    ));
  }
  const removeSection = (e, sectionIndex) => {
    e.preventDefault();
    const updatedFormData = placeholders.filter(
      (_, index) => index !== sectionIndex
    );
    setPlaceholder(updatedFormData);
  }
  // Edit functionality and validation
  const EditSubmit = (event) => {
    event.preventDefault();
    const { id, project_code, page_type, name, url, folder_path, placeholder, placeholder_value } = extraFormData;
    if (!project_code) {
      validationErrors.project_code = "Project code is required";
    }
    if (!page_type) {
      validationErrors.page_type = "Project type is required";
    }
    if (!name) {
      validationErrors.name = "Template name is required";
    }
    if (!folder_path) {
      validationErrors.folder_path = "Folder path is required";
    }
    if (!url) {
      validationErrors.url = "Template url is required";
    }
    // if (!placeholder) {
    //   validationErrors.url = "Template placeholder is required";
    // }
    // if (!placeholder_value) {
    //   validationErrors.url = "Template placeholder default value is required";
    // }
    setErrors(validationErrors);

    let placeholderObject = {};
    if (placeholders) {
      placeholders.forEach((object) => {
        placeholderObject[object.placeholder] = object.placeholder_value;
      });
    }
    if (Object.keys(validationErrors).length > 0) {
      return;
    }
    // the format for passing data to template updation api
    const transformedData = {
      project_code: project_code,
      id: id,
      page_type: page_type,
      template_details: {
        name: name,
        folder_path: folder_path,
        url: url,
        placeholder: placeholderObject
      }
    };
    if (transformedData) {
      (async () => {
        const response = await updateTemplate(transformedData);
        if (response && response.status == 200) {
          props.setShowEdit(false);
          props.setToggleListing(!props.toggleListing);
          history.push('/templates');
        }
      })();
    }

  };
  return (
    <>
      <Loader active={isLoading} />
      <div className="toggle-popup" >
        <Button onClick={handleShow} className="button_a button2" label="Add Template" />
        <Modal show={show || props.showEdit} onHide={handleClose} dialogClassName="toggle-popup modal-width" backdrop="static">
          <Modal.Header closeButton >
            <Modal.Title>{show && !props.showEdit ? `Add Template` : `Edit Template`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="row">
                <div className="col-md-6">
                  <div className="col-lg-3">
                    Project Code :
                  </div>
                  <div className="col-lg-8">
                    <SelectBox
                      className="form-select"
                      name="project_code"
                      id="project_code"
                      OptionValue={projectCode}
                      value={extraFormData.project_code}
                      onChange={handleInputChange}
                      error={errors.project_code}
                      emptyOption="Select project code"
                    />
                  </div>
                </div><br />
                <div className="col-md-6">
                  <div className="col-lg-3">
                    Page Type :
                  </div>
                  <div className="col-lg-8">
                    <SelectBox
                      className="form-select"
                      name="page_type"
                      id="page_type"
                      OptionValue={[
                        { value: 'LP', label: 'LP' },
                        { value: 'AP', label: 'AP' },
                        { value: 'TY', label: 'TY' },
                        { value: 'questionare', label: 'Questionare' },
                        { value: 'signature', label: 'Signature' },
                        { value: 'id_upload', label: 'Id Upload' },
                      ]}
                      value={extraFormData.page_type}
                      onChange={handleInputChange}
                      error={errors.page_type}
                      emptyOption="Select project Type"
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="col-lg-6"><b>
                Template Details</b>
              </div><br />
              <div className="col-lg-6">
                Template Name
              </div>
              <div className="col-lg-8">
                <TextField
                  className="form-control"
                  name="name"
                  id="name"
                  placeholder="Enter the template name"
                  onChange={handleInputChange}
                  value={extraFormData.name}
                  error={errors.name}
                />
              </div> <br />
              <div className="col-lg-6">
                Folder Path
              </div>
              <div className="col-lg-8">
                <TextField
                  className="form-control"
                  name="folder_path"
                  id="folder_path"
                  placeholder="Enter the folder path"
                  onChange={handleInputChange}
                  error={errors.folder_path}
                  value={extraFormData.folder_path}
                />
              </div><br />
              <div className="col-lg-6">
                Url
              </div>
              <div className="col-lg-8">
                <TextField
                  className="form-control"
                  name="url"
                  id="url"
                  placeholder="Enter the url"
                  onChange={handleInputChange}
                  error={errors.url}
                  value={extraFormData.url}
                />
              </div>
              <div>
                <div className="col mt-3">
                  <div className="row">
                    <div className="col d-flex align-items-center">
                      <h4 className="mb-0">Placeholder</h4>
                    </div>
                    <div className="col d-flex justify-content-end">
                      <button
                        className="add-section fw-bolder fs-6"
                        onClick={(e) => { handleAddSection(e) }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  {renderDynamicSections()}
                </div>
              </div>
              <br />
            </form>
          </Modal.Body>
          <Modal.Footer>
            <div className="col-lg-2">
              {!props.showEdit ? (<Button className="button_a button2" label="Add" onClick={handleSubmit} />) : (<Button className="button_a button2" label="Update" onClick={EditSubmit} />)}
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}

export default TogglePopup;