import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import SelectBox from "../UI/SelectBox";
import { Bodytype, MethodType } from "../../Constants/BodyTypeConstants";
import { useEmailListHook } from "../../Hooks/useEmailListHook";
import TextField from "../UI/TextField";
import { useProcessType } from "../../Hooks/useProcessType";
import Loader from "../Utility/Loader";

const Sendemailwidget = (props) => {
  const [formdata, setFormData] = useState({
    project_code: "",
    process_type_id: "",
    process_type: "",
    data: {
      email_name: "",
      email_from: "",
      email_subject: "",
      email_body: "",
      email_vendor: "",
      body: "",
      method: "",
      headers: [],
      url: "",
    },
    created_at: "",
    updated_at: "",
    response: [{ response_key: "", response_data: "", is_dynamic: "no" }],
  });
  const [errors, setErrors] = useState({});
  const [sendEmailObject, setSendEmailObject] = useState("");
  const [headervalues, setHeaderValues] = useState([
    {
      header_name: "",
      header_value: "",
    },
  ]);
  const rowId = props.process_type_id;
  const {
    createEmaillist,
    listEmail,
    updateEmail,
    deleteEmaillist,
    editEmail,
  } = useEmailListHook();
  const { listProcess } = useProcessType();
  const handleClose = () => {
    props.setView(false);
    setFormData({
      project_code: "",
      process_type_id: "",
      process_type: "",
      data: {
        email_name: "",
        email_from: "",
        email_subject: "",
        email_vendor: "",
        email_body: "",
        body: "",
        headers: [],
        response: [{ response_key: "", response_data: "", is_dynamic: "no" }],
        method: "",
        url: "",
      },
      response: [{ response_key: "", response_data: "", is_dynamic: "no" }],
    });
    setHeaderValues([
      {
        header_name: "",
        header_value: "",
      },
    ]);
    setSendEmailObject();
  };
  const handleAddDiv = () => {
    setHeaderValues((headers) => [
      ...headers,
      {
        header_name: "",
        header_value: "",
      },
    ]);
  };
  const removeSection = async (event, sectionIndex, operation) => {
    const data = {
      sectionIndex: sectionIndex,
      id: rowId,
    };
    try {
      const response = await deleteEmaillist(rowId);
      setFormData((prevData) => {
        const updatedFormData = { ...prevData };
        updatedFormData[operation].splice(sectionIndex, 1);
        return updatedFormData;
      });
    } catch (error) {
      console.error("Error deleting section:", error);
    }
  };

  const handleCheckboxChange = (sectionIndex) => {
    setFormData((prevData) => {
      const newData = { ...prevData };
      newData.response[sectionIndex] = {
        ...newData.response[sectionIndex],
        is_dynamic:
          newData.response[sectionIndex].is_dynamic === "no" ? "yes" : "no",
      };
      return newData;
    });
  };

  const handleheaderChange = (e, index) => {
    const name = e.target.name;
    const value = e.target.value;
    setHeaderValues((header) => {
      const newData = [...header];
      const originalName = name.replace(`_${index}`, "");
      newData[index] = {
        ...newData[index],
        [originalName]: value,
      };
      return newData;
    });
  };
  const handleMinus = (e, removeIndex) => {
    const array = headervalues.filter((item, index) => index !== removeIndex);
    setHeaderValues(array);
  };
  const handleAdd = () => {
    return headervalues.map((item, index) => (
      <>
        <React.Fragment key={index}>
          <div key={index} className="dynamic-section pt-3">
            <div className="row row-gap-3">
              <div className="col-sm-6 col-md-5">
                <label className="form-label">Header name</label>
                <TextField
                  type="text"
                  className="form-control"
                  id={`header_name_${index}`}
                  name={`header_name_${index}`}
                  value={item.header_name}
                  placeholder="name....."
                  onChange={(e) => handleheaderChange(e, index)}
                />
              </div>
              <div className="col-sm-6 col-md-5">
                <label className="form-label">Value</label>
                <TextField
                  type="text"
                  className="form-control"
                  id={`header_value_${index}`}
                  name={`header_value_${index}`}
                  value={item.header_value}
                  placeholder="value....."
                  onChange={(e) => handleheaderChange(e, index)}
                />
              </div>
              <div className="col-sm-2 col-md-2 mb-5">
                <label className="form-label"></label>
                {index > 0 && (
                  <div className="col d-flex justify-content-end">
                    <button
                      className="add-section fw-bolder fs-6"
                      onClick={(e) => handleMinus(e, index)}
                    >
                      -
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
      </>
    ));
  };
  const handleChange = (e, index) => {
    let value = e.target.value;
    let name = e.target.name;
    setFormData({
      ...formdata,
      data: {
        ...formdata.data,
        [name]: value,
      },
    });
  };
  const handleInputChange = (event, sectionIndex) => {
    const { name, value, options } = event.target;
    switch (name) {
      case "process_type":
        const selectedOption = options[options.selectedIndex];
        const label = selectedOption.label;
        break;
      default:
        setFormData((prevData) => {
          const newData = { ...prevData };
          const originalFieldName = name.replace(`_${sectionIndex}`, "");
          const fieldName = name.split("_")[0];

          newData[fieldName][sectionIndex] = {
            ...newData[fieldName][sectionIndex],
            [originalFieldName]: value,
          };
          return newData;
        });
        break;
    }
  };
  const converted = () => {
    const array = {
      project_code: props.project_code ? props.project_code : "",
      process_type_id: props.process_type_id ? props.process_type_id : "",
      process_type: props.process_type ? props.process_type : "",
      module_collection_id: props.moduleCollectionId
        ? props.moduleCollectionId
        : "",
      data: {
        email_name: formdata.data.email_name,
        email_from: formdata.data.email_from,
        email_subject: formdata.data.email_subject,
        email_vendor: formdata.data.email_vendor,
        email_body: formdata.data.email_body,
        body: formdata.data.body,
        method: formdata.data.method,
        url: formdata.data.url,
        headers: [],
        response: [],
      },
      created_at: "",
      updated_at: "",
    };
    headervalues.forEach((elements) => {
      const { header_name, header_value } = elements;
      array.data.headers.push({
        header_name: header_name,
        header_value: header_value,
      });
    });

    formdata.response.forEach((section) => {
      const { response_key, response_data, is_dynamic, ...rest } = section;
      if (response_key || response_data) {
        array.data.response.push({
          response_key: response_key,
          response_data: response_data,
          is_dynamic: is_dynamic,
        });
      }
    });

    return array;
  };

  // useEffect(() => {
  //   // props.setEmailId();
  // }, [props.emailid]);
  const handleSubmit = async () => {
    const array = converted();
    let response = null;
    let type = null;
    if (props.view == true && rowId && sendEmailObject) {
      type = "update";
      response = await updateEmail(array, sendEmailObject);
    } else {
      type = "create";
      response = await createEmaillist(array);
    }
    const newFormData = [...props.formData];
    newFormData[props.formDataIndex].module_collection_id = response.data.id;
    props.setFormData(newFormData);
    closeAll(response.data.id, response.status, type);
  };
  const closeAll = (id, status, type) => {
    if (id && status == 200) {
      props.setView({ view: false });
      if (type == "create") {
        props.finalSubmit(id);
      }
      handleClose();
    }
  };
  const handleAddSection = (request) => {
    switch (request) {
      case "response":
        setFormData((prevData) => {
          const updatedPrevData = { ...prevData };
          updatedPrevData.response = [
            ...updatedPrevData.response,
            {
              response_key: "",
              response_data: "",
              is_dynamic: "no",
            },
          ];
          return updatedPrevData;
        });
        break;
    }
  };

  const renderDynamicSectionsTwo = () => {
    return (
      formdata.response &&
      formdata.response.map((section, index) => (
        <React.Fragment key={index}>
          <div key={index} className="dynamic-section pt-3 mb-3">
            <div className="col">
              <div className="row">
                <div className="col-auto">
                  <label className="form-label">Is dynamic</label>
                </div>
                <div className="col-auto">
                  <input
                    className="form-check-input p-2"
                    type="checkbox"
                    name={`advancedCheckbox_${index}`}
                    onChange={() => handleCheckboxChange(index)}
                    checked={section.is_dynamic === "yes" ? true : false}
                  />
                </div>
              </div>
            </div>
            <div className="row row-gap-3">
              <div className="col-sm-6 col-md-5">
                <label className="form-label">Response Key</label>
                <TextField
                  className="form-control"
                  name={`response_key_${index}`}
                  id={`response_key_${index}`}
                  value={section.response_key}
                  onChange={(e) => handleInputChange(e, index)}
                  error={errors[`response_key_${index}`]}
                />
              </div>
              <div className="col-sm-6 col-md-5">
                <label className="form-label">Response data</label>
                <TextField
                  className="form-control"
                  name={`response_data_${index}`}
                  id={`response_data_${index}`}
                  value={section.response_data}
                  onChange={(e) => handleInputChange(e, index)}
                  error={errors[`response_data_${index}`]}
                />
              </div>
              <div className="col-sm-2 col-md-2 mb-3">
                <label className="form-label"></label>
                {index > 0 && (
                  <div className="col d-flex justify-content-end">
                    <button
                      className="add-section fw-bolder fs-6"
                      onClick={(e) => removeSection(e, index, "response")}
                    >
                      -
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
      ))
    );
  };

  useEffect(() => {
    if (props.process_type_id && props.view == true) {
      (async () => {
        try {
          props.setLoader(true);
          const listResponse = await listEmail();
          const listprocess = await listProcess();
          const processResult = listprocess.data.find(
            (item) => item._id == props.process_type_id
          );
          const stepResult = processResult.steps.find(
            (item) =>
              item.module == props.index.module &&
              item.order_number == props.index.order_number
          );
          const listResult = listResponse.data.find(
            (item) => item.process_type_id == props.process_type_id
          );
          const response = await editEmail(stepResult.module_collection_id);
          if (response.data._id) {
            setSendEmailObject(response.data._id);
          }
          if (response && response.status == 200) {
            formdata.data.email_from = response.data.data.email_from;
            formdata.data.email_name = response.data.data.email_name;
            formdata.data.email_subject = response.data.data.email_subject;
            formdata.data.email_vendor = response.data.data.email_vendor;
            formdata.data.email_body = response.data.data.email_body;
            formdata.data.url = response.data.data.url;
            formdata.data.body = response.data.data.body;
            formdata.data.method = response.data.data.method;
            if (response.data.data.headers) {
              setHeaderValues(response.data.data.headers);
            }
            if (response.data.data.response) {
              formdata.response = response.data.data.response;
            }
            props.setLoader(false);
          } else {
            setHeaderValues({
              header_name: "",
              header_value: "",
            });
          }
        } catch (error) {}
        props.setLoader(false);
      })();
    }
  }, [props.view, rowId]);
  return (
    <>
      {props.loader ? (
        <Loader active={props.loader} />
      ) : (
        <Modal show={props.view} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Send Email Widget</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="AddRule -table-section container-fluid -mb-5">
              <div className="row -tablesec-bg py-3 -px-3">
                <div className="col-sm-12 col-md-4">
                  <div className="row my-2">
                    <div className="col-md-12 col-lg-3">
                      <label className="col-form-label">Email Name</label>
                    </div>
                    <div className="col-md-12 col-lg-9">
                      <input
                        type="text"
                        defaultValue={formdata.data.email_name}
                        className="form-control"
                        id="email_name"
                        name="email_name"
                        placeholder="Email Name......"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="row my-2">
                    <div className="col-md-12 col-lg-3">
                      <label className="col-form-label">Email From</label>
                    </div>
                    <div className="col-md-12 col-lg-9">
                      <input
                        type="text"
                        defaultValue={formdata.data.email_from}
                        className="form-control"
                        id="email_from"
                        name="email_from"
                        placeholder="Email From......"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="row my-2">
                    <div className="col-md-12 col-lg-3">
                      <label className="col-form-label">Email Vendor</label>
                    </div>
                    <div className="col-md-12 col-lg-9">
                    <input
                      className="form-control"
                      name="email_vendor"
                      id={`email_vendor`}
                      defaultValue={formdata.data.email_vendor}
                      onChange={(e) => handleChange(e)}
                      placeholder="Email Vendor......"
                    />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-9">
                <div className="row my-2">
                  <div className="col-md-12 col-lg-3">
                  <label className="col-form-label">Email Subject</label>
                  </div>
                  <div className="col-md-12 col-lg-9">
                  <input
                        type="text"
                        defaultValue={formdata.data.email_subject}
                        className="form-control"
                        id="email_subject"
                        name="email_subject"
                        placeholder="Email Subject......"
                        onChange={(e) => handleChange(e)}
                      />
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-11">
                <div className="row my-2">
                  <div className="col-md-12 col-lg-2">
                    <label className="col-form-label">Email Body</label>
                  </div>
                  <div className="col-md-12 col-lg-10">
                    <textarea
                      rows="8"
                      className="form-control"
                      name="email_body"
                      id={`email_body`}
                      defaultValue={formdata.data.email_body}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>
                <div className="col-sm-12 col-md-4">
                  <div className="row my-2">
                    <div className="col-md-12 col-lg-3">
                      <label className="col-form-label">SMS URL</label>
                    </div>
                    <div className="col-md-12 col-lg-9">
                      <input
                        type="text"
                        defaultValue={formdata.data.url}
                        className="form-control"
                        id="url"
                        name="url"
                        placeholder="SMS URL........."
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="row my-2">
                    <div className="col-md-12 col-lg-3">
                      <label className="col-form-label">Body</label>
                    </div>
                    <div className="col-md-12 col-lg-9">
                      <SelectBox
                        className="form-select"
                        name="body"
                        id="project_code"
                        OptionValue={Bodytype}
                        value={formdata.data.body}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="row my-2">
                    <div className="col-md-12 col-lg-3">
                      <label className="col-form-label">Method</label>
                    </div>
                    <div className="col-md-12 col-lg-9">
                      <SelectBox
                        className="form-select"
                        name="method"
                        id="project_code"
                        OptionValue={MethodType}
                        value={formdata.data.method}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col mt-3">
                  <div className="row">
                    <div className="col d-flex align-items-center">
                      <h4 className="mb-0">Header</h4>
                    </div>
                    <div className="col d-flex justify-content-end">
                      <button
                        className="add-section fw-bolder fs-6"
                        onClick={handleAddDiv}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  {handleAdd()}
                  <div className="row">
                    <div className="col d-flex align-items-center">
                      <h4 className="mb-0">Response</h4>
                    </div>
                    <div className="col d-flex justify-content-end">
                      <button
                        className="add-section fw-bolder fs-6"
                        onClick={() => handleAddSection("response")}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  {renderDynamicSectionsTwo()}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <div className="AddRule">
              <button
                className="btn btn-danger fw-bolder fs-6"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
export default Sendemailwidget;
