import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const LeadDatasViewPopup = (props) => {
  const handleClose = () => props.setView(false);
  return (
    <div>
      <Modal show={props.view} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Lead Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
          {Object.keys(props.viewData).length > 0 ? (
              <pre>{JSON.stringify(props.viewData, null, 2)}</pre>
            ) : (
              "No Data Found"
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LeadDatasViewPopup;
