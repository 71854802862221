import { Api } from "../api/Api";
import { useState } from "react";

export const useSchedules = () => {
  const [isLoading, setIsLoading] = useState(false);
  const createSchedules = async (data) => {
    setIsLoading(true);
    const response = await Api.post("api/v1/schedules", {
      data,
    });
    setIsLoading(false);
    return response;
  };
  const listSchedules = async (data) => {
    setIsLoading(true);
    const response = await Api.get("api/v1/schedules", {
      data,
    });
    setIsLoading(false);
    return response;
  };
  const updateSchedules = async (data) => {
    console.log("update");
    console.log(data.ScheduleId);
    setIsLoading(true);
    const response = await Api.patch(`api/v1/schedules/${data.ScheduleId}`, {
      data,
    });
    setIsLoading(false);
    return response;
  };
  const deleteSchedules = async (rowId) => {
    setIsLoading(true);
    try {
      const response = await Api.delete(`api/v1/schedules/${rowId}`);
      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  return {
    createSchedules,
    listSchedules,
    updateSchedules,
    deleteSchedules,
    isLoading,
  };
};
