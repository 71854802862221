import { Api } from "../api/Api";
import { useState } from "react";

export const useAssignAttribute = () => {
  const [isLoading, setIsLoading] = useState(false);

  const createAssignAttribute = async (data) => {
    setIsLoading(true);
    const response = await Api.post("api/v1/assign-attribute", {
      data,
    });
    setIsLoading(false);
    return response;
  };

  const listAssignAttribute = async (data) => {
    setIsLoading(true);
    const response = await Api.get("/api/v1/assign-attribute", {
      data,
    });
    setIsLoading(false);
    return response;
  };

  const deleteAssignAttribute = async (rowId) => {
    setIsLoading(true);
    try {
      const response = await Api.delete(`api/v1/assign-attribute/${rowId}`);

      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  const editAssignAttribute = async (rowId) => {
    setIsLoading(true);
    try {
      const response = await Api.get(`api/v1/assign-attribute/${rowId}/edit`);
      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  const updateAssignAttribute = async (data, id) => {
    setIsLoading(true);
    try {
      const response = await Api.patch(`api/v1/assign-attribute/${id}`, {
        data,
      });
      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  const deleteMultipleItems = async (id, model) => {
    setIsLoading(true);
    try {
      const response = await Api.post(`api/v1/delete-multiple`, {
        id,
        model,
      });
      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  // CSV File Upload
  const CSVFileUpload = async (data) => {
    setIsLoading(true);
    const response = await Api.post("api/v1/csv-file-upload", data
    );
    setIsLoading(false);
    return response;
  };

  return {
    createAssignAttribute,
    listAssignAttribute,
    deleteAssignAttribute,
    editAssignAttribute,
    updateAssignAttribute,
    deleteMultipleItems,
    CSVFileUpload,
    isLoading,
  };
};
