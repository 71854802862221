import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import useApi from "../../Hooks/useApi";

const Lead_datas = () => {
    const [formData,setFormData] = useState({});
    const { makeRequest } = useApi();
    const handleInputChange = (e) =>{
        let name  = e.target.name;
        let value = e.target.value;
        setFormData(
            {
                ...formData,
                [name]:value
            }
        )
    }
    const handleSubmit =async () =>{
       await makeRequest('/api/v1/post-mongo-data/lead-data','POST',{ data: formData})

    }
    return (
        <>
        <section className="h-100 gradient-form" style={{backgroundcolor: "#eee"}}>
            <div className="container py-5 h-100">
                <form className="row g-3">
                    <div className="col-lg-3">
                        <label htmlFor="inputEmail4" className="form-label">
                            UUID
                        </label>
                        <input type="text" className="form-control" id="uuid" name="uuid" placeholder="uuid...." onChange={handleInputChange} />
                    </div>

                    <div className="col-lg-3">
                        <label htmlFor="project_code" className="form-label">
                            Project Code
                        </label>
                        <input type="text" className="form-control" id="project_code" name="project_code" placeholder="project code...." onChange={handleInputChange} />
                    </div>

                    <div className="col-md-3">
                        <label htmlFor="title" className="form-label">
                            title
                        </label>
                        <input type="text" className="form-control" name="title" id="title" placeholder="title...." onChange={handleInputChange} />
                    </div>

                    <div className="col-md-3">
                        <label htmlFor="txtFName" className="form-label">
                            First Name
                        </label>
                        <input type="text" className="form-control" id="first_name" name="first_name" placeholder="first name" onChange={handleInputChange} />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="txtLName" className="form-label">
                            Last Name
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="last_name"
                            name="last_name"
                            placeholder="last name...."
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="email" className="form-label">
                            Email
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="email..."
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="postcode" className="form-label">
                            Postcode
                        </label>
                        <input type="text" className="form-control" name="postcode" id="postcode" placeholder="postcode...." onChange={handleInputChange} />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="country" className="form-label">
                            Country
                        </label>
                        <input type="text" className="form-control" name="country" id="country" placeholder="country...." onChange={handleInputChange}/>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="dob" className="form-label">
                            DateOfBirth
                        </label>
                        <input type="text" className="form-control" id="dob" name="dob" placeholder="date of birth....."  onChange={handleInputChange} />
                    </div>

                    <div className="col-md-3">
                        <label htmlFor="is_qualified" className="form-label">
                            is_qualified
                        </label>
                        <input type="text" className="form-control" name="is_qualified" id="is_qualified" placeholder="is_qualified...."  onChange={handleInputChange} />
                    </div>

                    <div className="col-md-3">
                        <label htmlFor="split_name" className="form-label">
                            split_name
                        </label>
                        <input type="text" className="form-control" name="split_name" id="split_name" placeholder="split_name...." onChange={handleInputChange} />
                    </div>

                    <div className="col-md-3">
                        <label htmlFor="signature_created_date" className="form-label">
                        signature_created_date
                        </label>
                        <input type="date" className="form-control" name="signature_created_date" id="signature_created_date" placeholder="signature_created_date...."  onChange={handleInputChange}/>
                    </div>

                    <div className="col-md-3">
                        <label htmlFor="signature_image" className="form-label">
                        signature_image
                        </label>
                        <input type="text" className="form-control" name="signature_image" id="signature_image" placeholder="signature_image...." onChange={handleInputChange} />
                    </div>

                    <div className="col-md-3">
                        <label htmlFor="user_country" className="form-label">
                        user_country
                        </label>
                        <input type="text" className="form-control" name="user_country" id="user_country" placeholder="user_country...." onChange={handleInputChange} />
                    </div>

                    <div className="col-md-3">
                        <label htmlFor="town" className="form-label">
                        town
                        </label>
                        <input type="text" className="form-control" name="town" id="town" placeholder="town...." onChange={handleInputChange} />
                    </div>

                    <div className="col-md-3">
                        <label htmlFor="county" className="form-label">
                        county
                        </label>
                        <input type="text" className="form-control" name="county" id="county" placeholder="county...." onChange={handleInputChange} />
                    </div>

                    <div className="col-md-3">
                        <label htmlFor="street" className="form-label">
                        street
                        </label>
                        <input type="text" className="form-control" name="street" id="street" placeholder="street...." onChange={handleInputChange} />
                    </div>

                    <div className="col-md-3">
                        <label htmlFor="housenumber" className="form-label">
                        housenumber
                        </label>
                        <input type="text" className="form-control" name="housenumber" id="housenumber" placeholder="housenumber...." onChange={handleInputChange} />
                    </div>

                    <div className="col-md-3">
                        <label htmlFor="record_status" className="form-label">
                        record_status
                        </label>
                        <input type="text" className="form-control" name="record_status" id="record_status" placeholder="record_status...."  onChange={handleInputChange} />
                    </div>

                    <div className="col-md-3">
                        <label htmlFor="telephone" className="form-label">
                        telephone
                        </label>
                        <input type="tel" className="form-control" name="telephone" id="telephone" placeholder="telephone...." onChange={handleInputChange} />
                    </div>
                    
                    <div className="col-md-3">
                        <label htmlFor="created_at" className="form-label">
                        created_at
                        </label>
                        <input type="date" className="form-control" name="created_at" id="created_at" placeholder="created_at...." onChange={handleInputChange} />
                    </div>

                    <div className="col-md-3">
                        <label htmlFor="updated_at" className="form-label">
                        updated_at
                        </label>
                        <input type="date" className="form-control" name="updated_at" id="updated_at" placeholder="updated_at...." onChange={handleInputChange} />
                    </div>

                    <div className="col-12">
                        <button type="button" onClick={handleSubmit} className="btn btn-primary">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
</section>

        </>
    )
}
export default Lead_datas;