import { Api } from "../api/Api";
import { useState } from 'react';

export const useProjectConfiguration = () => {
    const [isLoading, setIsLoading] = useState(false);

    const createProjectConfiguration = async (data) => {
        setIsLoading(true);
        const response = await Api.post("api/v1/project-configuration", {
            data
        });
        setIsLoading(false);
        return response;
    };

    const listProjectConfiguration = async (data) => {
        setIsLoading(true);
        const response = await Api.get("/api/v1/project-configuration", {
            data
        });
        setIsLoading(false);
        return response;

    };

    const deleteProjectConfiguration = async (rowId) => {
        setIsLoading(true);
        try {
            const response = await Api.delete(`api/v1/project-configuration/${rowId}`);

            setIsLoading(false);
            return response;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };

    const editProjectConfiguration = async (rowId) => {
        setIsLoading(true);
        try {
            const response = await Api.get(`api/v1/project-configuration/${rowId}/edit`);
            setIsLoading(false);
            return response;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };


    const updateProjectConfiguration = async (data) => {
        setIsLoading(true);
        try {
            const response = await Api.patch(`api/v1/project-configuration/${data.id}`, {
                data
            });
            setIsLoading(false);
            return response;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };



    return { createProjectConfiguration, editProjectConfiguration, updateProjectConfiguration, listProjectConfiguration, deleteProjectConfiguration, isLoading };
};

