import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useProcessType } from '../../../Hooks/useProcessType';
import Loader from "../../Utility/Loader";

const ProcessDeletePopup = (props) => {
  const { deleteProcess,listProcess, isLoading} = useProcessType();
  const handleClose = () => props.setDelete(false);
  const listProcessFun = async () => {
    const response = await listProcess();
    const ProcessData = response.data.map(item => ({
      _id: item._id,
      process_type: item.name,
      project_code: item.project_code,
      slug: item.slug,
    }));
    const keyword = props.filterText.toLowerCase();
   
    const filteredData = ProcessData.filter(
      (item) =>
        (item._id && item._id.toString().toLowerCase().includes(keyword)) ||
        (item.process_type && item.process_type.toString().toLowerCase().includes(keyword))||
        (item.slug && item.slug.toString().toLowerCase().includes(keyword)) ||
        (item.project_code && item.project_code.toString().toLowerCase().includes(keyword))
    );
  
    props.setFilteredData(filteredData);
    props.setFilterText(keyword);
    props.setData(ProcessData);
  }

  const handleDelete = async () => {
    handleClose();
    const id = props.id;
      const response = await deleteProcess(id);
    // if(props.multipleValue == "multiple"){
    //   const response = await deleteMultipleItems(id,'ProcessType');
    //   props.setMultipleValue('');
    // }else{
    //   const response = await deleteProcess(id);
    // }
    listProcessFun();
    props.setToggleList(!props.toggleList)
  };

  return (
    <div>
      <Loader active={isLoading} />
      <Modal show={props.deletes} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProcessDeletePopup;
