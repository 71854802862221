import React, { useState } from 'react';

function URLPreviewEditor() {
  const [url, setUrl] = useState('');

  const handleUrlChange = (event) => {
    setUrl(event.target.value);
  };

  return (
    <div>
      <input
        type="text"
        value={url}
        onChange={handleUrlChange}
        placeholder="Enter Website URL"
      />
      <button onClick={() => setUrl('')}>Clear</button>
      {url && (
        <div>
          <h2>Preview:</h2>
          <iframe
            title="External Website Preview"
            src={url}
            style={{ width: '100%', height: '500px', border: '1px solid #ccc' }}
          />
        </div>
      )}
    </div>
  );
}

export default URLPreviewEditor;
