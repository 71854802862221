import { Api } from "../api/Api";
import { useState } from "react";

export const useRule = () => {
  const [isLoading, setIsLoading] = useState(false);

  const createRule = async (data) => {
    setIsLoading(true);
    const response = await Api.post("api/v1/rule", {
      data,
    });
    setIsLoading(false);
    return response;
  };

  const listrules = async (data) => {
    setIsLoading(true);
    const response = await Api.get("/api/v1/rule", {
      data,
    });
    setIsLoading(false);
    return response;
  };

  const deleteRule = async (rowId) => {
    setIsLoading(true);
    try {
      const response = await Api.delete(`api/v1/rule/${rowId}`);

      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  const editRule = async (rowId) => {
    setIsLoading(true);
    try {
      const response = await Api.get(`api/v1/rule/${rowId}/edit`);
      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  const updateRule = async (data, id) => {
    setIsLoading(true);
    try {
      const response = await Api.patch(`api/v1/rule/${id}`, {
        data,
      });
      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  const getRuleWithProcessId = async (data) => {
    setIsLoading(true);
    try {
      const response = await Api.post(`api/v1/get-rule-with-process-id`, {
        data,
      });
      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };


  return {
    deleteRule,
    listrules,
    createRule,
    editRule,
    updateRule,
    getRuleWithProcessId,
    isLoading,
  };
};
