
import React, { useEffect } from "react";
import instance from "../Utility/Interceptor";
import Header from "../Includes/Header";
import Footer from "../Includes/Footer";
import { useAuth } from "../Utility/AuthContext";


const Dashboard = () => {
 const user = useAuth();
  if (user.token == '' || user.token == undefined || user.token == null) return window.location.replace('/login');
  

  return (
    <>
      <Header />
      <div>  Dashboard page  here</div>

      <Footer />
    </>
  )
}

export default Dashboard;