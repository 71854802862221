import React from 'react'

const SelectBox = ({
  className,
  OptionValue,
  onChange,
  name,
  value,
  id,
  error,
  emptyOption,
}) => {
  const OptionData = OptionValue.map((options, index) => {
    return (
      <option value={options.value} key={index}>
        {options.label}
      </option>
    );
  });
  return (
    <>
      <select className={className} name={name} error={error} onChange={onChange} id={id} value={value}>
        {emptyOption  && (
          <option value="">
            {emptyOption}
          </option>
        )}
        {OptionData}
      </select>
      {error && (
        <span className="error-message">{error}</span>
      )}
    </>
  )
}

export default SelectBox