import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const ViewPopup = (props) => {
  
  const handleClose = () => props.setView(false);

  const shouldShowCustomAttributes = props.viewData.data.some((item) => item.is_advanced);

  const viewColumn = () => {
    if (props.viewData.data[0].assign_value) {
      return (
        <>
          <th>Attribute</th>
          <th>Assign Label</th>
          <th>Value</th>

        </>
      );
    }
    else {
      return (
        <>
          <th>Custom Attribute</th>
          <th>Assign Label</th>
        
        </>
      );
    }
  };

  const viewValue = () => {
    return props.viewData.data.map((value, index) => {
      return (
        <tr key={index}>
          <td>{shouldShowCustomAttributes ? value.attribute : value.attribute}</td>
          <td>{value.assign_label}</td>
          {shouldShowCustomAttributes || value.assign_value ? (
            <td>{value.assign_value}</td>
          ) : (
            <td>NA</td>
          )}
        </tr>
      );
    });
  };
  
console.log(props.viewData.data);
  return (
    <div>
      <Modal show={props.view} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Assign Attribute Lists</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {Object.keys(props.viewData.data).length > 0 ? (
              <table>
                <thead>
                  <tr>{viewColumn()}</tr>
                </thead>
                <tbody>{viewValue()}</tbody>
              </table>
            ) : (
              'No Data Found'
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ViewPopup;
