import { useEffect, useState } from "react";
import { useEmailListHook } from "../../Hooks/useEmailListHook";
import DataTable from "react-data-table-component";
import { ListEmailColumns } from "../../Constants/TableColumns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import ViewPopup from "../Includes/Email/ViewPopup";
import TextField from "../UI/TextField";
import DeletePopupEmail from "../Includes/Email/DeletePopupEmail";
import TableHead from "../Utility/TableHead";
import Loader from "../Utility/Loader";
import Sendemailwidget from "../ModuleWidgets/Sendemailwidget";

const EmailDataList = () => {
  const { listEmail, isLoading } = useEmailListHook();
  const [listAssignAttributes, setListAssignAttributes] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [filteredAssignAttribute, setFilteredAssignAttribute] = useState([]);
  const [filteredAssignAttributeTwo, setFilteredAssignAttributeTwo] = useState(
    []
  );
  const [deletes, setDelete] = useState(false);
  const [id, setDeleteId] = useState("");
  const [toggleList, setToggleList] = useState(true);
  const [view, setView] = useState({
    viewHttp: false,
    viewSms: false,
    viewAssign: false,
    viewEmail: false,
  });
  const [selectedFormData, setSelectedFormData] = useState([
    {
      project_code: "",
      process_type: "",
      process_type_id: "",
    },
  ]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false); // New state to track select all 
  const [checkboxClicked, setCheckboxClicked] = useState(false);
  const [showTrashButton, setShowTrashButton] = useState(false);
  const handleSelectAll = () => {
    const allRowIds = listAssignAttributes.map((row) => row._id);
    const updatedSelectedRows = selectAll ? [] : allRowIds;
    setSelectedRows(updatedSelectedRows);
    setSelectAll(!selectAll);
    setShowTrashButton(updatedSelectedRows.length > 0);
  };


  const SelectHeader = ({ selectAll, handleSelectAll }) => (
    <>
      <input
        style={{ marginRight: "5px" }}
        type="checkbox"
        checked={selectAll}
        onChange={handleSelectAll}
        id="select_header"
      />
      <label htmlFor="select_header">Select</label>
    </>
  );  

  const listAssignAttributeColumnsWithActions = [
    {
      name: <SelectHeader selectAll={selectAll} handleSelectAll={handleSelectAll}/>,
      cell: (row) => (
        <input
              type="checkbox"
              checked={selectAll || selectedRows.includes(row._id)}
              onChange={() => handleCheckboxChange(row._id)}
          />
      ),
      header: (row) => (
          <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
          />
      )
  },

    ...ListEmailColumns,
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <button
            onClick={() => handlepopupEmail(row._id)}
            style={{ margin: "3px" }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
          <button
            onClick={() => deleteEmaillist(row._id)}
            style={{ margin: "3px" }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
          {/* <button onClick={() => listAssignAttribute(row._id)} style={{ margin: "3px" }}>
          <FontAwesomeIcon icon={faEye} />
        </button> */}
        </div>
      ),
    },
  ];
  useEffect(() => {
    (async () => {
      const response = await listEmail();
      const extractedData = response.data.map((item) => ({
        _id: item._id,
        project_code: item.project_code,
        process_type: item.process_type,
        process_type_id: item.process_type_id,
        email_from: item.data.email_from,
        email_subject: item.data.email_subject,
        email_body: item.data.email_body,
        email_vendor: item.data.email_vendor,
        data: item.data,
      }));
      setFilteredAssignAttribute(extractedData);
      setListAssignAttributes(extractedData);
    })();
  }, [toggleList]);
  const handleFilter = (e) => {
    const keyword = e.target.value.toLowerCase();
    const filtered = listAssignAttributes.filter(
      (item) =>
        (item.id && item._id.toString().toLowerCase().includes(keyword)) ||
        (item.project_code &&
          item.project_code.toLowerCase().includes(keyword)) ||
        (item.process_type &&
          item.process_type.toString().toLowerCase().includes(keyword)) ||
        (item.data.email_from &&
          item.data.email_from.toLowerCase().includes(keyword)) ||
        (item.data.email_subject &&
          item.data.email_subject.toLowerCase().includes(keyword)) ||
        (item.data.email_body &&
          item.data.email_body.toLowerCase().includes(keyword)) ||
        (item.data.email_vendor &&
          item.data.email_vendor.toLowerCase().includes(keyword))
    );
    setFilteredAssignAttribute(filtered);
    setFilteredAssignAttributeTwo(filtered);
    setFilterText(keyword);
  };
  const handlepopupEmail = (rowId) => {
    const matchingProcessTypesId = listAssignAttributes.filter(
      (item) => item._id === rowId
    );
    const formattedValues = matchingProcessTypesId.map((type) => ({
      project_code: type.project_code,
      process_type: type.process_type,
      process_type_id: type.process_type_id,
    }));
    setSelectedFormData(formattedValues);

    setView({ viewEmail: true });
  };
  const deleteEmaillist = async (rowId) => {
    setDelete(true);
    setDeleteId(rowId);
  };

  const handleCheckboxChange = (rowId) => {
    const updatedSelectedRows = selectedRows.includes(rowId)
      ? selectedRows.filter((id) => id !== rowId)
      : [...selectedRows, rowId];
    setSelectedRows(updatedSelectedRows);
  
    // Check if any checkbox is selected, including the "Select All" checkbox
    const isAnyCheckboxSelected = updatedSelectedRows.length > 0 || selectAll;
    setShowTrashButton(isAnyCheckboxSelected);
  };

  const handleMultipleDelete = async () => {
    setDelete(true);
    setDeleteId(selectedRows);
  };


  return (
    <>
      <Loader active={isLoading} />
      <div className="col-lg-12 table-section project">
        <div className="row m-0">
          <div className="col-lg-12 col-md-12 p-1">
            <div className="tablesec-bg">
              <div className="col-lg-12 py-3 px-3">
                <div className="row">
                  <div className="col-lg-4" id="search_bar1">
                    <TableHead title="Email Management" />
                  </div>
                  <div className="col-lg-5"></div>
                  <div className="col-lg-1 text-right">
                    <a href="#">
                      <i
                        className="fa-solid fa-rotate px-2 mt-2"
                        aria-hidden="true"
                        data-toggle="tooltip"
                        title="Refresh Page"
                      ></i>
                    </a>
                  </div>
                  <div className="col-lg-2 text-right">
                    {/* <Button className="button_a button2" label="Assign Attributes" onClick={AddAssignAttributes}/> */}
                  </div>
                  <div className="table_hold">
                    <div>
                      <TextField
                        type="text"
                        value={filterText}
                        onChange={handleFilter}
                        placeholder="Search..."
                        className="keyword-search"
                      />
                      <div style={{ float: "right" }}>
                        {showTrashButton && (
                          <button
                            style={{ fontSize: "2 rem", margin: "10px", borderWidth: "0px" }}
                            onClick={handleMultipleDelete}
                            title="Delete Selected"
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        )}
                      </div>
          


                      <DataTable
                        columns={listAssignAttributeColumnsWithActions}
                        data={
                          filterText.length > 0
                            ? filteredAssignAttributeTwo
                            : filteredAssignAttribute
                        }
                        pagination
                        paginationPerPage={10}
                      />
                    </div>
                  </div>
                  {/* {view && (
                <ViewPopup
                setView={setView}
                view={view}
                listAssignAttributes={listAssignAttributes}
                viewData={viewData}
                />
              )} */}
                  <Sendemailwidget
                    setView={setView}
                    view={view.viewEmail}
                    project_code={selectedFormData[0].project_code}
                    process_type={selectedFormData[0].process_type}
                    process_type_id={selectedFormData[0].process_type_id}
                  />
                  <DeletePopupEmail
                    deletes={deletes}
                    setDelete={setDelete}
                    id={id}
                    setToggleList={setToggleList}
                    toggleList={toggleList}
                    setFilterText={setFilterText}
                    filterText={filterText}
                    setFilteredAssignAttributeTwo={
                      setFilteredAssignAttributeTwo
                    }
                    setFilteredAssignAttribute={setFilteredAssignAttribute}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EmailDataList;
