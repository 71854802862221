import CustomTooltip from "../Constants/CustomTooltip";
const ProjectColumns = [
    { name: 'Sl No.', selector: 'id', sortable: true, cell: (row, index) => index + 1 },
    {
        name: 'Project Name', selector: 'project_name', sortable: true, cell: (row) => (
            <CustomTooltip text={row.project_name}>
                <span>{(row.project_name && row.project_name.length > 20) ? row.project_name.slice(0, 20) + '...' : row.project_name}</span>
            </CustomTooltip>
        )
    },
    {
        name: 'Project Code', selector: 'project_code', sortable: true, cell: (row) => (
            <CustomTooltip text={row.project_code}>
                <span>{(row.project_code && row.project_code.length > 20) ? row.project_code.slice(0, 20) + '...' : row.project_code}</span>
            </CustomTooltip>
        )
    },
];

const ModuleColumns = [
    { name: 'Sl No.', selector: 'id', sortable: true, cell: (row, index) => index + 1 },
    {
        name: 'Module Name', selector: 'module_name', sortable: true, cell: (row) => (
            <CustomTooltip text={row.module_name}>
                <span>{(row.module_name && row.module_name.length > 20) ? row.module_name.slice(0, 20) + '...' : row.module_name}</span>
            </CustomTooltip>
        )
    },
    { name: 'Status', selector: 'status', sortable: true, },
    {
        name: 'slug', selector: 'slug', sortable: true, cell: (row) => (
            <CustomTooltip text={row.slug}>
                <span>{(row.slug && row.slug.length > 20) ? row.slug.slice(0, 20) + '...' : row.slug}</span>
            </CustomTooltip>
        )
    },
    {
        name: 'widgets', selector: 'widgets', sortable: true, cell: (row) => (
            <CustomTooltip text={row.widgets}>
                <span>{(row.widgets && row.widgets.length > 20) ? row.widgets.slice(0, 20) + '...' : row.widgets}</span>
            </CustomTooltip>
        )
    },
];

const Entitycolumns = [
    { name: 'Sl No.', selector: '_id', sortable: true, cell: (row, index) => index + 1 },
    {
        name: 'Project Code', selector: 'project_code', sortable: true, cell: (row) => (
            <CustomTooltip text={row.project_code}>
                <span>{(row.project_code && row.project_code.length > 20) ? row.project_code.slice(0, 20) + '...' : row.project_code}</span>
            </CustomTooltip>
        )
    },
    {
        name: 'Atribute Name', selector: 'attribute_name', sortable: true, cell: (row) => (
            <CustomTooltip text={row.attribute_name}>
                <span>{(row.attribute_name && row.attribute_name.length > 20) ? row.attribute_name.slice(0, 20) + '...' : row.attribute_name}</span>
            </CustomTooltip>
        )
    },
    {
        name: 'Slug', selector: 'slug', sortable: true, cell: (row) => (
            <CustomTooltip text={row.slug}>
                <span>{(row.slug && row.slug.length > 20) ? row.slug.slice(0, 20) + '...' : row.slug}</span>
            </CustomTooltip>
        )
    },
    {
        name: 'Status', selector: 'status', sortable: true, cell: row => (<div style={{ color: row.status === 1 ? 'green' : 'red' }}>{row.status === 1 ? 'ACTIVE' : 'INACTIVE'}</div>
        ),
    },

];
const ListRulecolumns = [
    { name: 'Sl No.', selector: '_id', sortable: true, cell: (row, index) => index + 1 },
    {
        name: 'Project Code', selector: 'project_code', sortable: true, cell: (row) => (
            <CustomTooltip text={row.project_code}>
                <span>{(row.project_code && row.project_code.length > 20) ? row.project_code.slice(0, 20) + '...' : row.project_code}</span>
            </CustomTooltip>
        )
    },
    {
        name: 'Process Type', selector: 'process_type', sortable: true, cell: (row) => (
            <CustomTooltip text={row.process_type}>
                <span>{(row.process_type && row.process_type.length > 20) ? row.process_type.slice(0, 20) + '...' : row.process_type}</span>
            </CustomTooltip>
        )
    },
];
const listAssignAttributeColumns = [
    { name: 'Sl No.', selector: '_id', sortable: true, cell: (row, index) => index + 1 },
    {
        name: 'Project Code', selector: 'project_code', sortable: true, cell: (row) => (
            <CustomTooltip text={row.project_code}>
                <span>{(row.project_code && row.project_code.length > 20) ? row.project_code.slice(0, 20) + '...' : row.project_code}</span>
            </CustomTooltip>
        )
    },
    {
        name: 'Process Type', selector: 'process_type', sortable: true, cell: (row) => (
            <CustomTooltip text={row.process_type}>
                <span>{(row.process_type && row.process_type.length > 20) ? row.process_type.slice(0, 20) + '...' : row.process_type}</span>
            </CustomTooltip>
        )
    },
];


const ListProcessColumns = [
    { name: 'Sl No.', selector: '_id', sortable: true, cell: (row, index) => index + 1 },
    {
        name: 'Project Code', selector: 'project_code', sortable: true, cell: (row) => (
            <CustomTooltip text={row.project_code}>
                <span>{row.project_code}</span>
            </CustomTooltip>
        )
    },
    {
        name: 'Project Type', selector: 'process_type', sortable: true, cell: (row) => (
            <CustomTooltip text={row.process_type}>
                <span>{(row.process_type && row.process_type.length > 20) ? row.process_type.slice(0, 20) + '...' : row.process_type}</span>
            </CustomTooltip>
        )
    },
    {
        name: 'Slug', selector: 'slug', sortable: true, cell: (row) => (
            <CustomTooltip text={row.slug}>
                <span>{(row.slug && row.slug.length > 20) ? row.slug.slice(0, 20) + '...' : row.slug}</span>
            </CustomTooltip>
        )
    },

];

const ListHttpClientsColumns = [
    { name: 'Sl No.', selector: '_id', sortable: true, cell: (row, index) => index + 1 },
    {
        name: 'Project Code', selector: 'project_code', sortable: true, cell: (row) => (
            <CustomTooltip text={row.project_code}>
                <span>{(row.project_code && row.project_code.length > 20) ? row.project_code.slice(0, 20) + '...' : row.project_code}</span>
            </CustomTooltip>
        )
    },
    {
        name: 'Process Type', selector: 'process_type', sortable: true, cell: (row) => (
            <CustomTooltip text={row.process_type}>
                <span>{(row.process_type && row.process_type.length > 20) ? row.process_type.slice(0, 20) + '...' : row.process_type}</span>
            </CustomTooltip>
        )
    },
    {
        name: 'url', selector: 'url_value', sortable: true, cell: (row) => (
            <CustomTooltip text={row.url_value}>
                <span>{(row.url_value && row.url_value.length > 20) ? row.url_value.slice(0, 20) + '...' : row.url_value}</span>
            </CustomTooltip>
        )
    },
];

const ScheduleColumns = [
    { name: 'Sl No.', selector: '_id', sortable: true, cell: (row, index) => index + 1 },
    {
        name: 'cron Name', selector: 'cron_name', sortable: true, cell: (row) => (
            <CustomTooltip text={row.cron_name}>
                <span>{(row.cron_name && row.cron_name.length > 20) ? row.cron_name.slice(0, 20) + '...' : row.cron_name}</span>
            </CustomTooltip>
        )
    },
    {
        name: 'Slug', selector: 'slug', sortable: true, cell: (row) => (
            <CustomTooltip text={row.slug}>
                <span>{(row.slug && row.slug.length > 20) ? row.slug.slice(0, 20) + '...' : row.slug}</span>
            </CustomTooltip>
        )
    },
    {
        name: 'project Code', selector: 'project_code', sortable: true, cell: (row) => (
            <CustomTooltip text={row.project_code}>
                <span>{(row.project_code && row.project_code.length > 20) ? row.project_code.slice(0, 20) + '...' : row.project_code}</span>
            </CustomTooltip>
        )
    },
    {
        name: 'Process Type', selector: 'process_type', sortable: true, cell: (row) => (
            <CustomTooltip text={row.process_type}>
                <span>{(row.process_type && row.process_type.length > 20) ? row.process_type.slice(0, 20) + '...' : row.process_type}</span>
            </CustomTooltip>
        )
    },
    {
        name: 'cron Tab', selector: 'cron_tab', sortable: true, cell: (row) => (
            <CustomTooltip text={row.cron_tab}>
                <span>{(row.cron_tab && row.cron_tab.length > 20) ? row.cron_tab.slice(0, 20) + '...' : row.cron_tab}</span>
            </CustomTooltip>
        )
    },
    {
        name: 'Status', selector: 'status', sortable: true, cell: (row) => (
            <CustomTooltip text={row.status}>
                <span>{(row.status && row.status.length > 20) ? row.status.slice(0, 20) + '...' : row.status}</span>
            </CustomTooltip>
        )
    },
]

const ListLeadDatasColumns = [
    { name: 'Sl No.', selector: '_id', sortable: true, cell: (row, index) => index + 1 },
    { name: 'uuid', selector: 'uuid', sortable: true },
]

const ListUserColumns = [
    { name: 'Sl No.', selector: 'id', sortable: true, cell: (row, index) => index + 1 },
    {
        name: 'Name', selector: 'name', sortable: true, cell: (row) => (
            <CustomTooltip text={row.name}>
                <span>{(row.name && row.name.length > 20) ? row.name.slice(0, 20) + '...' : row.name}</span>
            </CustomTooltip>
        )
    },
    {
        name: 'Email', selector: 'email', sortable: true, cell: (row) => (
            <CustomTooltip text={row.email}>
                <span>{(row.email && row.email.length > 20) ? row.email.slice(0, 20) + '...' : row.email}</span>
            </CustomTooltip>
        ) },
]

const ListSmsColumns = [
    { name: 'Sl No.', selector: 'id', sortable: true, cell: (row, index) => index + 1 },
    { name: 'SMS From Number', selector: 'sms_from_number', sortable: true },
    { name: 'SMS Content', selector: 'sms_content', sortable: true },
    { name: 'SMS Vendor', selector: 'sms_vendor', sortable: true },
    { name: 'SMS Url', selector: 'url', sortable: true },
    { name: 'Method', selector: 'method', sortable: true },
    { name: 'Body', selector: 'body', sortable: true },
]

const ListEmailColumns = [
    { name: 'Sl No.', selector: 'id', sortable: true, cell: (row, index) => index + 1 },
    { name: 'project Code', selector: 'project_code', sortable: true },
    { name: 'Email From', selector: 'email_from', sortable: true },
    { name: 'Email Subject', selector: 'email_subject', sortable: true },
    { name: 'Email Body', selector: 'email_body', sortable: true },
    { name: 'Email Vendor', selector: 'email_vendor', sortable: true },
    // { name: 'Url', selector: 'url', sortable: true },
    // { name: 'Method', selector: 'method', sortable: true },
    // { name: 'Body', selector: 'body', sortable: true },
]

const ListSplitFlowColumns = [
    { name: 'Sl No.', selector: 'id', sortable: true, cell: (row, index) => index + 1 },
    {
        name: 'Project Code', selector: 'project_code', sortable: true, cell: (row) => (
            <CustomTooltip text={row.project_code}>
                <span>{(row.project_code && row.project_code.length > 20) ? row.project_code.slice(0, 20) + '...' : row.project_code}</span>
            </CustomTooltip>
        )
    },
    {
        name: 'Flow Name', selector: 'flow_name', sortable: true, cell: (row) => (
            <CustomTooltip text={row.flow_name}>
                <span>{(row.flow_name && row.flow_name.length > 20) ? row.flow_name.slice(0, 20) + '...' : row.flow_name}</span>
            </CustomTooltip>
        )
    },
    {
        name: 'Flow Id', selector: 'flow_id', sortable: true, cell: (row) => (
            <CustomTooltip text={row.flow_id}>
                <span>{(row.flow_id && row.flow_id.length > 20) ? row.flow_id.slice(0, 20) + '...' : row.flow_id}</span>
            </CustomTooltip>
        )
    },

];

const ListProjectConfigurationColumns = [
    { name: 'Sl No.', selector: 'id', sortable: true, cell: (row, index) => index + 1 },
    {
        name: 'Project Code', selector: 'project_code', sortable: true, cell: (row) => (
            <CustomTooltip text={row.project_code}>
                <span>{(row.project_code && row.project_code.length > 20) ? row.project_code.slice(0, 20) + '...' : row.project_code}</span>
            </CustomTooltip>
        )
    }
];

const TemplateColumns = [
    { name: 'Sl No.', selector: 'id', sortable: true, cell: (row, index) => index + 1 },
    {
        name: 'Project Code', selector: 'project_code', sortable: true, cell: (row) => (
            <CustomTooltip text={row.project_code}>
                <span>{(row.project_code && row.project_code.length > 20) ? row.project_code.slice(0, 20) + '...' : row.project_code}</span>
            </CustomTooltip>
        )
    },
    {
        name: 'Page Type', selector: 'page_type', sortable: true, cell: (row) => (
            <CustomTooltip text={row.page_type}>
                <span>{(row.page_type && row.page_type.length > 20) ? row.page_type.slice(0, 20) + '...' : row.page_type}</span>
            </CustomTooltip>
        )
    },
    {
        name: 'Template Name', selector: 'template_name', sortable: true, cell: (row) => (
            <CustomTooltip text={row.template_name}>
                <span>{(row.template_name && row.template_name.length > 20) ? row.template_name.slice(0, 20) + '...' : row.template_name}</span>
            </CustomTooltip>
        )
    }
];
const ListFollowupRulecolumns = [
    { name: 'Sl No.', selector: '_id', sortable: true, cell: (row, index) => index + 1 },
    {
        name: 'Project Code', selector: 'project_code', sortable: true, cell: (row) => (
            <CustomTooltip text={row.project_code}>
                <span>{(row.project_code && row.project_code.length > 20) ? row.project_code.slice(0, 20) + '...' : row.project_code}</span>
            </CustomTooltip>
        )
    },
    {
        name: 'Followup Url', selector: 'followup_url', sortable: true, cell: (row) => (
            <CustomTooltip text={row.followup_url}>
                <span>{(row.followup_url && row.followup_url.length > 20) ? row.followup_url.slice(0, 20) + '...' : row.followup_url}</span>
            </CustomTooltip>
        )
    }
];
export {
    ListHttpClientsColumns,
    ProjectColumns,
    Entitycolumns,
    ListRulecolumns,
    ListProcessColumns,
    listAssignAttributeColumns,
    ModuleColumns,
    ScheduleColumns,
    ListLeadDatasColumns,
    ListUserColumns,
    ListSmsColumns,
    ListEmailColumns,
    ListSplitFlowColumns,
    ListProjectConfigurationColumns,
    TemplateColumns,
    ListFollowupRulecolumns
};
