import React, { useEffect, useState } from "react";
import Button from "../../UI/Button";
import TextField from "../../UI/TextField";
import Modal from 'react-bootstrap/Modal';
import "../../../assets/css/popup.scss";
import { useProjectCodeInfo } from "../../../Hooks/useProjectCodeInfo";
import Loader from "../../Utility/Loader";

const TogglePopup = (props) => {
  const resetForm = () => {
    setFormData({ projectName: '', projectCode: '', projectID: '' });
    setDefaultSplitFlow(true);
    setErrors({});
  };
  const [edit, setEdit] = useState({
    projectName: '',
    projectCode: '',
  });
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    props.setShowEdit(false);
    props.setshowCode('');
    props.setShowName('');
    resetForm();
    setErrors({});
  };


  const handleShow = () => {
    setShow(true);
    resetForm();
  }
  useEffect(() => {
    if (props.showName && props.showCode) {
      setEdit({ projectName: props.showName, projectCode: props.showCode });
      setFormData({ projectName: props.showName, projectCode: props.showCode });
      setErrors({});
    }
  }, [props.showName, props.showCode]);
  useEffect(() => {
    let project_details = props.showName && props.showCode ? { projectName: props.showName, projectCode: props.showCode } : '';
    setEdit(project_details);
  }, [props.showName]);
  const [formData, setFormData] = useState({ projectName: '', projectCode: '', projectID: '' });
  const [errors, setErrors] = useState({});
  const { createProjectCode, updateProjectCode, isLoading } = useProjectCodeInfo();
  const [defaultSplitFlow, setDefaultSplitFlow] = useState(true);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'default_split_flow':
        setDefaultSplitFlow(!defaultSplitFlow);
        break;

      default:
        setFormData({
          ...formData,
          [name]: value
        });
        break;
    }

  }
  const handleSubmit = () => {
    const newErrors = {};

    if (formData.projectName.trim() === '') {
      newErrors.projectName = 'Project Name is required';
    }

    if (formData.projectCode.trim() === '') {
      newErrors.projectCode = 'Project Code is required';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      formData.default_split_flow = defaultSplitFlow;
      (async () => {
        handleClose();
        const response = await createProjectCode(formData);
        props.setToggleListing(!props.toggleListing);
      })();
    }
  };


  const EditSubmit = (event) => {
    event.preventDefault();
    const updatedFormData = {
      ...formData,
      projectID: props.projectID
    };

    const newErrors = {};

    if (updatedFormData.projectName.trim() === '') {
      newErrors.projectName = 'Project Name is required';
    }

    if (updatedFormData.projectCode.trim() === '') {
      newErrors.projectCode = 'Project Code is required';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      (async () => {
        const response = await updateProjectCode(updatedFormData);
        handleClose();
        props.setToggleListing(!props.toggleListing);
      })();
    }
  };





  return (
    <>
      <Loader active={isLoading} />
      <div className="toggle-popup">
        <Button onClick={handleShow} className="button_a button2" label="Add Project" />
        <Modal show={show || props.showEdit} onHide={handleClose} dialogClassName="toggle-popup" backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>{show && !props.showEdit ? `Add Project` : `Edit Project`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="col-lg-6">
                Project Name
              </div>
              <div className="col-lg-8">
                <TextField
                  type="text"
                  className="form-control"
                  name="projectName"
                  value={formData.projectName}
                  onChange={(e) => handleInputChange(e)}
                  error={errors.projectName}
                />

              </div><br />
              <div className="col-lg-6">
                Project Code
              </div>
              <div className="col-lg-8">
                <TextField
                  type="text"
                  className="form-control"
                  name="projectCode"
                  value={formData.projectCode}
                  onChange={(e) => handleInputChange(e)}
                />
                {errors.projectCode && (
                  <span className="error-message">{errors.projectCode}</span>
                )}
              </div>
              {!props.showEdit &&
                <div className="col">
                  <div className="row">
                    <div className="col-auto">
                      <label className="form-label">Default home flow</label>
                    </div>
                    <div className="col-auto">
                      <input
                        className="form-check-input p-2"
                        type="checkbox"
                        name={`default_split_flow`}
                        onChange={(e) => handleInputChange(e)}
                        checked={defaultSplitFlow}
                      />
                    </div>
                  </div>
                </div>
              }
            </form>
          </Modal.Body>
          <Modal.Footer>
            <div className="col-lg-8">
              {!props.showEdit ? (<Button className="button_a button2" label="Add" onClick={handleSubmit} />) : (<Button className="button_a button2" label="Update" onClick={EditSubmit} />)}
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}

export default TogglePopup