import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useLeadData } from '../../../Hooks/useLeadDatas';

const LeadDatasDeletePopup = (props) => {
  const { deleteLeadData } = useLeadData();

  const handleClose = () => props.setDelete(false);

  const handleDelete = async () => {
    const id = props.id;
    const response = await deleteLeadData(id);
    handleClose();
    props.setToggleList(!props.toggleList);
  };

  return (
    <div>
      <Modal
        show={props.deletes}
        onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant='danger'
            onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LeadDatasDeletePopup;
