import React, { useState, useEffect } from "react";
import TableHead from "../Utility/TableHead";
import DataTable from "react-data-table-component";
import TextField from "../UI/TextField";
import "../../assets/css/project.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Loader from "../Utility/Loader";
import { ListProjectConfigurationColumns } from "../../Constants/TableColumns";
import { useProjectConfiguration } from "../../Hooks/useProjectConfiguration";
import { useHistory } from "react-router-dom";
import Button from "../UI/Button";
import { Link } from "react-router-dom";
import DeletePopup from "../Includes/Popup/DeletePopup";

const ListProjectConfiguration = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filterText, setFilterText] = useState("");
    const { listProjectConfiguration, deleteProjectConfiguration, isLoading } = useProjectConfiguration();
    const [deletes, setDelete] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [toggleListing, setToggleListing] = useState(true);
    const history = useHistory();
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false); // New state to track select all
    const [showTrashButton, setShowTrashButton] = useState(false);

    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        if (!selectAll) {
            const allRowIds = data.map((row) => row.id);
            setSelectedRows(allRowIds);
            const updatedSelectedRows = selectAll ? [] : allRowIds;
            setShowTrashButton(updatedSelectedRows.length > 0);
        } else {
            setSelectedRows([]);
            setShowTrashButton(false);
        }
    };

    const SelectHeader = ({ selectAll, handleSelectAll }) => (
        <>
            <input
                style={{ marginRight: "5px" }}
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
                id="select_header"
            />
            <label htmlFor="select_header">Select</label>
        </>
    ); 
    const ListProjectConfigurationColumnsWithActions = [
        {
            name: <SelectHeader selectAll={selectAll} handleSelectAll={handleSelectAll}/>,
            cell: (row) => (
                <input
                    type="checkbox"
                    checked={selectAll || selectedRows.includes(row.id)} // Check if select all is checked or row is selected
                    onChange={() => handleCheckboxChange(row.id)}
                />
            ),
            header: (row) => (
                <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                />
            )
        },
        ...ListProjectConfigurationColumns,
        {
            name: "Actions",
            cell: (row) => (
                <div>
                    <button onClick={() => splitFlowEdit(row.id)}>
                        <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <button onClick={() => splitFlowDelete(row.id)}>
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                </div>
            ),
        },
    ];
    useEffect(() => {
        (async () => {
            const response = await listProjectConfiguration();
            const projectConfigData = response.data.map((item) => ({
                id: item._id,
                project_code: item.project_code,
            }));
            setData(projectConfigData);
            setFilteredData(projectConfigData);
        })();
    }, [toggleListing]);

    const handleFilter = (e) => {
        const keyword = e.target.value.toLowerCase();
        console.log(keyword, data);
        const filtered = data.filter(
            (item) =>
                item.id.toString().toLowerCase().includes(keyword) ||
                item.project_code.toString().toLowerCase().includes(keyword) ||
                item.flow_name && item.flow_name.toLowerCase().includes(keyword) ||
                item.flow_id && item.flow_id.toLowerCase().includes(keyword)
        );
        setFilteredData(filtered);
        setFilterText(keyword);
    };

    const splitFlowDelete = async (rowId) => {
        setDelete(true);
        setDeleteId(rowId);
    };
    const splitFlowEdit = async (rowId) => {
        history.push("/project-configuration?id=" + rowId);
    };

    const handleCheckboxChange = (rowId) => {
        const updatedSelectedRows = selectedRows.includes(rowId)
            ? selectedRows.filter((id) => id !== rowId)
            : [...selectedRows, rowId];
        setSelectedRows(updatedSelectedRows);

        // Check if any checkbox is selected, including the "Select All" checkbox
        const isAnyCheckboxSelected = updatedSelectedRows.length > 0 || selectAll;
        setShowTrashButton(isAnyCheckboxSelected);
    };

    const handleMultipleDelete = async () => {
        setDelete(true);
        setDeleteId(selectedRows);
    };

    const deleteProjectConfig = async (configId) => {
        await deleteProjectConfiguration(configId);
        setToggleListing(!toggleListing);
    };

    return (
        <>
            <Loader active={isLoading} />
            <div className="col-lg-12 table-section project">
                <div className="row m-0">
                    <div className="col-lg-12 col-md-12 p-1">
                        <div className="tablesec-bg">
                            <div className="col-lg-12 py-3 px-3">
                                <div className="row">
                                    <div className="col-lg-4" id="search_bar1">
                                        <TableHead title="Project Configuration Management" />
                                    </div>
                                    <div className="col-lg-3"></div>

                                    <div className="col-lg-1 text-right">
                                        <a href="#">
                                            <i
                                                className="fa-solid fa-rotate px-2 mt-2"
                                                aria-hidden="true"
                                                data-toggle="tooltip"
                                                title="Refresh Page"
                                            ></i>
                                        </a>
                                    </div>
                                    <div className="col-lg-4 text-right">
                                        <Link to="/project-configuration">
                                            <Button
                                                type="button"
                                                className="button_a button2"
                                                label="Add Project Configuration"
                                            />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="table_hold">
                                <div>
                                    <TextField
                                        type="text"
                                        value={filterText}
                                        onChange={handleFilter}
                                        placeholder="Search..."
                                        className="keyword-search"
                                    />
                                    <div style={{ float: "right" }}>
                                        {showTrashButton && <button
                                            // className="btn btn-danger"
                                            onClick={handleMultipleDelete}
                                            style={{ fontSize: "2 rem", margin: "10px", borderWidth: "0px" }}
                                            title="Delete Selected"
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>}
                                    </div>
                                     {/* <label>
                                            <input
                                                type="checkbox"
                                                checked={selectAll}
                                                onChange={handleSelectAll}
                                            />
                                            Select All
                                        </label> */}

                                    <DataTable
                                        columns={ListProjectConfigurationColumnsWithActions}
                                        data={filteredData}
                                        pagination
                                        paginationPerPage={10}
                                    />
                                    <DeletePopup
                                        deletes={deletes}
                                        setDelete={setDelete}
                                        deleteId={deleteId}
                                        setToggleListing={setToggleListing}
                                        toggleListing={toggleListing}
                                        deleteCollection={deleteProjectConfig}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ListProjectConfiguration;
