import React, { useEffect, useState } from 'react';
import AWS from 'aws-sdk';
import TemplateElementEditor from '../Includes/Templates/TemplateElementEditor';
import { useTemplates } from '../../Hooks/useTemplates';
import "../../assets/css/templateViewer.scss"
import Loader from "../Utility/Loader";

import {
  AwsAccessKeyId,
  AwsSecretAccessKey,
  AwsRegion, AwsBucket
} from '../../Constants/EnvConstants';

const TemplateViewer = () => {
  // http://localhost:3000/template-editor?folder_path=POC/template1/slide1&url=POC/template1/slide1/index.html
  const { editTemplate, getPlaceholders, isLoading } = useTemplates();

  const urlParams = new URLSearchParams(window.location.search);
  const template_id = urlParams.get('template_id') || "";
  const page_id = urlParams.get('page_id') || "";

  const [absolutePath, setAbsolutePath] = useState("")
  const [htmlPath, setHtmlPath] = useState("")

  const [originalPlaceHolders, setOriginalPlaceHolders] = useState({})

  useEffect(() => {
    async function fetchData() {
      const response = await editTemplate(template_id);
      if (response.data?.status !== "Failed") {
        setAbsolutePath(response.data.template_details.folder_path + "/" || "");
        setHtmlPath(response.data.template_details.url || "");
      }
    }
    if (template_id) {
      fetchData();
    }
  }, [template_id])

  const [htmlContent, setHtmlContent] = useState('');

  // Configure AWS
  AWS.config.update({
    accessKeyId: AwsAccessKeyId,
    secretAccessKey: AwsSecretAccessKey,
    region: AwsRegion
  });

  // Create S3 instance
  const s3 = new AWS.S3();
  // Params for HTML file
  const htmlParams = {
    Bucket: AwsBucket,
    Key: `${htmlPath}`
  };

  useEffect(() => {
    const fetchDataAndRender = async () => {
      try {
        var newResponseData = '';
        if (template_id && page_id) {
          let data = {
            page_id: page_id,
            template_id: template_id
          };
          const response = await getPlaceholders(data);
          if (response.status === 200) {
            const responseData = response.data;
            newResponseData = responseData;
            // setEditedPlaceholders(responseData);
          } else {
            console.error("Failed to fetch data:", response.status);
          }
        }
        if (template_id && absolutePath && htmlPath) {
          s3.getObject(htmlParams, (err, data) => {
            if (err) {
              console.error('Error fetching HTML file from S3:', err);
              document.body.innerHTML += `<p>Template not found.</p><p>Error: ${err}</p>`;
            } else {
              // Parse HTML content
              const htmlString = data.Body.toString('utf-8');
              const parser = new DOMParser();
              const doc = parser.parseFromString(htmlString, 'text/html');

              let bodyContent = "";

              const tempBodyContent = document.createElement('div');
              tempBodyContent.innerHTML = doc.body.innerHTML.replace(new RegExp('src="dist/', 'gi'), 'src="https://dev.static-assets.yousted.org/' + absolutePath + 'dist/');

              if (newResponseData && page_id) {
                const elementsWithPlaceholder = tempBodyContent.querySelectorAll('[data-dynamic_placeholder]');
                elementsWithPlaceholder.forEach(element => {
                  const placeholderKey = element.getAttribute('data-dynamic_placeholder');

                  if (element.tagName.toLowerCase() === "img") {
                    const oldSrc = element.src;

                    setOriginalPlaceHolders(prevState => {
                      return {
                        ...prevState,
                        [placeholderKey]: oldSrc
                      };
                    });

                    if (newResponseData.hasOwnProperty(placeholderKey)) {
                      element.src = newResponseData[placeholderKey];
                    }
                  } else {
                    const oldInnerHTML = element.innerHTML;

                    setOriginalPlaceHolders(prevState => {
                      return {
                        ...prevState,
                        [placeholderKey]: oldInnerHTML
                      };
                    });

                    if (newResponseData.hasOwnProperty(placeholderKey)) {
                      element.innerHTML = newResponseData[placeholderKey];
                    }
                  }

                });
                bodyContent = tempBodyContent.innerHTML;
              } else {
                bodyContent = tempBodyContent.innerHTML;
              }

              // Find all <link> tags with rel="stylesheet" attribute
              const linkTags = doc.querySelectorAll('link[rel="stylesheet"]');

              // Extract the href attribute value containing the relative path to CSS files
              const cssPaths = Array.from(linkTags, link => link.getAttribute('href'));

              // Fetch and inject each CSS file
              cssPaths.forEach(cssPath => {
                const cssParams = {
                  Bucket: AwsBucket,
                  Key: `${absolutePath}${cssPath}`
                };
                s3.getObject(cssParams, (cssErr, cssData) => {
                  if (cssErr) {
                    console.error('Error fetching CSS file from S3:', cssErr);
                  } else {
                    // Inject CSS directly into the <head> of the document
                    const styleTag = document.createElement('style');
                    styleTag.innerHTML = cssData.Body.toString('utf-8');
                    document.head.appendChild(styleTag);
                  }
                });
              });

              // Render HTML content
              setHtmlContent(bodyContent);
            }
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchDataAndRender();
  }, [template_id, page_id, absolutePath, htmlPath]);

  useEffect(() => {
    // Fetch and inject each script tag
    const injectScripts = async () => {
      try {
        // Manually add jQuery script tag
        const jqueryScript = document.createElement('script');
        jqueryScript.src = 'https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js';
        jqueryScript.async = true;
        document.body.appendChild(jqueryScript);

        if (htmlContent) {
          const doc = new DOMParser().parseFromString(htmlContent, 'text/html');
          const scriptTags = doc.querySelectorAll('script');
          scriptTags.forEach(scriptTag => {
            let src = scriptTag.getAttribute('src');
            if (src) {
              const scriptElement = document.createElement('script');
              scriptElement.src = src;
              scriptElement.async = true; // Optional: async attribute for asynchronous loading
              document.body.appendChild(scriptElement);
            } else {
              const inlineScript = document.createElement('script');
              inlineScript.textContent = scriptTag.textContent;
              document.body.appendChild(inlineScript);
            }
          });
        }
      } catch (error) {
        console.error("Error injecting scripts:", error);
      }
    };
    injectScripts();
  }, [htmlContent, absolutePath]);

  // useEffect(() => {
  //   if (template_id && absolutePath && htmlPath) {
  //     s3.getObject(htmlParams, (err, data) => {
  //       if (err) {
  //         console.error('Error fetching HTML file from S3:', err);
  //         document.body.innerHTML += `<p>Template not found.</p><p>Error: ${err}</p>`;
  //       } else {
  //         // Parse HTML content
  //         const htmlString = data.Body.toString('utf-8');
  //         const parser = new DOMParser();
  //         const doc = parser.parseFromString(htmlString, 'text/html');

  //         const bodyContent = doc.body.innerHTML;
  //         // console.log(bodyContent);

  //         // Find all <link> tags with rel="stylesheet" attribute
  //         const linkTags = doc.querySelectorAll('link[rel="stylesheet"]');

  //         // Extract the href attribute value containing the relative path to CSS files
  //         const cssPaths = Array.from(linkTags, link => link.getAttribute('href'));

  //         // Fetch and inject each CSS file
  //         cssPaths.forEach(cssPath => {
  //           const cssParams = {
  //             Bucket: AwsBucket,
  //             Key: `${absolutePath}${cssPath}`
  //           };
  //           s3.getObject(cssParams, (cssErr, cssData) => {
  //             if (cssErr) {
  //               console.error('Error fetching CSS file from S3:', cssErr);
  //             } else {
  //               // Inject CSS directly into the <head> of the document
  //               const styleTag = document.createElement('style');
  //               styleTag.innerHTML = cssData.Body.toString('utf-8');
  //               document.head.appendChild(styleTag);
  //             }
  //           });
  //         });

  //         // Render HTML content
  //         setHtmlContent(bodyContent);
  //       }
  //     });
  //   }
  // }, [template_id, absolutePath, htmlPath]);

  const [element, setElement] = useState()

  const handleClick = (event) => {
    event.preventDefault()
    if (event.target.matches('[data-dynamic_placeholder]')) {
      setElement(event.target)
    }
  };

  const [allowEdit, setAllowEdit] = useState(false)

  return (
    <>
      <Loader active={isLoading} />
      {/* section where the html from aws gets injected */}
      <div
        onClick={allowEdit ? handleClick : undefined} className={`template-parent-class yourifeinsu ${allowEdit ? "edit-active" : ""}`}>
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </div>
      {/* end */}
      <TemplateElementEditor
        element={element}
        setElement={setElement}
        allowEdit={allowEdit}
        setAllowEdit={setAllowEdit}
        page_id={page_id}
        template_id={template_id}
        originalPlaceHolders={originalPlaceHolders}
      />
    </>
  )
}

export default TemplateViewer