import { useState, useEffect } from "react";
import { uuid } from "../../../Constants/Reports/ListOptions";
import { useGlobalLeads } from "../../../Hooks/Reports/useGlobalLeads";
import { useProjectCodeInfo } from "../../../Hooks/useProjectCodeInfo";
import { GloablLeadFilter } from "../../../Constants/Reports/ListOptions";
import { useLeadSummary } from "../../../Hooks/Reports/useLeadSummary";

const GlobalLeadsOptions = (props) => {
  const [entityAttribute, setEntityAttribute] = useState([]);
  const [customEntityAttribute, setCustomEntityAttribute] = useState([]);
  const [projectCode, setProjectCode] = useState([]);
  const [selectedProjectCode, setSelectedProjectCode] = useState([]);
  const [filterState, setFilterState] = useState(GloablLeadFilter);
  const [filterStateOptions, setFilterStateOptions] = useState({ data: '', status: 'd-none' });
  const [selectedFilterValues, setselectedFilterValues] = useState();
  const { getEntityAttributeLists, isLoading } = useGlobalLeads();
  const [viewData, setViewData] = useState();
  const { listProjectCode } = useProjectCodeInfo();
  const { viewLeadSummary } = useLeadSummary();

  const handleAttributeCheckboxChange = (index) => {
    setEntityAttribute(prevState => {
      const updatedAttributes = [...prevState];
      updatedAttributes[index] = {
        ...updatedAttributes[index],
        checked: !updatedAttributes[index].checked
      };
      return updatedAttributes;
    });
  };
  const handleDynamicAttributeCheckboxChange = (index) => {
    setCustomEntityAttribute(prevState => {
      const updatedAttributes = [...prevState];
      updatedAttributes[index] = {
        ...updatedAttributes[index],
        checked: !updatedAttributes[index].checked
      };
      return updatedAttributes;
    });
  };
  const handleProjectCodeSelectChange = (event) => {
    setEntityAttribute([]);
    setCustomEntityAttribute([]);
    props.setFilteredData([]);
    props.setLoader(true);
    clearAllFilterFields();
    const selectedProjectCodeValues = Array.from(event.target.selectedOptions, option => option.value);
    setSelectedProjectCode(selectedProjectCodeValues);
    getfilterData(selectedProjectCodeValues);
    const updatedProjectCode = projectCode.filter(code => !selectedProjectCodeValues.includes(code));
    const newProjectCode = [...selectedProjectCodeValues, ...updatedProjectCode];
    setProjectCode(newProjectCode);
    fetchProjectCodeEntityAttributes(selectedProjectCodeValues);
  }
  const clearAllFilterFields = () =>{
    setFilterStateOptions({ data: '', status: 'd-none' });
    const updatedFilterState = Object.fromEntries(
      Object.entries(filterState).map(([key, value]) => [
        key,
        { ...value, checked: false }
      ])
    );
    setFilterState(updatedFilterState);
  }
  const fetchProjectCodeEntityAttributes = async(projectCode) =>{
    const response = await getEntityAttributeLists(projectCode);
    if(response.data.length > 0){
      const options = []
      const attributeArray = response.data.filter(item => item.is_custom_attribute === "no");
      const uuidIndex = attributeArray.findIndex(item => item.attribute_slug === 'uuid');
      if (uuidIndex !== -1) {
          const uuidItem = attributeArray.splice(uuidIndex, 1)[0];
          attributeArray.unshift(uuidItem);
      }
      const customAttributeArray = response.data.filter(item => item.is_custom_attribute === "yes");
      attributeArray.forEach(item => {
        options.push(item);
      });
      customAttributeArray.forEach(item => {
        options.push(item);
      });
      setEntityAttribute(attributeArray);
      setCustomEntityAttribute(customAttributeArray);
      const { data, tableColumns } = fetchCheckedOptionsValues(options);
      props.setOptionsData({
        data:data.checkedDataOptions,
        projectCode:projectCode
      });
      props.setTableColumns(tableColumns);
    }
  }
  const getfilterData = async(projectCode) =>{
    const filterResponse = await viewLeadSummary(projectCode);
    setViewData(filterResponse.data);
    props.setLoader(false);
  }
  useEffect(() => {
    (async () => {
      const projectCodeList =  await listProjectCode();
      if (projectCodeList.data.length > 0) {
        const reorderedProjects = projectCodeList.data.sort((a, b) => {
          if (a.project_code === "YLI-DEMO") return -1;
          if (b.project_code === "YLI-DEMO") return 1;
          return 0;
        });
        const projectCodes = reorderedProjects.map(project => project.project_code);
        const defaultProjectCode = [projectCodes[0]];
        setSelectedProjectCode(defaultProjectCode);
        setProjectCode(projectCodes);
        getfilterData(defaultProjectCode);
        fetchProjectCodeEntityAttributes(defaultProjectCode);
      }
    })();
  }, []);
  useEffect(() =>{
    const combinedAttributes = entityAttribute.concat(customEntityAttribute);
    const { data, tableColumns } = fetchCheckedOptionsValues(combinedAttributes);
    props.setTableColumnsAfterFormSubmit(tableColumns);
    props.setOptionsDataAfterFormSubmit({
      data: data.checkedDataOptions,
      projectCode : selectedProjectCode,
      filterColumns: data.checkedFilterOptions,
      filterValues: selectedFilterValues
    });
  },[entityAttribute, customEntityAttribute, selectedProjectCode, filterState, selectedFilterValues])
  const fetchCheckedOptionsValues = (options) => {
    const checkedFilterOptions = Object.values(filterState).filter(item => item.checked) ?? [];
    const checkedDataOptions = options.filter(item => item.checked);
    const tableColumns = checkedDataOptions.map(item =>{
      return {
        name : <div className="column-header">{item.attribute_name}</div>,
        selector : item.attribute_slug,
        sortable : true
      }
    })
    return { data: { checkedDataOptions, checkedFilterOptions }, tableColumns };
  }
  const handleFilterCheckboxChange = (key) => {
    setFilterState(prevState => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        checked: !prevState[key].checked
      }
    }));
    showFilterOptions(key);
  }
  const showFilterOptions = (key) => {
    console.log(key.data_column);
    if (viewData.hasOwnProperty(key) && filterState[key].checked == false) {
      const filterStateOptions = viewData[key].filter(name => name[0] !== "" && name[0] !== null && name.length > 0).map((name, index) => {
        return (
          <option value={name[0]} key={index}>{name[0]}</option>
        );
      });
      setFilterStateOptions(prevState => ({
        ...prevState,
        [key]: { data: filterStateOptions, status: 'd-block' }
      }))
    } else {
     if (filterState[key]?.data_column && selectedFilterValues[filterState[key].data_column]) {
        const updatedFilterValues = { ...selectedFilterValues };
        delete updatedFilterValues[filterState[key].data_column];
        console.log("after removing");
        console.log(updatedFilterValues);
        setselectedFilterValues(updatedFilterValues);
      }
      setFilterStateOptions(prevState => ({
        ...prevState,
        [key]: { status: 'd-none' }
      }));
    }
  }
  const handleFilterSelectChange = (event, type) => {
    const selectedFilterValues = Array.from(event.target.selectedOptions, option => option.value);
    setselectedFilterValues(prevState => ({
      ...prevState,
      [type]: selectedFilterValues
    }));
  }
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  let attributes = 'No attributes found for this project code.';
   if(entityAttribute.length > 0){
    attributes = entityAttribute.map((attribute, index) => (
      <div key={`data-${attribute.attribute_slug}`} className="checkbox-item">
        <label htmlFor={`data-checkbox-${attribute.attribute_slug}`}>
          <input
            type="checkbox"
            id={`attribute-checkbox-${attribute.attribute_slug}`}
            name={attribute.attribute_name}
            checked={attribute.checked}
            className="option-check-input"
            onChange={() => handleAttributeCheckboxChange(index)}
          />{" "}
          {attribute.attribute_name}
        </label>
      </div>
    ));
  }
  let customAttributes = 'No custom attributes found for this project code.';
  if(customEntityAttribute.length > 0){
    customAttributes = customEntityAttribute.map((attribute, index) => (
     <div key={`data-${attribute.attribute_slug}`} className="checkbox-item">
       <label htmlFor={`data-checkbox-${attribute.attribute_slug}`}>
         <input
           type="checkbox"
           id={`dynamic-attribute-checkbox-${attribute.attribute_slug}`}
           name={attribute.attribute_name}
           checked={attribute.checked}
           className="option-check-input"
           onChange={() => handleDynamicAttributeCheckboxChange(index)}
         />{" "}
         {attribute.attribute_name}
       </label>
     </div>
   ));
 }
 let listProjectCodes = '';
  if (projectCode.length > 0) {
     listProjectCodes = projectCode.map(project => (
         <option key={project} value={project}>{project}</option>
     ));
  }
  const filterCheckboxes = Object.keys(GloablLeadFilter).map((key) => (
    <div key={`filter-${key}`} className="checkbox-item">
      <input
        type="checkbox"
        id={`filter-checkbox-${key}`}
        name={filterState[key].name}
        checked={filterState[key].checked}
        className="option-check-input"
        onChange={() => handleFilterCheckboxChange(key)}
      />{" "}
      <label htmlFor={`filter-checkbox-${key}`}>{filterState[key].value}</label>
    </div>
  ));
  return (
    <>
      <div className="row">
        <div className="col-md-1 control-label"><b>Project Code</b></div>
        <div className="col-md-4 col-sm-4 col-xs-4 checker">
          <select className="col-md-2 form-control" onChange={handleProjectCodeSelectChange}>
            {listProjectCodes}
          </select>
        </div>
      </div><br/>
      <div className="row">
        <div className="col-md-1 control-label"><b>Attributes</b></div>
        <div className="col-md-11 col-sm-8 col-xs-6 checker">{attributes}</div>
      </div><br />
      <div className="row">
        <div className="col-md-1 control-label"><b>Custom Attributes</b></div>
        <div className="col-md-11 col-sm-8 col-xs-6 checker">{customAttributes}</div>
      </div><br />
      <div className="row">
        <div className="col-md-1 control-label"><b>Filter</b></div>
        <div className="col-md-11 col-sm-8 col-xs-6 checker">{filterCheckboxes}</div>
      </div><br />
      <div className="row">
        {Object.keys(filterState).map(key => (
          <div key={key} className={`col-md-4 div_domain ${filterStateOptions[key]?.status || 'd-none'}`}>
            {capitalizeFirstLetter(key.replace(/([A-Z])/g, ' $1').trim())}:
            <select className="col-md-2 form-control" multiple onChange={(event) => handleFilterSelectChange(event, filterState[key].data_column)}>
              <option>Select</option>
              {filterStateOptions[key]?.data || null}
            </select>
          </div>
        ))}
      </div><br />
    </>
  );
}
export default GlobalLeadsOptions