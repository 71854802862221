import React, { useEffect, useState } from 'react';
import { useEntityAttribute } from '../../Hooks/useEntityAttribute';
import { useProjectCodeInfo } from '../../Hooks/useProjectCodeInfo';
import SelectBox from '../UI/SelectBox';
import TextField from '../UI/TextField';
import "../../assets/css/addRule.scss";
import { useAssignAttribute } from '../../Hooks/useAssignAttribute';
import Button from '../UI/Button';
import { useHistory } from 'react-router-dom';
import { useProcessType } from "../../Hooks/useProcessType";
import { CustomAssignAttribute } from '../../Constants/OperatorConstants';

const AssignAttribute = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const rowId = (urlParams.get('id')) ? urlParams.get('id') : "";
  const { listEntityAttribute } = useEntityAttribute();
  const { listProjectCode } = useProjectCodeInfo();
  const { createAssignAttribute, editAssignAttribute, updateAssignAttribute } = useAssignAttribute();
  const [update, setUpdate] = useState('');
  const history = useHistory();
  const { listProcess } = useProcessType();
  const formDatas = (rowId) ? [] : [{
    attribute: '',
    assign_label: '',
    assign_value: ''
  }];

  const [formData, setFormData] = useState(formDatas);
  const [processType, setProcessType] = useState([]);
  const [extraFormData, setExtraExtraFormData] = useState({
    project_code: "",
    process_type: "",
    process_type_id: "",

  });
  const [attributes, setAttributes] = useState([]);
  // const [processName, setProcessName] = useState([]);
  // const [processTypeName, setProcessTypeName] = useState([{}]);
  const [projectCode, setProjectCode] = useState([]);
  const [valuesCorrespondingToProjectCode, setValuesCorrespondingToProjectCode] = useState({
    processType: [],
    attributes: [],
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    // Define a function to filter values based on project code
    const filterValues = () => {
      const matchingAttributes = attributes.filter((item) => item.project_code === extraFormData.project_code);
      const matchingProcessTypes = processType.filter((item) => item.project_code === extraFormData.project_code);
      // Combine matching attributes and process types into label value format
      const formattedValues = {
        processType: matchingProcessTypes.map((type) => ({
          label: type.label,
          value: type.value,
        })),
        attributes: matchingAttributes.map((attribute) => ({
          label: attribute.label,
          value: attribute.value,
        })),
      };

      setValuesCorrespondingToProjectCode(formattedValues);
    };

    filterValues();
  }, [extraFormData.project_code, attributes, processType]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await listEntityAttribute();
        const response2 = await listProjectCode();
        const response3 = await listProcess();

        const formattedAttributes = response.data.map((item) => ({
          label: item.slug,
          value: item.slug,
          id: item._id,
          project_code: item.project_code,
        }));
        const formattedProjectCode = response2.data.map((item) => ({
          label: item.project_code,
          value: item.project_code,
        }));
        const formattedProcessType = response3.data.map((item) => ({
          label: item.name,
          value: item._id,
          project_code: item.project_code,
        }));
        //   const idToNameMap = new Map(formattedProcessType.map(item => [item.id,item.label,item.project_id]));
        //   console.log(idToNameMap,123);

        // const updatedFetchedData = formattedProcessType.map(item => ({
        //   ...item,
        //   process_type: idToNameMap.get(item.label) || item.project_id
        // }));
        // console.log(updatedFetchedData);
        //setData(updatedFetchedData);
        // Update the state with the formatted data
        setAttributes(formattedAttributes);
        setProjectCode(formattedProjectCode);
        setProcessType(formattedProcessType);
        // setProcessName(updatedFetchedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleInputChange = (event, sectionIndex) => {
    const { name, value, options } = event.target;
    switch (name) {
      case "project_code":
        // const process = processName.find((item)=> item.project_id == value);
        // const process_new = process ? process : "";
        // setProcessTypeName([process_new])
        setExtraExtraFormData((prevData) => {
          return {
            ...prevData,
            [name]: value,
          };
        });
        break;
      case "process_type":
        const selectedOption = options[options.selectedIndex];
        const label = selectedOption.label;

        setExtraExtraFormData((prevData) => {
          return {
            ...prevData,
            [`${name}_id`]: value,
            [name]: label,
          };
        });
        break;
      default:
        setFormData((prevData) => {
          const newData = [...prevData];
          const originalFieldName = name.replace(`_${sectionIndex}`, ''); // Remove the index from the field name
          newData[sectionIndex] = {
            ...newData[sectionIndex],
            [originalFieldName]: value,
          };
          return newData;
        });
        break;
    }
  };
  const handleAddSection = () => {
    // setSectionCount((prevCount) => prevCount + 1);
    setFormData((prevData) => [
      ...prevData,
      {
        attribute: '',
        assign_label: '',
        is_advanced: "no"
      }
    ]);
  };
  const removeSection = (event, sectionIndex) => {
    const updatedFormData = formData.filter((_, index) => index !== sectionIndex);
    setFormData(updatedFormData);
  }
  const handleCheckboxChange = (sectionIndex) => {
    setFormData((prevData) => {
      const newData = [...prevData];
      newData[sectionIndex] = {
        ...newData[sectionIndex],
        is_advanced: newData[sectionIndex].is_advanced === "no" ? "yes" : "no",
        // attribute: newData[sectionIndex].is_advanced === "no" ? "Custom Attribute" : newData[sectionIndex].attribute
      };

      // Reset relevant fields if is_advanced is set to "no"
      if (newData[sectionIndex].is_advanced === "no") {
        newData[sectionIndex] = {
          ...newData[sectionIndex],
        };
      }

      return newData;
    });
  };

  const renderDynamicSections = () => {
    return formData.map((section, index) => (
      <div key={index} className="dynamic-section pt-3">
        <div className="col">
            <div className="row">
              <div className="col-auto">
                <label className="form-label">Custom Attributes</label>
              </div>
              <div className="col-auto">
                <input
                  className="form-check-input p-2"
                  type="checkbox"
                  name={`advancedCheckbox_${index}`}
                  onChange={() => handleCheckboxChange(index)}
                  checked={section.is_advanced === "yes" ? true : false}
                />
              </div>
            </div>
          </div>
        <div className="row row-gap-3">
        {section.is_advanced === "yes" && (
            <div className={`col-sm-6 ${section.is_advanced === "yes" ? "col-md-3" : "col-md-4"}`}>
              <label className="form-label">Custom Attribute</label>
              <SelectBox
              className="form-select"
              name={`attribute_${index}`}
              id={`attribute_${index}`}
              OptionValue={CustomAssignAttribute}
              emptyOption="Select assign attribute"
              value= {section.attribute}
              onChange={(e) => handleInputChange(e, index)}
              error={errors[`attribute_${index}`]}
            />
            </div>
          )}
          {/* Add other fields with similar logic */}
          <div className={`col-sm-6 ${section.is_advanced === "yes" ? "d-none" : "col-md-4"}`}>
            <label className="form-label">Attributes</label>
            <SelectBox
              className="form-select"
              name={`attribute_${index}`}
              id={`attribute_${index}`}
              OptionValue={valuesCorrespondingToProjectCode.attributes}
              emptyOption="Select attribute"
              value= {section.attribute}
              onChange={(e) => handleInputChange(e, index)}
              error={errors[`attribute_${index}`]}
            />
          </div>
          <div className={`col-sm-6 ${section.is_advanced === "yes" ? "col-md-3" : "col-md-4"}`}>
            <label className="form-label">Assign Label</label>
            <TextField
              className="form-control"
              name={`assign_label_${index}`}
              id={`assign_label_${index}`}
              value={section.assign_label}
              onChange={(e) => handleInputChange(e, index)}
              error={errors[`assign_label_${index}`]}
            />
          </div>
          {section.is_advanced === "yes" && (
          <div className={`col-sm-6 ${section.is_advanced === "yes" ? "col-md-3" : "col-md-4"}`}>
            <label className="form-label">Value</label>
            <TextField
              className="form-control"
              name={`assign_value_${index}`}
              id={`assign_value_${index}`}
              value={section.assign_value}
              onChange={(e) => handleInputChange(e, index)}
              error={errors[`assign_value_${index}`]}
            />
          </div>
            )}
          <div className="col-sm-2 col-md-2">
            <label className="form-label"></label>
            {index > 0 && (
              <div className="col d-flex justify-content-end">
                <Button className='add-section fw-bolder fs-6' onClick={(e) => removeSection(e, index)} label="-" />
              </div>
            )}
          </div>
        </div>
      </div>
    ));
  };
  const convertToNewFormat = () => {
    const convertedData = {
      project_code: extraFormData.project_code,
      process_type_id: extraFormData.process_type_id,
      process_type: extraFormData.process_type,
      data: [],
    };

    formData.forEach((section) => {
      if(section.is_advanced == 'yes'){
        var { attribute, assign_value, assign_label } = section;
        convertedData.data.push({
          attribute,
          assign_value,
          assign_label,
        });
      }else{
        var { attribute, assign_value, assign_label } = section;
        convertedData.data.push({
          attribute,
          assign_label,
        });
      }
    });

    return convertedData;
  };

  useEffect(() => {
    if (rowId) {
      (async () => {
        try {
          const response = await editAssignAttribute(rowId);
          const rowData = response.data;
          setUpdate(rowData);
          if (response && response.status == 200) {

            if (rowData) {
              setExtraExtraFormData({
                project_code: rowData.project_code,
                process_type: rowData.process_type,
                process_type_id: rowData.process_type_id,
              })
            }
            if (rowData && rowData.data && rowData.data.length > 0) {
              const editAttribute = rowData.data.map((item) => ({
                attribute: item.attribute,
                assign_label: item.assign_label,
                assign_value: item.assign_value,
                is_advanced: (item.attribute === "Custom Attribute" || item.attribute === "Filter Attribute") ? "yes" : "no"
              }));
              setFormData((prevData) => [
                ...prevData,
                ...editAttribute,
              ]);

            }
          }
        } catch (error) {
          console.error('Error Edit Entity:', error);
        }
      })();
    }
  }, []);
  
  const finalSubmit = () => {
    const convertedData = convertToNewFormat();
    if (update) {
      convertedData.id = rowId;
      (async () => {
        const response = await updateAssignAttribute(convertedData);
        if (response && response.status == 200) {
          history.push("/assign-attribute-list");
        }
      })();
    } else {
      (async () => {
        const response = await createAssignAttribute(convertedData);
        if (response && response.status == 200) {
          history.push("/assign-attribute-list");
        }
      })();
    }
  }

  return (
    <>
      <div className="AddRule table-section container-fluid mb-5">
        <div className="row tablesec-bg py-3 px-3">
          <div className="col-sm-12 col-md-6">
            <div className="row justify-content-between my-2">
              <div className="col-md-12 col-lg-3">
                <label className="col-form-label">Project</label>
              </div>
              <div className="col-md-12 col-lg-9">
                <SelectBox
                  className="form-select"
                  name="project_code"
                  id="project_code"
                  OptionValue={projectCode}
                  value={extraFormData.project_code}
                  onChange={handleInputChange}
                  error={errors.project_code}
                  emptyOption="Select project code"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="row my-2">
              <div className="col-md-12 col-lg-3">
                <label className="col-form-label">Process Type</label>
              </div>
              <div className="col-md-12 col-lg-9">
                <SelectBox
                  className="form-select"
                  name="process_type"
                  id="process_type"
                  value={extraFormData.process_type_id}
                  OptionValue={valuesCorrespondingToProjectCode.processType}
                  onChange={handleInputChange}
                  error={errors.process_type}
                  emptyOption="Select process type"
                />
              </div>
            </div>
          </div>
          <div className="col mt-3">
            <div className="row">
              <div className="col d-flex align-items-center">
                <h4 className='mb-0'>Assign Attribute</h4>
              </div>
              <div className="col d-flex justify-content-end">
                <button className='add-section fw-bolder fs-6' onClick={handleAddSection}>+</button>
              </div>
            </div>
            {renderDynamicSections()}
            <div className="row mt-4">
              <div className="col d-flex justify-content-end">
                <button className='btn btn-danger fw-bolder fs-6' onClick={() => finalSubmit()}>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AssignAttribute;