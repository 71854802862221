import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useFollowupRule } from "../../../Hooks/useFollowupRule";

const ListDeletePopup = (props) => {
  const { deleteFollowupRule, listFollowupRules } = useFollowupRule();

  const listFollowupRule = async () => {
    const response = await listFollowupRules();
    const fetchedData = response.data.map((item) => ({
      _id: item._id,
      project_code: item.project_code,
      followup_url: item.followup_url,
    }));
    const keyword = props.filterText.toLowerCase();
    const filteredData = fetchedData.filter(
      (item) =>
        (item._id && item._id.toString().includes(keyword)) ||
        (item.project_code &&
          item.project_code.toLowerCase().includes(keyword)) ||
        (item.followup_url &&
          item.followup_url.toString().toLowerCase().includes(keyword))
    );
    props.setFilteredData(filteredData);
    props.setFilterText(keyword);
    props.setData(fetchedData);
  };
  const handleClose = () => props.setDelete(false);
  const handleDelete = async () => {
    handleClose();
    const id = props.id;
    const response = await deleteFollowupRule(id);
    props.setMultipleValue("");
    listFollowupRule();
    props.setToggleList(!props.toggleList);
  };
  
  return (
    <div>
      <Modal show={props.deletes} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ListDeletePopup;
