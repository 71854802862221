import React, { useEffect, useState } from 'react'
import { useProjectCodeInfo } from '../../Hooks/useProjectCodeInfo';
import SelectBox from '../UI/SelectBox';
import TextField from '../UI/TextField';
import "../../assets/css/addRule.scss";
import { Bodytype, MethodType } from '../../Constants/BodyTypeConstants';
import { useHttpClients } from '../../Hooks/useHttpClients';
import { useHistory } from 'react-router-dom';
import { useProcessType } from "../../Hooks/useProcessType";
import Button from '../UI/Button';

const HttpClients = () => {
  const { createHttpClients } = useHttpClients();
  const { listProjectCode } = useProjectCodeInfo();
  const { editHttpClient, updatetHttpClient } = useHttpClients();
  const { listProcess } = useProcessType();
  const history = useHistory();
  const [formData, setFormData] = useState(
    {
      header : [ {header_name: '',
                  header_value: ''}],
      response : [{response_key:'',
                  response_data:'',
                  is_dynamic:'no'}]
    }
  );
  const [extraFormData, setExtraExtraFormData] = useState({
    project_code: "",
    process_type: "",
    process_type_id: "",
    body_type: "",
    method_type: "",
    url_value: ""
  });
  const [projectCode, setProjectCode] = useState([]);
  const [errors, setErrors] = useState({});
  const [processType, setProcessType] = useState([]);
  const [valuesCorrespondingToProjectCode, setValuesCorrespondingToProjectCode] = useState({
    processType: [],
  });
  useEffect(() => {
    const filterValues = () => {
      const matchingProcessTypes = processType.filter((item) => item.project_code === extraFormData.project_code);
      const formattedValues = {
        processType: matchingProcessTypes.map((type) => ({
          label: type.label,
          value: type.value,
        })),
      };
      setValuesCorrespondingToProjectCode(formattedValues);
    };
    filterValues();
  }, [extraFormData.project_code, processType]);
  /* eslint-disable no-restricted-globals */
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const response = await editHttpClient(id);

          if (response.status === 200 && response.data) {
            const convertedData = convertApiResponseToFormData(response.data);
            setExtraExtraFormData({
              project_code: convertedData.project_code,
              process_type: convertedData.process_type,
              process_type_id: convertedData.process_type_id,
              body_type: convertedData.body_type,
              method_type: convertedData.method_type,
              url_value: convertedData.url_value,
            });
            setFormData(convertedData.formData);
          }
        } catch (error) {
          console.error('Error Edit Http:', error);
        }
      };

      fetchData();
    }
  }, []);
  useEffect(() => {
  }, [formData]);
  const convertApiResponseToFormData = (apiResponse) => {
    const { project_code, process_type, process_type_id } = apiResponse;
    const body_type = apiResponse.data.body;
    const method_type = apiResponse.data.method;
    const url_value = apiResponse.data.url;
    // const formData = apiResponse.data.headers.flatMap((option) => {
    //   // const { header_name, header_value } = option;
    //   // return [
    //   //   {
    //   //    [header_name]: header_value,
    //   //   },
    //   // ];
    //   const [key, value] = Object.entries(option)[0]; // Get the first key-value pair

    //   return {
    //     header_name: key,
    //     header_value: value
    //   };
    // });
    const formData  ={
      header : apiResponse.data.headers ,
      response : apiResponse.data.response 
    };
    return {
      project_code,
      process_type_id,
      process_type,
      body_type,
      method_type,
      url_value,
      formData,
    };
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response2 = await listProjectCode();
        const response3 = await listProcess();
        const formattedProjectCode = response2.data.map((item) => ({
          label: item.project_code,
          value: item.project_code,
        }));
        const formattedProcessType = response3.data.map((item) => ({
          label: item.name,
          value: item._id,
          project_code: item.project_code,
        }));
        setProjectCode(formattedProjectCode);
        setProcessType(formattedProcessType);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);
  const handleInputChange = (event, sectionIndex) => {
    const { name, value, options } = event.target;
    switch (name) {
      case "process_type":
        const selectedOption = options[options.selectedIndex];
        const label = selectedOption.label;

        setExtraExtraFormData((prevData) => {
          return {
            ...prevData,
            [`${name}_id`]: value,
            [`${name}`]: label,
          };
        });
        break;
      case "project_code":
      case "body_type":
      case "method_type":
      case "url_value":
      case "header_name":
        setExtraExtraFormData((prevData) => {
          return {
            ...prevData,
            [name]: value,
          };
        });
        break;
      default:
        setFormData((prevData) => {
          const newData = {...prevData};
          const originalFieldName = name.replace(`_${sectionIndex}`, '');
          const fieldName = name.split('_')[0];
          
          newData[fieldName][sectionIndex] = {
            ...newData[fieldName][sectionIndex],
            [originalFieldName]: value,
          };
          return newData;
        });
        break;
    }
  };

  const handleAddSection = (request) => {
    switch (request){
      case  "header":
        setFormData((prevData) => {
          const updatedPrevData = { ...prevData };
          updatedPrevData.header = [
            ...updatedPrevData.header,
            {
              header_name: '',
              header_value: '',
            },
          ];
          return updatedPrevData;
        });
 
        break;
        case  "response":
          setFormData((prevData) => {
            const updatedPrevData = { ...prevData };
            updatedPrevData.response = [
              ...updatedPrevData.response,
              {
                response_key: '',
                response_data: '',
                is_dynamic: "no"
              },
            ];
            return updatedPrevData;
          });
        break;
    }
  };
  const removeSection = (event, sectionIndex,operation) => {
    setFormData((prevData) => {

      const updatedFormData = {...prevData};
      updatedFormData[operation].splice(sectionIndex, 1);
      return updatedFormData;
    });
  };


  const handleCheckboxChange = (sectionIndex) => {
    setFormData((prevData) => {
      const newData = {...prevData};
      newData.response[sectionIndex] = {
        ...newData.response[sectionIndex],
        is_dynamic: newData.response[sectionIndex].is_dynamic === "no" ? "yes" : "no",
      };

      // Reset relevant fields if is_advanced is set to "no"
      // if (newData[sectionIndex].is_advanced === "no") {
      //   newData[sectionIndex] = {
      //     ...newData[sectionIndex],
      //     is_advanced: "no", // Reset operator or other relevant fields
      //   };
      // }

      return newData;
    });
  };
  const renderDynamicSections = () => {
    return formData.header.map((section, index) => (
      <div key={index} className="dynamic-section pt-3 mb-3">
        <div className="row row-gap-3">
          <div className="col-sm-6 col-md-5">
            <label className="form-label">Header name</label>
            <TextField
              className="form-control"
              name={`header_name_${index}`}
              id={`header_name_${index}`}
              value={section.header_name}
              onChange={(e) => handleInputChange(e, index)}
              error={errors[`header_name_${index}`]}
            />
          </div>
          <div className="col-sm-6 col-md-5 mb-5">
            <label className="form-label">Value</label>
            <TextField
              className="form-control"
              name={`header_value_${index}`}
              id={`header_value_${index}`}
              value={section.header_value}
              onChange={(e) => handleInputChange(e, index)}
              error={errors[`header_value_${index}`]}
            />
          </div>
          <div className="col-sm-2 col-md-2 mb-5">
            <label className="form-label"></label>
            {index > 0 && (
              <div className="col d-flex justify-content-end">
                <Button className='add-section fw-bolder fs-6' onClick={(e) => removeSection(e, index,'header')} label="-" />
              </div>
            )}
          </div>
        </div>
      </div>
    ));
  };
  const renderDynamicSectionsTwo = () => {
    return formData.response.map((section, index) => (
      <div key={index} className="dynamic-section pt-3 mb-3">
             <div className="col">
            <div className="row">
              <div className="col-auto">
                <label className="form-label">Is dynamic</label>
              </div>
              <div className="col-auto">
                <input
                  className="form-check-input p-2"
                  type="checkbox"
                  name={`advancedCheckbox_${index}`}
                  onChange={() => handleCheckboxChange(index)}
                  checked={section.is_dynamic === "yes" ? true : false}
                />
              </div>
            </div>
          </div>
        <div className="row row-gap-3">
          <div className="col-sm-6 col-md-5">
            <label className="form-label">Response Key</label>
            <TextField
              className="form-control"
              name={`response_key_${index}`}
              id={`response_key_${index}`}
              value={section.response_key}
              onChange={(e) => handleInputChange(e, index)}
              error={errors[`response_key_${index}`]}
            />
          </div>
          <div className="col-sm-6 col-md-5">
            <label className="form-label">Response data</label>
            <TextField
              className="form-control"
              name={`response_data_${index}`}
              id={`response_data_${index}`}
              value={section.response_data}
              onChange={(e) => handleInputChange(e, index)}
              error={errors[`response_data_${index}`]}
            />
          </div>
          <div className="col-sm-2 col-md-2 mb-3">
            <label className="form-label"></label>
            {index > 0 && (
              <div className="col d-flex justify-content-end">
                <Button className='add-section fw-bolder fs-6' onClick={(e) => removeSection(e, index,'response')} label="-" />
              </div>
            )}
          </div>
        </div>
      </div>
    ));
  };
  
  const convertToNewFormat = () => {
    const convertedData = {
      project_code: extraFormData.project_code,
      process_type_id: extraFormData.process_type_id,
      process_type: extraFormData.process_type,
      data: {
        body: extraFormData.body_type,
        method: extraFormData.method_type,
        url: extraFormData.url_value,
        headers: [],
        response:[]
      }
    };

    formData.header.forEach((section) => {
      const { header_name, header_value, ...rest } = section;
      convertedData.data.headers.push({
        "header_name" : header_name,
        "header_value" : header_value
      });
      
    });

    formData.response.forEach((section) => {
        const { response_key, response_data, is_dynamic, ...rest } = section;
          convertedData.data.response.push({
            "response_key" : response_key,
            "response_data": response_data,
            "is_dynamic": is_dynamic
          });
    });
    return convertedData;
  };

  const finalSubmit = () => {
    const convertedData = convertToNewFormat();
    let response = null;
    (async () => {
      if (id) {
        response = await updatetHttpClient(convertedData, id);
      } else {
        response = await createHttpClients(convertedData);
      }

      if (response && response.status == 200) {
        history.push('/list-http-clients');
      }
    })();
  }
  return (
    <>
      <div className="AddRule table-section container-fluid mb-5">
        <div className="row tablesec-bg py-3 px-3">
          <div className="col-sm-12 col-md-6">
            <div className="row justify-content-between my-2">
              <div className="col-md-12 col-lg-3">
                <label className="col-form-label">Project</label>
              </div>
              <div className="col-md-12 col-lg-9">
                <SelectBox
                  className="form-select"
                  name="project_code"
                  id="project_code"
                  OptionValue={projectCode}
                  value={extraFormData.project_code}
                  onChange={handleInputChange}
                  error={errors.project_code}
                  emptyOption="Select project code"
                />
              </div>
            </div>

          </div>
          <div className="col-sm-12 col-md-6">
            <div className="row my-2">
              <div className="col-md-12 col-lg-3">
                <label className="col-form-label">Process Type</label>
              </div>
              <div className="col-md-12 col-lg-9">
                <SelectBox
                  className="form-select"
                  name="process_type"
                  id="process_type"
                  OptionValue={valuesCorrespondingToProjectCode.processType}
                  value={extraFormData.process_type_id}
                  onChange={handleInputChange}
                  error={errors.process_type}
                  emptyOption="Select process type"
                />
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-4">
            <div className="row my-2">
              <div className="col-md-12 col-lg-3">
                <label className="col-form-label">URL</label>
              </div>
              <div className="col-md-12 col-lg-9">
                <TextField
                  className="form-control"
                  name="url_value"
                  id={`url_value`}
                  value={extraFormData.url_value}
                  onChange={(e) => handleInputChange(e)}
                  error={errors[`url_value`]}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <div className="row my-2">
              <div className="col-md-12 col-lg-3">
                <label className="col-form-label">Body Type</label>
              </div>
              <div className="col-md-12 col-lg-9">
                <SelectBox
                  className="form-select"
                  name="body_type"
                  id="body_type"
                  OptionValue={Bodytype}
                  value={extraFormData.body_type}
                  onChange={handleInputChange}
                  error={errors.body_type}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <div className="row justify-content-between my-2">
              <div className="col-md-12 col-lg-3">
                <label className="col-form-label">Method Type</label>
              </div>
              <div className="col-md-12 col-lg-9">
                <SelectBox
                  className="form-select"
                  name="method_type"
                  id="method_type"
                  OptionValue={MethodType}
                  value={extraFormData.method_type}
                  onChange={handleInputChange}
                  error={errors.method_type}
                />
              </div>
            </div>

          </div>
          <div className="col mt-3">
            <div className="row">
              <div className="col d-flex align-items-center">
                <h4 className='mb-0'>Header</h4>
              </div>
              <div className="col d-flex justify-content-end">
                <button className='add-section fw-bolder fs-6' onClick={()=>handleAddSection('header')}>+</button>
              </div>
            </div>
            {renderDynamicSections()}
            <div className="row">
              <div className="col d-flex align-items-center">
                <h4 className='mb-0'>Response</h4>
              </div>
              <div className="col d-flex justify-content-end">
                <button className='add-section fw-bolder fs-6' onClick={()=>handleAddSection('response')}>+</button>
              </div>
            </div>
            {renderDynamicSectionsTwo()}
            <div className="row mt-4">
              <div className="col d-flex justify-content-end">
                <button className='btn btn-danger fw-bolder fs-6' onClick={() => finalSubmit()}>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HttpClients;