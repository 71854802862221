import React, { useState } from "react";
import Button from "../../UI/Button";
import Modal from 'react-bootstrap/Modal';
import "../../../assets/css/popup.scss";
import SelectBox from "../../UI/SelectBox";
import AssignAttributeWidgets from "../../ModuleWidgets/AssignAttributeWidgets";
import Sendemailwidget from "../../ModuleWidgets/Sendemailwidget";
import Sendsmswidget from "../../ModuleWidgets/Sendsmswidget";
import AudienceSelection from "../../ModuleWidgets/AudienceSelection";
import HttpClientsWidgets from "../../ModuleWidgets/HttpClientsWidgets";

const EventModulePopUp = (props) => {
    const handleClose = () => {
        props.setEventPopUp(false);
    };
   
    const [moduleView, setModuleView] = useState({
        Sendemailwidget_view: false,
        Sendsmswidget_view: false,
        AssignAttributeWidgets_view: false,
        AudienceSelection_view: false,
        HttpClientsWidgets_view: false
    });
    const handleChange = (e) => {
        const { name, value, options } = e.target;
        switch (name) {
            case "page_module_type":
                const filteredModule = props.moduleType.find(item => item.slug === value);
                let filteredModuleWidgets = `${filteredModule.widgets}_view`;
                props.setGetEventModules((prevData) => {
                    return {
                        ...prevData,
                        module_name: filteredModule.slug,
                        module_id: filteredModule.id
                    };
                });
                break;
            default:
                props.setGetEventModules((prevData) => {
                    return {
                        ...prevData,
                        [name]: value
                    };
                });
                break;
        }
    };
    const handleModuleSubmit = (moduleRes) => {
        props.setGetEventModules((prevData) => {
            return {
                ...prevData,
                module_collection_id: moduleRes
            };
        });
    };
    return (
        <>
            <div className="toggle-popup">
                <Modal show={props.eventPopUp} onHide={handleClose} dialogClassName="toggle-popup" backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div className="col-lg-6">
                                Module
                            </div>
                            <div className="col-md-12 col-lg-9">
                                <SelectBox
                                    className="form-select"
                                    name="page_module_type"
                                    id="page_module_type"
                                    OptionValue={props.moduleType}
                                    value={props.getEventModules.module_name}
                                    onChange={(e) => handleChange(e)}
                                    // error={errors.page_module_type}
                                    emptyOption="Select module"
                                >
                                    </SelectBox>
                                
                            </div><br/>
                            <div className="col-lg-6">
                                Order Id
                            </div>
                            <div className="col-md-12 col-lg-9">
                            <input
                            type="text"
                            name="module_order_id"
                            className="form-select"
                            style={{ background: "none" }}
                            id="module_order_id"
                            placeholder="Enter Order Id"
                            value={props.getEventModules.module_order_id}
                            onChange={(e) => handleChange(e)}
                            />
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="col-lg-8">
                            <Button className="button_a button2" buttonName="page_module_event" label={props.getEventModules.action} onClick={(e) => { props.handleInputChange(e, props.getEventModules.pageIndex); props.setEventPopUp(false); }} />
                            {/* */}
                        </div>
                    </Modal.Footer>
                </Modal>

                {/* <AssignAttributeWidgets
                        //modules={modules}
                        //convertedData={convertedDatas}
                        finalSubmit={handleModuleSubmit}
                        setView={setModuleView}
                        view={moduleView.AssignAttributeWidgets_view}
                        project_code={props.extraFormData.project_code}
                        process_type={props.extraFormData.flow_name}
                        process_type_id={props.splitFlowId}
                        index=''
                        //setIndex={setIndex}
                        //removeSection={removeSection}
                        setLoader={props.setLoader}
                        loader={props.loader}
                        moduleCollectionId=''
                        setFormData={props.setFormData}
                        formDataIndex=''
                />
                <HttpClientsWidgets
                    //colectionid={colectionid}
                    process_type_id={props.splitFlowId}
                    moduleCollectionId=''
                    //setCollectionId={setCollectionId}
                    index=''
                    //setIndex={setIndex}
                    view={moduleView.HttpClientsWidgets_view}
                    setView={setModuleView}
                    project_code={props.extraFormData.project_code}
                    process_type={props.extraFormData.flow_name}
                    formData={''}
                    setFormData={props.setFormData}
                    setLoader={props.setLoader}
                    loader={props.loader}
                    formDataIndex=''
                    // removeSection={removeSection}
                    finalSubmit={handleModuleSubmit}
                /> */}
                {/* <Sendemailwidget/>
                <Sendsmswidget/>
                <AudienceSelection/> */}
            </div>
        </>
    )
}

export default EventModulePopUp;