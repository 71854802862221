import {useState} from "react";
import { Api } from "../../api/Api";

export const useLeadSummary = () =>{
    const [isLoading,setIsLoading] = useState(false);
    const manageLeadSummary  = async(requestData) =>{
        setIsLoading(true);
        const response =  await Api.post("api/v1/report/lead_summary/manage",{
            requestData
        })
        setIsLoading(false);
        return response;
    }
    const viewLeadSummary  = async(projectCode) =>{
        setIsLoading(true);
        const response =  await Api.post("api/v1/report/lead_summary",{
            project_code:projectCode
        })
        setIsLoading(false);
        return response;
    }
    return {manageLeadSummary, viewLeadSummary, isLoading}
}
