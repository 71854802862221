import React, { useEffect, useState } from "react";
import SelectBox from "../UI/SelectBox";
import TextField from "../UI/TextField";
import "../../assets/css/addRule.scss";
import { useHistory, useLocation } from "react-router-dom";
import { useProjectCodeInfo } from "../../Hooks/useProjectCodeInfo";
import Button from "../UI/Button";
import Loader from "../Utility/Loader";
import { useProjectConfiguration } from "../../Hooks/useProjectConfiguration";
import GenerateSlug from "../Utility/GenerateSlug";

const ProjectConfiguration = (props) => {
    const {
        createProjectConfiguration,
        editProjectConfiguration,
        updateProjectConfiguration,
        isLoading,
    } = useProjectConfiguration();

    const [extraFormData, setExtraFormData] = useState({
        project_code: ""
    });
    const [errors, setErrors] = useState({});
    const [loader, setLoader] = useState(false);
    const [rowId, setRowId] = useState("");
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    useEffect(() => {
        setRowId(queryParams.get("id"));
    }, [queryParams.get("id")]);

    const history = useHistory();
    const [formData, setFormData] = useState([
        {
            config_name: "",
            config_slug: "",
            config_value: "",
        },
    ]);
    const { listProjectCode } = useProjectCodeInfo();
    const [projectCodeList, setProjectCodeList] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const projectCodeResponse = await listProjectCode();
                const resProjectCodeList = projectCodeResponse.data.map((item) => ({
                    label: item.project_code,
                    value: item.project_code,
                }));
                setProjectCodeList(resProjectCodeList);
            } catch (error) {
                console.error("Error fetching initial data:", error);
            }
        };

        fetchData();
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (rowId) {
                    const response = await editProjectConfiguration(rowId);
                    const rowData = response.data;
                    if (rowData) {
                        if (response.status === 200) {
                            setExtraFormData((prevValues) => ({
                                ...prevValues,
                                project_code: rowData.project_code,
                            }));

                            if (rowData && rowData.config && rowData.config.length > 0) {
                                const config = rowData.config.map((item) => ({

                                }));
                                const formDataRes = rowData.config.map((item) => {
                                    return {
                                        config_name: item.name,
                                        config_slug: item.slug,
                                        config_value: item.value
                                    }
                                });
                                setFormData(formDataRes);
                            }
                        }
                    }

                }
            } catch (error) {
                console.error("Error fetching data based on rowId:", error);
            }
        };

        fetchData();
    }, [rowId]);

    const handleInputChange = (event, sectionIndex) => {
        const { name, value } = event.target;
        switch (name) {
            case "project_code":
                setExtraFormData((prevData) => {
                    return {
                        ...prevData,
                        [name]: value,
                    };
                });
                break;

            default:
                console.log(name);
                //Generate slug from configuration name
                const response = (name == 'config_name_'+sectionIndex) ? GenerateSlug(value) : '';
                console.log(response);
                setFormData((prevData) => {
                    const newData = [...prevData];
                    const originalFieldName = name.replace(`_${sectionIndex}`, ""); // Remove the index from the field name
                    console.log(originalFieldName);

                    newData[sectionIndex] = {
                        ...newData[sectionIndex],
                        [originalFieldName]: value,
                        ...(response !== '' ? { config_slug: response.slugValue } : {})
                    };
                    return newData;
                });

                break;
        }
    };

    const handleAddSection = () => {
        setFormData((prevData) => [
            ...prevData,
            {
                config_name: "",
                config_slug: "",
                config_value: ""
            },
        ]);
    };

    const removeSection = async (event, configIndex) => {
        const data = {
            configIndex: configIndex,
            id: rowId,
        };

        const updatedFormData = formData.filter(
            (_, index) => index !== configIndex
        );
        setFormData(updatedFormData);
    };
    console.log(formData);
    const renderDynamicSections = () => {
        return formData.map((item, index) => (
            <div key={index} className="dynamic-section pt-3">
                <div className="col">
                    {index >= 0 && (
                        <div className="col d-flex justify-content-end">
                            <Button
                                className="rule-dy-btn"
                                onClick={(e) => removeSection(e, index)}
                                label="-"
                            />
                        </div>
                    )}
                </div>
                <div className="row row-gap-3">
                    <div className="col-sm-12 col-md-4">
                        <label className="form-label">Configuration Name</label>
                        <TextField
                            className="form-control"
                            name={`config_name_${index}`}
                            id={`config_name_${index}`}
                            value={item.config_name}
                            placeholder="Enter configuration name"
                            onChange={(e) => handleInputChange(e, index)}
                            error={errors[`config_name_${index}`]}
                        />
                    </div>
                    <div className="col-sm-12 col-md-4">
                        <label className="form-label">Configuration Slug</label>
                        <TextField
                            className="form-control"
                            name={`config_slug_${index}`}
                            id={`config_slug_${index}`}
                            value={item.config_slug}
                            placeholder="Enter configuration slug"
                            onChange={(e) => handleInputChange(e, index)}
                            error={errors[`config_slug_${index}`]}
                        />
                    </div>
                    <div className="col-sm-12 col-md-4">
                        <label className="form-label">Configuration Value</label>
                        <TextField
                            className="form-control"
                            name={`config_value_${index}`}
                            id={`config_value_${index}`}
                            value={item.config_value}
                            placeholder="Enter configuration value"
                            onChange={(e) => handleInputChange(e, index)}
                            error={errors[`config_value_${index}`]}
                        />
                    </div>
                </div>
            </div>
        ));
    };
    const convertToNewFormat = () => {
        const convertedData = {
            id: rowId,
            project_code: extraFormData.project_code,
            config: [],
        };
        formData.map((item) => {
            convertedData.config.push({
                name: item.config_name,
                slug: item.config_slug,
                value: item.config_value
            });
        });
        return convertedData;
    };

    const finalSubmit = async () => {
        const convertedData = convertToNewFormat();
        const response = rowId
            ? await updateProjectConfiguration(convertedData)
            : await createProjectConfiguration(convertedData);
        if (response && response.status == 200) {
            history.push('/list-project-configuration');
        }
    }
    return (
        <>
            <Loader active={isLoading} />
            <div className="AddRule table-section container-fluid mb-5">
                <div className="row tablesec-bg py-3 px-3">
                    <div className="col-sm-12 col-md-6">
                        <div className="row justify-content-between my-2">
                            <div className="col-md-12 col-lg-3">
                                <label className="col-form-label">Project Code</label>
                            </div>
                            <div className="col-md-12 col-lg-9">
                                <SelectBox
                                    className="form-select"
                                    name="project_code"
                                    id="project_code"
                                    OptionValue={projectCodeList}
                                    value={extraFormData.project_code}
                                    onChange={handleInputChange}
                                    error={errors.project_code}
                                    emptyOption="Select project code"
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="col mt-3">
                            <div className="row">
                                <div className="col d-flex align-items-center">
                                    <h4 className="mb-0">Configurations</h4>
                                </div>
                                <div className="col d-flex justify-content-end">
                                    <button
                                        className="add-section fw-bolder fs-6"
                                        onClick={handleAddSection}
                                    >
                                        +
                                    </button>
                                </div>
                            </div>
                            {renderDynamicSections()}
                            <div className="row mt-4">
                                <div className="col d-flex justify-content-end">
                                    <button
                                        className="btn btn-danger fw-bolder fs-6"
                                        onClick={() => finalSubmit()}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProjectConfiguration;

