import React from "react";
import "../../assets/css/header.scss"
import LogoImage from "../../assets/img/logo.png"
import UserImage from "../../assets/img/user.png"
import LogoutImage from "../../assets/img/logout.png"
import { Link } from 'react-router-dom';

const Header = () => {
  const name = localStorage.getItem('name');
  const handleLogout = () => {
    localStorage.clear();
  }
  return (
    <header className="headernew">
      <div className="container">
        <div className="row top_nav_bar">
          <div className="col-lg-3 py-3">
            <div className="logo">
              <a href="/dashboard">
              </a>
            </div>
          </div>
          <div className="col-lg-5 padding-adjust">
            <nav className="navbar navbar-expand-lg">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link to="#" className="nav-link  active">Home</Link>
                </li>
                {/* <li className="nav-item dropdown">
                  <Link to="#"  className="nav-link  dropdown-toggle" data-bs-toggle="dropdown">Reports</Link>
                  <ul className="dropdown-menu mt-lg-3">
                    <li>
                    <Link to="/report/lead-summary" className="dropdown-item">Lead Summary Report</Link>
                    </li>
                  </ul>
                </li> */}
                <li className="nav-item dropdown">
                  <Link to="#"  className="nav-link  dropdown-toggle" data-bs-toggle="dropdown">Operations</Link>
                  <ul className="dropdown-menu mt-lg-3">
                    <li>
                    <Link to="/projects" className="dropdown-item">Projects Management</Link>
                    </li>
                    <li>
                      <Link to="/attributes" className="dropdown-item">Attributes Management</Link>
                    </li>
                    <li>
                      <Link to="/list-process-types" className="dropdown-item">Process types Management</Link>
                    </li>
                    <li>
                      <Link to="/schedules" className="dropdown-item">Schedule Management</Link>
                    </li>
                    <li>
                      <Link to="/module" className="dropdown-item">Module Management</Link>
                    </li>
                    <li>
                      <Link to="/list-split-flow" className="dropdown-item">SplitFlow Management</Link>
                    </li>
                    <li>
                      <Link to="/list-project-configuration" className="dropdown-item">Project Configuration Management</Link>
                    </li>
                    <li>
                      <Link to="/templates" className="dropdown-item">Template Management</Link>
                    </li>
                    <li>
                      <Link to="/gtm-data-layer" className="dropdown-item">Data Layer Management </Link>
                    </li>
                    <li>
                      <Link to="/followup-rules" className="dropdown-item">Followup Rules Management</Link>
                    </li>
                  </ul>
                </li>


                <li className="nav-item dropdown">
                  <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Settings</Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link to="/user-management" className="dropdown-item">User Management</Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item">Role Management</Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item">Access Management</Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item">Department Access Management</Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link to="#"  className="nav-link  dropdown-toggle" data-bs-toggle="dropdown">Reports</Link>
                  <ul className="dropdown-menu mt-lg-3">
                    <li>
                    <Link to="/report/lead-summary" className="dropdown-item">Lead Summary Report</Link>
                    </li>
                    <li>
                    <Link to="/report/global_leads" className="dropdown-item">Global Lead Report</Link>
                    </li>
                  </ul>
                </li>

                {/* <li className="nav-item dropdown">
                  <Link to="#" className="nav-link  dropdown-toggle" data-bs-toggle="dropdown">List Modals</Link>
                  <ul className="dropdown-menu mt-lg-3">
                    <li>
                      <Link to="/assign-attribute-list" className="dropdown-item">Assign Attribute list Management</Link>
                    </li>
                    <li>
                      <Link to="/list-rule" className="dropdown-item">List rule Management</Link>
                    </li>
                    <li>
                      <Link to="/list-http-clients" className="dropdown-item">List Http clients Management</Link>
                    </li>
                    <li>
                      <Link to="/sms-data-list" className="dropdown-item">Send SMS List</Link>
                    </li>
                    <li>
                      <Link to="/email-data-list" className="dropdown-item">Send Email List</Link>
                    </li>
                  </ul>
                </li> */}
              </ul>
            </nav>
          </div>
          <div className="col-lg-2 py-3 click_links border border-top-0 border-bottom-0">
            <p className="user_title mb-0 text-center user_content">
              <img src={UserImage} alt="" /> <b>Hi,{name}</b>
            </p>
          </div>
          <div className="col-lg-2 py-3 text-center click_links padding-adjust border-end" onClick={handleLogout}>
            <Link to="/login" style={{ textDecoration: "none" }} data-toggle="tooltip" 
            data-placement="left" title="" aria-hidden="true" data-bs-original-title="Logout" aria-label="Logout"> <img src={LogoutImage} alt="" /></Link>
          </div>
        </div>
      </div>
    </header>

  )
}

export default Header;