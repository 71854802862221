import { useState } from 'react';
import axios from 'axios';
import {
  AppApiUrl
} from "../Constants/EnvConstants";

const useApi = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const makeRequest = async (url, method, data = null, headers = {}) => {
    url = AppApiUrl + url;
    setIsLoading(true);

    try {
      const response = await axios({
        method,
        url,
        data,
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setIsLoading(false);
      return response.data;
    } catch (error) {
      setIsLoading(false);
      setError(error.response ? error.response.data.message : 'Something went wrong');
      throw error;
    }
  };

  const clearError = () => {
    setError(null);
  };

  return { isLoading, error, makeRequest, clearError };
};

export default useApi;
