import React, { useEffect, useState } from "react";
import "../../../src/assets/css/userManagement.scss";
import TableHead from "../Utility/TableHead";
import TogglePopup from "../Includes/UserMangement/TogglePopup";
import { useUserManagement } from "../../Hooks/useUserManagement";
import DataTable from "react-data-table-component";
import { ListUserColumns } from "../../Constants/TableColumns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import DeletePopup from "../Includes/UserMangement/DeletePopup";

const UserManagement = () => {
    const [data, setData] = useState([]);
    const [toggleListing, setToggleListing] = useState(true);
    const [filteredData, setFilteredData] = useState([]);
    const [deletes, setDelete] = useState(false);
    const [showID, setShowID] = useState('');
    const [showName, setShowName] = useState('');
    const [showEmail, setShowEmail] = useState('');
    const [showEdit, setShowEdit] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    console.log(showID);
    const { listUser, isLoading } = useUserManagement();
    const ProjectColumnsWithActions = [
        ...ListUserColumns,
        {
            name: 'Actions',
            cell: (row) => (
                <div>
                    {/* <button onClick={() => userEdit(row.id,row.name,row.email)} style={{ margin: "3px" }}>
                        <FontAwesomeIcon icon={faEdit} />
                    </button> */}
                    <button onClick={() => userDelete(row.id)} style={{ margin: "3px" }}>
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                </div>
            ),
        },
    ];
    

    useEffect(() => {
        (async () => {
            const response = await listUser();
            const userData = response.data.map(item => ({
                id: item.id,
                name: item.name,
                email: item.email
            }));
            console.log(userData);
            setData(userData);
            setFilteredData(userData);
        })();
    }, [toggleListing]);

    const userDelete = (user_id) => {
        setDelete(true);
        setShowID(user_id);
    }

    const userEdit = (user_id,name,email) => {
        setShowID(user_id);
        setShowName(name);
        setShowEmail(email);
        setShowEdit(true);

    }

    return (
        <>
            <div className="usermanagement">
                <div className="col-lg-12 table-section ">
                    <div className="row m-0">
                        <div className="col-lg-12 col-md-12 p-1">
                            <div className="tablesec-bg">
                                <div className="col-lg-12 py-3 px-3">
                                    <div className="row">
                                        <div className="col-lg-4" id="search_bar1">
                                            <TableHead title="User Management" />
                                        </div>
                                        <div className="col-lg-5" />
                                        <div className="col-lg-1 text-right">
                                            <a href="#">
                                                <i
                                                    className="fa-solid fa-rotate px-2 mt-2"
                                                    aria-hidden="true"
                                                    data-toggle="tooltip"
                                                    title="Refresh Page"
                                                />
                                            </a>
                                        </div>
                                        <div className=" text-right">
                                            <TogglePopup
                                                setData={setData}
                                                showEdit={showEdit}
                                                setShowEdit={setShowEdit}
                                                userID={showID}
                                                showName={showName}
                                                setShowName={setShowName}
                                                setShowEmail={setShowEmail}
                                                showEmail={showEmail}
                                                setToggleListing={setToggleListing}
                                                toggleListing={toggleListing}
                                            />
                                            <div className="table_hold  keyword-search">
                                                <div>
                                                    <DataTable
                                                        columns={ProjectColumnsWithActions}
                                                        data={filteredData}
                                                        pagination
                                                        paginationPerPage={10}
                                                    />
                                                </div>
                                            </div>
                                            <DeletePopup
                                                setData={setData}
                                                // setFilteredData={setFilteredData}
                                                deletes={deletes}
                                                setDelete={setDelete}
                                                userID={showID}
                                                setToggleListing={setToggleListing}
                                                toggleListing={toggleListing}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default UserManagement;