import { Api } from "../api/Api";
import { useState } from 'react';

export const useSplitFlow = () => {
    const [isLoading, setIsLoading] = useState(false);

    const createSplitFlow = async (data) => {
        setIsLoading(true);
        const response = await Api.post("api/v1/split-flow", {
            data
        });
        setIsLoading(false);
        return response;
    };

    const listSplitFlows = async (data) => {
        setIsLoading(true);
        const response = await Api.get("/api/v1/split-flow", {
            data
        });
        setIsLoading(false);
        return response;

    };

    const deleteSplitFlow = async (rowId) => {
        setIsLoading(true);
        try {
            const response = await Api.delete(`api/v1/split-flow/${rowId}`);

            setIsLoading(false);
            return response;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };

    const editSplitFlow = async (rowId) => {
        setIsLoading(true);
        try {
            const response = await Api.get(`api/v1/split-flow/${rowId}/edit`);
            setIsLoading(false);
            return response;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };

    const updateSplitFlow = async (data, id) => {
        setIsLoading(true);
        try {
            const response = await Api.patch(`api/v1/split-flow/${id}`, {
                data
            });
            setIsLoading(false);
            return response;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };

    const deleteSplitFlowPage = async (data) => {
        setIsLoading(true);
        const response = await Api.post("api/v1/split-flow-page-destroy", {
            data
        });
        setIsLoading(false);
        return response;
    };

    const updateSplitFlowForm = async (data) => {
        setIsLoading(true);
        const response = await Api.post("api/v1/split-flow-update", {
            data
        });
        setIsLoading(false);
        return response;
    };

    const getPageData = async (data) => {
        setIsLoading(true);
        const response = await Api.post("/api/v1/get-pages-data", {
            data
        });
        setIsLoading(false);
        return response;

    };

    return { deleteSplitFlow, listSplitFlows, createSplitFlow, editSplitFlow, updateSplitFlow, deleteSplitFlowPage, updateSplitFlowForm,getPageData, isLoading };
};
