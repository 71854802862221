import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useSplitFlow } from '../../../Hooks/useSplitFlow';
import Loader from '../../Utility/Loader';

const DeletePopup = (props) => {
  const { deleteSplitFlow, isLoading } = useSplitFlow();
  const handleClose = () =>props.setDelete(false);
  const handleDelete =async () => {
    handleClose();
    const response = await deleteSplitFlow(props.deleteId);    
    props.setToggleListing(!props.toggleListing);
  };
  return (
    <div>
      <Loader active={isLoading}/>
      <Modal show={props.deletes} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default DeletePopup;
