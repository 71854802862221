
import React,{ lazy, Suspense } from 'react';
const Sendemailwidget = lazy(() => import('../ModuleWidgets/Sendemailwidget'));
const HttpClientsWidgets = lazy(() => import('../ModuleWidgets/HttpClientsWidgets'));
const AssignAttributeWidgets = lazy(()=> import('../ModuleWidgets/AssignAttributeWidgets'));
const Sendsmswidget = lazy(() => import('../ModuleWidgets/Sendsmswidget'));
const  AudienceSelection = lazy(() => import('../ModuleWidgets/AudienceSelection'));


const ModuleWidgets = (props) =>{
  console.log("module details");
  console.log(props);
  const ModuleWidgetNames = {
    'Sendemailwidget' : Sendemailwidget,
    'HttpClientsWidgets' : HttpClientsWidgets,
    'AssignAttributeWidgets' : AssignAttributeWidgets,
    'Sendsmswidget' : Sendsmswidget,
    'AudienceSelection' : AudienceSelection
  };
  const SelectedModule = ModuleWidgetNames[props.moduleWidgetName];
 
return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        {SelectedModule && <SelectedModule {...props} />}
      </Suspense>
    </>
    )}

export default ModuleWidgets;