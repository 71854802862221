import { Api } from "../api/Api";
import { useState } from 'react';

export const useSendSms = () => {
    const [isLoading, setIsLoading] = useState(false);

    const listSendSms = async (data) => {
        setIsLoading(true);
        const response = await Api.get("api/v1/sms", {
            data
        });
        setIsLoading(false);
        return response;

    };
    const storeSms = async (data) => {
        setIsLoading(true);
        const response = await Api.post("api/v1/sms", {
            data
        });
        setIsLoading(false);
        return response;
    };
    const updateSms = async (data,id) => {
        setIsLoading(true);
        const response = await Api.patch(`api/v1/sms/${id}`, {
            data
        });
        setIsLoading(false);
        return response;
    };
    const editSms = async (id) => {
        setIsLoading(true);
        const response = await Api.get(`api/v1/sms/${id}/edit`);
        setIsLoading(false);
        return response;
    };
    const deleteSms = async (id) => {
        setIsLoading(true);
        const response = await Api.delete(`api/v1/sms/${id}`);
        setIsLoading(false);
        return response;
    };
    return { listSendSms,storeSms,updateSms,editSms,deleteSms ,isLoading };
};
