import React, { useEffect, useState } from 'react'
import { useProjectCodeInfo } from '../../Hooks/useProjectCodeInfo';
import { useSplitFlow } from '../../Hooks/useSplitFlow';
import SelectBox from '../UI/SelectBox';
import TextField from '../UI/TextField';
import { useHistory } from 'react-router-dom';
import EventModulePopUp from '../Includes/SplitFlow/EventModulePopUp';
import { useModuleInfo } from '../../Hooks/useModuleInfo';
import Button from '../UI/Button';
import Loader from '../Utility/Loader';
import SelectTemplatePopup from '../Includes/SplitFlow/SelectTemplatePopup';
import GenerateSlug from "../Utility/GenerateSlug";

const SplitFLow = () => {
  const history = useHistory();
  const { listProjectCode } = useProjectCodeInfo();
  const { listModuleCode } = useModuleInfo();
  const { createSplitFlow, editSplitFlow, updateSplitFlow, deleteSplitFlowPage, updateSplitFlowForm,getPageData, isLoading } = useSplitFlow();
  const [formData, setFormData] = useState([
    {
      page_name: '',
      page_slug: '',
      order_number: '',
      before_event: [],
      after_event: [],
      page_id: ''
    }
  ]);
  const [eventPopUp, setEventPopUp] = useState(false);
  // for template
  const [templatePopUp, setTemplatePopUp] = useState(false);
  const [dataForTemplate, setDataForTemplate] = useState({ pageIndex: '' });
  const [templates, setTemplates] = useState('');
  const [pageData, setPageData] = useState('');

  // end
  const [projectCode, setProjectCode] = useState([]);
  const [extraFormData, setExtraExtraFormData] = useState({
    project_code: "",
    flow_id: "",
    flow_name: "",
    flow_slug: "",
    flow_landing_url: ""
  });
  const [errors, setErrors] = useState({});
  const [moduleType, setModuleType] = useState({});
  const [getEventModules, setGetEventModules] = useState({ event: '', pageIndex: '', module_name: '', module_id: '', module_collection_id: '', pageId: '' });
  /* eslint-disable no-restricted-globals */
  const searchParams = new URLSearchParams(location.search);
  /* eslint-enable no-restricted-globals */
  const id = (searchParams.get('id')) ? searchParams.get('id') : '';
  const [splitFlowId, setSplitFlowId] = useState(id);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    (id) ?? setSplitFlowId(id);
  }, [id]);

  const fetchData = async () => {
    try {
      const response = await editSplitFlow(id);
      if (response.status === 200 && response.data) {
        const convertedData = convertApiResponseToFormData(response.data);
        setExtraExtraFormData({
          project_code: convertedData.project_code,
          flow_id: convertedData.flow_id,
          flow_name: convertedData.flow_name,
          flow_slug: convertedData.flow_slug,
          flow_landing_url: convertedData.flow_landing_url
        });

        setFormData(convertedData.formData);
      }
    } catch (error) {
      console.error('Error Edit SplitFlow:', error);
    }
  };

  const fetchDataPage = async () => {
    try {
      const data = {
        project_code: extraFormData.project_code
      }
      const pageDataRes = await getPageData(data);
      setPageData(pageDataRes.data);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    if (extraFormData.project_code) {
      fetchDataPage();
    }
  }, [extraFormData.project_code]);

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, []);

  const convertApiResponseToFormData = (apiResponse) => {
    const { pages, project_code, flow_name, flow_slug, flow_id, flow_landing_url } = apiResponse;

    const formData = pages.flatMap((option) => {
      return [{
        page_name: option.page_name || '',
        page_slug: option.page_slug || '',
        order_number: option.order_number || '',
        page_id: option.page_id,
        after_event: (option.after_event || []).sort((a, b) => a.module_order_id - b.module_order_id),
        before_event: (option.before_event || []).sort((a, b) => a.module_order_id - b.module_order_id)
      }]
    });

    return {
      project_code,
      flow_name,
      flow_slug,
      flow_id,
      flow_landing_url,
      formData,
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const projectCodeRes = await listProjectCode();
        const formattedProjectCode = projectCodeRes.data.map((item) => ({
          label: item.project_code,
          value: item.project_code,
        }));
        const moduleListResponse = await listModuleCode('split_flows');
        const moduleListRes = moduleListResponse.data.map((item) => ({
          label: item.module_name,
          value: item.slug,
          slug: item.slug,
          id: item._id,
          widgets: item.widgets
        }));
        setModuleType(moduleListRes);
        setProjectCode(formattedProjectCode);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);
  const handleInputChange = (event, sectionIndex) => {
    const { name, value, options } = event.target;
    const response = (name == 'flow_name') ? GenerateSlug(value) : '';
    switch (name) {
      case "project_code":
      case "flow_id":
      case "flow_name":
      case "flow_slug":
      case "flow_landing_url":
        console.log("flow name");
        console.log(name);
        console.log(value);
        setExtraExtraFormData((prevData) => {
          return {
            ...prevData,
            [name]: value,
            ...(response !== '' ? { flow_slug: response.slugValue } : {}),
          };
        });
        break;
      case "page_module_event":
        setFormData((prevData) => {
          const newData = [...prevData];
          const originalFieldName = getEventModules.event;
          const action = getEventModules.action;

          switch (action) {
            case "Update":
              newData[getEventModules.pageIndex][originalFieldName][getEventModules.moduleIndex] = {
                module: getEventModules.module_name,
                module_id: getEventModules.module_id,
                module_collection_id: getEventModules.module_collection_id,
                module_order_id: getEventModules.module_order_id
              }
              break;
            default:
              newData[getEventModules.pageIndex][originalFieldName].push({ module: getEventModules.module_name, module_id: getEventModules.module_id, module_collection_id: getEventModules.module_collection_id, module_order_id: getEventModules.module_order_id });
              break;
          }
          newData[getEventModules.pageIndex][originalFieldName].sort((a, b) => a.module_order_id - b.module_order_id);
          return newData;
        });
        pageSubmit(getEventModules);
        break;
      default:
        setFormData((prevData) => {
          const newData = [...prevData];
          const originalFieldName = name.replace(`_${sectionIndex}`, '');
          newData[sectionIndex] = {
            ...newData[sectionIndex],
            [originalFieldName]: value,
            // ...(response !== '' ? { page_slug: response.slugValue } : {})
          };
          return newData;
        });
        break;
    };
  };

  const handleAddSection = () => {
    setFormData((prevData) => [
      ...prevData,
      {
        page_name: '',
        page_slug: '',
        order_number: '',
        before_event: [],
        after_event: [],
        page_id: ''
      }
    ]);
  };

  const handleDeleteSection = (index) => {
    (async () => {
      let deletePageId = formData[index].page_id;
      var deleteData = {
        split_flow_id: splitFlowId,
        page_id: formData[index].page_id,
        module_collection_id: "",
        module_id: "",
        action_event: ""
      };

      if (deletePageId) {
        let response = await deleteSplitFlowPage(deleteData);
      }
    })();
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData.splice(index, 1);
      return updatedFormData;
    });
  };

  const pageSubmit = (eventDetails) => {
    (async () => {
      let response = await updateSplitFlow({ formData, eventDetails }, splitFlowId);
      setFormData((prevData) => {
        const newData = [...prevData];

        newData[eventDetails.pageIndex] = {
          ...newData[eventDetails.pageIndex],
          page_id: response.data.split_page_id,
        };
        return newData;
      });
    })();
  }

  const getTemplateName = (pageId) => {
    var templateDetails = '';
    if (pageData) {
      var pageDetails = pageData.find(item => item._id === pageId);
      if (templates && (pageDetails && pageDetails.template_id)) {
        templateDetails = templates.find(item => item._id == pageDetails.template_id)
      }
    }

  return templateDetails?.template_details?.name;
  }
  const renderDynamicSections = () => {
    return formData.map((section, index) => (
      <div key={index} className="dynamic-section tablesec-bg py-3 px-3 pt-3">
        <div className="row mb-1">
          <div className="col d-flex justify-content-end">
            <button className='add-section fw-bolder fs-6 mr-1' onClick={() => handleDeleteSection(index)}>- </button>
          </div>
        </div>
        <div className="row row-gap-3">
          <div className={`col-sm-6 col-md-4`}>
            <label className="form-label">Page name</label>
            <TextField
              className="form-control"
              name={`page_name_${index}`}
              id={`page_name_${index}`}
              value={section.page_name}
              onChange={(e) => handleInputChange(e, index)}
              error={errors[`page_name_${index}`]}
            />
          </div>
          <div className={`col-sm-6 col-md-4`}>
            <label className="form-label">Page slug</label>
            <TextField
              className="form-control"
              name={`page_slug_${index}`}
              id={`page_slug_${index}`}
              value={section.page_slug}
              onChange={(e) => handleInputChange(e, index)}
              error={errors[`page_slug_${index}`]}
            />
          </div>
          <div className={`col-sm-6 col-md-4`}>
            <label className="form-label">Order number</label>
            <TextField
              className="form-control"
              name={`order_number_${index}`}
              id={`order_number_${index}`}
              value={section.order_number}
              onChange={(e) => handleInputChange(e, index)}
              error={errors[`order_number_${index}`]}
            />
          </div>
          <div>Selected template : {getTemplateName(section.page_id)}</div>
          <div className="col">
            <div className="row">
              <button className='rule-dy-btn ' onClick={() => handlebeforeAfteEvents('before_event', index, '', 'Add')}>Before events </button>
              <div style={{ 'display': 'flex' }}>
                {formData[index].before_event.map((module, moduleIndex) => (
                  <React.Fragment key={moduleIndex}>
                    {/* {module.module_id} */}
                    <Button className='btn-flw-evt ms-4' label={module.module} onClick={() => handlebeforeAfteEvents('before_event', index, moduleIndex, 'Update')} />
                    <Button className='btn-flw-evt-cls' label='x' onClick={(e) => removeEventModule(e, 'before_event', index, moduleIndex)} />

                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className='row'>
              <button className={`rule-dy-btn mt-3`} onClick={() => handlebeforeAfteEvents('after_event', index, '', 'Add')}>After events </button>
              <div style={{ 'display': 'flex' }}>
                {formData[index].after_event.map((module, moduleIndex) => (
                  <React.Fragment key={moduleIndex}>
                    <Button className='btn-flw-evt ms-4' label={module.module} onClick={() => handlebeforeAfteEvents('after_event', index, moduleIndex, 'Update')} />
                    <Button className='btn-flw-evt-cls' label='x' onClick={(e) => removeEventModule(e, 'after_event', index, moduleIndex, 'Update')} />
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
          <div className="col">
            <div className="row justify-content-end">
              <button className='rule-dy-btn ' onClick={() => toggleTemplateModal(index)}>Submit & Select template</button>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  const toggleTemplateModal = async (index) => {
    const convertedData = convertToNewFormat();
    if (splitFlowId) {
      convertedData.split_flow_collection_id = splitFlowId;
      const response = await updateSplitFlowForm(convertedData);
      if (response && response.status == 200) {
        await fetchData()
        setTemplatePopUp(true)
        setDataForTemplate({ pageIndex: index })
      }
    } else {
      const response = await createSplitFlow(convertedData);
      if (response && response.status == 200) {
        setSplitFlowId(response.data.split_flow_id);
      }
    }

    // fetchData()
    // setTemplatePopUp(true)
    // setDataForTemplate({ pageIndex: index })
  }

  const convertToNewFormat = () => {
    const convertedData = {
      project_code: extraFormData.project_code,
      flow_id: extraFormData.flow_id,
      flow_name: extraFormData.flow_name,
      flow_slug: extraFormData.flow_slug,
      flow_landing_url: extraFormData.flow_landing_url,
      pages: [],
    };

    formData.map((section) => {
      var { page_name, page_slug, order_number, after_event, before_event, page_id } = section;
      convertedData.pages.push({
        page_name,
        page_slug,
        order_number,
        after_event,
        before_event,
        page_id
      });
    });

    return convertedData;
  };

  const finalSubmit = () => {
    const convertedData = convertToNewFormat();
    let response = null;
    (async () => {
      if (splitFlowId) {
        convertedData.split_flow_collection_id = splitFlowId;
        response = await updateSplitFlowForm(convertedData);
        if (response && response.status == 200) {
          history.push('/list-split-flow');
        }
      } else {
        response = await createSplitFlow(convertedData);
        if (response && response.status == 200) {
          setSplitFlowId(response.data.split_flow_id);
        }
      }

    })();
  }

  const handlebeforeAfteEvents = (event, index, moduleIndex = '', action) => {
    if (!splitFlowId) { return false };
    setGetEventModules({
      event: event,
      pageIndex: index,
      module_name: (formData?.[index]?.[event]?.[moduleIndex]?.module) ? formData?.[index]?.[event]?.[moduleIndex]?.module : '',
      module_id: (formData?.[index]?.[event]?.[moduleIndex]?.module_id) ? formData?.[index]?.[event]?.[moduleIndex]?.module_id : '',
      module_collection_id: (formData?.[index]?.[event]?.[moduleIndex]?.module_collection_id) ? formData?.[index]?.[event]?.[moduleIndex]?.module_collection_id : '',
      module_order_id: (formData?.[index]?.[event]?.[moduleIndex]?.module_order_id) ? formData?.[index]?.[event]?.[moduleIndex]?.module_order_id : '',
      pageId: formData[index].page_id,
      action: action,
      moduleIndex: moduleIndex
    });
    setEventPopUp(true);
  };
  const removeEventModule = (e, triggerEvent, index, moduleIndex) => {
    (async () => {
      var deleteData = {
        split_flow_id: splitFlowId,
        page_id: formData[index].page_id,
        module_collection_id: formData[index]?.[triggerEvent]?.[moduleIndex]?.module_collection_id,
        module_id: formData[index]?.[triggerEvent]?.[moduleIndex]?.module_id,
        action_event: triggerEvent
      };

      let response = await deleteSplitFlowPage(deleteData);
    })();

    setFormData((preFormData) => {
      const updatedFormData = [...preFormData];
      if (formData[index]?.[triggerEvent]?.[moduleIndex]?.module_collection_id) {
        updatedFormData[index][triggerEvent].splice(moduleIndex, 1);
      } else {
        updatedFormData[index][triggerEvent] = updatedFormData[index][triggerEvent].filter(moduleEvent => moduleEvent.module_id != formData[index]?.[triggerEvent]?.[moduleIndex]?.module_id);
      }
      return updatedFormData;
    });
  }
  return (
    <>
      <Loader active={isLoading} />
      <div className="splitFlow table-section container-fluid mb-5">
        <div className="row tablesec-bg py-3 px-3">
          <div className="col-sm-12 col-md-6">
            <div className="row justify-content-between my-2">
              <div className="col-md-12 col-lg-3">
                <label className="col-form-label">Project</label>
              </div>
              <div className="col-md-12 col-lg-9">
                <SelectBox
                  className="form-select"
                  name="project_code"
                  id="project_code"
                  OptionValue={projectCode}
                  value={extraFormData.project_code}
                  onChange={handleInputChange}
                  error={errors.project_code}
                  emptyOption="Select project code"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="row my-2">
              <div className="col-md-12 col-lg-3">
                <label className="col-form-label">Flow Name</label>
              </div>
              <div className="col-md-12 col-lg-9">
                <TextField
                  className="form-control"
                  name="flow_name"
                  id="flow_name"
                  placeholder="Enter flow name"
                  value={extraFormData.flow_name}
                  onChange={handleInputChange}
                // error={errors.name}
                />
                {errors.flow_name && (
                  <span className="error-message">{errors.flow_name}</span>
                )}
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="row my-2">
              <div className="col-md-12 col-lg-3">
                <label className="col-form-label">Flow Slug</label>
              </div>
              <div className="col-md-12 col-lg-9">
                <TextField
                  className="form-control"
                  name="flow_slug"
                  id="flow_slug"
                  placeholder="Enter flow slug"
                  value={extraFormData.flow_slug}
                  onChange={handleInputChange}
                // error={errors.flow_slug}
                />
                {errors.flow_slug && (
                  <span className="error-message">{errors.flow_slug}</span>
                )}
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="row my-2">
              <div className="col-md-12 col-lg-3">
                <label className="col-form-label">Flow ID</label>
              </div>
              <div className="col-md-12 col-lg-9">
                <TextField
                  className="form-control"
                  name="flow_id"
                  id="flow_id"
                  placeholder="Enter flow id"
                  value={extraFormData.flow_id}
                  onChange={handleInputChange}
                // error={errors.flow_id}
                />
                {errors.flow_id && (
                  <span className="error-message">{errors.flow_id}</span>
                )}
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6">
            <div className="row my-2">
              <div className="col-md-12 col-lg-3">
                <label className="col-form-label">Flow landing url</label>
              </div>
              <div className="col-md-12 col-lg-9">
                <TextField
                  className="form-control"
                  name="flow_landing_url"
                  id="flow_landing_url"
                  placeholder="Enter flow landing url"
                  value={extraFormData.flow_landing_url}
                  onChange={handleInputChange}
                // error={errors.flow_landing_url}
                />
                {errors.flow_landing_url && (
                  <span className="error-message">{errors.flow_landing_url}</span>
                )}
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6">
            {!splitFlowId && <div className="row mt-4">
              <div className="col d-flex justify-content-end">
                <button className='btn btn-danger fw-bolder fs-6' onClick={() => finalSubmit()}>Submit</button>
              </div>
            </div>}
          </div>
          {splitFlowId && <div className="col mt-3">
            <div className="row">
              <div className="col d-flex align-items-center">
                <h4 className='mb-0'>Add Page</h4>
              </div>
              <div className="col d-flex justify-content-end">
                <button className='add-section fw-bolder fs-6' onClick={handleAddSection}>+ </button>
              </div>
            </div>
            {renderDynamicSections()}
            <div className="row mt-4">
              <div className="col d-flex justify-content-end">
                <button className='btn btn-danger fw-bolder fs-6' onClick={() => finalSubmit()}>Submit</button>
              </div>
            </div>
          </div>}
        </div>
      </div>
      <EventModulePopUp
        eventPopUp={eventPopUp}
        moduleType={moduleType}
        setGetEventModules={setGetEventModules}
        getEventModules={getEventModules}
        handleInputChange={handleInputChange}
        setEventPopUp={setEventPopUp}
        setLoader={setLoader}
        loader={loader}
        extraFormData={extraFormData}
        splitFlowId={splitFlowId}
        formData={formData}
      />
      <SelectTemplatePopup
        templatePopUp={templatePopUp}
        setTemplatePopUp={setTemplatePopUp}
        extraFormData={extraFormData}
        formData={formData}
        dataForTemplate={dataForTemplate}
        setDataForTemplate={setDataForTemplate}
        setTemplates={setTemplates}
        templates={templates}
        fetchDataPage={fetchDataPage}
      />
    </>
  )
}

export default SplitFLow;