import { Api } from "../api/Api";
import { useState } from 'react';

export const useQueueConnection = () => {
    const [isLoading, setIsLoading] = useState(false);
    const listQueueConnection = async (data) => {
        setIsLoading(true);
        const response = await Api.get("api/v1/get-queue-connection", {
            data
        });
        setIsLoading(false);
        console.log(response);
        return response;
    }

    return { listQueueConnection, isLoading };
};
