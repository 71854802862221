import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useHttpClients } from '../../../Hooks/useHttpClients';

const HttpClientsDeletePopup = (props) => {
   const {  deleteHttpClients,listingHttpClients } = useHttpClients();


  const handleClose = () => props.setDelete(false);

  const listClient = async () => {
    const response = await listingHttpClients();
    const HttpData = response.data.map((item, index) => ({
      _id: item._id,
      project_code: item.project_code,
      process_type: item.process_type,
      url_value: item.data['url'],
    }));
  
    const keyword = props.filterText.toLowerCase();
    const filteredData = HttpData.filter(
      (item) =>
      ( item._id && item._id.toString().toLowerCase().includes(keyword)) ||
      ( item.project_code &&  item.project_code.toLowerCase().includes(keyword)) ||
       (item.process_type && item.process_type.toString().toLowerCase().includes(keyword) )||
       ( item.url_value && item.url_value.toString().toLowerCase().includes(keyword))
    );
  
    props.setFilteredData(filteredData);
    props.setFilterText(keyword);
    props.setData(HttpData);
  };

  const handleDelete = async () => {
    const id = props.id;
    const response = await deleteHttpClients(id);

    handleClose();
    listClient();
    props.setToggleList(!props.toggleList);
  };

  return (
    <div>
      <Modal show={props.deletes} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default HttpClientsDeletePopup;
