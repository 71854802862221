
import React, { useEffect, useState } from "react";
import Button from "../../UI/Button";
import Modal from 'react-bootstrap/Modal';
import "../../../assets/css/popup.scss";
import { useTemplates } from "../../../Hooks/useTemplates";
import { usePages } from "../../../Hooks/usePages";

const SelectTemplatePopup = (props) => {
  const { createPlaceholders } = usePages();
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const { getTemplates, isLoading } = useTemplates();

  useEffect(() => {
    async function fetchData() {
      const response = await getTemplates(props.extraFormData.project_code);
      if (response.data?.status !== "Failed") {
        props.setTemplates(response.data);
      }
    }
    if (props.extraFormData.project_code) {
      fetchData();
    }
  }, [props.extraFormData.project_code])

  const handleClose = () => {
    props.setTemplatePopUp(false);
    setSelectedTemplate(null)
    props.setDataForTemplate({ pageIndex: '' })
  };

  const handleSelect = (id) => {
    setSelectedTemplate(id)
  }

  const handleSubmit = async () => {
    if (selectedTemplate) {
      const data = {
        page_id: props.formData[props.dataForTemplate.pageIndex].page_id,
        template_id: selectedTemplate,
        place_holders: {}
      };

      const response = await createPlaceholders(data);
      const url = `/template-editor?template_id=${selectedTemplate}&page_id=${props.formData[props.dataForTemplate.pageIndex].page_id || ""}`;
      props.fetchDataPage();

      window.open(url, "_blank");
      handleClose()
    }
  }

  return (
    <div className="toggle-popup">
      <Modal show={props.templatePopUp} onHide={handleClose} dialogClassName="toggle-popup" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="row text-center gy-3">
              {props.templates.length === 0 ? (
                <p className="col-12 my-2">No template found</p>
              ) : (
                props.templates.map((template, key) => (
                  <div className={`col-12 ${props.templates.length === 1 ? "" : "col-sm-6"}`} key={key} onClick={() => handleSelect(template._id)}>
                    <p className={`p-3 template-card mb-0 ${template._id === selectedTemplate ? "active" : ""}`}>
                      {template.template_details.name}
                    </p>
                  </div>
                ))
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="">
            <Button className={`btn btn-md ${selectedTemplate ? "btn-primary" : "btn-secondary"}`} buttonName="page_module_event" label="proceed" onClick={(e) => {
              // props.handleInputChange(e, props.getEventModules.pageIndex);
              handleSubmit();
            }} />
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default SelectTemplatePopup