import { Api } from "../api/Api";
import { useState } from 'react';

export const usePages = () => {
    const [isLoading, setIsLoading] = useState(false);

    const createPlaceholders = async (data) => {
        setIsLoading(true);
        const response = await Api.post("api/v1/save-pages-placeholder", {
            data
        });
        setIsLoading(false);
        return response;
    };

    return { createPlaceholders, isLoading };
};