import React, { useEffect, useState } from 'react'
import { ComparisonOperators } from '../../Constants/OperatorConstants';
import { JoinOperators } from '../../Constants/OperatorConstants';
import { useEntityAttribute } from '../../Hooks/useEntityAttribute';
import { useProjectCodeInfo } from '../../Hooks/useProjectCodeInfo';
import { useRule } from '../../Hooks/useRule';
import SelectBox from '../UI/SelectBox';
import TextField from '../UI/TextField';
import "../../assets/css/addRule.scss";
import { useHistory } from 'react-router-dom';
import { useProcessType } from '../../Hooks/useProcessType';

const AddRule = () => {
  const history = useHistory();
  const { listEntityAttribute } = useEntityAttribute();
  const { listProjectCode } = useProjectCodeInfo();
  const { createRule, editRule, updateRule } = useRule();
  const { listProcess } = useProcessType();
  const [formData, setFormData] = useState([
    {
      join_operator: '',
      attribute: '',
      operator: '',
      value: '',
      is_advanced: "no",
      is_advanced_rules: [{
        rules_combined_by: '',
        properties: [{
          join_operator: '',
          attribute: '',
          operator: '',
          value: '',
        }]
      }]
    }
  ]);

  const [projectCode, setProjectCode] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [processType, setProcessType] = useState([]);

  const [extraFormData, setExtraExtraFormData] = useState({
    project_code: "",
    process_type_name: "",
    process_type_id: "",
  });
  const [valuesCorrespondingToProjectCode, setValuesCorrespondingToProjectCode] = useState({
    processType: [],
    attributes: [],
  });
  const [errors, setErrors] = useState({});

  // to find the corresponding processType and attributes to project_code
  useEffect(() => {
    // Define a function to filter values based on project code
    const filterValues = () => {
      const matchingAttributes = attributes.filter((item) => item.project_code === extraFormData.project_code);
      const matchingProcessTypes = processType.filter((item) => item.project_code === extraFormData.project_code);

      // Combine matching attributes and process types into label value format
      const formattedValues = {
        processType: matchingProcessTypes.map((type) => ({
          label: type.label,
          value: type.value,
        })),
        attributes: matchingAttributes.map((attribute) => ({
          label: attribute.label,
          value: attribute.value,
        })),
      };

      setValuesCorrespondingToProjectCode(formattedValues);
    };

    filterValues();
  }, [extraFormData.project_code, attributes, processType]);

  /* eslint-disable no-restricted-globals */
  const searchParams = new URLSearchParams(location.search);
  /* eslint-enable no-restricted-globals */
  const id = searchParams.get('id');
  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const response = await editRule(id);
          if (response.status === 200 && response.data) {
            const convertedData = convertApiResponseToFormData(response.data);

            setExtraExtraFormData({
              project_code: convertedData.project_code,
              process_type_name: convertedData.process_type_name,
              process_type_id: convertedData.process_type_id,
            });

            setFormData(convertedData.formData);
          }
        } catch (error) {
          console.error('Error Edit Rule:', error);
        }
      };

      fetchData();
    }

  }, []);

  const convertApiResponseToFormData = (apiResponse) => {
    const { options, project_code, process_type_id, process_type_name } = apiResponse;

    const formData = options.flatMap((option) => {
      const { is_advanced } = option;

      if (is_advanced == 'no') {
        return [
          {
            join_operator: option.join_operator,
            attribute: option.attribute,
            operator: option.operator,
            value: option.value,
            is_advanced: "no",
            is_advanced_rules: [{
              rules_combined_by: '',
              properties: [{
                join_operator: '',
                attribute: '',
                operator: '',
                value: '',
              }]
            }]
          }
        ];
      } else {
        return [
          {
            join_operator: '',
            attribute: '',
            operator: '',
            value: '',
            is_advanced: "yes",
            is_advanced_rules: option.rules
          }
        ]
      }
    });

    return {
      project_code,
      process_type_id,
      process_type_name,
      formData,
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await listEntityAttribute();
        const response2 = await listProjectCode();
        const response3 = await listProcess();

        const formattedAttributes = response.data.map((item) => ({
          label: item.slug,
          id: item._id,
          project_code: item.project_code,
        }));
        const formattedProjectCode = response2.data.map((item) => ({
          label: item.project_code,
          value: item.project_code,
        }));
        const formattedProcessType = response3.data.map((item) => ({
          label: item.name,
          value: item._id,
          project_code: item.project_code,
        }));
        // Update the state with the formatted data
        setAttributes(formattedAttributes);
        setProjectCode(formattedProjectCode);
        setProcessType(formattedProcessType);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleCheckboxChange = (sectionIndex) => {
    setFormData((prevData) => {
      const newData = [...prevData];
      newData[sectionIndex] = {
        ...newData[sectionIndex],
        is_advanced: newData[sectionIndex].is_advanced === "no" ? "yes" : "no",
      };

      // Reset relevant fields if is_advanced is set to "no"
      if (newData[sectionIndex].is_advanced === "no") {
        newData[sectionIndex] = {
          ...newData[sectionIndex],
          join_operator: '', // Reset operator or other relevant fields
        };
      }

      return newData;
    });
  };
  const handleInputChange = (event, sectionIndex, ruleIndex, propertiesIndex) => {
    const { name, value, options } = event.target;
    switch (name) {
      case "project_code":
        setExtraExtraFormData((prevData) => {
          return {
            ...prevData,
            [name]: value,
          };
        });
        break;
      case "process_type":
        const selectedOption = options[options.selectedIndex];
        const label = selectedOption.label;

        setExtraExtraFormData((prevData) => {
          return {
            ...prevData,
            [`${name}_id`]: value,
            [`${name}_name`]: label,
          };
        });
        break;
      default:
        setFormData((prevData) => {
          const newData = [...prevData];
          const originalFieldName = name.replace(`_${sectionIndex}`, ''); // Remove the index from the field name
          if (newData[sectionIndex].is_advanced == 'yes') {
            if (originalFieldName == 'rules_combined_by') {
              newData[sectionIndex].is_advanced_rules[ruleIndex] = {
                ...newData[sectionIndex].is_advanced_rules[ruleIndex],
                [originalFieldName]: value

              };
            } else {
              newData[sectionIndex].is_advanced_rules[ruleIndex].properties[propertiesIndex] = {
                ...newData[sectionIndex].is_advanced_rules[ruleIndex].properties[propertiesIndex],
                [originalFieldName]: value

              };
            }

          } else {
            newData[sectionIndex] = {
              ...newData[sectionIndex],
              [originalFieldName]: value,
            };
          }
          return newData;
        });
        break;
    };
  };

  const handleAddSection = () => {
    // setSectionCount((prevCount) => prevCount + 1);
    setFormData((prevData) => [
      ...prevData,
      {
        join_operator: '',
        attribute: '',
        operator: '',
        value: '',
        is_advanced: "no",
        is_advanced_rules: [{
          rules_combined_by: '',
          properties: [{
            join_operator: '',
            attribute: '',
            operator: '',
            value: '',
          }]
        }]
      }
    ]);
  };

  const handleDeleteSection = (index) => {
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData.splice(index, 1);
      return updatedFormData;
    });
  };

  const handleCombineRule = (index, advancedRuleIndex, operation) => {
    switch (operation) {
      case "Add":
        setFormData((preFormData) => {
          const newCombineRuleFormData = [...preFormData];
          newCombineRuleFormData[index] = {
            ...newCombineRuleFormData[index],
            is_advanced_rules: [
              ...newCombineRuleFormData[index].is_advanced_rules,
              {
                rules_combined_by: '',
                properties: [{
                  join_operator: '',
                  attribute: '',
                  operator: '',
                  value: '',
                }]
              }
            ]
          };
          return newCombineRuleFormData;
        });
        break;
      case "Delete":
        setFormData((preFormData) => {
          const updatedFormData = [...preFormData];
          updatedFormData[index].is_advanced_rules.splice(advancedRuleIndex, 1);
          return updatedFormData;
        });
        break;
      default:
        break;
    }

  }
  const handlePropertiesRule = (index, advancedRuleIndex, PropertiesRuleIndex, operation) => {
    switch (operation) {
      case "Add":
        setFormData((preFormData) => {
          const newPropertiesRuleFormData = [...preFormData];
          newPropertiesRuleFormData[index].is_advanced_rules[advancedRuleIndex] = {
            ...newPropertiesRuleFormData[index].is_advanced_rules[advancedRuleIndex],
            properties: [
              ...newPropertiesRuleFormData[index].is_advanced_rules[advancedRuleIndex].properties,
              {
                join_operator: '',
                attribute: '',
                operator: '',
                value: '',
              }
            ]
          };
          return newPropertiesRuleFormData;
        });
        break;
      case "delete":
        setFormData((preFormData) => {
          const updatedFormData = [...preFormData];
          updatedFormData[index].is_advanced_rules[advancedRuleIndex].properties.splice(PropertiesRuleIndex, 1);
          return updatedFormData;
        });
        break;
      default:
        break;
    };

  }
  const renderDynamicSections = () => {
    return formData.map((section, index) => (
      <div key={index} className="dynamic-section pt-3">
        <div className="row mb-1">
          <div className="col">
            <div className="row">
              <div className="col-auto">
                <label className="form-label">Advanced</label>
              </div>
              <div className="col-auto">
                <input
                  className="form-check-input p-2"
                  type="checkbox"
                  name={`advancedCheckbox_${index}`}
                  onChange={() => handleCheckboxChange(index)}
                  checked={section.is_advanced === "yes" ? true : false}
                />
              </div>
            </div>
          </div>
          <div className="col d-flex justify-content-end">
            <button className='add-section fw-bolder fs-6 mr-1' onClick={() => handleDeleteSection(index)}>- remove rule</button>
          </div>
        </div>
        <div className="row row-gap-3">
          {section.is_advanced === "yes" && (
            <div className="row tablesec-bg py-3 px-3">
              <button className='rule-dy-btn' onClick={() => handleCombineRule(index, null, 'Add')}>+ Combine rule </button>
              {renderDynamicAdvancedRules(index)}
            </div>
          )}
          {/* Add other fields with similar logic */}
          {section.is_advanced === "no" && (
            <>
              <div className={`col-sm-6 ${section.is_advanced === "yes" ? "col-md-3" : "col-md-4"}`}>
                <label className="form-label">Attributes</label>
                <SelectBox
                  className="form-select"
                  name={`attribute_${index}`}
                  id={`attribute_${index}`}
                  OptionValue={valuesCorrespondingToProjectCode.attributes}
                  emptyOption="Select attribute"
                  emptyOptionVal=""
                  value={section.attribute}
                  onChange={(e) => handleInputChange(e, index)}
                  error={errors[`attribute_${index}`]}
                />
              </div>
              <div className={`col-sm-6 ${section.is_advanced === "yes" ? "col-md-3" : "col-md-4"}`}>
                <label className="form-label">Operator</label>
                <SelectBox
                  className="form-select"
                  name={`operator_${index}`}
                  id={`operator_${index}`}
                  OptionValue={ComparisonOperators}
                  value={section.operator}
                  onChange={(e) => handleInputChange(e, index)}
                  error={errors[`operator_${index}`]}
                />
              </div>
              <div className={`col-sm-6 ${section.is_advanced === "yes" ? "col-md-3" : "col-md-4"}`}>
                <label className="form-label">Value</label>
                <TextField
                  className="form-control"
                  name={`value_${index}`}
                  id={`value_${index}`}
                  value={section.value}
                  onChange={(e) => handleInputChange(e, index)}
                  error={errors[`value_${index}`]}
                />
              </div>
            </>
          )}
        </div>
      </div>
    ));
  };

  const renderDynamicAdvancedRules = (mainRuleIndex) => {
    return formData.map((section, index) => (
      (index === mainRuleIndex) && (
        <>
          {section.is_advanced_rules.map((isAdvancedRules, advancedRuleIndex) => (
            <div key={advancedRuleIndex} className={`row tablesec-bg py-3 px-3 col-sm-12`}>
              <div>
                {section.is_advanced_rules.length > 1 && <button className='rule-dy-btn' onClick={() => handleCombineRule(index, advancedRuleIndex, 'Delete')}>- Combine rule</button>}
                <button className='rule-dy-btn' onClick={() => handlePropertiesRule(index, advancedRuleIndex, null, 'Add')}>+ Properties rule</button>
              </div>
              <label className="form-label">Rules Combined By</label>
              <SelectBox
                className="form-select"
                name={`rules_combined_by_${mainRuleIndex}`}
                id={`rules_combined_by_${mainRuleIndex}`}
                OptionValue={JoinOperators}
                value={isAdvancedRules.rules_combined_by}
                onChange={(e) => handleInputChange(e, mainRuleIndex, advancedRuleIndex)}
                error={errors[`rules_combined_by_${mainRuleIndex}`]}
                emptyOption="Select combined rule operator"
              />

              {isAdvancedRules.properties.map((rulesProperties, propertiesIndex) => (
                <div className="row tablesec-bg py-3 px-3">
                  {isAdvancedRules.properties.length > 1 && <div>
                    <button className='rule-dy-btn' onClick={() => handlePropertiesRule(index, advancedRuleIndex, propertiesIndex, 'delete')}>- properties rule</button>
                  </div>}
                  <div className={`col-sm-6 col-md-3`}>
                    <label className="form-label">Join Operator</label>
                    <SelectBox
                      className="form-select"
                      name={`join_operator_${mainRuleIndex}`}
                      id={`join_operator_${mainRuleIndex}`}
                      OptionValue={JoinOperators}
                      value={rulesProperties.join_operator}
                      onChange={(e) => handleInputChange(e, mainRuleIndex, advancedRuleIndex, propertiesIndex)}
                      error={errors[`join_operator_${mainRuleIndex}`]}
                      emptyOption="Select join operator"
                    />
                  </div>
                  <div className={`col-sm-6 col-md-3`}>
                    <label className="form-label">Attributes</label>
                    <SelectBox
                      className="form-select"
                      name={`attribute_${mainRuleIndex}`}
                      id={`attribute_${mainRuleIndex}`}
                      OptionValue={valuesCorrespondingToProjectCode.attributes}
                      emptyOption="Select attribute"
                      emptyOptionVal=""
                      value={rulesProperties.attribute}
                      onChange={(e) => handleInputChange(e, mainRuleIndex, advancedRuleIndex, propertiesIndex)}
                      error={errors[`attribute_${mainRuleIndex}`]}
                    />
                  </div>
                  <div className={`col-sm-6 col-md-3`}>
                    <label className="form-label">Operator</label>
                    <SelectBox
                      className="form-select"
                      name={`operator_${mainRuleIndex}`}
                      id={`operator_${mainRuleIndex}`}
                      OptionValue={ComparisonOperators}
                      value={rulesProperties.operator}
                      onChange={(e) => handleInputChange(e, mainRuleIndex, advancedRuleIndex, propertiesIndex)}
                      error={errors[`operator_${mainRuleIndex}`]}
                    />
                  </div>
                  <div className={`col-sm-6 col-md-3`}>
                    <label className="form-label">Value</label>
                    <TextField
                      className="form-control"
                      name={`value_${mainRuleIndex}`}
                      id={`value_${mainRuleIndex}`}
                      value={rulesProperties.value}
                      onChange={(e) => handleInputChange(e, mainRuleIndex, advancedRuleIndex, propertiesIndex)}
                      error={errors[`value_${mainRuleIndex}`]}
                    />
                  </div>
                </div>
              ))}
            </div>
          ))}
        </>
      )
    ));
  };

  const convertToNewFormat = () => {
    const convertedData = {
      project_code: extraFormData.project_code,
      process_type: extraFormData.process_type_name,
      process_type_id: extraFormData.process_type_id,
      options: [],
    };

    formData.map((section) => {
      if (section.is_advanced == 'yes') {
        var { is_advanced, join_operator, attribute, operator, value } = section;
        convertedData.options.push({
          is_advanced,
          "rules": section.is_advanced_rules
        });
      } else {
        var { is_advanced, join_operator, attribute, operator, value } = section;
        convertedData.options.push({
          is_advanced,
          join_operator,
          attribute,
          operator,
          value
        });
      }
    });

    return convertedData;
  };

  const finalSubmit = () => {
    const convertedData = convertToNewFormat();
    let response = null;
    (async () => {
      if (id) {
        response = await updateRule(convertedData, id);
      } else {
        response = await createRule(convertedData);
      }
      if (response && response.status == 200) {
        history.push('/list-rule');
      }
    })();
  }
  return (
    <>
      <div className="AddRule table-section container-fluid mb-5">
        <div className="row tablesec-bg py-3 px-3">
          <div className="col-sm-12 col-md-6">
            <div className="row justify-content-between my-2">
              <div className="col-md-12 col-lg-3">
                <label className="col-form-label">Project</label>
              </div>
              <div className="col-md-12 col-lg-9">
                <SelectBox
                  className="form-select"
                  name="project_code"
                  id="project_code"
                  OptionValue={projectCode}
                  value={extraFormData.project_code}
                  onChange={handleInputChange}
                  error={errors.project_code}
                  emptyOption="Select project code"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="row my-2">
              <div className="col-md-12 col-lg-3">
                <label className="col-form-label">Process Type</label>
              </div>
              <div className="col-md-12 col-lg-9">
                <SelectBox
                  className="form-select"
                  name="process_type"
                  id="process_type"
                  OptionValue={valuesCorrespondingToProjectCode.processType}
                  value={extraFormData.process_type_id}
                  onChange={handleInputChange}
                  error={errors.process_type}
                  emptyOption="Select process type"
                />
              </div>
            </div>
          </div>
          <div className="col mt-3">
            <div className="row">
              <div className="col d-flex align-items-center">
                <h4 className='mb-0'>Add Rule</h4>
              </div>
              <div className="col d-flex justify-content-end">
                <button className='add-section fw-bolder fs-6' onClick={handleAddSection}>+ add rule</button>
              </div>
            </div>
            {renderDynamicSections()}
            <div className="row mt-4">
              <div className="col d-flex justify-content-end">
                <button className='btn btn-danger fw-bolder fs-6' onClick={() => finalSubmit()}>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddRule