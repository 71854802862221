const GenerateSlug = (attribute, value = "yes") => {
    const trim = attribute.trim();
    const removedSpecialChars = trim.replace(/[^\w\s]/gi, '');
    const slugValue = removedSpecialChars.toLowerCase().replace(/\s+/g, '_');
    let finalValue = '';
    if (value == "no") {
        finalValue = slugValue + ".value";
    } else {
        finalValue = slugValue;
    }
    return {finalValue : finalValue,slugValue:slugValue};
};
export default GenerateSlug;
