import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useSendSms } from "../../../Hooks/useSendSms";

const DeletePopupSms = (props) => {

  const { deleteSms, listSendSms } = useSendSms();
  const handleClose = () => {
    props.setDelete(false);
  };

  const listSms = async () => {
    const response = await listSendSms();

    const SmsData = response.data.map((item, index) => ({
      _id: item._id,
      process_type: item.process_type,
      project_code: item.project_code,
      process_type_id: item.process_type_id,
      sms_from_number: item.data.sms_from_number,
      sms_content: item.data.sms_content,
      sms_vendor: item.data.sms_vendor,
      url: item.data.url,
      method: item.data.method,
      body: item.data.body,
    }));

    const keyword = props.filterValue.toLowerCase();
    const filteredData = SmsData.filter((item) => {
      return (
        (item.id && item._id.toString().toLowerCase().includes(keyword)) ||
        (item.project_code &&
          item.project_code.toLowerCase().includes(keyword)) ||
        (item.process_type &&
          item.process_type.toString().toLowerCase().includes(keyword)) ||
        (item.sms_vendor && item.sms_vendor.toLowerCase().includes(keyword))
      );
    });

    props.setFilteredDataTwo(filteredData);
    props.setFilterValue(keyword);
    props.setFilteredData(SmsData);
  };

  const handleDelete = async () => {
    const id = props.id;
    const response = await deleteSms(id);

    handleClose();
    listSms();
  };

  return (
    <div>
      <Modal show={props.deletes} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeletePopupSms;
