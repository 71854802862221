import React, { useEffect, useState } from "react";
import SelectBox from "../UI/SelectBox";
import TextField from "../UI/TextField";
import "../../assets/css/addRule.scss";
import { useProcessType } from "../../Hooks/useProcessType";
import { useHistory, useLocation } from "react-router-dom";
import { useProjectCodeInfo } from "../../Hooks/useProjectCodeInfo";
import Button from "../UI/Button";
import { useModuleInfo } from "../../Hooks/useModuleInfo";
import Loader from "../Utility/Loader";
import ModuleWidgetsMapping from "../Utility/ModuleWidgetsMapping";
import { useQueueConnection } from "../../Hooks/useQueueConnection";
import GenerateSlug from "../Utility/GenerateSlug";

const ProcessTypes = (props) => {
  const {
    createProcessType,
    editProcessType,
    updateProcessType,
    deleteProcessStep,
    newProcessUpdate,
    isLoading,
  } = useProcessType();

  const [extraFormData, setExtraFormData] = useState({
    id: "",
    process_type: "",
    process_type_slug: "",
    project_code: "",
    queue_connection: ""
  });
  const [errors, setErrors] = useState({});
  const [colectionid, setCollectionId] = useState("");
  const [smsid, setSmsId] = useState("");
  const [emailid, setEmailId] = useState("");
  const [moduleCollectionId, setModuleCollectionId] = useState();
  const [loader, setLoader] = useState(false);
  const [moduleWidgetName, setModuleWidgetName] = useState({});
  const [modules, setModules] = useState({
    module: "",
    order_number: "",
  });
  const [convertedDatas, setConvertedData] = useState("");
  const [rowId, setRowId] = useState("");
  const [passrowId, saveRowId] = useState("");
  const location = useLocation();
  const tempModuleCollectionIds = [];
  const queryParams = new URLSearchParams(location.search);
  useEffect(() => {
    setRowId(queryParams.get("id"));
    saveRowId(queryParams.get("id"));
  }, [queryParams.get("id")]);

  const history = useHistory();
  const [formData, setFormData] = useState([
    {
      module: "",
      order_number: "",
    },
  ]);
  const { listProjectCode } = useProjectCodeInfo();
  const [projectCodeList, setProjectCodeList] = useState([]);
  const { listModuleCode } = useModuleInfo();
  const [moduleType, setModuleType] = useState([]);
  const [view, setView] = useState(false);
  const [index, setIndex] = useState("");
  const [formDataIndex, setFormDataIndex] = useState();
  const { listQueueConnection } = useQueueConnection();
  const [queueList, setQueueList] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const projectCodeResponse = await listProjectCode();
        const resProjectCodeList = projectCodeResponse.data.map((item) => ({
          label: item.project_code,
          value: item.project_code,
        }));
        setProjectCodeList(resProjectCodeList);
        const moduleListResponse = await listModuleCode('process_types');
        const filteredModuleList = moduleListResponse.data.filter(item => {
          if (item.allowed_in) {
            return item.allowed_in.includes("process_types");
          } else {
            return false;
          }
        });
        const resModuleList = filteredModuleList.map(item => ({
          label: item.module_name,
          value: item.module_name,
          slug: item.slug,
          id: item._id,
          widgets: item.widgets,
          allowed_in: item.allowed_in,
        }));
        setModuleType(resModuleList);
        const queueConnectionRes = await listQueueConnection();
        const queueConnectionList = queueConnectionRes.data.map((item) => ({
          label : item.queue_type,
          value : item.queue_slug
        }));
        setQueueList(queueConnectionList);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (rowId) {
          const response = await editProcessType(rowId);
          const rowData = response.data;
          if (rowData) {
            setExtraFormData({
              id: rowData._id,
              project_code: rowData.project_code,
            });
          }
          if (response.status === 200) {
            setExtraFormData((prevValues) => ({
              ...prevValues,
              process_type: rowData.name,
              process_type_slug: rowData.slug,
              project_code: rowData.project_code,
              queue_connection: rowData.queue_connection
            }));

            if (rowData && rowData.steps && rowData.steps.length > 0) {
              const mod = rowData.steps.map((item) => ({
                module: item.module,
                order_number: item.order_number,
                module_collection_id: item.module_collection_id,
              }));
              setFormData((prevData) => [
                ...mod,
              ]);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching data based on rowId:", error);
      }
    };

    fetchData();
  }, [rowId]);

  const handleInputChange = (event, sectionIndex) => {
    const { name, value } = event.target;
    const response = (name == 'process_type') ? GenerateSlug(value) : '';
    switch (name) {
      case "process_type":
      case "project_code":
      case "process_type_slug":
      case "queue_connection":
        setExtraFormData((prevData) => {
          return {
            ...prevData,
            [name]: value,
            ...(response !== '' ? { process_type_slug: response.slugValue } : {}),
          };
        });
        break;

      default:
        setFormData((prevData) => {
          const newData = [...prevData];
          const originalFieldName = name.replace(`_${sectionIndex}`, ""); // Remove the index from the field name
          newData[sectionIndex] = {
            ...newData[sectionIndex],
            [originalFieldName]: value,
          };
          return newData;
        });

        break;
    }
  };

  const handleAddSection = () => {
    setFormData((prevData) => [
      ...prevData,
      {
        module: "",
        order_number: "",
      },
    ]);
  };

  const removeSection = async (event, sectionIndex) => {
    const data = {
      sectionIndex: sectionIndex,
      id: rowId,
      module_collection_id: formData[sectionIndex].module_collection_id,
    };
    await deleteProcessStep(data);
    const updatedFormData = formData.filter(
      (_, index) => index !== sectionIndex
    );
    setFormData(updatedFormData);
  };

  const renderDynamicSections = () => {
    return formData.map((section, index) => (
      <div key={index} className="dynamic-section pt-3">
        <div className="row row-gap-3">
          {/* Add other fields with similar logic */}
          <div className="col-sm-12 col-md-4">
            <label className="form-label">Modules</label>
            <SelectBox
              className="form-select"
              id={`module_${index}`}
              name={`module_${index}`}
              OptionValue={moduleType}
              value={section.module}
              onChange={(e) => handleInputChange(e, index)}
              error={errors[`module_${index}`]}
              emptyOption="Select module"
            />
          </div>
          <div className="col-sm-12 col-md-5">
            <label className="form-label">Order Number</label>
            <TextField
              className="form-control"
              name={`order_number_${index}`}
              id={`order_number_${index}`}
              value={section.order_number}
              placeholder="Enter order number"
              onChange={(e) => handleInputChange(e, index)}
              error={errors[`order_number_${index}`]}
            />
          </div>
          <div className="col-sm-12 col-md-3">
            <div className="row gap-3">
              <div className="col">
                <label className="form-label"></label>
                {index >= 0 && (
                  <div className="col d-flex justify-content-end">
                    <Button
                      className="add-section fw-bolder fs-6"
                      onClick={(e) => removeSection(e, index)}
                      label="-"
                    />
                  </div>
                )}
              </div>
              {section.module && section.order_number && (
                <div className="col-md-6 col-sm-2 col-xs-6">
                  <label className="form-label"></label>
                  <div className="col d-flex justify-content-end">
                    <Button
                      className="add-section fw-bolder fs-6"
                      onClick={() =>
                        handleModuleButtonClick(section.module, index)
                      }
                      label="Module"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    ));
  };
  const handleModuleButtonClick = (selectedModule, index) => {
    setFormDataIndex(index);
    setModuleCollectionId(formData[index]["module_collection_id"]);
    let indexof = "";
    if (formData) {
      indexof = formData[index];
    }
    if (formData) {
      indexof = formData[index];
    }
    setIndex(indexof);
    setModules(formData);
    const ModuleComponent = moduleType.find(item => item.label === selectedModule);
    if (ModuleComponent) {
      setModuleWidgetName(ModuleComponent.widgets);
      setView(true);
    } else {
      alert("Module not found");
    }
  };
  const convertToNewFormat = (parameter) => {
    const convertedData = {
      project_code: extraFormData.project_code,
      process_type: extraFormData.process_type,
      process_type_slug: extraFormData.process_type_slug,
      queue_connection: extraFormData.queue_connection,
      steps: [],
    };

    let moduleCollectionId;
    if (rowId) {
      formData.forEach((section, index) => {
        let modlueIndex = moduleType.findIndex(
          (item) => item.value == section.module
        );
        const { module, order_number } = section;
        moduleCollectionId = formData[index]['module_collection_id'];
        tempModuleCollectionIds.push(moduleCollectionId);
        const step = {
          module,
          order_number,
          module_slug: modlueIndex !== -1 ? moduleType[modlueIndex].slug : '',
          module_id: modlueIndex !== -1 ? moduleType[modlueIndex].id : '',
          module_collection_id: modlueIndex !== -1 ? moduleCollectionId : '',
        };
        convertedData.steps.push(step);
      });
    }
    setConvertedData(convertedData);
    return convertedData;
  };

  const finalSubmit = async (parameter) => {
    if (parameter === "end") {
      const convertedData = convertToNewFormat();
      if (rowId) {
        if (rowId) convertedData.id = rowId;
        const response1 = await newProcessUpdate(convertedData);
        history.push('list-process-types');
      } 
      history.push('list-process-types');
    } else if (parameter === "start") {
      const convertedData = convertToNewFormat(parameter);
      let updateArrayData = {
        id: rowId,
        project_code: extraFormData.project_code,
        process_type: extraFormData.process_type,
        process_type_slug: extraFormData.process_type_slug,
        queue_connection: extraFormData.queue_connection,
        steps: []
      };
      if (rowId) convertedData.id = rowId;
      const response = rowId
        ? await updateProcessType(updateArrayData)
        : await createProcessType(updateArrayData);
      if (!rowId) {
        queryParams.set("id", response.data.created_process_type_id);
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        window.history.pushState({ path: newUrl }, "", newUrl);
        setRowId(response.data.created_process_type_id);
      }
    }
    else {
      const convertedData = convertToNewFormat(parameter);
      let orderNumber = index.order_number;
      const fetchDataForOrderNumber = convertedData.steps.filter(
        (item) => item.order_number === orderNumber
      );
      let updateArrayData = {
        id: rowId,
        project_code: extraFormData.project_code,
        process_type: extraFormData.process_type,
        process_type_slug: extraFormData.process_type_slug,
        queue_connection: extraFormData.queue_connection,
        steps: fetchDataForOrderNumber,
      };
      if (rowId) convertedData.id = rowId;
      const response = rowId
        ? await updateProcessType(updateArrayData)
        : await createProcessType(updateArrayData);
      if (!rowId) {
        queryParams.set("id", response.data.created_process_type_id);
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        window.history.pushState({ path: newUrl }, "", newUrl);
        setRowId(response.data.created_process_type_id);
      }
    }
    parameter = null;
  };
  return (
    <>
      <Loader active={isLoading} />
      <div className="AddRule table-section container-fluid mb-5">
        <div className="row tablesec-bg py-3 px-3">
          <div className="col-sm-12 col-md-6">
            <div className="row my-2">
              <div className="col-md-12 col-lg-3">
                <label className="col-form-label">Process Type</label>
              </div>
              <div className="col-md-12 col-lg-9">
                <TextField
                  className="form-control"
                  name="process_type"
                  id="process_type"
                  placeholder="Enter process type"
                  value={extraFormData.process_type}
                  onChange={handleInputChange}
                // error={errors.process_type}
                />
                {errors.process_type && (
                  <span className="error-message">{errors.process_type}</span>
                )}
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="row my-2">
              <div className="col-md-12 col-lg-3">
                <label className="col-form-label">Process Type Slug</label>
              </div>
              <div className="col-md-12 col-lg-9">
                <TextField
                  className="form-control"
                  name="process_type_slug"
                  id="process_type_slug"
                  placeholder="Enter process type slug"
                  value={extraFormData.process_type_slug}
                  onChange={handleInputChange}
                // error={errors.process_type_slug}
                />
                {errors.process_type_slug && (
                  <span className="error-message">
                    {errors.process_type_slug}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="row justify-content-between my-2">
              <div className="col-md-12 col-lg-3">
                <label className="col-form-label">Project Code</label>
              </div>
              <div className="col-md-12 col-lg-9">
                <SelectBox
                  className="form-select"
                  name="project_code"
                  id="project_code"
                  OptionValue={projectCodeList}
                  value={extraFormData.project_code}
                  onChange={handleInputChange}
                  error={errors.project_code}
                  emptyOption="Select project code"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="row justify-content-between my-2">
              <div className="col-md-12 col-lg-3">
                <label className="col-form-label">Queue Connection</label>
              </div>
              <div className="col-md-12 col-lg-9">
                <SelectBox
                  className="form-select"
                  name="queue_connection"
                  id="queue_connection"
                  OptionValue={queueList}
                  value={extraFormData.queue_connection}
                  onChange={handleInputChange}
                  error={errors.queue_Connection}
                  emptyOption="Select Queue Connection"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6"></div>
          {/* {extraFormData.process_type != "" && extraFormData.process_type_slug != "" && extraFormData.project_code != "" && !rowId && ( */}
          <div className="col-sm-12 col-md-6 my-4 d-flex justify-content-end">
            <button
              className="btn btn-danger fw-bolder fs-6"
              onClick={() => finalSubmit("start")}
            >
              Submit
            </button>
          </div>
          {/* )} */}
          {rowId && (
            <div>
              <div className="col mt-3">
                <div className="row">
                  <div className="col d-flex align-items-center">
                    <h4 className="mb-0">Process Steps</h4>
                  </div>
                  <div className="col d-flex justify-content-end">
                    <button
                      className="add-section fw-bolder fs-6"
                      onClick={handleAddSection}
                    >
                      +
                    </button>
                  </div>
                </div>
                {renderDynamicSections()}
                <div className="row mt-4">
                  <div className="col d-flex justify-content-end">
                    <button
                      className="btn btn-danger fw-bolder fs-6"
                      onClick={() => finalSubmit("end")}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ModuleWidgetsMapping
        moduleWidgetName={moduleWidgetName}
        modules={modules}
        index={index}
        convertedData={convertedDatas}
        emailid={emailid}
        finalSubmit={finalSubmit}
        setView={setView}
        view={view}
        project_code={extraFormData.project_code}
        process_type={extraFormData.process_type}
        process_type_id={rowId}
        setEmailId={setEmailId}
        moduleType={moduleType}
        removeSection={removeSection}
        setLoader={setLoader}
        loader={loader}
        moduleCollectionId={moduleCollectionId}
        setFormData={setFormData}
        formData={formData}
        formDataIndex={formDataIndex}
        colectionid={colectionid}
        setCollectionId={setCollectionId}
        setIndex={setIndex}
        updateSms={view.updateSms}
        project_id={extraFormData.project_code}
        setSmsId={setSmsId}
        smsid={smsid}
        passrowId={passrowId}
      />
    </>
  );
};

export default ProcessTypes;

