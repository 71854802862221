import React, { useEffect, useState } from "react";
import TogglePopUpAttribute from "../Includes/Attributes/TogglePopUpAttribute";
import TableHead from "../Utility/TableHead";
import DataTable from 'react-data-table-component';
import { useEntityAttribute } from "../../Hooks/useEntityAttribute";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Entitycolumns } from "../../Constants/TableColumns";
import TextField from "../UI/TextField";
import DeletePopup from "../Includes/Attributes/DeletePopup";
import Loader from "../Utility/Loader";


const Attributes = () => {
  const [data, setData] = useState([]);
  const [deletes, setDelete] = useState(false);
  const [id, setDeleteId] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [rowDataForEdit, setRowDataForEdit] = useState(null);
  const [toggleList, setToggleList] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [checkboxClicked, setCheckboxClicked] = useState(false);
  const [selectAll, setSelectAll] = useState(false); // New state to track select all 
  const [showTrashButton, setShowTrashButton] = useState(false);
  const [editAttribute, setEditAttribute] = useState(false);


  const { editEntityAttribute, listEntityAttribute, isLoading } = useEntityAttribute();
  const handleSelectAll = () => {
    const allRowIds = data.map((row) => row.id);
    const updatedSelectedRows = selectAll ? [] : allRowIds;
    setSelectedRows(updatedSelectedRows);
    setSelectAll(!selectAll);
    setShowTrashButton(updatedSelectedRows.length > 0);
  };
  

  const SelectHeader = ({ selectAll, handleSelectAll }) => (
    <>
      <input
        style={{ marginRight: "5px" }}
        type="checkbox"
        checked={selectAll}
        onChange={handleSelectAll}
        id="select_header"
      />
      <label htmlFor="select_header">Select</label>
    </>
  );  

  const EntityColumnsWithActions = [
    {
      name: <SelectHeader selectAll={selectAll} handleSelectAll={handleSelectAll}/>,
      cell: (row) => (
        <input
              type="checkbox"
              checked={selectAll || selectedRows.includes(row._id)}
              onChange={() => handleCheckboxChange(row._id)}
          />
      ),
      header: (row) => (
          <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
          />
      )
  },
    ...Entitycolumns,
    {
      name: 'Actions',
      cell: (row) => (
        <div>
          <button onClick={() => handleEdit(row._id)} style={{ /*color: '#008CBA',*/margin: "3px" }}>
            <FontAwesomeIcon icon={faEdit} />
          </button>
          <button onClick={() => handleDelete(row._id)} style={{ /*color: '#ff005d',*/margin: "3px" }}>
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      ),
    },
  ];

  const handleFilter = (e) => {
    const keyword = e.target.value.toLowerCase();
    const filtered = filterAttributeData(data, keyword);
    setFilteredData(filtered);
    setFilterText(keyword);
  };
  const filterAttributeData = (fetchedData, filterText) => {
    return fetchedData.filter(
      (item) =>
        (item._id && item._id.toString().toLowerCase().includes(filterText)) ||
        (item.project_code && item.project_code.toLowerCase().includes(filterText)) ||
        (item.attribute_name && item.attribute_name.toString().toLowerCase().includes(filterText)) ||
        (item.slug && item.slug.toLowerCase().includes(filterText))
    );
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await listEntityAttribute();
        const fetchedData = response.data.map(item => ({
          _id: item._id,
          project_code: item.project_code,
          attribute_name: item.attribute_name,
          slug: item.slug,
          status: item.status
        }));
        setData(fetchedData); // Update the state with fetched data
      } catch (error) {
        console.error('Error fetching entities:', error);
      }
    })();
  }, [toggleList]);

  useEffect(() => {
    (async () => {
      try {
        const response = await listEntityAttribute();
        const fetchedData = response.data.map(item => ({
          _id: item._id,
          project_code: item.project_code,
          attribute_name: item.attribute_name,
          slug: item.slug,
          status: item.status
        }));
        setData(fetchedData);
        if (filterText) {
          const filtered = filterAttributeData(fetchedData, filterText);
          setFilteredData(filtered);
        } else {
          setFilteredData(fetchedData);
        }
      } catch (error) {
        console.error('Error fetching entities:', error);
      }
    })();
  }, [toggleList, filterText]);

  const handleDelete = async (rowId) => {
    setDelete(true);
    setDeleteId(rowId);
  }


  const handleEdit = async (rowId) => {
    try {
      const response = await editEntityAttribute(rowId);
      const rowData = response.data;
      if (response.status === 200) {
        setEditAttribute(true);
        setRowDataForEdit(rowData);
        setIsEditModalOpen(true);
      }
    } catch (error) {
      console.error('Error Edit Entity:', error);
    }
  };

  const handleCheckboxChange = (rowId) => {
    const updatedSelectedRows = selectedRows.includes(rowId)
      ? selectedRows.filter((id) => id !== rowId)
      : [...selectedRows, rowId];
    setSelectedRows(updatedSelectedRows);
  
    // Check if any checkbox is selected, including the "Select All" checkbox
    const isAnyCheckboxSelected = updatedSelectedRows.length > 0 || selectAll;
    setShowTrashButton(isAnyCheckboxSelected);
  };

  const handleMultipleDelete = async () => {
    setDelete(true);
    setDeleteId(selectedRows);
  };

  return (
    <>
      <Loader active={isLoading} />
      <div className="col-lg-12 table-section">
        <div className="row m-0">
          <div className="col-lg-12 col-md-12 p-1">
            <div className="tablesec-bg">
              <div className="col-lg-12 py-3 px-3">
                <div className="row">
                  <div className="col-lg-7" id="search_bar1">
                    <TableHead title="Attributes Management" />
                  </div>
                  <div className="col-lg-1 text-right">
                    <a href="#"><i className="fa-solid fa-rotate px-2 mt-2" aria-hidden="true" data-toggle="tooltip" title="Refresh Page"></i></a>
                  </div>
                  <div className="col-lg-4 text-right">
                    <TogglePopUpAttribute
                      rowDataForEdit={rowDataForEdit}
                      isEditModalOpen={isEditModalOpen}
                      setIsEditModalOpen={setIsEditModalOpen}
                      setRowDataForEdit={setRowDataForEdit}
                      setData={setData}
                      setFilteredData={setFilteredData}
                      setFilterText={setFilterText}
                      setToggleList={setToggleList}
                      toggleList={toggleList}
                      filteredData={filteredData}
                      filterText={filterText}
                      setEditAttribute = {setEditAttribute}
                      editAttribute={editAttribute}
                    />
                  </div>
                </div>
              </div>
              <TextField
                type="text"
                value={filterText}
                onChange={handleFilter}
                placeholder="Search..."
                className="form-control keyword-search"
              />
              <div style={{ float: "right" }}>
                {showTrashButton && (
                  <button
                    style={{ fontSize: "2 rem", margin: "10px", borderWidth: "0px" }}
                    onClick={handleMultipleDelete}
                    title="Delete Selected"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                )}
              </div>

              <DataTable
                columns={EntityColumnsWithActions}
                data={filterText.length > 0 ? filteredData : data}
                pagination
                paginationPerPage={10}
              />
              <DeletePopup
                setData={setData}
                setFilteredData={setFilteredData}
                setFilterText={setFilterText}
                deletes={deletes}
                setDelete={setDelete}
                id={id}
                setToggleList={setToggleList}
                toggleList={toggleList}
                filterText={filterText}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Attributes;