import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useEntityAttribute } from '../../../Hooks/useEntityAttribute';

const DeletePopup = (props) => {
  const { deleteEntityAttribute, listEntityAttribute } = useEntityAttribute();

  const handleClose = () => props.setDelete(false);
  
  const listEntity = async () => {
    const response = await listEntityAttribute();
    const entityData = response.data.map(item => ({
      _id: item._id,
      project_code: item.project_code,
      attribute_name: item.attribute_name,
      slug: item.slug,
      status: item.status
    }));
  
    const keyword = props.filterText.toLowerCase();
    const filteredData = entityData.filter(
      (item) =>
        (item._id && item._id.toString().toLowerCase().includes(keyword)) ||
        (item.project_code && item.project_code.toLowerCase().includes(keyword)) ||
        (item.attribute_name && item.attribute_name.toString().toLowerCase().includes(keyword)) ||
        (item.slug && item.slug.toLowerCase().includes(keyword))
    );
  
    props.setFilteredData(filteredData);
    props.setFilterText(keyword);
    props.setData(entityData);
  };

  const handleDelete = async () => {
    const id = props.id;
    const response = await deleteEntityAttribute(id)
  
    handleClose();
    listEntity();
    props.setToggleList(!props.toggleList)
  };

  return (
    <div>
      <Modal show={props.deletes} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeletePopup;
