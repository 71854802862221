import {useState} from "react";
import { Api } from "../../api/Api";

export const useGlobalLeads = () =>{
    const [isLoading,setIsLoading] = useState(false);
    const getEntityAttributeLists = async(project_code) =>{
        setIsLoading(true);
        const response = await Api.post("api/v1/report/get-entity_attributes",{
            project_code
        })
        setIsLoading(false);
        return response;
    }
    const manageGlobalLeads  = async(requestData) =>{
        setIsLoading(true);
        const response =  await Api.post("api/v1/report/global_leads/manage",{
            requestData
        })
        setIsLoading(false);
        return response;
    }
    return {manageGlobalLeads ,getEntityAttributeLists, isLoading}
}
