import { Api } from "../api/Api";
import { useState } from 'react';

export const useModuleInfo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const createModule = async (data) => {
    setIsLoading(true);
    const response = await Api.post("api/v1/module", {
      data
    });
    setIsLoading(false);
    return response;
  };

  const updateModule = async (data) => {
    setIsLoading(true);
    const response = await Api.patch("api/v1/module/update", {
      data
    });
    setIsLoading(false);
    return response;
  };

  const deleteModule = async (data) => {
    setIsLoading(true);
    const response = await Api.delete("api/v1/module/delete", {
      data
    });
    setIsLoading(false);
    return response;
  };

  const listModuleCode = async (data = '') => {
    setIsLoading(true);
    const response = await Api.get(`api/v1/module?allowed_in=${data}`, {
      data
    });
    setIsLoading(false);
    return response;
  }



  return { createModule, updateModule, deleteModule, listModuleCode, isLoading };
};
