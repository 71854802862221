import { Api } from "../api/Api";
import {useState} from 'react';
 
export const useProjectCodeInfo = () => {
  const [isLoading,setIsLoading] = useState(false);
  const createProjectCode = async (data) => {
    setIsLoading(true);
    const response = await Api.post("api/v1/project", {
        data
    });
    setIsLoading(false);
    return response;
  };

  const updateProjectCode = async (data) => {
    setIsLoading(true);
    const response = await Api.patch("api/v1/project/update", {
        data
    });
    setIsLoading(false);
    return response;
  };

  const deleteProjectCode = async (data) => {
    setIsLoading(true);
    const response = await Api.delete("api/v1/project/delete", {
        data
    });
    setIsLoading(false);
    return response;
  };
  
  const listProjectCode = async(data) =>{
    setIsLoading(true);
    const response = await Api.get("api/v1/project",{
      data
    });
    setIsLoading(false);
    return response;
  }



  return { createProjectCode, updateProjectCode, deleteProjectCode,listProjectCode, isLoading };
};
