import React, { useState, useEffect } from "react";
import { useEntityAttribute } from "../../Hooks/useEntityAttribute";
import SelectBox from "../UI/SelectBox";
import TextField from "../UI/TextField";
import "../../assets/css/addRule.scss";
import { useAssignAttribute } from "../../Hooks/useAssignAttribute";
import Button from "../UI/Button";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { CustomAssignAttribute } from "../../Constants/OperatorConstants";
import { useProcessType } from "../../Hooks/useProcessType";
import Loader from "../Utility/Loader";

const AssignAttributeWidgets = (props) => {
  const urlParams = new URLSearchParams(window.location.search);
  const rowId = urlParams.get("id") ? urlParams.get("id") : "";
  const [sendAssignObject, setSendAssignObject] = useState("");
  const [isChecked, setIsChecked] = useState("no");

  const { listEntityAttribute } = useEntityAttribute();
  const {
    createAssignAttribute,
    editAssignAttribute,
    updateAssignAttribute,
  } = useAssignAttribute();

  const history = useHistory();
  const [formData, setFormData] = useState([
    {
      attribute: "",
      assign_label: "",
      assign_value: "",
      is_advanced: "no",
    },
  ]);

  const { listProcess } = useProcessType();
  const [attributes, setAttributes] = useState([]);
  const [
    valuesCorrespondingToProjectCode,
    setValuesCorrespondingToProjectCode,
  ] = useState({
    processType: [],
    attributes: [],
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const filterValues = () => {
      const matchingAttributes = attributes.filter(
        (item) => item.project_code === props.project_code
      );

      const formattedValues = {
        attributes: matchingAttributes.map((attribute) => ({
          label: attribute.label,
          value: attribute.value,
        })),
      };
      setValuesCorrespondingToProjectCode(formattedValues);
    };

    filterValues();
  }, [props.project_code, attributes]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await listEntityAttribute();

        const formattedAttributes = response.data.map((item) => ({
          label: item.attribute_name,
          value: item.slug,
          id: item._id,
          project_code: item.project_code,
        }));

        setAttributes(formattedAttributes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [props.view]);

  useEffect(() => {
    if (props.process_type_id && props.view == true) {
      const fetchData1 = async () => {
        try {
          props.setLoader(true);
          const listprocess = await listProcess();
          const processResult = listprocess.data.find(
            (item) => item._id == props.process_type_id
          );
          if(processResult != undefined){
          const stepResult = processResult.steps.find(
            (item) =>
              item.module == props.index.module &&
              item.order_number == props.index.order_number
          );
          if (stepResult && stepResult.module_collection_id) {
            const response = await editAssignAttribute(
              stepResult.module_collection_id
            );

            if (response.data._id) {
              setSendAssignObject(response.data._id);
            }

            if (response && response.status === 200) {
              const responseData = response.data.data;

              if (responseData && responseData.length > 0) {
                const updatedFormData = responseData.map((item) => {
                  let isAdvanced = "no"; // Initialize isAdvanced with "no"
                  if (
                    item.attribute === "Filter Attribute" ||
                    item.attribute === "Custom Attribute"
                  ) {
                    isAdvanced = "yes"; // Update isAdvanced to "yes" if the condition is met
                  }
                  return {
                    attribute: item.attribute || "",
                    assign_label: item.assign_label || "",
                    assign_value: item.assign_value || "",
                    is_advanced: isAdvanced, // Use the updated isAdvanced value
                    response: "",
                  };
                });
                setFormData(updatedFormData);
                setIsChecked("yes");
              } else {
                console.log(
                  "Response data is empty. Setting formData to default."
                );
              }
            }
          } else {
            const defaultFormData = formData.map((item) => ({
              ...item,
              is_advanced: "no",
            }));
            setFormData(defaultFormData);
            setIsChecked("no"); // Update isChecked state to "no"

            console.log(
              "Response status is not 200. Setting formData to default."
            );
          }
        }
        } catch (error) {
          console.error("Error in fetching data for edit:", error);
        }
        props.setLoader(false);
      };

      fetchData1();
    }
  }, [props.view, rowId]);

  const handleInputChange = (event, sectionIndex) => {
    const { name, value, options } = event.target;

    setFormData((prevData) => {
      const newData = [...prevData];
      const originalFieldName = name.replace(`_${sectionIndex}`, ""); // Remove the index from the field name
      newData[sectionIndex] = {
        ...newData[sectionIndex],
        [originalFieldName]: value,
      };
      return newData;
    });
  };

  const handleAddSection = () => {
    setFormData((prevData) => [
      ...prevData,
      {
        attribute: "",
        assign_label: "",
        assign_value: "",
        is_advanced: "no",
      },
    ]);
  };
  const removeSection = (event, sectionIndex) => {
    const updatedFormData = formData.filter(
      (_, index) => index !== sectionIndex
    );
    setFormData(updatedFormData);
  };

  const handleCheckboxChange = (sectionIndex) => {
    setFormData((prevData) => {
      const newData = [...prevData];
      newData[sectionIndex] = {
        ...newData[sectionIndex],
        is_advanced: newData[sectionIndex].is_advanced === "no" ? "yes" : "no",
      };

      // Reset relevant fields if is_advanced is set to "no"
      if (newData[sectionIndex].is_advanced === "no") {
        newData[sectionIndex] = {
          ...newData[sectionIndex],
        };
      }
      return newData;
    });
  };

  const renderDynamicSections = () => {
    return formData.map((section, index) => (
      <div key={index} className="dynamic-section pt-3">
        <div className="col">
          <div className="row">
            <div className="col-auto">
              <label className="form-label">Custom Attributes</label>
            </div>
            <div className="col-auto">
              <input
                className="form-check-input p-2 checked"
                type="checkbox"
                name={`advancedCheckbox_${index}`}
                onChange={() => handleCheckboxChange(index)}
                checked={section.is_advanced === "yes" ? true : false}
              />
            </div>
          </div>
        </div>
        <div className="row row-gap-3">
          {section.is_advanced === "yes" && (
            <div
              className={`col-sm-6 ${
                section.is_advanced === "yes" ? "col-md-3" : "col-md-4"
              }`}
            >
              <label className="form-label">Custom Attribute</label>
              <SelectBox
                className="form-select"
                name={`attribute_${index}`}
                id={`attribute_${index}`}
                OptionValue={CustomAssignAttribute}
                emptyOption="Select assign attribute"
                value={section.attribute}
                onChange={(e) => handleInputChange(e, index)}
                error={errors[`attribute_${index}`]}
              />
            </div>
          )}
          {/* Add other fields with similar logic */}
          <div
            className={`col-sm-6 ${
              section.is_advanced === "yes" ? "d-none" : "col-md-4"
            }`}
          >
            <label className="form-label">Attributes</label>

            {sendAssignObject ? (
              <SelectBox
                className="form-select"
                name={`attribute_${index}`}
                id={`attribute_${index}`}
                OptionValue={valuesCorrespondingToProjectCode.attributes}
                emptyOption="Select attribute"
                value={section.attribute}
                onChange={(e) => handleInputChange(e, index)}
                error={errors[`attribute_${index}`]}
              />
            ) : (
              <SelectBox
                className="form-select"
                name={`attribute_${index}`}
                id={`attribute_${index}`}
                OptionValue={valuesCorrespondingToProjectCode.attributes}
                emptyOption="Select attribute"
                onChange={(e) => handleInputChange(e, index)}
                error={errors[`attribute_${index}`]}
              />
            )}
          </div>
          <div
            className={`col-sm-6 ${
              section.is_advanced === "yes" ? "col-md-3" : "col-md-4"
            }`}
          >
            <label className="form-label">Assign Label</label>
            {sendAssignObject ? (
              <TextField
                className="form-control"
                name={`assign_label_${index}`}
                id={`assign_label_${index}`}
                value={section.assign_label}
                onChange={(e) => handleInputChange(e, index)}
                error={errors[`assign_label_${index}`]}
              />
            ) : (
              <TextField
                className="form-control"
                name={`assign_label_${index}`}
                id={`assign_label_${index}`}
                onChange={(e) => handleInputChange(e, index)}
                error={errors[`assign_label_${index}`]}
              />
            )}
          </div>
          {section.is_advanced === "yes" && (
            <div
              className={`col-sm-6 ${
                section.is_advanced === "yes" ? "col-md-3" : "col-md-4"
              }`}
            >
              <label className="form-label">Value</label>
              {sendAssignObject ? (
                <TextField
                  className="form-control"
                  name={`assign_value_${index}`}
                  id={`assign_value_${index}`}
                  value={section.assign_value}
                  onChange={(e) => handleInputChange(e, index)}
                  error={errors[`assign_value_${index}`]}
                />
              ) : (
                <TextField
                  className="form-control"
                  name={`assign_value_${index}`}
                  id={`assign_value_${index}`}
                  onChange={(e) => handleInputChange(e, index)}
                  error={errors[`assign_value_${index}`]}
                />
              )}
            </div>
          )}
          <div className="col-sm-2 col-md-2">
            <label className="form-label"></label>
            {index > 0 && (
              <div className="col d-flex justify-content-end">
                <Button
                  className="add-section fw-bolder fs-6"
                  onClick={(e) => removeSection(e, index)}
                  label="-"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    ));
  };

  const finalSubmit = async () => {
    const array = convertToNewFormat();
    let response = null;
    let type = null;
    if (props.view == true && rowId && sendAssignObject) {
      type = "update";
      response = await updateAssignAttribute(array, sendAssignObject);
    } else {
      type = "create";
      response = await createAssignAttribute(array);
    }

    const moduleCollectionId = {
      module_collection_id: response.data.id,
    };
    if(props.formData != undefined){
      const newFormData = [...props.formData];
      newFormData[props.formDataIndex].module_collection_id = response.data.id;
      props.setFormData(newFormData);
    }
    closeAll(response.data.id, response.status, type);
  };

  const closeAll = (id, status, type) => {
    if (id && status == 200) {
      props.setView({ view: false });
      if (type == "create") {
        props.finalSubmit(id);
      }
      handleClose();
    }
  };

  const convertToNewFormat = () => {
    const convertedData = {
      project_code: props.project_code,
      process_type_id: props.process_type_id,
      process_type: props.process_type,
      module_collection_id: props.moduleCollectionId
        ? props.moduleCollectionId
        : "",
      data: [],
    };
    formData.forEach((section) => {
      if (section.is_advanced == "yes") {
        var { attribute, assign_value, assign_label } = section;
        convertedData.data.push({
          attribute,
          assign_value,
          assign_label,
        });
      } else {
        var { attribute, assign_value, assign_label } = section;
        convertedData.data.push({
          attribute,
          assign_label,
        });
      }
    });
    return convertedData;
  };

  const handleClose = () => {
    props.setView(false);
    setSendAssignObject();
    setFormData([
      {
        attribute: "",
        assign_label: "",
        assign_value: "",
        is_advanced: "no",
      },
    ]);
  };

  return (
    <div>
      {props.loader ? (
        <Loader active={props.loader} />
      ) : (
        <Modal show={props.view} size="lg">
          <Modal.Header closeButton onHide={handleClose}>
            <Modal.Title>Assign Attribute</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="AddRule -table-section container-fluid mb-5">
              <div className="row -tablesec-bg py-3 px-3">
                <div className="col mt-3">
                  <div className="row">
                    <div className="col d-flex align-items-center"></div>
                    <div className="col d-flex justify-content-end">
                      <button
                        className="add-section fw-bolder fs-6"
                        onClick={handleAddSection}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  {renderDynamicSections()}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={handleClose}>
              Cancel
            </button>
            <div className="AddRule">
              <button
                className="btn btn-danger fw-bolder fs-6"
                onClick={() => finalSubmit()}
              >
                Submit
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default AssignAttributeWidgets;
