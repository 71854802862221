import React, { useState, useEffect } from "react";
import TogglePopup from "../Includes/Projects/TogglePopup";
import TableHead from "../Utility/TableHead";
import DataTable from "react-data-table-component";
import { ProjectColumns } from "../../Constants/TableColumns";
import { useProjectCodeInfo } from "../../Hooks/useProjectCodeInfo";
import TextField from "../UI/TextField";
import "../../assets/css/project.scss";
import DeletePopup from "../Includes/Projects/DeletePopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Loader from "../Utility/Loader";


const Projects = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const { listProjectCode, isLoading } = useProjectCodeInfo();
  const [showEdit, setShowEdit] = useState(false);
  const [projectCode, setProjectcode] = useState("");
  const [showCode, setshowCode] = useState("");
  const [showName, setShowName] = useState("");
  const [deletes, setDelete] = useState(false);
  const [showID, setShowID] = useState("");
  const [toggleListing, setToggleListing] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [multipleValue, setMultipleValue] = useState("");
  const [id, setDeleteId] = useState("");
  const [selectAll, setSelectAll] = useState(false); // New state to track select all 
  const [checkboxClicked, setCheckboxClicked] = useState(false);
  const [showTrashButton, setShowTrashButton] = useState(false);
  const handleSelectAll = () => {
    const allRowIds = data.map((row) => row._id);
    const updatedSelectedRows = selectAll ? [] : allRowIds;
    setSelectedRows(updatedSelectedRows);
    setSelectAll(!selectAll);
    setShowTrashButton(updatedSelectedRows.length > 0);
  };

  const SelectHeader = ({ selectAll, handleSelectAll }) => (
    <>
      <input
        style={{ marginRight: "5px" }}
        type="checkbox"
        checked={selectAll}
        onChange={handleSelectAll}
        id="select_header"
      />
      <label htmlFor="select_header">Select</label>
    </>
  );  
  const ProjectColumnsWithActions = [
    {
      name: <SelectHeader selectAll={selectAll} handleSelectAll={handleSelectAll}/>,
      cell: (row) => (
        <input
              type="checkbox"
              checked={selectAll || selectedRows.includes(row.id)}
              onChange={() => handleCheckboxChange(row.id)}
          />
      ),
      header: () => (
          <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
          />
      )
  },
    ...ProjectColumns,
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <button onClick={() => projectEdit(row.id, row.project_code)}>
            <FontAwesomeIcon icon={faEdit} />
          </button>
          <button onClick={() => projecDelete(row.id)}>
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      ),
    },
  ];
  const filterProjectData = (projectData, filterText) => {
    return projectData.filter(
      (item) =>
        item.id.toString().toLowerCase().includes(filterText) ||
        item.project_name.toLowerCase().includes(filterText) ||
        item.project_code.toString().toLowerCase().includes(filterText)
    );
  };
  useEffect(() => {
    (async () => {
      const response = await listProjectCode();
      const projectData = response.data.map((item) => ({
        id: item._id,
        project_name: item.project_name,
        project_code: item.project_code,
      }));
      setData(projectData);
      //setFilteredData(projectData);
    })();
  }, [toggleListing]);

  useEffect(() => {
    (async () => {
      const response = await listProjectCode();
      const projectData = response.data.map((item) => ({
        id: item._id,
        project_name: item.project_name,
        project_code: item.project_code,
      }));
      setData(projectData);
      if (filterText) {
        const filtered = filterProjectData(projectData, filterText);
        setFilteredData(filtered);
      } else {
        setFilteredData(projectData);
      }
    })();
  }, [toggleListing, filterText]);

  const handleFilter = (e) => {
    const keyword = e.target.value.toLowerCase();
    const filtered = filterProjectData(data, keyword);
    setFilteredData(filtered);
    setFilterText(keyword);
  };

  const projectEdit = (project_id, project_code) => {
    setShowEdit(true);
    setProjectcode(project_code);
    const editFilter = data.find((item) => item.id === project_id);
    setshowCode(editFilter.project_code);
    setShowName(editFilter.project_name);
    setShowID(editFilter.id);
  };

  const projecDelete = async (project_id) => {
    setDelete(true);
    setShowID(project_id);
  };

  const handleCheckboxChange = (rowId) => {
    const updatedSelectedRows = selectedRows.includes(rowId)
      ? selectedRows.filter((id) => id !== rowId)
      : [...selectedRows, rowId];
    setSelectedRows(updatedSelectedRows);
  
    // Check if any checkbox is selected, including the "Select All" checkbox
    const isAnyCheckboxSelected = updatedSelectedRows.length > 0 || selectAll;
    setShowTrashButton(isAnyCheckboxSelected);
  };
  

  const handleMultipleDelete = async () => {
    setDelete(true);
    setDeleteId(selectedRows);
    setMultipleValue("multiple");
  };


  return (
    <>
      <Loader active={isLoading} />
      <div className="col-lg-12 table-section project">
        <div className="row m-0">
          <div className="col-lg-12 col-md-12 p-1">
            <div className="tablesec-bg">
              <div className="col-lg-12 py-3 px-3">
                <div className="row">
                  <div className="col-lg-4" id="search_bar1">
                    <TableHead title="Project Management" />
                  </div>
                  <div className="col-lg-5"></div>

                  <div className="col-lg-1 text-right">
                    <a href="#">
                      <i
                        className="fa-solid fa-rotate px-2 mt-2"
                        aria-hidden="true"
                        data-toggle="tooltip"
                        title="Refresh Page"
                      ></i>
                    </a>
                  </div>
                  <div className="col-lg-2 text-right">
                    <TogglePopup
                      setData={setData}
                      setFilteredData={setFilteredData}
                      showEdit={showEdit}
                      setShowEdit={setShowEdit}
                      projectCode={projectCode}
                      showCode={showCode}
                      setshowCode={setshowCode}
                      showName={showName}
                      setShowName={setShowName}
                      projectID={showID}
                      setToggleListing={setToggleListing}
                      toggleListing={toggleListing}
                    />
                  </div>
                </div>
              </div>
              <div className="table_hold">
                <div>
                  <TextField
                    type="text"
                    value={filterText}
                    onChange={handleFilter}
                    placeholder="Search..."
                    className="keyword-search"
                  />
                  <div style={{ float: "right" }}>
                    {showTrashButton && (
                      <button
                        style={{ fontSize: "2 rem", margin: "10px", borderWidth: "0px" }}
                        onClick={handleMultipleDelete}
                        title="Delete Selected"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    )}
                  </div>

                  <DataTable
                    columns={ProjectColumnsWithActions}
                    data={filterText.length > 0 ? filteredData : data}
                    pagination
                    paginationPerPage={10}
                  />
                </div>
              </div>
              <DeletePopup
                setData={setData}
                setFilteredData={setFilteredData}
                deletes={deletes}
                setDelete={setDelete}
                projectID={showID}
                setToggleListing={setToggleListing}
                toggleListing={toggleListing}
                setFilterText={setFilterText}
                filterText={filterText}
                multipleValue={multipleValue}
                setMultipleValue={setMultipleValue}
                id={id}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Projects;
