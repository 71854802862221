import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useAssignAttribute } from "../../../Hooks/useAssignAttribute";

const DeletePopupAttribute = (props) => {
  const { deleteAssignAttribute, listAssignAttribute } =
    useAssignAttribute();

  const handleClose = () => props.setDelete(false);
  const listAssignEntity = async () => {
    const response = await listAssignAttribute();
    const assignentityData = response.data.map((item) => ({
      _id: item._id,
      project_code: item.project_code,
      process_type: item.process_type,
      data: item.data,
    }));

    const keyword = props.filterText.toLowerCase();

    const filteredData = assignentityData.filter(
      (item) =>
        (item._id && item._id.toString().includes(keyword)) ||
        (item.project_code &&
          item.project_code.toLowerCase().includes(keyword)) ||
        (item.process_type &&
          item.process_type.toString().toLowerCase().includes(keyword))
    );

    props.setFilteredAssignAttribute(filteredData);
    props.setFilterText(keyword);
    props.setListAssignAttributes(assignentityData);
  };

  const handleDelete = async () => {
    const id = props.id;

    const response = await deleteAssignAttribute(id);
    handleClose();
    listAssignEntity();
    // props.setToggleList(!props.toggleList)
  };

  return (
    <div>
      <Modal show={props.deletes} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeletePopupAttribute;
