import React, { useState } from "react";
import LogoImage from "../../assets/img/logo.png";
import "../../assets/css/login.scss";
import { useHistory } from "react-router-dom";
import useApi from '../../Hooks/useApi';
import { useAuth } from "../Utility/AuthContext";

const Login = ({ handleLogin }) => {
  const { user,loginAction }  = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const history = useHistory();
  const { makeRequest, error: apiError, clearError, isLoading } = useApi(); // Using the useApi hook

  const isEmailValid = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleSignIn = async () => {
    try {
      clearError(); // Clear any previous errors
      if (!isEmailValid(email)) {
        setError('Invalid email format. Please enter a valid email address.');
        return;
      }
  
      if (!password.trim()) {
        setError('Password field cannot be empty.');
        return;
      }
  
      const requestBody = {
        email: email,
        password: password,
      };
      loginAction(requestBody);
      
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  const onEmailChange = (event) => {
    setEmail(event.target.value);
    setError('');
  };

  const onPasswordChange = (event) => {
    setPassword(event.target.value);
    setError('');
  };

  const enterSub = (e) =>{
    if(e.key === "Enter"){
      handleSignIn();
    }
  }
  
  return (
    <>
    <div className="login">
      <section className="logopart">
        <div className="logo py-4">
          {/* <img src={LogoImage} alt="Logo" className="img_logo" /> */}
        </div>
      </section>
      <section>
        <h3 className="text-center">Login To Your Account</h3>
        <div className="loginesec">
          <div className="row">
            <div className="col-lg-12" id="search_bar1">
              <i className="fa fa-user user_image" aria-hidden="true"></i>
              <input
                name="email"
                className="username"
                id="username"
                placeholder="Email Id"
                value={email}
                onChange={onEmailChange}
              />
            </div>

            <div className="col-lg-12" id="search_bar1">
              <i className="fa fa-unlock-alt user_image" aria-hidden="true"></i>
              <input
                type="password"
                className="username"
                id="pwd"
                name="password"
                placeholder="Password"
                value={password}
                onChange={onPasswordChange}
                onKeyDown={(e) =>enterSub(e)}
              />
            </div>
             {error && (
          <div className="col-lg-12 text-danger" id="search_bar1">
            {error}
          </div>
        )}
            <div className="col-lg-12" id="search_bar1">
            </div>

            <div className="d-flex justify-content-around align-items-center mb-4">
              <a href="/reset/password">Forgot password?</a>
            </div>

            <div className="btn_st">
              <button type="button" className="btn newbtn btn-primary btn-lg btn-block" onClick={handleSignIn}>Sign in</button>
            </div>

          </div>
        </div>
      </section>
      </div>
    </>

  )
}

export default Login;