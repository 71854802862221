const ComparisonOperators = [
    { label: "Select operator", value: "" },
    { label: "Equal", value: "=" },
    { label: "Not Equal", value: "!=" },
    { label: "Greater Than", value: ">" },
    { label: "Greater Than Or Equal", value: ">=" },
    { label: "Less Than", value: "<" },
    { label: "Less Than Or Equal", value: "<=" },
    { label: "Strict Equality", value: "===" },
    { label: "Strict InEquality", value: "!==" },
    { label: "LIKE", value: "LIKE" },
    { label: "LIKE %...%", value: "LIKE %...%" },
    { label: "NOT LIKE ", value: "NOT LIKE" },
    { label: "NOT LIKE %...% ", value: "NOT LIKE %...%" },
    { label: "IN (...)", value: "IN (...)" },
    { label: "NOT IN (...)", value: "NOT IN (...)" },
    { label: "BETWEEN", value: "BETWEEN" },
    { label: "NOT BETWEEN", value: "NOT BETWEEN" },
    { label: "IS NULL", value: "IS NULL" },
    { label: "IS NOT NULL", value: "IS NOT NULL" },
    { label: "Range", value: "Range" }
];

const JoinOperators = [
    { label: "AND", value: "AND" },
    { label: "OR", value: "OR" },
];

const CustomAssignAttribute = [
    { label: "Custom Attribute", value: "Custom Attribute" },
    { label: "Filter Attribute", value: "Filter Attribute" }
]
const Section = [
    { label: 'Display', value: 'display', },
    { label: 'Validate', value: 'validate', },
  ]

export { ComparisonOperators, JoinOperators, CustomAssignAttribute, Section };