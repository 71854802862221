import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useRule } from "../../../Hooks/useRule";
import { useMultipleDelete } from "../../../Hooks/useMultipleDelete";

const ListDeletePopup = (props) => {
  const { deleteRule, listrules } = useRule();
  const {deleteMultipleItems} = useMultipleDelete();

  const listRule = async () => {
    const response = await listrules();
    const fetchedData = response.data.map((item) => ({
      _id: item._id,
      project_code: item.project_code,
      process_type: item.process_type,
    }));

    const keyword = props.filterText.toLowerCase();
    const filteredData = fetchedData.filter(
      (item) =>
        (item._id && item._id.toString().includes(keyword)) ||
        (item.project_code &&
          item.project_code.toLowerCase().includes(keyword)) ||
        (item.process_type &&
          item.process_type.toString().toLowerCase().includes(keyword))
    );
    props.setFilteredData(filteredData);
    props.setFilterText(keyword);
    props.setData(fetchedData);
  };

  const handleClose = () => props.setDelete(false);

  const handleDelete = async () => {
    const id = props.id;
    const response = await deleteRule(id);
    props.setMultipleValue("");
    handleClose();
    listRule();
    props.setToggleList(!props.toggleList);
  };

  return (
    <div>
      <Modal show={props.deletes} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ListDeletePopup;
