import React, { useEffect, useState } from "react";
import TableHead from '../Utility/TableHead'
import TextField from '../UI/TextField'
import DataTable from 'react-data-table-component'
import { ListProcessColumns } from "../../Constants/TableColumns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from 'react-router-dom';
import { useProcessType } from "../../Hooks/useProcessType";
import ProcessDeletePopup from "../Includes/ProcessType/ProcessDeletePopup";
import Button from "../UI/Button";
import Loader from "../Utility/Loader";

const ListProcessType = () => {

  const [toggleList, setToggleList] = useState(true);
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deletes, setDelete] = useState(false);
  const [id, setDeleteId] = useState("");
  // const [multipleValue, setMultipleValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filterText, setFilterText] = useState('');
  const { listProcess, isLoading } = useProcessType();
  const history = useHistory();
  const [selectAll, setSelectAll] = useState(false); // New state to track select all 
  const [checkboxClicked, setCheckboxClicked] = useState(false);
  const [showTrashButton, setShowTrashButton] = useState(false);

  const handleSelectAll = () => {
    const allRowIds = data.map((row) => row._id);
    const updatedSelectedRows = selectAll ? [] : allRowIds;
    setSelectedRows(updatedSelectedRows);
    setSelectAll(!selectAll);
    setShowTrashButton(updatedSelectedRows.length > 0);
  };

  const SelectHeader = ({ selectAll, handleSelectAll }) => (
    <>
      <input
        style={{ marginRight: "5px" }}
        type="checkbox"
        checked={selectAll}
        onChange={handleSelectAll}
        id="select_header"
      />
      <label htmlFor="select_header">Select</label>
    </>
  );  
  const ProcessColumnsWithActions = [
    {
      name: <SelectHeader selectAll={selectAll} handleSelectAll={handleSelectAll}/>,
      cell: (row) => (
        <input
              type="checkbox"
              checked={selectAll || selectedRows.includes(row._id)}
              onChange={() => handleCheckboxChange(row._id)}
          />
      ),
      header: (row) => (
          <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
          />
      )
  },
    ...ListProcessColumns,
    {
      name: 'Actions',
      cell: (row) => (
        <div>
          <button onClick={() => handleEdit(row._id)} style={{ /*color: '#008CBA',*/margin: "3px" }}>
            <FontAwesomeIcon icon={faEdit} />
          </button>
          <button onClick={() => handleDelete(row._id)} style={{ /*color: '#ff005d',*/margin: "3px" }}>
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      ),
    }
  ];

  useEffect(() => {
    fetchCategories();
  }, [toggleList]);

  const handleFilter = (e) => {
    const keyword = e.target.value.toLowerCase();
    const filtered = data.filter(
      (item) =>
        (item._id && item._id.toString().toLowerCase().includes(keyword)) ||
        (item.process_type && item.process_type.toString().toLowerCase().includes(keyword)) ||
        (item.slug && item.slug.toString().toLowerCase().includes(keyword)) ||
        (item.project_code && item.project_code.toString().toLowerCase().includes(keyword))
    );
    setFilteredData(filtered);
    setFilterText(keyword);
  };

  const fetchCategories = async () => {
    try {
      const response = await listProcess();
      const fetchedData = response.data.map(item => ({
        _id: item._id,
        process_type: item.name,
        slug: item.slug,
        project_code: item.project_code

      }));
      setData(fetchedData); // Update the state with fetched data
    } catch (error) {
      console.error('Error fetching process:', error);
    }
  };

  const handleCheckboxChange = (rowId) => {
    const updatedSelectedRows = selectedRows.includes(rowId)
      ? selectedRows.filter((id) => id !== rowId)
      : [...selectedRows, rowId];
    setSelectedRows(updatedSelectedRows);
  
    // Check if any checkbox is selected, including the "Select All" checkbox
    const isAnyCheckboxSelected = updatedSelectedRows.length > 0 || selectAll;
    setShowTrashButton(isAnyCheckboxSelected);
  };
  
  // const handleSelectAll = () => {
  //   const allRowIds = data.map((row) => row._id);
  //   setSelectedRows(selectedRows.length === allRowIds.length ? [] : allRowIds);
  // };
  const handleMultipleDelete = async () => {
    setDelete(true);
    setDeleteId(selectedRows);
    // setMultipleValue("multiple");
  }

  const handleDelete = async (rowId) => {
    setDelete(true);
    setDeleteId(rowId);
  }

  const handleEdit = async (rowId) => {
    history.push('/process-types?id=' + rowId);
  };

  return (
    <>
      <Loader active={isLoading} />
      <div className="col-lg-12 table-section">
        <div className="row m-0">
          <div className="col-lg-12 col-md-12 p-1">
            <div className="tablesec-bg">
              <div className="col-lg-12 py-3 px-3">
                <div className="row">
                  <div className="col-lg-4" id="search_bar1">
                    <TableHead title="ProcessType Management" />
                  </div>
                  <div className="col-lg-5">
                  </div>
                  <div className="col-lg-1 text-right">
                    <a href="#"><i className="fa-solid fa-rotate px-2 mt-2" aria-hidden="true" data-toggle="tooltip" title="Refresh Page"></i></a>
                  </div>
                  <div className="col-lg-2 text-right">
                    <Button className="button_a button2" label="Add" onClick={() => { history.push("/process-types") }} />
                  </div>
                </div>
              </div>
              <TextField
                type="text"
                value={filterText}
                onChange={handleFilter}
                placeholder="Search..."
                className="form-control keyword-search"
              />
              <div style={{ float: "right" }}>
                {showTrashButton && (
                  <button
                    style={{ fontSize: "2 rem", margin: "10px", borderWidth: "0px" }}
                    onClick={handleMultipleDelete}
                    title="Delete Selected"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                )}
              </div>

              <DataTable
                columns={ProcessColumnsWithActions}
                data={filterText.length > 0 ? filteredData : data}
                pagination
              />
              <ProcessDeletePopup
                setData={setData}
                setFilteredData={setFilteredData}
                deletes={deletes}
                setDelete={setDelete}
                id={id}
                setToggleList={setToggleList}
                setFilterText={setFilterText}
                filterText={filterText}
                toggleList={toggleList}
                // multipleValue={multipleValue}
                // setMultipleValue={setMultipleValue}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ListProcessType