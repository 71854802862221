import { useEffect, useState } from "react";
import { useAssignAttribute } from "../../Hooks/useAssignAttribute";
import DataTable from "react-data-table-component";
import { listAssignAttributeColumns } from "../../Constants/TableColumns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import ViewPopup from "../Includes/AssignAttribute/ViewPopup";
import TextField from "../UI/TextField";
import AssignAttributePopup from "../Includes/AssignAttribute/DeletePopupAttribute";
import { useHistory } from "react-router-dom";
import TableHead from "../Utility/TableHead";
import Button from "../UI/Button";
import Loader from "../Utility/Loader";

const AssignAttributeList = () => {
  const { listAssignAttribute, isLoading } = useAssignAttribute();
  const [listAssignAttributes, setListAssignAttributes] = useState([]);
  const [view, setView] = useState(false);
  const [viewData, setViewData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [filteredAssignAttribute, setFilteredAssignAttribute] = useState([]);
  const [deletes, setDelete] = useState(false);
  const [id, setDeleteId] = useState("");
  const [toggleList, setToggleList] = useState(true);
  const history = useHistory();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false); // New state to track select all 
  const [checkboxClicked, setCheckboxClicked] = useState(false);
  const [showTrashButton, setShowTrashButton] = useState(false);
  const handleSelectAll = () => {
    const allRowIds = listAssignAttributes.map((row) => row._id);
    const updatedSelectedRows = selectAll ? [] : allRowIds;
    setSelectedRows(updatedSelectedRows);
    setSelectAll(!selectAll);
    setShowTrashButton(updatedSelectedRows.length > 0);
  };

  const SelectHeader = ({ selectAll, handleSelectAll }) => (
    <>
      <input
        style={{ marginRight: "5px" }}
        type="checkbox"
        checked={selectAll}
        onChange={handleSelectAll}
        id="select_header"
      />
      <label htmlFor="select_header">Select</label>
    </>
  ); 

  const listAssignAttributeColumnsWithActions = [
    {
      name: <SelectHeader selectAll={selectAll} handleSelectAll={handleSelectAll}/>,
      cell: (row) => (
        <input
              type="checkbox"
              checked={selectAll || selectedRows.includes(row._id)}
              onChange={() => handleCheckboxChange(row._id)}
          />
      ),
      header: (row) => (
          <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
          />
      )
  },
    ...listAssignAttributeColumns,
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <button
            onClick={() => assignAttributeEdit(row._id)}
            style={{ margin: "3px" }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
          <button
            onClick={() => assignAttributeDelete(row._id)}
            style={{ margin: "3px" }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
          <button
            onClick={() => assignAttributeView(row._id)}
            style={{ margin: "3px" }}
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
        </div>
      ),
    },
  ];
  useEffect(() => {
    (async () => {
      const response = await listAssignAttribute();
      console.log(response);
      const extractedData = response.data.map((item) => ({
        _id: item._id,
        project_code: item.project_code,
        process_type: item.process_type,
        data: item.data,
      }));
      setFilteredAssignAttribute(extractedData);
      setListAssignAttributes(extractedData);
    })();
  }, [toggleList]);
  const handleFilter = (e) => {
    const keyword = e.target.value.toLowerCase();
    const filtered = listAssignAttributes.filter(
      (item) =>
        (item._id && item._id.toString().includes(keyword)) ||
        (item.project_code &&
          item.project_code.toLowerCase().includes(keyword)) ||
        (item.process_type &&
          item.process_type.toString().toLowerCase().includes(keyword))
    );
    setFilteredAssignAttribute(filtered);
    setFilterText(keyword);
  };
  const assignAttributeEdit = (rowId) => {
    const editFilter = listAssignAttributes.find((item) => item.id === rowId);

    history.push("/assign-attribute?id=" + rowId);
  };
  const assignAttributeDelete = async (rowId) => {
    setDelete(true);
    setDeleteId(rowId);
  };
  const assignAttributeView = async (id) => {
    const getViewData = listAssignAttributes.find((item) => item._id === id);
    setViewData(getViewData);
    setView(true);
  };
  const AddAssignAttributes = () => {
    history.push("/assign-attribute");
  };

  const handleCheckboxChange = (rowId) => {
    const updatedSelectedRows = selectedRows.includes(rowId)
      ? selectedRows.filter((id) => id !== rowId)
      : [...selectedRows, rowId];
    setSelectedRows(updatedSelectedRows);
  
    // Check if any checkbox is selected, including the "Select All" checkbox
    const isAnyCheckboxSelected = updatedSelectedRows.length > 0 || selectAll;
    setShowTrashButton(isAnyCheckboxSelected);
  };

  const handleMultipleDelete = async () => {
    setDelete(true);
    setDeleteId(selectedRows);
  };

  return (
    <>
      <Loader active={isLoading} />
      <div className="col-lg-12 table-section project">
        <div className="row m-0">
          <div className="col-lg-12 col-md-12 p-1">
            <div className="tablesec-bg">
              <div className="col-lg-12 py-3 px-3">
                <div className="row">
                  <div className="col-lg-4" id="search_bar1">
                    <TableHead title="Assign Attribute List Management" />
                  </div>
                  <div className="col-lg-5"></div>
                  <div className="col-lg-1 text-right">
                    <a href="#">
                      <i
                        className="fa-solid fa-rotate px-2 mt-2"
                        aria-hidden="true"
                        data-toggle="tooltip"
                        title="Refresh Page"
                      ></i>
                    </a>
                  </div>
                  <div className="col-lg-2 text-right">
                    <Button
                      className="button_a button2"
                      label="Assign Attributes"
                      onClick={AddAssignAttributes}
                    />
                  </div>
                  <div className="table_hold">
                    <div>
                      <TextField
                        type="text"
                        value={filterText}
                        onChange={handleFilter}
                        placeholder="Search..."
                        className="keyword-search"
                      />
                      <div style={{ float: "right" }}>
                        {showTrashButton && (
                          <button
                            style={{ fontSize: "2 rem", margin: "10px",borderWidth:"0px"}}
                            onClick={handleMultipleDelete}
                            title="Delete Selected"
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        )}
                      </div>

                      <DataTable
                        columns={listAssignAttributeColumnsWithActions}
                        data={filteredAssignAttribute}
                        pagination
                        paginationPerPage={10}
                      />
                    </div>
                  </div>
                  {view && (
                    <ViewPopup
                      setView={setView}
                      view={view}
                      listAssignAttributes={listAssignAttributes}
                      viewData={viewData}
                    />
                  )}
                  <AssignAttributePopup
                    deletes={deletes}
                    setDelete={setDelete}
                    id={id}
                    setToggleList={setToggleList}
                    toggleList={toggleList}
                    setFilterText={setFilterText}
                    setFilteredAssignAttribute={setFilteredAssignAttribute}
                    filterText={filterText}
                    setListAssignAttributes={setListAssignAttributes}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AssignAttributeList;
