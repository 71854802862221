import React, { useState } from 'react';

const CustomTooltip = ({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      style={{ position: 'absolute', display: 'inline-block' }}
    >
      {children}
      {showTooltip && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            // left: '0',
            transform: 'translateY(-90%)',
            backgroundColor: '#fff',
            color: '#000',
            padding: '5px',
            borderRadius: '5px',
            zIndex: '1',
            whiteSpace: 'nowrap', 
            overflow: 'hidden',   
            border: '1px solid #000', 
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default CustomTooltip;
