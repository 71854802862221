import { useEffect, useState, useMemo } from "react";
import SlideToggle from "react-slide-toggle";
import DataTable from "react-data-table-component-footer";
import Options from "../../Includes/Reports/Options";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../assets/css/Reports/leadSummary.scss";
import Button from "../../UI/Button";
import { useLeadSummary } from "../../../Hooks/Reports/useLeadSummary";
import Loader from "../../Utility/Loader";
import { customStyles } from "../../../Constants/Reports/projectSettings";

const LeadSummaryReport = () => {
  const [showOptions, setshowOptions] = useState("d-none");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [optionsData, setOptionsData] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [loader, setLoader] = useState(true);
  const [tableColumnsAfterFormSubmit, setTableColumnsAfterFormSubmit] = useState([]);
  const [optionsDataAfterFormSubmit, setOptionsDataAfterFormSubmit] = useState({});
  const [checkSubmit, setCheckSubmit] = useState(false);
  const { manageLeadSummary, isLoading } = useLeadSummary();
  const [footer, setFooter] = useState({});
  const [selectedProjectCode, setSelectedProjectCode] = useState([]);

  const handleStartDateChange = (date) => {
    if (date > endDate) {
      setStartDate(date);
      setEndDate(date);
    } else {
      setStartDate(date);
    }
  };
  const handleEndDateChange = (date) => {
    if (date < startDate) {
      setStartDate(date);
      setEndDate(date);
    } else {
      setEndDate(date);
    }
  };
  useEffect(() => {
    if (Object.keys(optionsData).length > 0 && !checkSubmit) {
      handleLeadSummary(optionsData, tableColumns);
    }
  }, [optionsData])

  const handleSubmitButtonChange = () => {
    setLoader(true);
    setCheckSubmit(true);
    setOptionsData(optionsDataAfterFormSubmit);
    setTableColumns(tableColumnsAfterFormSubmit);
    if (Object.keys(optionsDataAfterFormSubmit).length > 0) {
      handleLeadSummary(optionsDataAfterFormSubmit, tableColumnsAfterFormSubmit);
    }
  }
  const handleLeadSummary = (options, columns) => {
    const requestData = {
      groupBy: (options.data).map(item => item.data_group),
      columns: (columns).map(item => item.selector),
      data_checker: (options.data).map(item => item.data_column),
      statistics_checker: (options.statistics).map(item => item.data_column),
      calculations_checker: (options.calculations).map(item => item.data_column),
      interval_checker: (options.interval || []).map(item => item.data_column),
      filter_checker: (options.filterColumns || []).map(item => item.data_column),
      filter_values: (options.filterValues || []),
      exclude_filter_checker: (options.excludeFilterColumns || []).map(item => item.data_column),
      exclude_filter_values: (options.excludeFilterValues || []),
      project_code:options.projectCode,
      start_date: convertDateTimeFormat(startDate),
      end_date: convertDateTimeFormat(endDate)
    };
    (async () => {
      const response = await manageLeadSummary(requestData);
      setLoader(isLoading);
      setFilteredData(response.data);
      setCheckSubmit(false);
    })();
  }
  const convertDateTimeFormat = (dateTime) => {
    const date = new Date(dateTime);
    const convertedDate = date.toISOString().split('T')[0];
    return convertedDate;
  }
  useEffect(() => {
    const sums = {};
    const total = {};

    tableColumns.forEach(column => {
      sums[column.selector] = 0;
    });
    filteredData.forEach(row => {
      tableColumns.forEach(column => {
        const value = row[column.selector];
        if (typeof value === "number" && !isNaN(value)) {
          sums[column.selector] += value;
        } else {
          sums[column.selector] = null;
        }
      });
    });
    console.log(sums);
    Object.keys(sums).forEach(column => {
      if (sums[column] !== null) {
        const leads = sums['leads'];
        const visitors = sums['visitors'];
        total[column] = `Total : ${sums[column]}`;
         if (leads != null && visitors != null) {
          const ffePercentage = (leads / visitors) * 100;
          total['ffe'] = `Total : ${ffePercentage.toFixed(2)}%`;
        } 
      }
    });
    setFooter(total);
  }, [filteredData, tableColumns]);
  return (
    <>
      <Loader active={loader} />
      <div className="lead-summary">
        <div className="col-lg-12 table-section project">
          <div className="row m-0">
            <div className="col-lg-12 col-md-12 p-1">
              <div className="tablesec">
                <div className="col-lg-12 py-3 px-3">
                  <div className="row">
                    <div className="col-lg-2 text-left">
                    </div>
                    <div className="col-lg-4" id="search_bar1">
                    </div>
                    <div className="col-lg-5"></div>
                  </div>
                </div>
                <SlideToggle
                  duration={1000}
                  collapsed={true}
                  whenReversedUseBackwardEase={false}
                  render={({ toggle, setCollapsibleElement }) => (
                    <div className="card mt-4">
                      <div className="card-header">
                        <button className="btn btn-primary" onClick={toggle}>
                          Options
                        </button>
                      </div>
                      <div className="card-body p-0" ref={setCollapsibleElement}>
                        <div className="p-3">

                          <Options
                            showOptions={showOptions}
                            setOptionsData={setOptionsData}
                            setTableColumns={setTableColumns}
                            setTableColumnsAfterFormSubmit={setTableColumnsAfterFormSubmit}
                            setOptionsDataAfterFormSubmit={setOptionsDataAfterFormSubmit}
                            setLoader={setLoader}
                            setFilteredData={setFilteredData}
                            handleLeadSummary={handleLeadSummary}
                            optionsDataAfterFormSubmit={optionsDataAfterFormSubmit}
                            tableColumnsAfterFormSubmit={tableColumnsAfterFormSubmit}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                />
                <div className="row m-t-20 date-picker">
                  <div className="col-md-4">
                    <div className="form-group"></div>
                  </div>
                  <div className="col-md-6">
                  <div className="row">
                    <div className="form-group">
                      <div className="input-daterange input-group" id="date-range">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => handleStartDateChange(date)}
                          startDate={startDate}
                          endDate={endDate}
                          maxDate={new Date()}
                          placeholderText="Start Date"
                          className="custom-datepicker form-control"
                          dateFormat="yyyy-MM-dd" 
                        />
                        <span className="input-group-addon">➔</span>
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => handleEndDateChange(date)}
                          startDate={startDate}
                          endDate={endDate}
                          maxDate={new Date()}
                          placeholderText="End Date"
                          className="custom-datepicker form-control"
                          dateFormat="yyyy-MM-dd" 
                        />
                      </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <Button onClick={handleSubmitButtonChange} type="button" className="button_a button2" label="Submit" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table_hold">
                    <DataTable
                      columns={tableColumns }
                      data={filteredData.length > 0 ? filteredData : ''}
                      pagination
                      paginationPerPage={10}
                      footer={footer}
                      customStyles={customStyles}
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default LeadSummaryReport






