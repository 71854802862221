import React, { useState } from "react";
import { createContext, useContext } from "react";
import { Redirect, useHistory } from "react-router-dom";

import useApi from "../../Hooks/useApi";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const history = useHistory();
  const { makeRequest, error: apiError, clearError, isLoading } = useApi();
  const [user, setUser] = useState({ name: '', toke: '',message : ''});
  const [users,setUsers] = useState(null);

  const loginAction = async (requestBody) => {
    try {
      const response = await makeRequest('api/v1/login', 'POST', requestBody);
      if (response.user_data.name && response.token) {
        setUser({ name: response.user_data.name, token: response.token ,message: response.message });
        setUsers(response.user_data.name);
        localStorage.setItem('token', response.token);
        localStorage.setItem('name', response.user_data.name);
        history.push('/dashboard');
      }
      else{
        return <Redirect to="/login"/>
      }
    } catch (error) {
      console.log(error);
    }

  }
   let token = localStorage.getItem('token');
   let name = localStorage.getItem('name')
  return (
    <AuthContext.Provider value={{ user,users, loginAction,token,name }}>
      {children}
    </AuthContext.Provider>
  )

}
export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
}