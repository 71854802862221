import React, { useEffect, useState } from "react";
import TableHead from "../Utility/TableHead";
import TextField from "../UI/TextField";
import DataTable from "react-data-table-component";
import { ListFollowupRulecolumns } from "../../Constants/TableColumns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useRule } from "../../Hooks/useRule";
import { useFollowupRule } from "../../Hooks/useFollowupRule";
import Button from "../UI/Button";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ListDeletePopup from "../Includes/Followup/ListDeletePopup";
import { useHistory } from "react-router-dom";
import Loader from "../Utility/Loader";

const ListFollowupRule = () => {
  const [toggleList, setToggleList] = useState(true);
  const [data, setData] = useState([]);
  const [deletes, setDelete] = useState(false);
  const [id, setDeleteId] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const { listFollowupRules, isLoading } = useFollowupRule();
  const history = useHistory();
  const [selectedRows, setSelectedRows] = useState([]);
  const [multipleValue, setMultipleValue] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [showTrashButton, setShowTrashButton] = useState(false);
  const handleSelectAll = () => {
    const allRowIds = data.map((row) => row._id);
    const updatedSelectedRows = selectAll ? [] : allRowIds;
    setSelectedRows(updatedSelectedRows);
    setSelectAll(!selectAll);
    setShowTrashButton(updatedSelectedRows.length > 0);
  };
  const SelectHeader = ({ selectAll, handleSelectAll }) => (
    <>
      <input
        style={{ marginRight: "5px" }}
        type="checkbox"
        checked={selectAll}
        onChange={handleSelectAll}
        id="select_header"
      />
      <label htmlFor="select_header">Select</label>
    </>
  );  
  const EntityColumnsWithActions = [
    {
      name: <SelectHeader selectAll={selectAll} handleSelectAll={handleSelectAll}/>,
      cell: (row) => (
        <input
              type="checkbox"
              checked={selectAll || selectedRows.includes(row._id)}
              onChange={() => handleCheckboxChange(row._id)}
          />
      ),
      width: '50px',
      header: (row) => (
          <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
          />
      )
  },
    ...ListFollowupRulecolumns,
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <button
            onClick={() => handleEdit(row._id)}
            style={{ margin: "3px" }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
          <button
            onClick={() => handleDelete(row._id)}
            style={{ margin: "3px" }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      ),
    },
  ];
  useEffect(() => {
    fetchCategories();
  }, [toggleList]);
  const handleFilter = (e) => {
    const keyword = e.target.value.toLowerCase();
    const filtered = data.filter(
      (item) =>
        (item._id && item._id.toString().includes(keyword)) ||
        (item.project_code &&
          item.project_code.toLowerCase().includes(keyword)) ||
        (item.process_type &&
          item.process_type.toString().toLowerCase().includes(keyword))
    );
    setFilteredData(filtered);
    setFilterText(keyword);
  };

  const fetchCategories = async () => {
    try {
      const response = await listFollowupRules();
      const fetchedData = response.data.map((item) => ({
        _id: item._id,
        project_code: item.project_code,
        followup_url: item.followup_url,
      }));
      setData(fetchedData);
    } catch (error) {
      console.error("Error fetching entities:", error);
    }
  };
  const handleDelete = async (rowId) => {
    setDelete(true);
    setDeleteId(rowId);
  };
  const handleEdit = async (rowId) => {
    history.push("/add-followup-rules?id=" + rowId);
  };
  const handleCheckboxChange = (rowId) => {
    const updatedSelectedRows = selectedRows.includes(rowId)
      ? selectedRows.filter((id) => id !== rowId)
      : [...selectedRows, rowId];
    setSelectedRows(updatedSelectedRows);
    const isAnyCheckboxSelected = updatedSelectedRows.length > 0 || selectAll;
    setShowTrashButton(isAnyCheckboxSelected);
  };
  const handleMultipleDelete = async () => {
    setDelete(true);
    setDeleteId(selectedRows);
    setMultipleValue("multiple");
  };
  return (
    <>
      <Loader active={isLoading} />
      <div className="col-lg-12 table-section">
        <div className="row m-0">
          <div className="col-lg-12 col-md-12 p-1">
            <div className="tablesec-bg">
              <div className="col-lg-12 py-3 px-3">
                <div className="row">
                  <div className="col-lg-4" id="search_bar1">
                    <TableHead title="Rule Management" />
                  </div>
                  <div className="col-lg-5"></div>
                  <div className="col-lg-3 text-right">
                    <Link to="/add-followup-rules">
                      <Button
                        type="button"
                        className="button_a button2"
                        label="Add Followup Rule"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <TextField
                type="text"
                value={filterText}
                onChange={handleFilter}
                placeholder="Search..."
                className="form-control keyword-search"
              />
              <div style={{ float: "right" }}>
                {showTrashButton && (
                  <button
                    style={{ fontSize: "2 rem", margin: "10px", borderWidth: "0px" }}
                    onClick={handleMultipleDelete}
                    title="Delete Selected"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                )}
              </div>
              <DataTable
                columns={EntityColumnsWithActions}
                data={filterText.length > 0 ? filteredData : data}
                pagination
                paginationPerPage={10}
              />
              <ListDeletePopup
                setData={setData}
                setFilteredData={setFilteredData}
                deletes={deletes}
                setDelete={setDelete}
                id={id}
                setToggleList={setToggleList}
                toggleList={toggleList}
                setFilterText={setFilterText}
                filterText={filterText}
                multipleValue={multipleValue}
                setMultipleValue={setMultipleValue}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListFollowupRule;
