const Data = {

  chk_split: {
    name: "chk_split",
    id: "chk_split",
    class: "chk_split",
    value: "Split Name",
    data_column: "splitname",
    data_group: "splitname",
    checked: true
  },
  chk_domain: {
    name: "chk_domain",
    id: "chk_domain",
    class: "chk_domain",
    value: "Domain",
    data_column: "domain_name",
    data_group: "domain_name",
    checked: false
  },
  chk_browser: {
    name: "chk_browser",
    id: "chk_browser",
    class: "chk_browser",
    value: "Browser",
    data_column: "browser",
    data_group: "browser",
    checked: false
  },
  chk_country: {
    name: "chk_country",
    id: "chk_country",
    class: "chk_country",
    value: "Country",
    data_column: "visitor_country",
    data_group: "visitor_country",
    checked: false
  },
  chk_device: {
    name: "chk_device",
    id: "chk_device",
    class: "chk_device",
    value: "Device",
    data_column: "device",
    data_group: "device",
    checked: false
  },
  chk_platform: {
    name: "chk_platform",
    id: "chk_platform",
    class: "chk_platform",
    value: "Platform",
    data_column: "platform",
    data_group: "platform",
    checked: false
  },
  chk_campaign: {
    name: "chk_campaign",
    id: "chk_campaign",
    class: "chk_campaign",
    value: "Campaign",
    data_column: "facebook_cid",
    data_group: "facebook_cid",
    checked: false
  },
  chk_tracker_type: {
    name: "chk_tracker_type",
    id: "chk_tracker_type",
    class: "chk_tracker_type",
    value: "Tracker Type",
    data_column: "tracker",
    data_group: "tracker",
    checked: false
  },
  chk_adtopia_vendor: {
    name: "chk_adtopia_vendor",
    id: "chk_adtopia_vendor",
    class: "chk_adtopia_vendor",
    value: "Adtopia Vendor",
    data_column: "atp_vendor",
    data_group: "atp_vendor",
    checked: false
  },
  chk_adtopia_source: {
    name: "chk_adtopia_source",
    id: "chk_adtopia_source",
    class: "chk_adtopia_source",
    value: "Adtopia Source",
    data_column: "atp_source",
    data_group: "atp_source",
    checked: false
  }

}

const Statistics = {
  chk_visitors: {
    name: "chk_visitors",
    id: "chk_visitors",
    class: "chk_visitors",
    value: "Visitors",
    data_column: "visitors",
    data_group: "visitors",
    checked: true
  },
  chk_univisitors: {
    name: "chk_univisitors",
    id: "chk_univisitors",
    class: "chk_univisitors",
    value: "Unique Visitors",
    data_column: "unique_visitors",
    data_group: "unique_visitors",
    checked: false
  },
  chk_leads: {
    name: "chk_leads",
    id: "chk_leads",
    class: "chk_leads",
    value: "Leads",
    data_column: "leads",
    data_group: "leads",
    checked: true
  },
  chk_success: {
    name: "chk_success",
    id: "chk_success",
    class: "chk_success",
    value: "Qualified Leads",
    data_column: "qualified",
    data_group: "qualified",
    checked: true
  },
  chk_error: {
    name: "chk_error",
    id: "chk_error",
    class: "chk_error",
    value: "Unqualified Leads",
    data_column: "unqualified",
    data_group: "unqualified",
    checked: true
  },
  chk_is_signed: {
    name: "chk_is_signed",
    id: "chk_is_signed",
    class: "chk_is_signed",
    value: "Signed Leads",
    data_column: "user_signature",
    data_group: "user_signature",
    checked: false
  },
  chk_is_not_signed: {
    name: "chk_is_not_signed",
    id: "chk_is_not_signed",
    class: "chk_is_not_signed",
    value: "Not Signed Leads",
    data_column: "user_not_signature",
    data_group: "user_not_signature",
    checked: false
  },
  chk_api_success: {
    name: "chk_api_success",
    id: "chk_api_success",
    class: "chk_api_success",
    value: "API Sucess",
    data_column: "api_success",
    data_group: "api_success",
    checked: false
  },
  chk_api_error: {
    name: "chk_api_error",
    id: "chk_api_error",
    class: "chk_api_error",
    value: "API Error",
    data_column: "api_error",
    data_group: "api_error",
    checked: false
  },
}
const Calculations = {
  chk_ffe: {
    name: "chk_ffe",
    id: "chk_ffe",
    class: "chk_ffe",
    value: "FFE",
    data_column: "ffe",
    data_group: "ffe",
    checked: true
  }
}
const Interval = {
  chk_intvl_year: {
    name: "chk_intvl_year",
    id: "chk_intvl_year",
    class: "chk_intvl_year",
    value: "Year",
    data_column: "year",
    data_group: "year",
    checked: false
  },
  chk_intvl_month: {
    name: "chk_intvl_month",
    id: "chk_intvl_month",
    class: "chk_intvl_month",
    value: "Month",
    data_column: "month",
    data_group: "month",
    checked: false
  },
  chk_intvl_week: {
    name: "chk_intvl_week",
    id: "chk_intvl_week",
    class: "chk_intvl_week",
    value: "Week",
    data_column: "week",
    data_group: "week",
    checked: false
  },
  chk_intvl_date: {
    name: "chk_intvl_date",
    id: "chk_intvl_date",
    class: "chk_intvl_date",
    value: "Date",
    data_column: "date",
    data_group: "date",
    checked: false
  },
  chk_intvl_hour: {
    name: "chk_intvl_hour",
    id: "chk_intvl_hour",
    class: "chk_intvl_hour",
    value: "Hour",
    data_column: "hour",
    data_group: "hour",
    checked: false
  }
}

const Filter = {

  splitName: {
    name: "split_name",
    id: "split_name",
    class: "split_name",
    value: "Split",
    data_column: "splitname",
    data_group: "splitname",
    checked: false
  },
  domainName: {
    name: "domain_name",
    id: "domain_name",
    class: "domain_name",
    value: "Domain",
    data_column: "domain_name",
    data_group: "domain_name",
    checked: false
  },
  country: {
    name: "country",
    id: "country",
    class: "country",
    value: "Country",
    data_column: "visitor_country",
    data_group: "visitor_country",
    checked: false
  },
  device: {
    name: "device",
    id: "device",
    class: "device",
    value: "Device",
    data_column: "device",
    data_group: "device",
    checked: false
  },
  platform: {
    name: "platform",
    id: "platform",
    class: "platform",
    value: "Platform",
    data_column: "platform",
    data_group: "platform",
    checked: false
  },
  campaign: {
    name: "campaign",
    id: "campaign",
    class: "campaign",
    value: "Campaign",
    data_column: "facebook_cid",
    data_group: "facebook_cid",
    checked: false
  },
  trackerType: {
    name: "tracker_type",
    id: "tracker_type",
    class: "tracker_type",
    value: "Tracker Type",
    data_column: "tracker",
    data_group: "tracker",
    checked: false
  },
  adtopiaVendor: {
    name: "adtopia_vendor",
    id: "adtopia_vendor",
    class: "adtopia_vendor",
    value: "Adtopia Vendor",
    data_column: "atp_vendor",
    data_group: "atp_vendor",
    checked: false
  },
  adtopiaSource: {
    name: "adtopia_source",
    id: "adtopia_source",
    class: "adtopia_source",
    value: "Adtopia Source",
    data_column: "atp_source",
    data_group: "atp_source",
    checked: false
  }
}
const ExcludeFilter = {

  splitName: {
    name: "split_name",
    id: "split_name",
    class: "split_name",
    value: "Split",
    data_column: "splitname",
    data_group: "splitname",
    checked: false
  },
  country: {
    name: "country",
    id: "country",
    class: "country",
    value: "Country",
    data_column: "visitor_country",
    data_group: "visitor_country",
    checked: false
  },
  device: {
    name: "device",
    id: "device",
    class: "device",
    value: "Device",
    data_column: "device",
    data_group: "device",
    checked: false
  },
  platform: {
    name: "platform",
    id: "platform",
    class: "platform",
    value: "Platform",
    data_column: "platform",
    data_group: "platform",
    checked: false
  }
}
const uuid = {
  attribute_name : "uuid",
  attribute_slug:"uuid",
  attribute_dynamic_slug : "uuid",
  checked:true
}
const GloablLeadFilter = {
  domainName: {
    name: "domain_name",
    id: "domain_name",
    class: "domain_name",
    value: "Domain",
    data_column: "domain_name",
    data_group: "domain_name",
    checked: false
  },
  device: {
    name: "device",
    id: "device",
    class: "device",
    value: "Device",
    data_column: "device",
    data_group: "device",
    checked: false
  },
  campaign: {
    name: "campaign",
    id: "campaign",
    class: "campaign",
    value: "Campaign",
    data_column: "facebook_cid",
    data_group: "facebook_cid",
    checked: false
  },
  trackerType: {
    name: "tracker_type",
    id: "tracker_type",
    class: "tracker_type",
    value: "Tracker Type",
    data_column: "tracker",
    data_group: "tracker",
    checked: false
  }
}
export { Data, Statistics, Filter, Interval, ExcludeFilter, Calculations, uuid, GloablLeadFilter }