import { Api } from "../api/Api";
import { useState } from 'react';

export const useTemplates = () => {
    const [isLoading, setIsLoading] = useState(false);

    const createTemplates = async (data) => {
        setIsLoading(true);
        const response = await Api.post("api/v1/template", {
            data
        });
        setIsLoading(false);
        return response;
    };

    const listTemplates = async (data) => {
        setIsLoading(true);
        const response = await Api.get("api/v1/template", {
            data
        });
        setIsLoading(false);
        return response;
    };

    const getTemplates = async (projectCode) => {
        setIsLoading(true);
        try {
            const response = await Api.post(`api/v1/template/${projectCode}`);
            setIsLoading(false);
            return response;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };

    const editTemplate = async (rowId) => {
        setIsLoading(true);
        try {
            const response = await Api.get(`api/v1/template/${rowId}/edit`);
            setIsLoading(false);
            return response;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };

    const updateTemplate = async (data) => {
        setIsLoading(true);
        const response = await Api.patch("api/v1/template/edit", {
            data
        });
        setIsLoading(false);
        return response;
    };

    const deleteTemplate = async (rowId) => {
        setIsLoading(true);
        try {
            const response = await Api.delete(`api/v1/template/${rowId}`);
            setIsLoading(false);
            return response;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };

    const getPlaceholders = async (data) => {
        setIsLoading(true);
        const response = await Api.post("api/v1/get-pages-placeholder", {
            data
        });
        setIsLoading(false);
        return response;
    };

    return { createTemplates, getTemplates, listTemplates, editTemplate, updateTemplate, deleteTemplate,getPlaceholders, isLoading };
};