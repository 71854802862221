import { Api } from "../api/Api";
import {useState} from 'react';
 
export const useUserManagement = () => {
  const [isLoading,setIsLoading] = useState(false);
  const createUser = async (data) => {
    setIsLoading(true);
    const response = await Api.post("api/v1/signup", {
        data
    });
    setIsLoading(false);
    return response;
  };

  const listUser = async(data) =>{
    setIsLoading(true);
    const response = await Api.get("api/v1/get-user",{
      data
    });
    setIsLoading(false);
    console.log(response);
    return response;
  }

  const deleteUser = async (data) => {
    setIsLoading(true);
    const response = await Api.delete("api/v1/get-user/delete", {
        data
    });
    setIsLoading(false);
    return response;
  };

  const updateUser = async (data) => {
    setIsLoading(true);
    const response = await Api.patch("api/v1/get-user/update", {
        data
    });
    setIsLoading(false);
    return response;
  };

  return { createUser,listUser,deleteUser,updateUser, isLoading };
};
