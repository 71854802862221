const Bodytype =[
    {label:"Select Body type", value:""},
    {label:"JSON", value:"JSON"},
    {label:"PLAIN", value:"PLAIN"},
    {label:"FORM", value:"FORM"},
];
const MethodType =[
    {label:"Select Method type", value:""},
    {label:"POST", value:"POST"},
    {label:"GET", value:"GET"},
    {label:"PUT", value:"PUT"},
    {label:"PATCH", value:"PATCH"},
    {label:"DELETE", value:"DELETE"},
];

export {Bodytype,MethodType};