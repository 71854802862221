import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useEmailListHook } from '../../../Hooks/useEmailListHook';

const DeletePopupEmail = (props) => {
  const { deleteEmaillist,listEmail} = useEmailListHook();
  const handleClose = () => props.setDelete(false);
  const listEmailList = async () => {
    const response = await listEmail();

    const EmailData = response.data.map((item) => ({
      _id: item._id,
      project_code: item.project_code,
      process_type: item.process_type,
      process_type_id: item.process_type_id,
      email_from: item.data.email_from,
      email_subject: item.data.email_subject,
      email_body: item.data.email_body,
      email_vendor: item.data.email_vendor,
      data: item.data,
    }));

    const keyword = props.filterText.toLowerCase();
 

      const filteredData = EmailData.filter(
        (item) =>
          (item.id && item._id.toString().toLowerCase().includes(keyword)) ||
          (item.project_code &&
            item.project_code.toLowerCase().includes(keyword)) ||
          (item.process_type &&
            item.process_type.toString().toLowerCase().includes(keyword)) ||
          (item.data.email_from &&
            item.data.email_from.toLowerCase().includes(keyword)) ||
          (item.data.email_subject &&
            item.data.email_subject.toLowerCase().includes(keyword)) ||
          (item.data.email_body &&
            item.data.email_body.toLowerCase().includes(keyword)) ||
          (item.data.email_vendor &&
            item.data.email_vendor.toLowerCase().includes(keyword))
      );
  
    props.setFilteredAssignAttributeTwo(filteredData);
    props.setFilterText(keyword);
    props.setFilteredAssignAttribute(EmailData);

  }

  const handleDelete = async () => {
    const id = props.id;
    const response = await deleteEmaillist(id);

    handleClose();
    listEmailList()
    props.setToggleList(!props.toggleList)
  };

  return (
    <div>
      <Modal show={props.deletes} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeletePopupEmail;
