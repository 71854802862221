import axios from "axios";
import {AppAdtopiaToken,
  AppApiUrl
  } from "../Constants/EnvConstants";

require("dotenv").config();

export const Api = axios.create({
  baseURL: AppApiUrl,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + AppAdtopiaToken,
  },
});
export const ApiFormData = axios.create({
  baseURL: AppApiUrl,
  timeout: 30000,
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + AppAdtopiaToken,
  },
});

