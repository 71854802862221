import React from 'react';

const ProjectSelectBox = ({
  className,
  OptionValue,
  onChange,
  name,
  value,
  id,
  error,
  emptyOption
}) => {
  const OptionData = OptionValue.map((options, index) => (
  
    <option value={options.project_code} key={index}>
      {options.project_code}
    </option>
  ));
  return (
    <>
      <select
        className={className}
        name={name}
        error={error}
        onChange={onChange}
        id={id}
        value={value}
      >
        {emptyOption && (
          <option value={emptyOption}>
            {emptyOption}
          </option>
        )}
        {OptionData}
      </select>
      {error && <span className="error-message">{error}</span>}
    </>
  );
};

export default ProjectSelectBox;