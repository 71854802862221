import React from 'react'
import "../../assets/css/loader.scss"
import { loader } from "../../assets/img/loader.png";


const Loader = ({active}) => {
    const activeLoader = (active==true)?"d-block":"d-none";
    return (
        <>
            <div className={`loaders ${activeLoader}`}>
                <div className='loader-container'>
                    <div className='loader' />
                </div>
            </div>
        </>
    )
}

export default Loader

