require("dotenv").config();

const AppApiUrl = process.env.REACT_APP_API_URL;
const AppAdtopiaToken = process.env.REACT_APP_ADTOPIA_TOKEN;
const AwsAccessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const AwsSecretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const AwsRegion = process.env.REACT_APP_AWS_REGION;
const AwsBucket = process.env.REACT_APP_AWS_BUCKET;

export {
    AppApiUrl,
    AppAdtopiaToken,
    AwsAccessKeyId,
    AwsSecretAccessKey,
    AwsRegion,
    AwsBucket
}