import React, { useEffect, useState } from 'react';
import TableHead from '../Utility/TableHead';
import TextField from '../UI/TextField';
import Button from '../UI/Button';
import Loader from '../Utility/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash ,faEye } from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import { ListLeadDatasColumns } from '../../Constants/TableColumns';
import { useLeadData } from '../../Hooks/useLeadDatas';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import LeadDatasDeletePopup from '../Includes/LeadDatas/LeadDatasDeletePopup';
import { useHistory } from 'react-router-dom';
import { useProcessType } from '../../Hooks/useProcessType';
import LeadDatasViewPopup from '../Includes/LeadDatas/LeadDatasViewPopup';

const ListLeadDatas = () => {
  const [toggleList, setToggleList] = useState(true);
  const [data, setData] = useState([]);
  const [processType, setProcessType] = useState([]);
  const [deletes, setDelete] = useState(false);
  const [id, setDeleteId] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [view, setView] = useState(false);
  const [viewData, setViewData] = useState([]);
  const [responseData, setResponseData] = useState([]);

  const [filterText, setFilterText] = useState('');
  const { listLeadData, isLoading } = useLeadData();
  const history = useHistory();
  const { listProcess } = useProcessType();

  const EntityColumnsWithActions = [
    ...ListLeadDatasColumns,
    {
      name: 'Actions',
      cell: (row) => (
        <div>
          <button
            onClick={() => handleView(row._id)}
            style={{ margin: '3px' }}>
            <FontAwesomeIcon icon={faEye} />
          </button>
          <button
            onClick={() => handleDelete(row._id)}
            style={{ /*color: '#ff005d',*/ margin: '3px' }}>
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      ),
    },
  ];
  useEffect(() => {
    fetchCategories();
  }, [toggleList]);
  const handleFilter = (e) => {
    const keyword = e.target.value.toLowerCase();
    const filtered = data.filter(
      (item) =>
        item._id.toString().includes(keyword) ||
        item.uuid.toString().includes(keyword)
    );
    setFilteredData(filtered);
    setFilterText(keyword);
  };

  const fetchCategories = async () => {
    try {
      const response = await listLeadData();
      setResponseData(response.data);
      const fetchedData = response.data.map((item) => ({
        _id: item._id,
        uuid: item.uuid,
      }));

      setData(fetchedData); // Update the state with fetched data
    } catch (error) {
      console.error('Error fetching entities:', error);
    }
  };
  const handleDelete = async (rowId) => {
    setDelete(true);
    setDeleteId(rowId);
  };

  const handleView = async (id) => {
    const getViewData = responseData.find((item) => item._id === id);
    setViewData(getViewData);
    setView(true);
  }
  // const handleEdit = async (rowId) => { 
  //   history.push('/add-rule?id=' + rowId);
  // };
  return (
    <>
      <Loader active={isLoading} />
      <div className='col-lg-12 table-section'>
        <div className='row m-0'>
          <div className='col-lg-12 col-md-12 p-1'>
            <div className='tablesec-bg'>
              <div className='col-lg-12 py-3 px-3'>
                <div className='row'>
                  <div
                    className='col-lg-4'
                    id='search_bar1'>
                    <TableHead title='Lead Data Management' />
                  </div>
                  <div className='col-lg-5'></div>
                  <div className='col-lg-1 text-right'>
                    <a href='#'>
                      <i
                        className='fa-solid fa-rotate px-2 mt-2'
                        aria-hidden='true'
                        data-toggle='tooltip'
                        title='Refresh Page'></i>
                    </a>
                  </div>
                  <div className='col-lg-2 text-right'>
                    <Link to='/lead-datas'>
                      <Button
                        type='button'
                        className='button_a button2'
                        label='Add Lead Data'
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <TextField
                type='text'
                value={filterText}
                onChange={handleFilter}
                placeholder='Search...'
                className='form-control keyword-search'
              />
              <DataTable
                columns={EntityColumnsWithActions}
                data={filterText.length > 0 ? filteredData : data}
                pagination
                paginationPerPage={10}
              />
                 {view && (
                <LeadDatasViewPopup
                setView={setView}
                view={view}
                viewData={viewData}
                />
              )}
              <LeadDatasDeletePopup
                setData={setData}
                setFilteredData={setFilteredData}
                deletes={deletes}
                setDelete={setDelete}
                id={id}
                setToggleList={setToggleList}
                toggleList={toggleList}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListLeadDatas;
