import React, { useEffect, useState } from "react";
import Button from "../../UI/Button";
import TextField from "../../UI/TextField";
import Modal from 'react-bootstrap/Modal';
import "../../../assets/css/popup.scss";
import Loader from "../../Utility/Loader";
import { useUserManagement } from "../../../Hooks/useUserManagement";

const TogglePopup = (props) => {

    const resetForm = () => {
        setFormData({ name: '', email: '', password: '' });
        setErrors({});
    };
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState({ name: '', email: '', password: '' });
    const [errors, setErrors] = useState({});
    const [edit, setEdit] = useState({
        name: '',
        email: '',
    });

    const { createUser, updateUser, isLoading } = useUserManagement();
    console.log(formData);
    // useEffect(() => {
    //     setFormData({
    //         ...formData,
    //         id: props.userID
    //     })
    // }, [props.userID]);

    useEffect(() => {
        if (props.showName && props.showEmail) {
            setEdit({ name: props.showName, email: props.showEmail, id: props.userID });
            setFormData({ name: props.showName, email: props.showEmail, id: props.userID });
            setErrors({});
        }
    }, [props.showName, props.showCode, props.userID]);

    const handleShow = () => {
        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
        props.setShowEdit(false);
        props.setShowName('');
        props.setShowEmail('');
        resetForm();
        setErrors({});
    };

    const handleSubmit = () => {
        handleClose();
        (async () => {
            const response = await createUser(formData);
        })();
        props.setToggleListing(!props.toggleListing);
    }

    const editSubmit = (event) => {
        event.preventDefault();
        (async () => {
            const response = await updateUser(formData);
            handleClose();
            props.setToggleListing(!props.toggleListing);
        })();
    }


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    return (
        <>
            <Loader active={isLoading} />
            <div className="toggle-popup ">
                <Button onClick={handleShow} className="button_a button2 " label="Add User" />
                <Modal show={show || props.showEdit} onHide={handleClose} dialogClassName="toggle-popup" backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>{show && !props.showEdit ? `Add New user` : `Edit user`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div class="col-lg-6">
                                Name
                            </div>
                            <div className="col-lg-8">
                                <TextField
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    onChange={(e) => handleInputChange(e)}
                                    value={formData.name}
                                />
                            </div><br />
                            <div className="col-lg-6">
                                Email
                            </div>
                            <div className="col-lg-8">
                                <TextField
                                    type="text"
                                    className="form-control"
                                    name="email"
                                    onChange={(e) => handleInputChange(e)}
                                    value={formData.email}
                                />
                            </div><br />
                            <div className="col-lg-6">
                                Password
                            </div>
                            <div className="col-lg-8">
                                <TextField
                                    type="text"
                                    className="form-control"
                                    name="password"
                                    onChange={(e) => handleInputChange(e)}
                                    value={formData.password}
                                />
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="col-lg-8">
                            {!props.showEdit ? (<Button className="button_a button2" label="Add" onClick={handleSubmit} />) : (<Button className="button_a button2" label="Edit" onClick={editSubmit} />)}
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}

export default TogglePopup