import React, { useState, useEffect } from "react";
import Button from "../../UI/Button";
import TextField from "../../UI/TextField";
import Modal from 'react-bootstrap/Modal';
import "../../../assets/css/popup.scss";
import { useEntityAttribute } from "../../../Hooks/useEntityAttribute";
import { useProjectCodeInfo } from "../../../Hooks/useProjectCodeInfo";
import ProjectSelectBox from '../../UI/ProjectSelectBox';
import GenerateSlug from "../../Utility/GenerateSlug";

const TogglePopUpAttribute = ({ toggleList, setToggleList, setData, setFilteredData, rowDataForEdit, isEditModalOpen, setIsEditModalOpen, setRowDataForEdit, filterText, setFilterText, setEditAttribute, editAttribute }) => {

  const [showCSV, setShowCSV] = useState(false);
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({ projectCode: '', attributeName: '', slug: '', dynamic_slug: '', is_custom_attribute: 'no', allow_multiple_user: 'no' });
  const [isCustomAttribute, setIsCustomAttribute] = useState(false);
  const [allowMultipleUser, setAllowMultipleUser] = useState(false);
  const [errors, setErrors] = useState({});
  const [checkBoxValue, setCheckBoxValue] = useState(false);
  const [attributeName, setAttributeName] = useState({ finalValue: '', slugValue: '' });
  const [prevValue, setPrevValue] = useState('');
  const { createEntityAttribute, listEntityAttribute, updateEntityAttribute, attributeSlugChange, CSVFileUpload } = useEntityAttribute();
  const { listProjectCode } = useProjectCodeInfo();
  const [projectCode, setProjectCode] = useState();
  const [selectedProjectCode, setSelectedProjectCode] = useState('');
  // creating a state for showing the uploaded CSV files list
  const [selectedFiles, setSelectedFiles] = useState([]);

  useEffect(() => {
    if (rowDataForEdit && rowDataForEdit.project_code) {
      setSelectedProjectCode(rowDataForEdit.project_code);
    }
  }, [rowDataForEdit]);
  useEffect(() => {
    if (formData.projectCode !== selectedProjectCode) {
      setSelectedProjectCode(formData.projectCode);
    }
  }, [formData.projectCode]);

  const handleShow = () => {
    setShow(true);
    resetForm();
  }
  const handleShowCSV = () => {
    setShowCSV(true);
    resetFormcsv();
  }
  const handleFileChange = () => {
    const inputCSVFile = document.getElementById("inputCSVFile");
    const CSVfile = inputCSVFile.files[0];
    setSelectedFiles(CSVfile);
  };
  const resetForm = () => {
    setFormData({
      projectCode: '',
      attributeName: '',
      slug: '',
      dynamic_slug: '',
      is_custom_attribute: 'no',
      allow_multiple_user: 'no'
    });
    setErrors({});
    setIsCustomAttribute(false);
    setAllowMultipleUser(false);
  };
  const resetFormcsv = () => {
    setFormData({
      projectCode: '',
      attributeName: '',
      slug: '',
      dynamic_slug: '',
      is_custom_attribute: 'no',
      allow_multiple_user: 'no'
    });
    setErrors({});
    setIsCustomAttribute(false);
    setAllowMultipleUser(false);
  };

  const handleClose = () => {
    setShow(false);
    formData.projectCode = "";
    formData.attributeName = "";
    formData.slug = "";
    formData.status = "";
    setIsEditModalOpen(false);
    setRowDataForEdit(null);
    resetForm();
  };
  const handleCloseCSV = () => {
    setShowCSV(false);
    setSelectedFiles([]);
    formData.projectCode = "";
    formData.status = "";
    setIsEditModalOpen(false);
    setRowDataForEdit(null);
    resetFormcsv();
  };
  const handleInputChange = async (event) => {
    const { name, value, checked } = event.target;
    console.log("slug change");
    console.log(name);
    setFormData({
      ...formData,

      [name]: value
    });
    if(name === 'slug'){
      console.log("cheked");
      if(formData.is_custom_attribute === 'no'){
        console.log(formData.is_custom_attribute);
        setFormData((formData) => ({
          ...formData,
          dynamic_slug: value + '.value'
        }));
      }else{
        setFormData((formData) => ({
          ...formData,
          dynamic_slug: value
        }));
      }
    }
    if (name === 'projectCode') {
      setErrors({
        ...errors,
        projectCode: ''
      });
    }
    if (name === 'attributeName') {
      setEditAttribute(false);
      setErrors({
        ...errors,
        attributeName: ''
      });
      setPrevValue(value);
    }

    // if (name === 'attributeName') {
    //   if (value.length > 0) {
    //     let check = (checkBoxValue == false) ? 'no' : 'yes';
    //     const response = GenerateSlug(value, check);
    //     console.log(response);
    //     setAttributeName({ finalValue: response.finalValue, slugValue: response.slugValue });
    //     setPrevValue(value);

    // setFormData((formData) => ({
    //   ...formData,
    //   slug: response.slugValue,
    //   dynamic_slug: response.finalValue,
    // }));

    //   }
    // }
  }

  const listEntity = async () => {
    const response = await listEntityAttribute();
    const entityData = response.data.map(item => ({
      _id: item._id,
      project_code: item.project_code,
      attribute_name: item.attribute_name,
      slug: item.slug,
      status: item.status
    }));

    const keyword = filterText.toLowerCase();
    const filteredData = entityData.filter(
      (item) =>
        (item._id && item._id.toString().toLowerCase().includes(keyword)) ||
        (item.project_code && item.project_code.toLowerCase().includes(keyword)) ||
        (item.attribute_name && item.attribute_name.toString().toLowerCase().includes(keyword)) ||
        (item.slug && item.slug.toLowerCase().includes(keyword))
    );

    setFilteredData(filteredData);
    setFilterText(keyword);
    setData(entityData);
  };

  const handleCheckBox = (name, checked) => {
    setEditAttribute(false);
    if (name === 'is_custom_attribute') {
      setIsCustomAttribute(!isCustomAttribute);
      setFormData({
        ...formData,
        [name]: checked ? 'yes' : 'no'
      });
    } else if (name === 'allow_multiple_user') {
      setAllowMultipleUser(!allowMultipleUser);
      setFormData({
        ...formData,
        [name]: checked ? 'yes' : 'no'
      });
    }
  };


  useEffect(() => {
    // if (attributeName && isCustomAttribute) {
    if(!editAttribute){  
      let check = isCustomAttribute ? 'yes' : 'no';
      const attributeValue = formData.attributeName;
      const response = GenerateSlug(attributeValue, check);
      setAttributeName({ finalValue: response.finalValue, slugValue: response.slugValue });
      setFormData((formData) => ({
        ...formData,
        slug: response.slugValue,
        dynamic_slug: response.finalValue,
      }));
    }
    
    // }
  }, [isCustomAttribute, attributeName.finalValue, prevValue])

  useEffect(() => {
    (async () => {
      const response = await listProjectCode();
      const projectData = response.data.map(item => ({
        id: item.id,
        project_name: item.project_name,
        project_code: item.project_code
      }));
      setProjectCode(projectData);
      // setFilteredData(projectData);
    })();
  }, []);

  const handleSubmit = () => {
    const newErrors = {};
    if (formData.projectCode.trim() === '') {
      newErrors.projectCode = 'Project Code is required';
    }
    else if (formData.attributeName.trim() === '') {
      newErrors.attributeName = 'attribute Name is required';
    }
    if(editAttribute){
      formData.slug = attributeName.slugValue;
      formData.dynamic_slug = attributeName.finalValue;
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      (async () => {
        const response = await createEntityAttribute(formData);
        if (response && response.status == 200) {
          handleClose();
          setToggleList(!toggleList);
        }
      })();
      listEntity();
    }
  }
  const handleSubmitCSV = (e) => {
    const newErrors = {};
    if (formData.projectCode.trim() === '') {
      newErrors.projectCode = 'Project Code is required';
    }
    const inputCSVFile = document.getElementById("inputCSVFile");
    const CSVfile = inputCSVFile.files[0];

    const data = new FormData();
    data.append('projectcode', selectedProjectCode)
    data.append('csvFile', CSVfile);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    }
    else {
      (async () => {
        const response = await CSVFileUpload(data);
        if (response && response.status == 200) {
          handleCloseCSV();
          setToggleList(!toggleList);
        }
      })();
      listEntity();
    }
  }

  const handleEditSubmit = () => {

    const newErrors = {};
    if (formData.projectCode.trim() === '') {
      newErrors.projectCode = 'Project Code is required';
    }
    else if (formData.attributeName.trim() === '') {
      newErrors.attributeName = 'attribute Name is required';
    }
    else if (formData.slug.trim() === '') {
      newErrors.slug = 'slug is required';
    }
    formData.slug = formData.slug;
    formData.dynamic_slug = (formData.dynamic_slug) ? formData.dynamic_slug : attributeName.finalValue;
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      (async () => {
        const response = await updateEntityAttribute(formData);
        if (response && response.status == 200) {
          handleClose();
          setToggleList(!toggleList);
        }
      })();
      listEntity();
    }
  }

  useEffect(() => {
    if (isEditModalOpen) {
      setShow(true);
      // If rowDataForEdit exists, populate the form for editing
      if (rowDataForEdit) {
        setFormData({
          id: rowDataForEdit._id || '',
          projectCode: rowDataForEdit.project_code || '',
          attributeName: rowDataForEdit.attribute_name || '',
          slug: rowDataForEdit.slug || '',
          dynamic_slug: rowDataForEdit.dynamic_slug || '',
          status: rowDataForEdit.status || '',
          is_custom_attribute: rowDataForEdit.is_custom_attribute || '',
          allow_multiple_user: rowDataForEdit.allow_multiple_user || '',
        });
        setIsCustomAttribute(rowDataForEdit.is_custom_attribute === 'yes');
        setAllowMultipleUser(rowDataForEdit.allow_multiple_user === 'yes');

      }
    }
  }, [isEditModalOpen, rowDataForEdit]);

  return (
      <>
      <div className="toggle-popup">
      <div className="button-container">
        {/* add a button for CSV upload */}
        <Button onClick={handleShowCSV} type="button" className="button1 csvuploadbtn" label="Upload CSV" />
        <Button onClick={handleShow} type="button" className="button2 csvuploadbtn" label="Add Attribute" />
      </div>
        <Modal show={show} onHide={handleClose} dialogClassName="toggle-popup" backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>{rowDataForEdit ? 'Edit Attribute' : 'Add Attribute'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="col-lg-6">
                Project Code
              </div>
              <div className="col-lg-8">
                <ProjectSelectBox
                  className="form-select"
                  name="projectCode"
                  emptyOption="Select project code"
                  OptionValue={projectCode}
                  value={isEditModalOpen ? selectedProjectCode : formData.projectCode}
                  onChange={handleInputChange}

                />
                {errors.projectCode && (
                  <span className="error-message">{errors.projectCode}</span>
                )}
              </div><br />
              <div>
                <input
                  type="checkbox"
                  name='is_custom_attribute'
                  className="form-check-input"
                  checked={formData.is_custom_attribute === 'yes'}
                  onChange={(e) => handleCheckBox('is_custom_attribute', e.target.checked)}
                />
                {" "} is custom attribute {" "}



                <input
                  type="checkbox"
                  name='allow_multiple_user'
                  className="form-check-input"
                  checked={formData.allow_multiple_user === 'yes'}
                  onChange={(e) => handleCheckBox('allow_multiple_user', e.target.checked)}
                />


                {" "} allow multiple user
              </div>
              <br />
              <div className="col-lg-6">
                Attribute Name
              </div>
              <div className="col-lg-8">
                <TextField
                  type="text"
                  className="form-control"
                  name="attributeName"
                  value={formData.attributeName}
                  onChange={handleInputChange}
                />
                {errors.attributeName && (
                  <span className="error-message">{errors.attributeName}</span>
                )}

              </div>
              <div className="col-lg-6">
                Slug
              </div>
              <div className="col-lg-8">
                <TextField
                  type="text"
                  className="form-control"
                  // readOnly={attributeName.finalValue ? true : false}
                  name="slug"
                  value={formData.slug}
                  onChange={handleInputChange}
                  // readOnly={isCustomAttribute ? false : true}
                />
                {errors.slug && (
                  <span className="error-message">{errors.slug}</span>
                )}
              </div>
              <div className="col-lg-8">
                <TextField
                  type="hidden"
                  className="form-control"
                  readOnly={attributeName ? true : false}
                  name="dynamic_slug"
                  value={formData.dynamic_slug ? formData.dynamic_slug : attributeName.finalValue}
                  onChange={handleInputChange}
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <div className="col-lg-8">
              {rowDataForEdit ?
                <Button className="button_a button2" type="button" label="Update"
                  onClick={handleEditSubmit} />
                :
                <Button className="button_a button2" type="button" label="Add"
                  onClick={handleSubmit} />}
            </div>
          </Modal.Footer>
        </Modal>

        {/* CSV Upload section*/}
        <Modal show={showCSV} onHide={handleCloseCSV} dialogClassName="toggle-popup" backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>{rowDataForEdit ? 'Edit Attribute' : 'Upload CSV'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="col-lg-6">
                Project Code
              </div>
              <div className="col-lg-8">
                <ProjectSelectBox
                  className="form-select"
                  name="projectCode"
                  emptyOption="Select project code"
                  OptionValue={projectCode}
                  value={isEditModalOpen ? selectedProjectCode : formData.projectCode}
                  onChange={handleInputChange}

                />
                {errors.projectCode && (
                  <span className="error-message">{errors.projectCode}</span>
                )}
              </div><br />
              <br />
              <div className="col-lg-8">
                {/* CSV File Upload */}
                <input type="file" accept=".csv" className="form-control" id="inputCSVFile" onChange={handleFileChange}/>
              </div>
              <div className="col-lg-8">
                <TextField
                  type="hidden"
                  className="form-control"
                  readOnly={attributeName ? true : false}
                  name="dynamic_slug"
                  value={formData.dynamic_slug ? formData.dynamic_slug : attributeName.finalValue}
                  onChange={handleInputChange}
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <div className="col-lg-8">
              {rowDataForEdit ?
                <Button className="button_a button2" type="button" label="Edit"
                  onClick={handleEditSubmit} />
                :
                <Button className="button_a button2" type="button" label="Upload Files"
                  onClick={handleSubmitCSV} />}
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}

export default TogglePopUpAttribute;