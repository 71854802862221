import TextField from "../UI/TextField";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Button from "../UI/Button";
import Sendsmswidget from "../ModuleWidgets/Sendsmswidget";
import { useEffect, useState } from "react";
import { ListSmsColumns } from "../../Constants/TableColumns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useSendSms } from "../../Hooks/useSendSms";
import Loader from "../Utility/Loader";
import DeletePopupSms from "../Includes/Sms/DeletePopupSms";
import TableHead from "../Utility/TableHead";
const SmsDataList = () => {
  const [view, setView] = useState({
    viewSms: false,
    updateSms: false,
    deleteId: false,
    id: "",
  });
  const [filteredData, setFilteredData] = useState([]);
  const [filteredDataTwo, setFilteredDataTwo] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [formData, setFormData] = useState({
    process_type_id: "",
    results: [
      {
        id: "",
        process_type: "",
        process_type_id: "",
        project_code: "",
        sms_from_number: "",
        sms_content: "",
        sms_vendor: "",
        url: "",
        method: "",
        body: "",
      },
    ],
    props_details: {},
  });

  const [selectedRows, setSelectedRows] = useState([]);
  const [id, setDeleteId] = useState("");
  const [deletes, setDelete] = useState(false);
  const { listSendSms, deleteSms, isLoading } = useSendSms();
  const [selectAll, setSelectAll] = useState(false); // New state to track select all 
  const [checkboxClicked, setCheckboxClicked] = useState(false);
  const [showTrashButton, setShowTrashButton] = useState(false);
  const handleSelectAll = () => {
    const allRowIds = filteredData.map((row) => row._id);
    const updatedSelectedRows = selectAll ? [] : allRowIds;
    setSelectedRows(updatedSelectedRows);
    setSelectAll(!selectAll);
    setShowTrashButton(updatedSelectedRows.length > 0);
    console.log(allRowIds,"allRowIdsSMS")
  };



  const SelectHeader = ({ selectAll, handleSelectAll }) => (
    <>
      <input
        style={{ marginRight: "5px" }}
        type="checkbox"
        checked={selectAll}
        onChange={handleSelectAll}
        id="select_header"
      />
      <label htmlFor="select_header">Select</label>
    </>
  );  

  

  const handleView = () => {
    setView({ viewSms: true, deleteId: false });
  };
  const EntityColumnsWithActions = [
    {
      name: <SelectHeader selectAll={selectAll} handleSelectAll={handleSelectAll}/>,
      cell: (row) => (
        <input
              type="checkbox"
              checked={selectAll || selectedRows.includes(row._id)}
              onChange={() => handleCheckboxChange(row._id)}
          />
      ),
      header: (row) => (
          <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
          />
      )
  },
    ...ListSmsColumns,
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <button
            onClick={() => handleEdit(row.process_type_id)}
            style={{ margin: "3px" }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
          <button
            onClick={() => handleDelete(row._id)}
            style={{ margin: "3px" }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      ),
    },
  ];

  const handleEdit = (id) => {
    setFormData({ ...formData, process_type_id: id });
    setView({ viewSms: true, updateSms: true, deleteId: false });
  };

  const handleDelete = async (id) => {
    setView({ deleteId: true, id: id });
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await listSendSms();
      if (response.status === 200 && response.data) {
        const result = response.data.map((item, index) => ({
          _id: item._id,
          process_type: item.process_type,
          project_code: item.project_code,
          process_type_id: item.process_type_id,
          sms_from_number: item.data.sms_from_number,
          sms_content: item.data.sms_content,
          sms_vendor: item.data.sms_vendor,
          url: item.data.url,
          method: item.data.method,
          body: item.data.body,
        }));
        setFormData({ results: result });
        setFilteredData(result);
      }
    };
    fetchData();
  }, [view.deleteId]);

  const handleFilter = (e) => {
    const keyword = e.target.value.toLowerCase();
    const filtered = formData.results.filter(
      (item) =>
        (item.id && item._id.toString().toLowerCase().includes(keyword)) ||
        (item.project_code &&
          item.project_code.toLowerCase().includes(keyword)) ||
        (item.process_type &&
          item.process_type.toString().toLowerCase().includes(keyword)) ||
        (item.sms_vendor && item.sms_vendor.toLowerCase().includes(keyword))
    );
    setFilteredData(filtered);
    setFilteredDataTwo(filtered);
    setFilterValue(keyword);
  };

  const handleCheckboxChange = (rowId) => {
    const updatedSelectedRows = selectedRows.includes(rowId)
      ? selectedRows.filter((id) => id !== rowId)
      : [...selectedRows, rowId];
    setSelectedRows(updatedSelectedRows);
  
    // Check if any checkbox is selected, including the "Select All" checkbox
    const isAnyCheckboxSelected = updatedSelectedRows.length > 0 || selectAll;
    setShowTrashButton(isAnyCheckboxSelected);
  };

  const handleMultipleDelete = async () => {
    setDelete(true);
    setDeleteId(selectedRows);
  };



  return (
    <>
      <Loader active={isLoading} />
      <div className="col-lg-12 table-section">
        <div className="row m-0">
          <div className="col-lg-12 col-md-12 p-1">
            <div className="tablesec-bg">
              <div className="col-lg-12 py-3 px-3">
                <div className="row">
                  <div className="col-lg-4" id="search_bar1"></div>
                  <TableHead title="SMS Management" />
                  <div className="col-lg-5"></div>
                  <div className="col-lg-1 text-right">
                    <a href="#">
                      <i
                        className="fa-solid fa-rotate px-2 mt-2"
                        aria-hidden="true"
                        data-toggle="tooltip"
                        title="Refresh Page"
                      ></i>
                    </a>
                  </div>
                  <div className="col-lg-2 text-right">
                    <Button
                      type="button"
                      onClick={handleView}
                      className="button_a button2"
                      label="Add SMS Details"
                    />
                    <Sendsmswidget
                      view={view.viewSms}
                      setView={setView}
                      process_type_id={formData.process_type_id}
                      updateSms={view.updateSms}
                      props_details={formData.props_details}
                      setFormData={setFormData}
                      formData={formData}
                    />
                  </div>
                </div>
              </div>
              <TextField
                type="text"
                value={filterValue}
                onChange={(e) => handleFilter(e)}
                placeholder="Search..."
                className="form-control keyword-search"
              />
                  <div style={{ float: "right" }}>
                    {showTrashButton && (
                      <button
                        style={{ fontSize: "2 rem", margin: "10px",borderWidth:"0px"}}
                        onClick={handleMultipleDelete}
                        title="Delete Selected"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    )}
                  </div>
   
              <DataTable
                columns={EntityColumnsWithActions}
                data={filterValue.length > 0 ? filteredDataTwo : filteredData}
                pagination
                paginationPerPage={10}
              />
              <DeletePopupSms
                setView={setView}
                setFilteredData={setFilteredData}
                setFilteredDataTwo={setFilteredDataTwo}
                setFilterValue={setFilterValue}
                filterValue={filterValue}
                id={id}
                deletes={deletes}
                setDelete={setDelete}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmsDataList;
