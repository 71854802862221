import React, { useEffect, useState } from "react";
import TableHead from "../Utility/TableHead";
import TextField from "../UI/TextField";
import DataTable from "react-data-table-component";
import { ListRulecolumns } from "../../Constants/TableColumns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useRule } from "../../Hooks/useRule";
import Button from "../UI/Button";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ListDeletePopup from "../Includes/ListRule/ListDeletePopup";
import { useHistory } from "react-router-dom";
import { useProcessType } from "../../Hooks/useProcessType";
import Loader from "../Utility/Loader";

const ListRule = () => {
  const [toggleList, setToggleList] = useState(true);
  const [data, setData] = useState([]);
  const [processType, setProcessType] = useState([]);
  const [deletes, setDelete] = useState(false);
  const [id, setDeleteId] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const { listrules, isLoading } = useRule();
  const history = useHistory();
  const { listProcess } = useProcessType();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false); // New state to track select all 
  const [checkboxClicked, setCheckboxClicked] = useState(false);
  const [showTrashButton, setShowTrashButton] = useState(false);
  const handleSelectAll = () => {
    const allRowIds = data.map((row) => row._id);
    const updatedSelectedRows = selectAll ? [] : allRowIds;
    setSelectedRows(updatedSelectedRows);
    setSelectAll(!selectAll);
    setShowTrashButton(updatedSelectedRows.length > 0);
  };


  const SelectHeader = ({ selectAll, handleSelectAll }) => (
    <>
      <input
        style={{ marginRight: "5px" }}
        type="checkbox"
        checked={selectAll}
        onChange={handleSelectAll}
        id="select_header"
      />
      <label htmlFor="select_header">Select</label>
    </>
  );  

  const EntityColumnsWithActions = [
    {
      name: <SelectHeader selectAll={selectAll} handleSelectAll={handleSelectAll}/>,
      cell: (row) => (
        <input
              type="checkbox"
              checked={selectAll || selectedRows.includes(row._id)}
              onChange={() => handleCheckboxChange(row._id)}
          />
      ),
      header: (row) => (
          <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
          />
      )
  },
    ...ListRulecolumns,
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <button
            onClick={() => handleEdit(row._id)}
            style={{ /*color: '#008CBA',*/ margin: "3px" }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
          <button
            onClick={() => handleDelete(row._id)}
            style={{ /*color: '#ff005d',*/ margin: "3px" }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      ),
    },
  ];
  useEffect(() => {
    fetchCategories();
  }, [toggleList]);
  const handleFilter = (e) => {
    const keyword = e.target.value.toLowerCase();
    const filtered = data.filter(
      (item) =>
        (item._id && item._id.toString().includes(keyword)) ||
        (item.project_code &&
          item.project_code.toLowerCase().includes(keyword)) ||
        (item.process_type &&
          item.process_type.toString().toLowerCase().includes(keyword))
    );
    setFilteredData(filtered);
    setFilterText(keyword);
  };

  const fetchCategories = async () => {
    try {
      const response = await listrules();
      const response1 = await listProcess();

      const fetchedData = response.data.map((item) => ({
        _id: item._id,
        project_code: item.project_code,
        process_type: item.process_type,
      }));
      const fetchedData1 = response1.data.map((item) => ({
        id: item._id,
        process_type: item.name,
      }));

      // Create a mapping from id to name in fetchedData1 for quick lookups
      const idToNameMap = new Map(
        fetchedData1.map((item) => [item.id, item.process_type])
      );

      // Update the process_type in fetchedData based on the id-to-name mapping
      const updatedFetchedData = fetchedData.map((item) => ({
        ...item,
        process_type: idToNameMap.get(item._id) || item.process_type,
      }));

      setData(updatedFetchedData); // Update the state with fetched data
    } catch (error) {
      console.error("Error fetching entities:", error);
    }
  };
  const handleDelete = async (rowId) => {
    setDelete(true);
    setDeleteId(rowId);
  };
  const handleEdit = async (rowId) => {
    history.push("/add-rule?id=" + rowId);
  };

  const handleCheckboxChange = (rowId) => {
    const updatedSelectedRows = selectedRows.includes(rowId)
      ? selectedRows.filter((id) => id !== rowId)
      : [...selectedRows, rowId];
    setSelectedRows(updatedSelectedRows);
  
    // Check if any checkbox is selected, including the "Select All" checkbox
    const isAnyCheckboxSelected = updatedSelectedRows.length > 0 || selectAll;
    setShowTrashButton(isAnyCheckboxSelected);
  };

  const handleMultipleDelete = async () => {
    setDelete(true);
    setDeleteId(selectedRows);
  };


  return (
    <>
      <Loader active={isLoading} />
      <div className="col-lg-12 table-section">
        <div className="row m-0">
          <div className="col-lg-12 col-md-12 p-1">
            <div className="tablesec-bg">
              <div className="col-lg-12 py-3 px-3">
                <div className="row">
                  <div className="col-lg-4" id="search_bar1">
                    <TableHead title="Rule Management" />
                  </div>
                  <div className="col-lg-5"></div>
                  <div className="col-lg-1 text-right">
                    <a href="#">
                      <i
                        className="fa-solid fa-rotate px-2 mt-2"
                        aria-hidden="true"
                        data-toggle="tooltip"
                        title="Refresh Page"
                      ></i>
                    </a>
                  </div>
                  <div className="col-lg-2 text-right">
                    <Link to="/add-rule">
                      <Button
                        type="button"
                        className="button_a button2"
                        label="Add Rule"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <TextField
                type="text"
                value={filterText}
                onChange={handleFilter}
                placeholder="Search..."
                className="form-control keyword-search"
              />
              <div style={{ float: "right" }}>
                {showTrashButton && (
                  <button
                    style={{ fontSize: "2 rem", margin: "10px", borderWidth: "0px" }}
                    onClick={handleMultipleDelete}
                    title="Delete Selected"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                )}
              </div>

              <DataTable
                columns={EntityColumnsWithActions}
                data={filterText.length > 0 ? filteredData : data}
                pagination
                paginationPerPage={10}
              />
              <ListDeletePopup
                setData={setData}
                setFilteredData={setFilteredData}
                deletes={deletes}
                setDelete={setDelete}
                id={id}
                setToggleList={setToggleList}
                toggleList={toggleList}
                setFilterText={setFilterText}
                filterText={filterText}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListRule;
