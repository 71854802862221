import { Api } from '../api/Api';
import { useState } from 'react';

export const useLeadData = () => {
  const [isLoading, setIsLoading] = useState(false);

  // const createLeadData = async (data) => {
  //     setIsLoading(true);
  //     const response = await Api.post("api/v1/rule", {
  //         data
  //     });
  //     setIsLoading(false);
  //     return response;
  // };

  const listLeadData = async (data) => {
    setIsLoading(true);
    // const response = await Api.get("/api/v1/rule", {
    //     data
    // });
    const response = await Api.get('/api/v1/lead-data/list');
    setIsLoading(false);
    return response;
  };

  const deleteLeadData = async (id) => {
    setIsLoading(true);
    try {
      const response = await Api.post('api/v1/lead-data/destroy', {
        id:id,
      });

      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  // const editRule = async (rowId) => {
  //   setIsLoading(true);
  //   try {
  //     const response = await Api.get(`api/v1/rule/${rowId}/edit`);
  //     setIsLoading(false);
  //     return response;
  //   } catch (error) {
  //     setIsLoading(false);
  //     throw error;
  //   }
  // };

  // const updateRule = async (data, id) => {
  //   setIsLoading(true);
  //   try {
  //     const response = await Api.patch(`api/v1/rule/${id}`, {
  //       data,
  //     });
  //     setIsLoading(false);
  //     return response;
  //   } catch (error) {
  //     setIsLoading(false);
  //     throw error;
  //   }
  // };

  return { listLeadData, deleteLeadData, isLoading };
};
