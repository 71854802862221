import React, { useEffect, useState } from "react";
import Button from "../../UI/Button";
import TextField from "../../UI/TextField";
import Modal from "react-bootstrap/Modal";
import "../../../assets/css/popup.scss";
import { useModuleInfo } from "../../../Hooks/useModuleInfo";
import Loader from "../../Utility/Loader";
import { Status } from "../../../Constants/Status";
import { ModuleAllowedIn } from "../../../Constants/ModuleAllowedIn";
import GenerateSlug from "../../Utility/GenerateSlug";

const TogglePopup = (props) => {
  const resetForm = () => {
    setFormData({
      module_name: "",
      status: "Active",
      moduleID: "",
      slug: "",
      widgets: "",
      allowed_in: [],
    });
    setErrors({});
  };
  const [edit, setEdit] = useState({
    module_name: "",
    status: "Active",
    slug: "",
    widgets: "",
    allowed_in: [],
  });
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    props.setShowEdit(false);
    props.setshowCode("");
    props.setShowName("");
    props.setShowSlug("");
    props.setShowWidgets("");
    props.setshowAllowedIn("");
    resetForm();
  };
  const handleShow = () => {
    setShow(true);
    resetForm();
  };

  useEffect(() => {
    if (
      props.showName &&
      props.showCode &&
      props.showSlug &&
      props.showAllowedIn
    ) {
      setEdit({
        module_name: props.showName,
        status: props.showCode === "INACTIVE" ? "Inactive" : "Active",
        slug: props.showSlug,
        widgets: props.showWidgets,
        allowed_in: props.showAllowedIn,
      });
      setFormData((prevState) => ({
        ...prevState,
        module_name: props.showName,
        status: props.showCode === "INACTIVE" ? "Inactive" : "Active",
        slug: props.showSlug,
        widgets: props.showWidgets,
        allowed_in:
          typeof props.showAllowedIn === "string"
            ? props.showAllowedIn.split(",").map((item) => item.trim())
            : props.showAllowedIn,
      }));
      setErrors({});
    }
  }, [props.showName, props.showCode, props.showSlug, props.showAllowedIn]);

  useEffect(() => {
    let module_details =
      props.showName && props.showCode && props.showSlug && props.showAllowedIn
        ? {
            module_name: props.showName,
            status: props.showCode === "INACTIVE" ? "Inactive" : "Active",
            slug: props.showSlug,
            widgets: props.showWidgets,
            allowed_in: props.showAllowedIn,
          }
        : "";
    setEdit(module_details);
  }, [props.showName, props.showCode, props.showSlug, props.showAllowedIn]);

  const [formData, setFormData] = useState({
    module_name: "",
    status: "Active",
    slug: "",
    widgets: "",
    moduleID: "",
    allowed_in: [],
  });

  const [errors, setErrors] = useState({});
  const { createModule, updateModule,listModuleCode, isLoading } = useModuleInfo();
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    //Generate slug from module name
    const response = GenerateSlug(value);
    setFormData({
      ...formData,
      [name]: value,
      slug:response.slugValue
    });
  };

  const listModule = async () => {
    const response = await listModuleCode();
    const listData = response.data.map(item => ({
      id: item._id,
      module_name: item.module_name,
      status: item.status === 1 ? "ACTIVE" : "INACTIVE",
      slug: item.slug,
      widgets: item.widgets,
      allowed_in:
        Array.isArray(item.allowed_in) && item.allowed_in.length >= 2
          ? item.allowed_in.join(" , ")
          : item.allowed_in || [],
    }));
  
    const keyword = props.filterText.toLowerCase();
    const filteredData = listData.filter(
      (item) =>
      item.id.toString().toLowerCase().includes(keyword) ||
      item.module_name.toLowerCase().includes(keyword) ||
      item.status.toString().toLowerCase().includes(keyword) ||
      item.slug.toString().toLowerCase().includes(keyword) ||
      item.allowed_in.toString().toLowerCase().includes(keyword)
    );
  
    props.setFilteredData(filteredData);
    props.setFilterText(keyword);
    props.setData(listData);
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    let updatedAllowedIn = [...formData.allowed_in];

    if (checked) {
      updatedAllowedIn.push(value);
    } else {
      updatedAllowedIn = updatedAllowedIn.filter((item) => item !== value);
    }

    setFormData({
      ...formData,
      allowed_in: updatedAllowedIn,
    });
  };

  const handleSubmit = () => {
    const newErrors = {};
    if (formData.module_name.trim() === "") {
      newErrors.module_name = "Module Name is required";
    } else if (formData.slug.trim() === "") {
      newErrors.slug = "slug is required";
    }

    if (edit.status && formData.status.trim() === "") {
      delete newErrors.module_name;
      delete newErrors.slug;
      delete newErrors.widgets;
      delete newErrors.allowed_in;
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      (async () => {
        handleClose();
        const response = await createModule(formData);
        props.setToggleListing(!props.toggleListing);
      })();
      listModule();
    }
  };

  const EditSubmit = (event) => {
    event.preventDefault();
    const updatedFormData = {
      ...formData,
      moduleID: props.moduleID,
    };
    const newErrors = {};

    if (updatedFormData.module_name.trim() === "") {
      newErrors.module_name = "Module Name is required";
    } else if (updatedFormData.slug.trim() === "") {
      newErrors.slug = "slug is required";
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      (async () => {
        const response = await updateModule(updatedFormData);
        handleClose();
        props.setToggleListing(!props.toggleListing);
      })();
      listModule();
    }
  };

  const statusOptions = Status.map((item) => ({
    label: item.name,
    value: item.value,
  }));

  const handleStatusChange = (selectedStatus) => {
    setFormData({
      ...formData,
      status: selectedStatus,
    });
  };

  const handleSlugChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleWidgetsChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      <Loader active={isLoading} />
      <div className="toggle-popup">
        <Button
          onClick={handleShow}
          className="button_a button2"
          label="Add Module"
        />
        <Modal
          show={show || props.showEdit}
          onHide={handleClose}
          dialogClassName="toggle-popup"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {show && !props.showEdit ? `Add Module` : `Edit Module`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="col-lg-6">Module Name</div>
              <div className="col-lg-8">
                <TextField
                  type="text"
                  className="form-control"
                  name="module_name"
                  value={formData.module_name}
                  onChange={(e) => handleInputChange(e)}
                />
                {errors.module_name && (
                  <span className="error-message">{errors.module_name}</span>
                )}
              </div>
              <br />
              <div className="col-lg-6">status</div>
              <div className="col-lg-8">
                <select
                  className="form-control"
                  name="status"
                  value={formData.status}
                  onChange={(e) => handleStatusChange(e.target.value)}
                >
                  {statusOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {errors.status && (
                  <span className="error-message">{errors.status}</span>
                )}
              </div>
              <br />
              <div className="col-lg-6">slug</div>
              <div className="col-lg-8">
                <TextField
                  type="text"
                  className="form-control"
                  name="slug"
                  value={formData.slug}
                  onChange={(e) => handleSlugChange(e)}
                />
                {errors.slug && (
                  <span className="error-message">{errors.slug}</span>
                )}
              </div>
              <br />
              <div className="col-lg-6">widgets</div>
              <div className="col-lg-8">
                <TextField
                  type="text"
                  className="form-control"
                  name="widgets"
                  value={formData.widgets}
                  onChange={(e) => handleWidgetsChange(e)}
                />
                {errors.widgets && (
                  <span className="error-message">{errors.widgets}</span>
                )}
              </div>
              <br />
              <div className="col-lg-6">Module Allowed In:</div>
              <div className="col-lg-8 checkbox-container">
                {ModuleAllowedIn.map((option) => (
                  <div key={option.value} className="checkbox-item">
                    <input
                      type="checkbox"
                      name="allowed_in"
                      value={option.value}
                      checked={formData.allowed_in.includes(option.value)}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor={option.value}>{option.name}</label>
                  </div>
                ))}
                {errors.allowed_in && (
                  <span className="error-message">{errors.allowed_in}</span>
                )}
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <div className="col-lg-8">
              {!props.showEdit ? (
                <Button
                  className="button_a button2"
                  label="Add"
                  onClick={handleSubmit}
                />
              ) : (
                <Button
                  className="button_a button2"
                  label="Update"
                  onClick={EditSubmit}
                />
              )}
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default TogglePopup;
