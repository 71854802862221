import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useTemplates } from "../../../Hooks/useTemplates";
import Loader from "../../Utility/Loader";
import { useMultipleDelete } from "../../../Hooks/useMultipleDelete";

const DeletePopup = (props) => {
  const { deleteTemplate, listTemplates, isLoading } = useTemplates();
  const handleClose = () => props.setDelete(false);
  const { deleteMultipleItems } = useMultipleDelete();

  const listProject = async () => {
    const response = await listTemplates();
    const projectData = response.data.map((item) => ({
      id: item._id,
      page_type: item.page_type,
      project_code: item.project_code,
      template_name: item.template_details.name
    }));

    const keyword = props.filterText.toLowerCase();
    const filteredData = projectData.filter(
      (item) =>
        item.id.toString().toLowerCase().includes(keyword) ||
        item.project_name.toLowerCase().includes(keyword) ||
        item.project_code.toString().toLowerCase().includes(keyword)
    );

    props.setFilteredData(filteredData);
    props.setFilterText(keyword);
    props.setData(projectData);
  };

  const handleDelete = async () => {
    const template_id = props.id;
    if(props.multipleValue == "multiple"){
      const response = await deleteMultipleItems(template_id,'Template');
      props.setMultipleValue('');
    }else{
      const response = await deleteTemplate(template_id);
    }
    handleClose();
    listProject();
    props.setToggleListing(!props.toggleListing);
  };
  return (
    <div>
      <Loader active={isLoading} />
      <Modal show={props.deletes} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default DeletePopup;
