import React from "react";
const TextField = ({ type, className, name, onChange, placeholder, value, error,readOnly, key }) => {
  return (
    <div>
      <input type={type} value={value} className={className} name={name} onChange={onChange} placeholder={placeholder} readOnly={readOnly}/>
      {error && (
        <span className="error-message">{error}</span>
      )}
    </div>
  )
}
export default TextField;