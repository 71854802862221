import React from "react";

const Footer = () => {
    return (
      <footer className="text-center">
         <p className="style_p"></p>
      </footer>
    )
}

export default Footer;