import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useProjectCodeInfo } from "../../../Hooks/useProjectCodeInfo";
import Loader from "../../Utility/Loader";
import { useMultipleDelete } from "../../../Hooks/useMultipleDelete";

const DeletePopup = (props) => {
  const { deleteProjectCode, isLoading } = useProjectCodeInfo();
  const { listProjectCode } = useProjectCodeInfo();
  const handleClose = () => props.setDelete(false);
  const { deleteMultipleItems } = useMultipleDelete();

  const listProject = async () => {
    const response = await listProjectCode();
    const projectData = response.data.map((item) => ({
      id: item._id,
      project_name: item.project_name,
      project_code: item.project_code,
    }));

    const keyword = props.filterText.toLowerCase();
    const filteredData = projectData.filter(
      (item) =>
        item.id.toString().toLowerCase().includes(keyword) ||
        item.project_name.toLowerCase().includes(keyword) ||
        item.project_code.toString().toLowerCase().includes(keyword)
    );

    props.setFilteredData(filteredData);
    props.setFilterText(keyword);
    props.setData(projectData);
  };

  const handleDelete = async () => {
    const data = {
      data: {
        projectID: props.projectID,
      },
    };
    if (props.multipleValue == "multiple") {
      const response = await deleteMultipleItems(props.id, "ProjectCode");
      props.setMultipleValue("");
    } else {
      const response = await deleteProjectCode(data);
    }

    handleClose();
    listProject();
    props.setToggleListing(!props.toggleListing);
  };
  return (
    <div>
      <Loader active={isLoading} />
      <Modal show={props.deletes} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default DeletePopup;
