import { Api,ApiFormData } from "../api/Api";
import { useState } from 'react';

export const useEntityAttribute = () => {
    const [isLoading, setIsLoading] = useState(false);
    const createEntityAttribute = async (data) => {
        setIsLoading(true);
        const response = await Api.post("api/v1/entity-attribute", {
            data
        });
        setIsLoading(false);
        return response;
    };

    const listEntityAttribute = async (data) => {
        setIsLoading(true);
        const response = await Api.get("api/v1/entity-attribute", {
            data
        });
        setIsLoading(false);
        return response;
    };

    const deleteEntityAttribute = async (rowId) => {
        setIsLoading(true);
        try {
            const response = await Api.delete(`api/v1/entity-attribute/${rowId}`);

            setIsLoading(false);
            return response;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };

    const editEntityAttribute = async (rowId) => {
        setIsLoading(true);
        try {
            const response = await Api.get(`api/v1/entity-attribute/${rowId}/edit`);
            setIsLoading(false);
            return response;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };

    const updateEntityAttribute = async (data) => {
        setIsLoading(true);
        try {
            const response = await Api.patch(`api/v1/entity-attribute/${data.id}`, {
                data
            });
            setIsLoading(false);
            return response;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };

    // CSV file upload API Call
    const CSVFileUpload = async (data) => {
        setIsLoading(true);
        const response = await ApiFormData.post("api/v1/csv-file-upload", data);
        setIsLoading(false);
        return response;
    };
    //Datalayer insert
    const CreateDataLayer = async (data) => {
        setIsLoading(true);
        const response = await Api.post("api/v1/gtm-store", {
            data
        });
        setIsLoading(false);
        return response;
    };

    const Deletedatalayer = async (data) => {
        setIsLoading(true);
        const response = await Api.post("api/v1/gtm-delete", {
            data
        });
        setIsLoading(false);
        return response;
    };

 

    return { createEntityAttribute, listEntityAttribute, deleteEntityAttribute, editEntityAttribute, updateEntityAttribute, CSVFileUpload,CreateDataLayer,Deletedatalayer, isLoading };
};
