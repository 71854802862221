import { Api } from "../api/Api";
import { useState } from "react";

export const useFollowupRule = () =>{
  const [isLoading, setIsLoading] = useState(false);
  const createFollowupRule = async (data) => {
        setIsLoading(true);
        const response = await Api.post("api/v1/followup-rule", {
          data,
        });
        setIsLoading(false);
        return response;
  };
  const listFollowupRules = async (data) => {
      setIsLoading(true);
      const response = await Api.get("/api/v1/followup-rule", {
        data,
      });
      setIsLoading(false);
      return response;
  };
  const editFollowupRule = async (rowId) => {
    setIsLoading(true);
    try {
      const response = await Api.get(`api/v1/followup-rule/${rowId}/edit`);
      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };
  const updateFollowupRule = async (data, id) => {
    setIsLoading(true);
    try {
      const response = await Api.patch(`api/v1/followup-rule/${id}`, {
        data,
      });
      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };
  const deleteFollowupRule = async (rowId) => {
    setIsLoading(true);
    try {
      const response = await Api.delete(`api/v1/followup-rule/${rowId}`);
      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };
  return {
    createFollowupRule,
    listFollowupRules,
    editFollowupRule,
    updateFollowupRule,
    deleteFollowupRule,
    isLoading
  };
}