import React, { useEffect, useState } from "react";
import HttpClientsWidgets from "./HttpClientsWidgets";
import { Button } from "react-bootstrap";
import AssignAttributeWidgets from "./AssignAttributeWidgets";
import SelectBox from "../UI/SelectBox";
import { useProcessType } from "../../Hooks/useProcessType";
import { useProjectCodeInfo } from "../../Hooks/useProjectCodeInfo";
import Sendsmswidget from "./Sendsmswidget";
import Sendemailwidget from "./Sendemailwidget";
import AudienceSelection from "./AudienceSelection";

const TestModuleWidgets = () => {
  const { listProjectCode } = useProjectCodeInfo();
  const { listProcess } = useProcessType();
  const [selectedFormData, setSelectedFormData] = useState({
    project_code: "",
    process_type_name: "",
    process_type_id: "",
  });
  const [
    valuesCorrespondingToProjectCode,
    setValuesCorrespondingToProjectCode,
  ] = useState({
    processType: [],
  });

  const [view, setView] = useState({
    viewHttp: false,
    viewSms: false,
    viewAssign: false,
    viewEmail: false,
    viewAudienceSelection : false,
    updateSms: false

  });
  const handlepopupHttp = () => setView({ viewHttp: true });
  const handlepopup = () => setView({ viewSms: true,updateSms : true });
  const handlepopupEmail = () => setView({ viewEmail: true });
  const handlepopupAssign = () => setView({ viewAssign: true });
  const handlepopupAudienceSelection = () => setView({ viewAudienceSelection: true });
  const [projectCode, setProjectCode] = useState([]);
  const [processType, setProcessType] = useState([]);
 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response2 = await listProjectCode();
        const response3 = await listProcess();

        const formattedProjectCode = response2.data.map((item) => ({
          label: item.project_code,
          value: item.project_code,
        }));
        const formattedProcessType = response3.data.map((item) => ({
          label: item.name,
          value: item._id,
          project_code: item.project_code,
        }));
        setProjectCode(formattedProjectCode);
        setProcessType(formattedProcessType);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleInputChange = (event, sectionIndex) => {
    console.log(projectCode,'projectRCode');
    const { name, value, options } = event.target;
    switch (name) {
      case "project_code":
        setSelectedFormData((prevData) => {
          return {
            ...prevData,
            [name]: value,
          };
        });
        break;
       
      case "process_type":
        const selectedOption = options[options.selectedIndex];
        const label = selectedOption.label;

        setSelectedFormData((prevData) => {
          return {
            ...prevData,
            [`${name}_id`]: value,
            [`${name}_name`]: label,
          };
        });
        break;
    }
  };

  useEffect(() => {
    // Define a function to filter values based on project code
    const filterValues = () => {
      const matchingProcessTypes = processType.filter(
        (item) => item.project_code === selectedFormData.project_code
      );

      // Combine matching attributes and process types into label value format
      const formattedValues = {
        processType: matchingProcessTypes.map((type) => ({
          label: type.label,
          value: type.value,
        })),
      };

      setValuesCorrespondingToProjectCode(formattedValues);
    };

    filterValues();
  }, [selectedFormData.project_code, processType]);

  return (
    <>
      <div className="AddRule table-section container-fluid mb-5">
        <div className="row tablesec-bg py-3 px-3">
          <div className="col-sm-12 col-md-6">
            <div className="row justify-content-between my-2">
              <div className="col-md-12 col-lg-3">
                <label className="col-form-label">Project</label>
              </div>
              <div className="col-md-12 col-lg-9">
                <SelectBox
                  className="form-select"
                  name="project_code"
                  id="project_code"
                  OptionValue={projectCode}
                  value={selectedFormData.project_code}
                  onChange={handleInputChange}
                  //   error={errors.project_code}
                  emptyOption="Select project code"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="row my-2">
              <div className="col-md-12 col-lg-3">
                <label className="col-form-label">Process Type</label>
              </div>
              <div className="col-md-12 col-lg-9">
                <SelectBox
                  className="form-select"
                  name="process_type"
                  id="process_type"
                  OptionValue={valuesCorrespondingToProjectCode.processType}
                  value={selectedFormData.process_type_id}
                  onChange={handleInputChange}
                  //   error={errors.process_type}
                  emptyOption="Select process type"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2>TestModuleWidgets</h2>
      <Button variant="primary" className="m-2" onClick={handlepopupHttp}>
        Http Click
      </Button>
      <HttpClientsWidgets
        setView={setView}
        view={view.viewHttp}
        project_code={selectedFormData.project_code}
        process_type={selectedFormData.process_type_name}
        process_type_id={selectedFormData.process_type_id}
      />

      <Button variant="primary" onClick={handlepopupAssign}>
        Assign Click
      </Button>
      <AssignAttributeWidgets
        project_code={selectedFormData.project_code}
        process_type={selectedFormData.process_type_name}
        process_type_id={selectedFormData.process_type_id}
        setView={setView}
        view={view.viewAssign}
      />

      <Button variant="primary" onClick={handlepopupAudienceSelection}>
        Audience Selection
      </Button>

      <AudienceSelection
        project_code={selectedFormData.project_code}
        process_type={selectedFormData.process_type_name}
        process_type_id={selectedFormData.process_type_id}
        setView={setView}
        view={view.viewAudienceSelection}
      />
      <h2>Send Sms Widget</h2>
      <Button variant="danger" onClick={handlepopup}>
        Click
      </Button>
      <Sendsmswidget
        setView={setView}
        view={view.viewSms}
        project_id={selectedFormData.project_code}
        process_type_id={selectedFormData.process_type_id}
        process_type={selectedFormData.process_type_name}
        updateSms={view.updateSms}
      />

<h2>Send Email Widget</h2>
      <Button variant="danger" onClick={handlepopupEmail}>
        Click
      </Button>
      <Sendemailwidget
        setView={setView}
        view={view.viewEmail}
        project_code={selectedFormData.project_code}
        process_type={selectedFormData.process_type_name}
        process_type_id={selectedFormData.process_type_id}
      />
    </>
  );
};

export default TestModuleWidgets;
