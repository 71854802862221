import React, { useState, useEffect } from "react";
import Header from "../Includes/Header";
import Footer from "../Includes/Footer";
import Loader from "../Utility/Loader";
import Projects from "../Pages/Projects";
import Module from "../Pages/Module";
import Attributes from "../Pages/Attributes";
import { Route, Switch, useHistory } from 'react-router-dom';
import AddRule from "../Pages/AddRule";
import AssignAttribute from "../Pages/AssignAttribute";
import ListRule from "../Pages/ListRule";
import HttpClients from "../Pages/HttpClients";
import AssignAttributeList from "../Pages/AssignAttributeList";
import EmailDataList from "../Pages/EmailDataList";
import ProcessTypes from "../Pages/ProcessTypes";
import ListProcessType from "../Pages/ListProcessType";
import ListHttpClients from "../Pages/ListHttpClients";
import Lead_datas from "../Pages/Lead_datas";
import Schedules from "../Pages/Schedules";
import ListLeadDatas from "../Pages/ListLeadDatas";
import TestModuleWidgets from "../ModuleWidgets/TestModuleWidgets";
import UserManagement from "../Pages/UserManagement";
import SmsDataList from "../Pages/SmsDataList";
import SplitFLow from "../Pages/SplitFlow";
import ListSplitFlow from "../Pages/ListSplitFlow";
import { useAuth } from "../Utility/AuthContext";
import ProjectConfiguration from "../Pages/ProjectConfiguration";
import ListProjectConfiguration from "../Pages/ListProjectConfiguration";
import LeadSummaryReport from "../Pages/Reports/LeadSummaryReport";
import URLPreviewEditor from "../Pages/URLPreviewEditor";
import Templates from "../Pages/Templates";
import GtmDataLayerpage from "../Pages/GtmDataLayerpage";
import AddFollowupRules from "../Pages/AddFollowupRules";
import ListFollowupRule from "../Pages/ListFollowupRule";
import GlobalLeadsReport from "../Pages/Reports/GlobalLeadsReport";

const Layout = () => {
  const history = useHistory();
  const user = useAuth();
  if (user.token == '' || user.token == undefined || user.token == null) return history.push('/login');
  
  return (
    <>
      <Header />
      {/* <Loader active={isLoading} /> */}
      <Switch>
        <Route exact path="/projects" component={Projects} />
        <Route exact path="/module" component={Module} />
        <Route exact path="/attributes" component={Attributes} />
        <Route exact path="/add-rule" component={AddRule} />
        <Route exact path="/assign-attribute" component={AssignAttribute} />
        <Route exact path="/assign-attribute-list" component={AssignAttributeList} />
        <Route exact path="/list-rule" component={ListRule} />
        <Route exact path="/http-clients" component={HttpClients} />
        <Route exact path="/process-types" component={ProcessTypes} />
        <Route exact path="/list-process-types" component={ListProcessType} />
        <Route exact path="/list-http-clients" component={ListHttpClients} />
        <Route exact path="/lead-datas" component={Lead_datas} />
        <Route exact path="/list-lead-datas" component={ListLeadDatas} />
        <Route exact path="/schedules" component={Schedules} />
        <Route exact path="/Test-Module-Widgets" component={TestModuleWidgets} />
        <Route exact path="/email-data-list" component={EmailDataList} />
        <Route exact path="/user-management" component={UserManagement} />
        <Route exact path="/sms-data-list" component={SmsDataList}/>
        <Route exact path="/list-split-flow" component={ListSplitFlow}/>
        <Route exact path="/add-split-flow" component={SplitFLow}/>
        <Route exact path="/project-configuration" component={ProjectConfiguration}/>
        <Route exact path="/list-project-configuration" component={ListProjectConfiguration}/>
        <Route exact path="/report/lead-summary" component={LeadSummaryReport}/>
        <Route exact path="/preview" component={URLPreviewEditor}/>
        <Route exact path="/templates" component={Templates} />
        <Route exact path="/gtm-data-layer" component={GtmDataLayerpage}/>
        <Route exact path="/add-followup-rules" component={AddFollowupRules}/>
        <Route exact path="/followup-rules" component={ListFollowupRule} />
        <Route exact path="/report/global_leads" component={GlobalLeadsReport}/>
      </Switch>
      <Footer />
    </>
  )
}

export default Layout;