import React, { useEffect, useState } from "react";
import Button from "../../UI/Button";
import TextField from "../../UI/TextField";
import Modal from 'react-bootstrap/Modal';
import "../../../assets/css/popup.scss";
import { useProjectCodeInfo } from "../../../Hooks/useProjectCodeInfo";
import Loader from "../../Utility/Loader";
import ProjectSelectBox from "../../UI/ProjectSelectBox";
import {useProcessType} from "../../../Hooks/useProcessType";
import SelectBox from "../../UI/SelectBox";
import { Status} from "../../../Constants/Status";
import { useSchedules } from "../../../Hooks/useSchedules";
import GenerateSlug from "../../Utility/GenerateSlug";

const TogglePopup = (props) => {
  const [show, setShow] = useState(false);
  const [projectCode, setProjectCode] = useState({});
  const [ProcessType, setProcessType] = useState({});
  const [ProcessName, setProcessName] = useState({processType: [],});
  const [formData, setFormData] = useState({ 
    cronName: '', 
    slug: '', 
    projectCode: '',
    ProcessType:'',
    ProcessTypeLabel:'',
    cronTab:'',
    status:'Active',
    ScheduleId:''
  });

  const [errors, setErrors] = useState({});
  const { listProjectCode } = useProjectCodeInfo();
  const { listProcess }  = useProcessType();
  const { createSchedules, updateSchedules,listSchedules, isLoading } = useSchedules();

  const listSchedule = async () => {
    const response = await listSchedules();
    const listData = response.data.map(item => ({
      _id: item._id,
        cron_name: item.name,
        slug: item.slug,
        project_code: item.project_code,
        process_type: item.process_type,
        cron_tab: item.cron_tab,
        status: item.status,
        process_type_id: item.process_type_id,
    }));
  
    const keyword = props.filterText.toLowerCase();
    const filteredData = listData.filter(
      (item) =>
      item._id.toString().toLowerCase().includes(keyword) ||
      item.cron_name.toLowerCase().includes(keyword) ||
      item.project_code.toString().toLowerCase().includes(keyword)||
      item.status.toString().toLowerCase().includes(keyword)
    );
  
    props.setFilteredData(filteredData);
    props.setFilterText(keyword);
    props.setData(listData);
  };

  const ProcessTypeStatus = Status.map((item) => ({
    label: item.name,
    value: item.value,
  }));
  
const uniqueProcessTypes = [...new Set(ProcessName.processType.map(type => type.value))].map(value => ProcessName.processType.find(type => type.value === value));

  const handleClose = () => {
    setShow(false);
    props.setShowEdit(false);
    props.setshowCode('');
    props.setShowName('');
    setFormData({
      cronName: '',
      slug: '',
      projectCode: '',
      ProcessType: '',
      ProcessTypeLabel: '',
      cronTab: '',
      status: 'Active',
      ScheduleId: ''
    });
  }
  const handleShow = () => {
    setShow(true);
  }
  const handleInputChange = (event) => {
    setErrors({});
    const { name, value } = event.target;
    switch (name) {
      case "projectCode":
        const matchingProcessTypes = ProcessType.filter(item => item.projectCode === value);
        const formattedValues = {
          processType: matchingProcessTypes.map((type) => ({
            label: type.label,
            value: type.value,
          })),
        };
        setProcessName(formattedValues);
        //const prcNme = ProcessType.filter(item => item.projectCode === value);
        //const prcNme = ProcessType.find((item)=>item.projectCode == value) ?  ProcessType.find((item)=>item.projectCode == value) : { "": ""} ;
        //setProcessName([prcNme]);
        setFormData({
          ...formData,
          [name]: value
        });
        break;
    
      default:
        const response = (name == 'cronName' ) ? GenerateSlug(value) : '';
        setFormData({
          ...formData,
          [name]: value,
          ...(response !== '' ? { slug: response.slugValue } : {})
        });
        break;
    }
  }
  const handleSelectBoxChange = (event) => {
    const selectedValue = event.target.value;
    if(selectedValue !== "") {
    const { name, value } = event.target;
    const selectedIndex = event.target.selectedIndex;
    const selectedOptionLabel = (event.target.options)?event.target.options[selectedIndex].text:'';
    setFormData({
      ...formData,
      [name]: value,
      ProcessTypeLabel:selectedOptionLabel
    });
  }
  }

  const handleSubmit = () => {
    const newErrors = {};
    if (formData.cronName.trim() === '') {
      newErrors.cronName = 'Cron Name is required';
    } else if (formData.slug.trim() === '') {
      newErrors.slug = 'Slug is required';
    } else if (formData.projectCode.trim() === '') {
      newErrors.projectCode = 'ProjectCode is required';
    }else if (formData.ProcessType.trim() === '') {
      newErrors.ProcessType = 'Process Type is required';
    }else if (formData.cronTab.trim() === '') {
      newErrors.cronTab = 'Cron Tab is required';
    }else if (formData.status.trim() === '') {
      newErrors.status = 'Status is required';
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      (async () => {
        handleClose();
        await createSchedules(formData);
        props.setToggleListing(!props.toggleListing);
      })();
      listSchedule();
    }
  };
  const EditSubmit = (event) => {
    event.preventDefault();
    const newErrors = {};
    if (formData.cronName.trim() === '') {
      newErrors.cronName = 'Cron Name is required';
    } else if (formData.slug.trim() === '') {
      newErrors.slug = 'Slug is required';
    } else if (formData.projectCode.trim() === '') {
      newErrors.projectCode = 'ProjectCode is required';
    }else if (formData.ProcessType.trim() === '') {
      newErrors.ProcessType = 'Process Type is required';
    }else if (formData.cronTab.trim() === '') {
      newErrors.cronTab = 'Cron Tab is required';
    }else if (formData.status.trim() === '') {
      newErrors.status = 'Status is required';
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
     
    (async () => {
        await updateSchedules(formData);
        handleClose();
        props.setToggleListing(!props.toggleListing);
    })();
    listSchedule();
  }
  };
  useEffect(() => {
    (async () => {
      const response = await listProjectCode();
      const projectData = response.data.map(item => ({
        id: item.id,
        project_name: item.project_name,
        project_code: item.project_code
      }));
      const processTypeResponse = await listProcess();
      const prcessTypeData = processTypeResponse.data.map(item => ({
        value: item._id,
        label: item.name,
        projectCode:item.project_code
      }));
      setProjectCode(projectData);
      setProcessType(prcessTypeData);
    })();
  }, []);
  useEffect(()=>{
    // for inserting editScheduleData to formData
    if (props.showEdit) {
      const selectedProcessType = ProcessType.filter(item => item.projectCode === props.editScheduleData.project_code);
      const prcName = ProcessType.find(item => item.value === props.editScheduleData.process_type_id
        );

      const formattedValues = {
          processType: selectedProcessType.map((type) => ({
            label: type.label,
            value: type.value,
          })),
        };
        setProcessName(formattedValues);
      setFormData({
        cronName: props.editScheduleData.cron_name,
        slug: props.editScheduleData.slug,
        projectCode: props.editScheduleData.project_code,
        // ProcessType: selectedProcessType ? selectedProcessType.label : "",
        ProcessType:prcName.value?prcName.value : props.editScheduleData.process_type,
        process_type_id:props.editScheduleData.process_type_id,
        ProcessTypeLabel: props.editScheduleData.process_type,
        cronTab: props.editScheduleData.cron_tab,
        status: props.editScheduleData.status,
        ScheduleId: props.editScheduleData._id
      })
    }
  },[props.showEdit])

  
  return (
    <>
      <Loader active={isLoading} />
      <div className="toggle-popup">
        <Button onClick={handleShow} className="button_a button2" label="Add Schedule" />
        <Modal show={show || props.showEdit} onHide={handleClose} dialogClassName="toggle-popup" backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>{show && !props.showEdit ? `Add Schedule` : `Edit Schedule`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="col-lg-6">
                Name
              </div>
              <div className="col-lg-12">
                <TextField
                  type="text"
                  className="form-control"
                  name="cronName"
                  value={formData.cronName}
                  onChange={(e) => handleInputChange(e)}
                  error={errors.cronName}
                />
              </div><br />
              <div className="col-lg-6">
                Slug
              </div>
              <div className="col-lg-12">
                <TextField
                  type="text"
                  className="form-control"
                  name="slug"
                  value={formData.slug}
                  onChange={(e) => handleInputChange(e)}
                  error={errors.slug}
                />
              </div><br/>
              <div className="col-lg-6">
                Project Code
              </div>
              <ProjectSelectBox
                className="form-select"
                name="projectCode"
                emptyOption={formData.projectCode? formData.projectCode : "Select project code"}
                OptionValue={projectCode}
                onChange={(e) => handleInputChange(e)}
                error={errors.projectCode}
              /><br/>
              <div className="col-lg-6">
                Process Type
              </div>
              <div className="col-lg-12">

                <select
                className="form-select"
                name="ProcessType"
                value={formData.ProcessType}
                onChange={(e) => handleSelectBoxChange(e)}
                error={errors.ProcessType}
                >
                <option value="">Select project Type</option>
                {uniqueProcessTypes.map(option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                ))}
               </select>
 
              {/* <SelectBox
                className="form-select"
                name="ProcessType"
                //emptyOptionVal={props.editScheduleData.process_type_id ? props.editScheduleData.process_type_id : "Select Process Type"}
                emptyOption={formData.ProcessTypeLabel ? formData.ProcessTypeLabel : "Select project Type"}
                // OptionValue={ProcessName.processType}
                // OptionValue={uniqueProcessTypes}
                onChange={(e) => handleSelectBoxChange(e)}
                error={errors.ProcessType}
                // value={formData.ProcessType ? formData.ProcessType : props.editScheduleData.process_type_id}
              /> */}




              </div><br/>
              <div className="col-lg-6">
                Cron Tab
              </div>
              <div className="col-lg-12">
                <TextField
                  type="text"
                  className="form-control"
                  name="cronTab"
                  value={formData.cronTab}
                  onChange={(e) => handleInputChange(e)}
                  error={errors.cronTab}
                />
              </div><br/>
              <div className="col-lg-6">
                Status
              </div>
              <select
                  className="form-select"
                  name="status"
                  value={formData.status}
                  onChange={(e) => handleInputChange(e)}
                  error={errors.status}
                >
                  {ProcessTypeStatus.map(option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                  ))}
                </select>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <div className="col-lg-8">
              {!props.showEdit ? (<Button className="button_a button2" label="Add" onClick={handleSubmit} />) : (<Button className="button_a button2" label="Update" onClick={EditSubmit} />)}
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}

export default TogglePopup