import { Api } from "../api/Api";
import { useState } from 'react';

export const useMultipleDelete = () => {
    const [isLoading, setIsLoading] = useState(false);
    const deleteMultipleItems = async (id, model) => {
        setIsLoading(true);
        try {
          const response = await Api.post(`api/v1/delete-multiple`, {
            id,
            model,
          });
          setIsLoading(false);
          return response;
        } catch (error) {
          setIsLoading(false);
          throw error;
        }
      };

    return {deleteMultipleItems,  isLoading };
};
